import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getAccountType, isLoggedIn, isStaff } from "../auth/functions";

import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Divider from "@mui/material/Divider";
import DoneIcon from "@mui/icons-material/Done";
import Dropzone from "../common/Dropzone";
import EditableTextField from "../common/EditableTextField";
import Fab from "@mui/material/Fab";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";
import IconButton from "@mui/material/IconButton";
import { CustomInfiniteScroll as InfiniteScroll } from "../common/CustomInfiniteScroll";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import JobCard from "../jobs/JobCard";
import Link from "@mui/material/Link";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MapRoundedIcon from "@mui/icons-material/MapRounded";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import OutlinedInput from "@mui/material/OutlinedInput";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import PublicRoundedIcon from "@mui/icons-material/PublicRounded";
import Rating from "@mui/material/Rating";
import Skeleton from "@mui/material/Skeleton";
import { SocialIcon } from "react-social-icons";
import Stack from "@mui/material/Stack";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import axiosInstance from "../axiosInstance";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useParams } from "react-router-dom";
import { usePrompt } from "../common/ReactRouterBlocker";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";

const EmployerJobsList = ({ employerId }) => {
    const [jobs, setJobs] = useState([]);
    const nextData = useRef("/api/jobs/?employer=" + employerId + "&ordering=-rolling_deadline,-application_deadline,-premium");
    const [hasMore, setHasMore] = useState(false);
    const [totalItems, setTotalItems] = useState(0);

    const fetchMoreData = useCallback(() => {
        axiosInstance.get(nextData.current).then((response) => {
            setJobs((items) => items.concat(response.data.results));
            setTotalItems(response.data.count);

            var next = "";
            if (response.data.next) {
                let next_queries = response.data.next.split("?");
                next = next_queries[next_queries.length - 1];
                nextData.current = "/api/jobs/?" + next;
                setHasMore(true);
            } else {
                nextData.current = undefined;
                setHasMore(false);
            }
        });
    }, []);

    useEffect(() => {
        fetchMoreData();
    }, [fetchMoreData]);

    return (
        <Box>
            <Typography
                variant="subtitle"
                color="primary"
                align="center"
                sx={{ display: "block", padding: 0, paddingBottom: "20px" }}
            >
                {totalItems} job{totalItems === 1 ? "" : "s"}
            </Typography>
            <InfiniteScroll dataLength={jobs.length} next={fetchMoreData} hasMore={hasMore}>
                <Grid container spacing={2}>
                    {jobs.map((job) => (
                        <Grid item md={6} xs={12} key={job?.id}>
                            <JobCard job={job} />
                        </Grid>
                    ))}
                </Grid>
            </InfiniteScroll>
        </Box>
    );
};

const EmployerPage = ({ user, addBannerNotification }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    let params = useParams();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const [employer, setEmployer] = useState({});
    const [loading, setLoading] = useState(true);
    const [error404, setError404] = useState(false);
    const [edits, setEdits] = useState({});
    const [countryOptions, setCountryOptions] = useState([]);
    const [registrationLink, setRegistrationLink] = useState("");

    useEffect(() => {
        if (isStaff()) {
            axiosInstance
                .get(`/api/employers/${employer.id}/registration_token/`)
                .then((response) => {
                    setRegistrationLink(window.location.origin + "/signup/" + response.data);
                });
        }
    }, [employer, setRegistrationLink]);

    const [searchOptions, setSearchOptions] = useState([]);
    const isDirty = useMemo(() => {
        return (
            Object.keys(edits).length > 0 &&
            Object.keys(edits).some((key) => edits[key] !== employer[key])
        );
    }, [edits]);
    useEffect(() => {
        loadEmployerInfo();
    }, []);

    usePrompt(
        "Your changes have not been saved. Are you sure you want to leave this page?",
        isDirty
    );

    const loadEmployerInfo = () => {
        axiosInstance
            .get(`/api/employers/${params.employerId}/`)
            .then((response) => {
                setEmployer(response.data);
                setLoading(false);
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    setError404(true);
                }
            });
    };

    useEffect(() => {
        axiosInstance.get("/api/employers/search_options/").then((response) => {
            setSearchOptions(response.data);
        });

        axiosInstance.get("/api/employers/countries/").then((response) => {
            setCountryOptions(response.data);
        });
    }, []);

    const submitEdits = useCallback(() => {
        let formData = new FormData();
        for (let key in edits) {
            formData.append(key, edits[key]);
        }

        axiosInstance
            .patch(`/api/employers/${params.employerId}/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                setEmployer(response.data);
                setEdits({});
                enqueueSnackbar("Employer updated successfully.", { variant: "success" });
            })
            .catch((err) => {
                enqueueSnackbar("Error updating employer.", { variant: "error" });
            });
    }, [edits]);

    useEffect(() => {
        addBannerNotification(
            isDirty ? (
                <Typography align="center">
                    You have unsaved changes.
                    <Link
                        onClick={submitEdits}
                        sx={{
                            marginLeft: "5px",
                            cursor: "pointer",
                            color: theme.palette.highlight.main,
                        }}
                    >
                        Save changes
                    </Link>
                </Typography>
            ) : undefined,
            "dirty"
        );
        return () => {
            addBannerNotification(undefined, "dirty");
        };
    }, [isDirty, submitEdits]);

    useEffect(() => {
        // remove any keys from edits where the value is undefined
        if (Object.values(edits).some((e) => e === undefined)) {
            setEdits((oldEdits) => {
                let newEdits = { ...oldEdits };
                Object.keys(newEdits).forEach((key) => {
                    if (newEdits[key] === undefined) {
                        delete newEdits[key];
                    }
                });
                return newEdits;
            });
        }
    }, [edits]);

    const formatUrl = (url) => {
        if (url) {
            return url.replaceAll(/http:\/\/|www\.|https:\/\//g, "");
        }
        return "";
    };

    useEffect(() => {
        addBannerNotification(
            Object.keys(employer).length && employer?.can_edit
                ? "You can edit this employer."
                : undefined,
            "ownership"
        );
        return () => {
            addBannerNotification(undefined, "ownership");
        };
    }, [employer]);

    return (
        <PageContainer error404={error404}>
            <Helmet>
                {employer?.name ? <title>{employer?.name + " - SpaceCareers.uk"}</title> : null}
            </Helmet>
            <Breadcrumbs
                color={theme.palette.text.primary}
                separator={<NavigateNextIcon fontSize="small" />}
            >
                <Link underline="hover" color="inherit" href="/home">
                    Home
                </Link>
                <Link underline="hover" color="inherit" href="/employers/">
                    Employers
                </Link>
                <Typography color="inherit">
                    {loading ? <Skeleton width={300} /> : employer.name}
                </Typography>
            </Breadcrumbs>
            {employer?.can_edit ? (
                <Tooltip title="Save Changes">
                    <Fab
                        color="primary"
                        sx={{
                            position: "fixed",
                            bottom: "20px",
                            right: "40px",
                            zIndex: 10,
                        }}
                        onClick={submitEdits}
                        disabled={Object.keys(edits).length === 0}
                    >
                        <DoneIcon />
                    </Fab>
                </Tooltip>
            ) : null}
            <Box>
                {isLoggedIn() && getAccountType() === "candidate" ? (
                    <Tooltip title="Save">
                        <Rating
                            icon={<StarRoundedIcon fontSize="inherit" />}
                            emptyIcon={<StarBorderRoundedIcon fontSize="inherit" />}
                            sx={{
                                float: "right",
                                paddingTop: "10px",
                                paddingRight: "10px",
                            }}
                            value={employer.saved ? 1 : 0}
                            max={1}
                            onChange={(e, value) => {
                                let url =
                                    value === 1
                                        ? `/api/employers/${params.employerId}/save/`
                                        : `/api/employers/${params.employerId}/unsave/`;
                                axiosInstance.post(url).then((response) => {
                                    loadEmployerInfo();
                                });
                            }}
                        />
                    </Tooltip>
                ) : null}
                <Paper sx={{ padding: "20px" }}>
                    {loading ? (
                        <Grid container spacing={5}>
                            <Grid item xs={8}>
                                <Typography variant="h4">
                                    <Skeleton variant="text" />
                                </Typography>
                                <Typography variant="h6">
                                    <Skeleton variant="text" />
                                </Typography>
                                <Typography variant="h6">
                                    <Skeleton variant="text" />
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Skeleton variant="square" width={200} height={200} />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={2}>
                            {smallScreen ? (
                                <Grid item xs={12}>
                                    {employer?.can_edit ? (
                                        <Dropzone
                                            imageProps={{
                                                style: {
                                                    maxWidth: "100%",
                                                    height: 200,
                                                    objectFit: "scale-down",
                                                    margin: "auto",
                                                    display: "block",
                                                    marginBottom: "20px",
                                                },
                                                src: employer.logo,
                                                alt: "Employer",
                                            }}
                                            onChange={(file) => {
                                                setEdits({ ...edits, logo: file });
                                            }}
                                        />
                                    ) : (
                                        <img
                                            style={{
                                                maxWidth: "100%",
                                                height: 200,
                                                objectFit: "scale-down",
                                                margin: "auto",
                                                display: "block",
                                            }}
                                            src={employer.logo}
                                            alt="Employer"
                                        />
                                    )}
                                </Grid>
                            ) : null}
                            <Grid item md={8} xs={12}>
                                <Stack direction="row">
                                    <EditableTextField
                                        value={"name" in edits ? edits.name : employer.name}
                                        onChange={(value) => {
                                            setEdits({ ...edits, name: value });
                                        }}
                                        dirty={
                                            edits?.name !== employer.name &&
                                            edits?.name !== undefined
                                        }
                                        variant="h4"
                                        color="primary"
                                        editable={employer?.can_edit}
                                        tooltip="Employer Name"
                                    />
                                    <span
                                        style={{
                                            minWidth: "110px",
                                            flexDirection: "row",
                                            display: "flex",
                                        }}
                                    >
                                        <EditableTextField
                                            value={
                                                "linkedin" in edits
                                                    ? edits.linkedin
                                                    : employer.linkedin
                                            }
                                            onChange={(value) => {
                                                setEdits({
                                                    ...edits,
                                                    linkedin: value,
                                                });
                                            }}
                                            dirty={
                                                edits?.linkedin !== employer.linkedin &&
                                                edits?.linkedin !== undefined
                                            }
                                            color="info"
                                            sx={{ fontWeight: 600 }}
                                            editable={employer?.can_edit}
                                            tooltip="LinkedIn URL"
                                        >
                                            {employer?.linkedin || edits?.linkedin ? (
                                                <SocialIcon
                                                    url={
                                                        "linkedin" in edits
                                                            ? edits.linkedin
                                                            : employer.linkedin
                                                    }
                                                    network="linkedin"
                                                    style={{
                                                        width: "31px",
                                                        height: "31px",
                                                        margin: "5px",
                                                        marginLeft: "20px",
                                                    }}
                                                />
                                            ) : null}
                                        </EditableTextField>

                                        <EditableTextField
                                            value={
                                                "twitter_handle" in edits
                                                    ? edits.twitter_handle
                                                    : employer.twitter_handle
                                            }
                                            onChange={(value) => {
                                                setEdits({
                                                    ...edits,
                                                    twitter_handle: value,
                                                });
                                            }}
                                            dirty={
                                                edits?.twitter_handle !== employer.twitter_handle &&
                                                edits?.twitter_handle !== undefined
                                            }
                                            color="info"
                                            sx={{ fontWeight: 600 }}
                                            editable={employer?.can_edit}
                                            tooltip="Twitter Handle"
                                        >
                                            {employer?.twitter_handle ? (
                                                <SocialIcon
                                                    url={
                                                        "https://www.twitter.com/" +
                                                        ("twitter_handle" in edits
                                                            ? edits.twitter_handle
                                                            : employer.twitter_handle)
                                                    }
                                                    network="twitter"
                                                    style={{
                                                        width: "31px",
                                                        height: "31px",
                                                        margin: "5px",
                                                        marginLeft: "20px",
                                                    }}
                                                />
                                            ) : null}
                                        </EditableTextField>
                                    </span>
                                </Stack>
                                <Stack>
                                    <EditableTextField
                                        value={
                                            "website" in edits ? edits.website : employer.website
                                        }
                                        onChange={(value) => {
                                            setEdits({ ...edits, website: value });
                                        }}
                                        dirty={
                                            edits?.website !== employer.website &&
                                            edits?.website !== undefined
                                        }
                                        color="info"
                                        sx={{ fontWeight: 600 }}
                                        editable={employer?.can_edit}
                                        tooltip="Website"
                                    >
                                        <Link
                                            variant="h6"
                                            href={
                                                "website" in edits
                                                    ? edits.website
                                                    : employer.website
                                            }
                                        >
                                            {formatUrl(
                                                "website" in edits
                                                    ? edits.website
                                                    : employer.website
                                            )}
                                        </Link>
                                    </EditableTextField>
                                    <Stack
                                        direction={!smallScreen ? "row" : "column"}
                                        spacing={2}
                                        sx={{ paddingTop: "24px" }}
                                    >
                                        {employer.org_type || employer.can_edit ? (
                                            <Stack direction="row" spacing={1}>
                                                <Tooltip title="Organisation Type">
                                                    <BusinessRoundedIcon />
                                                </Tooltip>
                                                <EditableTextField
                                                    value={
                                                        "org_type" in edits
                                                            ? edits.org_type
                                                            : employer.org_type
                                                    }
                                                    onChange={(value) => {
                                                        setEdits({ ...edits, org_type: value });
                                                    }}
                                                    dirty={
                                                        edits?.org_type !== employer.org_type &&
                                                        edits?.org_type !== undefined
                                                    }
                                                    variant="Subtitle2"
                                                    color="text"
                                                    editable={employer?.can_edit}
                                                    selectValues={searchOptions?.org_type?.map(
                                                        (option) => [option, option]
                                                    )}
                                                    sx={{ lineHeight: "26px" }}
                                                    tooltip="Organisation Type"
                                                />
                                            </Stack>
                                        ) : null}
                                        {employer.activity || employer.can_edit ? (
                                            <Stack direction="row" spacing={1}>
                                                <Tooltip title="Activity">
                                                    <WorkOutlineOutlinedIcon />
                                                </Tooltip>
                                                <EditableTextField
                                                    value={
                                                        employer.can_edit
                                                            ? "activity" in edits
                                                                ? edits.activity
                                                                : employer.activity
                                                            : employer?.activity
                                                    }
                                                    onChange={(value) => {
                                                        setEdits({ ...edits, activity: value });
                                                    }}
                                                    dirty={
                                                        edits?.activity !== employer.activity &&
                                                        edits?.activity !== undefined
                                                    }
                                                    variant="Subtitle2"
                                                    color="text"
                                                    editable={employer?.can_edit}
                                                    selectValues={searchOptions?.activity?.map(
                                                        (option) => [option, option]
                                                    )}
                                                    sx={{ lineHeight: "26px" }}
                                                    tooltip="Activity"
                                                />
                                            </Stack>
                                        ) : null}
                                    </Stack>
                                    <Stack
                                        direction={!smallScreen ? "row" : "column"}
                                        spacing={2}
                                        sx={{ paddingTop: "24px" }}
                                    >
                                        {employer.location || employer.can_edit ? (
                                            <Stack direction="row" spacing={1}>
                                                <Tooltip title="Location">
                                                    <LocationOnIcon />
                                                </Tooltip>
                                                <EditableTextField
                                                    value={
                                                        "location" in edits
                                                            ? edits.location
                                                            : employer.location
                                                    }
                                                    onChange={(value) => {
                                                        setEdits({ ...edits, location: value });
                                                    }}
                                                    dirty={
                                                        edits?.location !== employer.location &&
                                                        edits?.location !== undefined
                                                    }
                                                    variant="Subtitle2"
                                                    color="text"
                                                    editable={employer?.can_edit}
                                                    sx={{ lineHeight: "26px" }}
                                                    tooltip="Location"
                                                />
                                            </Stack>
                                        ) : null}
                                        {employer.region || employer.can_edit ? (
                                            <Stack direction="row" spacing={1}>
                                                <Tooltip title="Region">
                                                    <MapRoundedIcon />
                                                </Tooltip>
                                                <EditableTextField
                                                    value={
                                                        "region" in edits
                                                            ? edits.region
                                                            : employer.region
                                                    }
                                                    onChange={(value) => {
                                                        setEdits({ ...edits, region: value });
                                                    }}
                                                    dirty={
                                                        edits?.region !== employer.region &&
                                                        edits?.region !== undefined
                                                    }
                                                    variant="Subtitle2"
                                                    color="text"
                                                    editable={employer?.can_edit}
                                                    selectValues={searchOptions?.region?.map(
                                                        (option) => [option, option]
                                                    )}
                                                    sx={{ lineHeight: "26px" }}
                                                    tooltip="Region"
                                                />
                                            </Stack>
                                        ) : null}
                                        {employer.country || employer.can_edit ? (
                                            <Stack direction="row" spacing={1}>
                                                <Tooltip title="Country">
                                                    <PublicRoundedIcon />
                                                </Tooltip>
                                                <EditableTextField
                                                    value={
                                                        "country" in edits
                                                            ? edits.country
                                                            : employer.country
                                                    }
                                                    onChange={(value) => {
                                                        setEdits({ ...edits, country: value });
                                                    }}
                                                    dirty={
                                                        edits?.country !== employer.country &&
                                                        edits?.country !== undefined
                                                    }
                                                    variant="Subtitle2"
                                                    color="text"
                                                    editable={employer?.can_edit}
                                                    selectValues={countryOptions.map((option) => [
                                                        option[0],
                                                        option[1],
                                                    ])}
                                                    sx={{ lineHeight: "26px" }}
                                                    tooltip="Country"
                                                />
                                            </Stack>
                                        ) : null}
                                    </Stack>
                                </Stack>
                            </Grid>
                            {smallScreen ? null : (
                                <Grid item sm={4}>
                                    {employer?.can_edit ? (
                                        <Dropzone
                                            imageProps={{
                                                style: {
                                                    width: 200,
                                                    height: 200,
                                                    objectFit: "scale-down",
                                                    margin: "auto",
                                                    display: "block",
                                                },
                                                src: employer.logo,
                                                alt: "Employer",
                                            }}
                                            onChange={(file) => {
                                                setEdits({ ...edits, logo: file });
                                            }}
                                        />
                                    ) : (
                                        <img
                                            style={{
                                                width: 200,
                                                height: 200,
                                                objectFit: "scale-down",
                                                margin: "auto",
                                                display: "block",
                                            }}
                                            src={employer.logo}
                                            alt="Employer"
                                        />
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Paper>
            </Box>
            <Grid container spacing={2}>
                {registrationLink && isStaff() ? (
                    <Grid item xs={12}>
                        <Paper sx={{ padding: "20px" }}>
                            <FormControl variant="outlined">
                                <InputLabel>Registration link</InputLabel>
                                <OutlinedInput
                                    value={registrationLink}
                                    disabled
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Tooltip title="Copy to clipboard">
                                                <IconButton
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(
                                                            registrationLink
                                                        );
                                                        enqueueSnackbar("Link copied", {
                                                            variant: "success",
                                                        });
                                                    }}
                                                    edge="end"
                                                >
                                                    <ContentCopyIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    }
                                    label="Registration link"
                                />
                            </FormControl>
                        </Paper>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <Paper sx={{ padding: "20px" }}>
                        {loading ? (
                            <span>
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                            </span>
                        ) : (
                            <EditableTextField
                                value={"about" in edits ? edits.about : employer.about}
                                onChange={(value) => {
                                    setEdits({ ...edits, about: value });
                                }}
                                dirty={
                                    edits?.about !== employer.about && edits?.about !== undefined
                                }
                                markdown
                                editable={employer?.can_edit}
                                markdownEditorExclude={user?.premium ? [] : ["imageInsert"]}
                            />
                        )}
                    </Paper>
                </Grid>
            </Grid>
            <Divider sx={{ borderColor: "rgba(255,255,255, 0.5)" }} />
            <EmployerJobsList employerId={params.employerId} />
        </PageContainer>
    );
};

export default EmployerPage;
