import { Navigate, useLocation } from "react-router-dom";

import Button from "@mui/material/Button";
import PageContainer from "./../common/PageContainer";
import Paper from "@mui/material/Paper";
import React from "react";
import Typography from "@mui/material/Typography";
import axiosInstance from "../axiosInstance";

const EmailVerificationPage = () => {
    let location = useLocation();
    const email = location?.state?.email;

    const handleResend = (event) => {
        axiosInstance
            .post("/api/auth/users/resend_activation/", {
                email: email,
            })
            .then((response) => {
                console.log(email);
            })
            .catch((error) => {
                throw error;
            });
    };

    return !email ? (
        <Navigate to="/login" />
    ) : (
        <PageContainer maxWidth="sm">
            <Paper>
                <Typography variant="h6" paragraph>
                    An email has been sent to verify your email address. Please follow the link
                    contained in this email to activate your account. If you don't see the email, be
                    sure to check your spam folder.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleResend}
                    sx={{ margin: "auto", display: "block" }}
                >
                    Resend email
                </Button>
            </Paper>
        </PageContainer>
    );
};
export default EmailVerificationPage;
