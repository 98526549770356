import InfiniteScroll from "react-infinite-scroll-component";
import React from "react";
import Typography from "@mui/material/Typography";

export const CustomInfiniteScroll = (props) => {
    return (
        <InfiniteScroll
            loader={
                <Typography
                    color={props.textColor || "secondary"}
                    align="center"
                    style={{ marginTop: "0.8em" }}
                >
                    {props.loadingText || "Loading..."}
                </Typography>
            }
            endMessage={
                <Typography
                    color={props.textColor || "secondary"}
                    align="center"
                    style={{ marginTop: "0.8em" }}
                >
                    {props.endText || "That's all we have!"}
                </Typography>
            }
            {...props}
        />
    );
};
