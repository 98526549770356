import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useDropzone } from "react-dropzone";

const baseStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "4px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    transition: "border .3s ease-in-out",
};

const activeStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

function Dropzone({ editable = true, imageProps = {}, styleOverrides = {}, onChange = () => {} }) {
    const [file, setFile] = useState(undefined);
    const initialRender = useRef(true);

    const onDrop = useCallback((acceptedFiles) => {
        setFile(
            Object.assign(acceptedFiles[0], {
                preview: URL.createObjectURL(acceptedFiles[0]),
            })
        );
    }, []);

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        accept: "image/jpeg, image/png",
        maxFiles: 1,
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
            ...styleOverrides,
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const thumb = (
        <div key={file?.name}>
            <img
                {...imageProps}
                src={file?.preview || imageProps.src}
                alt={file?.name || imageProps.alt}
            />
        </div>
    );

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            onChange(file);
        }
    }, [file]);

    // clean up
    useEffect(
        () => () => {
            URL.revokeObjectURL(file?.preview);
        },
        [file]
    );

    return editable ? (
        <section>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {file || imageProps.src ? (
                    thumb
                ) : (
                    <p>Drag 'n' drop some files here, or click to select files</p>
                )}
            </div>
        </section>
    ) : (
        <img {...imageProps} />
    );
}

export default Dropzone;
