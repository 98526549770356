import Card from "@mui/material/Card";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const AuthorCard = ({ author }) => {
    return (
        <Card sx={{ padding: "15px" }}>
            <Stack spacing={2}>
                <Typography variant="h5" color="text">
                    Author
                </Typography>
                {author?.image ? (
                    <img
                        style={{
                            maxWidth: "100%",
                            maxHeight: "150px",
                            objectFit: "contain",
                            objectPosition: "left",
                        }}
                        src={author?.image}
                        alt="Article"
                    />
                ) : null}
                <Typography variant="h5" color="primary">
                    {author?.name}
                </Typography>
                <Typography variant="subtitle">{author?.bio}</Typography>
            </Stack>
        </Card>
    );
};

export default AuthorCard;
