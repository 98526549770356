import React, { useCallback, useMemo, useState } from "react";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TabPanel from "../common/TabPanel";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import axiosInstance from "../axiosInstance";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const NotificationsPanel = ({ updateUser, user, ...props }) => {
    const [edits, setEdits] = useState({});
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const submitEdits = useCallback(() => {
        let formData = new FormData();
        formData.append(
            `profile.preferences`,
            JSON.stringify(_.merge(_.cloneDeep(user.profile.preferences), { notifications: edits }))
        );
        submitFormData(formData);
    }, [edits]);

    const editsDirty = useMemo(() => {
        return !_.isEqual(
            user.profile.preferences.notifications,
            _.merge(_.cloneDeep(user.profile.preferences.notifications), edits)
        );
    }, [edits, user]);

    const submitFormData = (formData) => {
        const notificationId = enqueueSnackbar("Updating profile...", {
            variant: "info",
            persist: true,
        });
        axiosInstance
            .patch(`/api/users/${user.id}/`, formData)
            .then((response) => {
                updateUser();
                setEdits({});

                closeSnackbar(notificationId);
                enqueueSnackbar("Profile updated successfully.", { variant: "success" });
            })
            .catch(() => {
                closeSnackbar(notificationId);
                enqueueSnackbar("Error updating profile.", { variant: "error" });
            });
    };
    const basicNotifications = [
        "job.new_job_from_saved_employer",
        "job.saved_job_closing_soon",
        "job.saved_job_closed",
        "job.saved_job_edited",
        "job.saved_job_deadline_changed",
        "job.saved_job_removed",
        "job.new_job_matches_profile",
        "event.event_added",
        "employer.new_employer",
    ];

    return (
        <TabPanel {...props}>
            <Grid container>
                <Grid item xs={4}>
                    <Typography variant="h6" mb={2}>
                        Notification Type
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h6"> Web</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h6"> Email</Typography>
                </Grid>
                <Grid item xs={4}></Grid>
                {basicNotifications.map((key) => (
                    <NotificationsRow
                        preferenceKey={key}
                        key={key}
                        preferences={user?.profile?.preferences?.notifications}
                        edits={edits}
                        setEdits={setEdits}
                    />
                ))}
            </Grid>
            <Stack direction="row" spacing={2} mt={2}>
                <Button variant="contained" onClick={submitEdits} disabled={!editsDirty}>
                    Save
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        setEdits({});
                    }}
                    disabled={!editsDirty}
                >
                    Reset
                </Button>
            </Stack>
        </TabPanel>
    );
};

const NotificationsRow = ({ preferenceKey, preferences, edits, setEdits }) => {
    const { t, i18n } = useTranslation();
    const web = useMemo(() => {
        return _.defaultTo(edits?.[preferenceKey]?.web, preferences?.[preferenceKey]?.web);
    }, [preferences, preferenceKey, edits]);
    const email = useMemo(() => {
        return _.defaultTo(edits?.[preferenceKey]?.email, preferences?.[preferenceKey]?.email);
    }, [preferences, preferenceKey, edits]);

    return (
        <>
            <Grid item xs={4}>
                {t("notifications." + preferenceKey)}
            </Grid>
            <Grid item xs={2}>
                <Checkbox
                    checked={web}
                    onChange={(event) => {
                        setEdits(
                            _.cloneDeep(
                                _.merge(edits, { [preferenceKey]: { web: event.target.checked } })
                            )
                        );
                    }}
                ></Checkbox>
            </Grid>
            <Grid item xs={2}>
                <Checkbox
                    checked={email}
                    onChange={(event) => {
                        setEdits(
                            _.cloneDeep(
                                _.merge(edits, { [preferenceKey]: { email: event.target.checked } })
                            )
                        );
                    }}
                ></Checkbox>
            </Grid>
            <Grid item xs={4}></Grid>
        </>
    );
};

export default NotificationsPanel;
