import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";

import Button from "@mui/material/Button";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axiosInstance from "../axiosInstance";
import { useSnackbar } from "notistack";

const PasswordChangePage = () => {
    const { enqueueSnackbar } = useSnackbar();
    let params = useParams();
    let navigate = useNavigate();
    const [currentPassword, setCurrentPassword] = useState("");
    const [password, setPassword] = useState("");
    const [rePassword, setRePassword] = useState("");
    const [errors, setErrors] = useState({});

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        axiosInstance
            .post("/api/auth/users/set_password/", {
                uid: params.uid,
                token: params.token,
                current_password: currentPassword,
                new_password: password,
                re_new_password: rePassword,
            })
            .then((response) => {
                if (response !== undefined) {
                    enqueueSnackbar("Password changed successfully", { variant: "success" });
                    navigate("/home");
                } else {
                    // Token expired during request, retry
                    handleSubmit();
                }
            })
            .catch((error) => {
                enqueueSnackbar("There was a problem change your password", { variant: "error" });
                setErrors(error.response.data);
            });
    };

    return (
        <PageContainer maxWidth="sm">
            <Paper>
                <Typography variant="h4" paragraph>
                    Change password
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={1}>
                        <TextField
                            size="small"
                            error={errors.current_password}
                            helperText={errors.current_password}
                            type="password"
                            placeholder="Current password"
                            fullWidth
                            name="rePassword"
                            variant="outlined"
                            value={currentPassword}
                            onChange={(event) => setCurrentPassword(event.target.value)}
                            required
                        />
                        <TextField
                            size="small"
                            error={errors.new_password || errors.non_field_errors}
                            helperText={errors.new_password || errors.non_field_errors}
                            type="password"
                            placeholder="New password"
                            fullWidth
                            name="password"
                            variant="outlined"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            required
                        />
                        <TextField
                            size="small"
                            error={errors.new_re_password || errors.non_field_errors}
                            helperText={errors.new_re_password || errors.non_field_errors}
                            type="password"
                            placeholder="Retype new password"
                            fullWidth
                            name="rePassword"
                            variant="outlined"
                            value={rePassword}
                            onChange={(event) => setRePassword(event.target.value)}
                            required
                        />
                        <Button variant="contained" color="primary" type="submit">
                            Submit
                        </Button>
                    </Stack>
                </form>
            </Paper>
        </PageContainer>
    );
};
export default PasswordChangePage;
