import React, { useEffect, useMemo } from "react";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";

function getStyles(item, selectedItems, theme) {
    // change style for selected list items
    return selectedItems?.indexOf(item) === -1
        ? { fontWeight: theme.typography.fontWeightRegular, color: theme.palette.primary.main }
        : {
              fontWeight: theme.typography.fontWeightRegular,
              color: theme.palette.secondary.main,
              backgroundColor: theme.palette.primary.main,
          };
}

const FilterSelect = ({
    options = [],
    onChange,
    label = "Selector",
    width = 300,
    selectedItems,
    allowDisabling = true,
}) => {
    const theme = useTheme();

    const handleChange = (event) => {
        const value = event.target.value;
        onChange(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
    };

    const validSelectedItems = useMemo(() => {
        return (
            selectedItems?.filter((value) => options.find((option) => option[0] === value)) || []
        );
    }, [options, selectedItems]);
    return (
        <div>
            <FormControl sx={{ width: { width } }} margin="dense" size="small">
                <InputLabel color="primary">{label}</InputLabel>
                <Select
                    color="primary"
                    multiple
                    value={validSelectedItems}
                    onChange={handleChange}
                    input={<OutlinedInput label={label} />}
                    renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                            {selected
                                .filter((value) => options.find((option) => option[0] === value))
                                .map((value) => (
                                    <Chip
                                        key={value}
                                        label={options.find((option) => option[0] === value)[1]}
                                        color="primary"
                                    />
                                ))}
                        </Box>
                    )}
                >
                    {options
                        .filter(
                            (item) =>
                                allowDisabling === false ||
                                item[2] === undefined ||
                                item[2] === true
                        )
                        .map((item) => (
                            <MenuItem
                                key={item[0]}
                                value={item[0]}
                                style={getStyles(item[0], validSelectedItems, theme)}
                            >
                                {item[1]}
                            </MenuItem>
                        ))}
                    {options
                        .filter((item) => allowDisabling === true && item[2] === false)
                        .map((item) => (
                            <MenuItem
                                key={item[0]}
                                value={item[0]}
                                style={getStyles(item[0], validSelectedItems, theme)}
                                disabled={true}
                            >
                                {item[1]}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default FilterSelect;
