import { useEffect, useState } from "react";

const PathTracker = (props) => {
    const [activeMilestone, setActiveMilestone] = useState(null);
    const [activeMilestoneIndex, setActiveMilestoneIndex] = useState(0);
    const [nextMilestone, setNextMilestone] = useState(null);
    const [progressBetweenMilestones, setProgressBetweenMilestones] = useState(0);
    const scrollTo = (milestone) => {
        const element = document.querySelector(milestone.href);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleScroll = () => {
        let currentMilestone = null;
        let currentIndex = 0;

        // Find the active milestone based on scroll position
        props.milestones.forEach((milestone, index) => {
            const element = document.querySelector(milestone.href);
            if (element) {
                const rect = element.getBoundingClientRect();
                if (rect.top <= window.innerHeight / 2) {
                    currentMilestone = milestone;
                    currentIndex = index;
                }
            }
        });

        // Update active and next milestones
        setActiveMilestone(currentMilestone);
        setActiveMilestoneIndex(currentIndex);
        setNextMilestone(props.milestones[currentIndex + 1] || null);

        // Calculate progress between the current and next milestones
        if (currentMilestone && props.milestones[currentIndex + 1]) {
            const activeElement = document.querySelector(currentMilestone.href);
            const nextElement = document.querySelector(props.milestones[currentIndex + 1].href);

            if (activeElement && nextElement) {
                const activeRect = activeElement.getBoundingClientRect();
                const nextRect = nextElement.getBoundingClientRect();
                const distanceBetweenMilestones = nextRect.top - activeRect.top;
                const scrolledDistance = window.innerHeight / 10 - activeRect.top;

                const progress = Math.min(Math.max(scrolledDistance / distanceBetweenMilestones, 0), 1);
                setProgressBetweenMilestones(progress);
            }
        } else if (window.scrollY === 0) {
            // Special handling for when at the top of the page
            setProgressBetweenMilestones(0);
            setActiveMilestone(props.milestones[0]);
            setActiveMilestoneIndex(0);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>

            {/* Vertical navigation bar */}
            <div className="max-lg:hidden fixed h-screen flex items-center justify-center top-0 z-40 right-0 w-[20px] mx-4">
                <div className="relative flex flex-col gap-0">
                    <img
                        style={{
                            top:
                                activeMilestoneIndex === props.milestones.length - 1
                                    ? activeMilestoneIndex * 50  + activeMilestoneIndex * 16-5
                                    : activeMilestoneIndex * 50 + progressBetweenMilestones * 50 + activeMilestoneIndex * 16 -5 + "px",
                        }}
                        className={"absolute -left-6 w-7 h-7"}
                        src="landing/PathTrackerRocket.svg"
                        alt=""
                    />
                    {props.milestones.map((milestone, index) => (
                        <div key={index} className="flex flex-col items-center">
                            <div className="relative group cursor-pointer">
                                <a
                                    href={milestone.href}
                                    className={`${
                                        index <= activeMilestoneIndex
                                            ? "border-landing-yellow bg-landing-yellow"
                                            : "border-landing-gray"
                                    } h-4 w-4 rounded-full border-[3px] block`}
                                ></a>
                                <div
                                    className="absolute -top-1 -left-[150px] w-[150px] px-4 text-end text-[13px] text-landing-gray opacity-0 group-hover:opacity-100 pointer-events-none">
                                    <span>{milestone.name}</span>
                                </div>
                            </div>
                            {index !== props.milestones.length - 1 && (
                                <div
                                    className={`h-[50px] w-[3px] relative ${
                                        index < activeMilestoneIndex ? "bg-landing-yellow" : "bg-landing-gray"
                                    }`}
                                >
                                    {index === activeMilestoneIndex && (
                                        <div
                                            style={{height: progressBetweenMilestones * 50 + "px"}}
                                            className="absolute top-0 bg-landing-yellow w-[3px]"
                                        ></div>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default PathTracker;
