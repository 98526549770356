import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import PageContainer from "./../common/PageContainer";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import axiosInstance from "../axiosInstance";

const ActivationPage = () => {
    let params = useParams();
    let navigate = useNavigate();
    const [activating, setActivating] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
        if (!activating) {
            setActivating(true);
            axiosInstance
                .post(`/api/auth/users/activation/`, { uid: params.uid, token: params.token })
                .then((response) => {
                    setActivating(false);
                    setTimeout(() => {
                        navigate("/login");
                    }, 5000);
                })
                .catch((error) => {
                    setErrorMessage(error?.response?.data?.detail);
                    setActivating(false);
                });
        }
    }, [params]);

    return (
        <PageContainer maxWidth="sm">
            <Paper>
                {activating ? (
                    <Stack>
                        <Typography variant="h6">Activating...</Typography>
                        <CircularProgress />
                    </Stack>
                ) : null}

                {!activating && errorMessage ? (
                    <Typography variant="h6" color="error">
                        {errorMessage}
                    </Typography>
                ) : null}

                {!activating && !errorMessage ? (
                    <Typography variant="h6">
                        Activation successful! Redirecting to login page in 5 seconds...
                    </Typography>
                ) : null}
            </Paper>
        </PageContainer>
    );
};
export default ActivationPage;
