import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import ArticleCard, { ArticleCardSkeletons } from "../articles/ArticleCard";
import { Autoplay, Navigation, Pagination } from "swiper";
import JobCard, { JobCardSkeletons } from "../jobs/JobCard";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import BackgroundImages from "../common/BackgroundImages";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CareerCycling from "../common/CareerCycling";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import { Link as RouterLink } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import axiosInstance from "../axiosInstance";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const CornerBox = ({ children, color, cornerWidth = "10px", cornerLength = "100px", sx = {} }) => {
    return (
        <Box
            sx={{
                ...sx,
                background: `
                    linear-gradient(to right, ${color} ${cornerWidth}, transparent ${cornerWidth}) 0 0,
                    linear-gradient(to left, ${color}  ${cornerWidth}, transparent ${cornerWidth}) 100% 100%,
                    linear-gradient(to bottom, ${color}  ${cornerWidth}, transparent ${cornerWidth}) 0 0,
                    linear-gradient(to top, ${color}  ${cornerWidth}, transparent ${cornerWidth}) 100% 100%`,
                backgroundRepeat: "no-repeat",
                backgroundSize: `${cornerLength} ${cornerLength}`,
                minHeight: "240px",
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                flexDirection: "column",
                padding: "40px",
                marginBottom: 0,
            }}
        >
            {children}
        </Box>
    );
};

const SocialIcons = () => {
    const theme = useTheme();
    const socialIconSize = "56px";
    const iconStyle = {
        width: socialIconSize,
        height: socialIconSize,
        margin: "0px 5px 0px 5px",
    };
    const iconColor = "white";

    return (
        <Grid
            container
            sx={{
                position: "absolute",
                top: "21px",
                right: "34px",
                width: "240px",
                "& .social-svg-mask": { fill: "transparent !important" },
                "& .social-svg:hover > .social-svg-icon": {
                    fill: `${theme.palette.primary.main} !important`,
                },
            }}
        >
            <Grid item xs={3}>
                <SocialIcon
                    url="https://www.linkedin.com/company/spacecareers-uk/"
                    fgColor={iconColor}
                    style={iconStyle}
                />
            </Grid>
            <Grid item xs={3}>
                <SocialIcon
                    url="https://www.instagram.com/spacecareersuk/"
                    fgColor={iconColor}
                    style={iconStyle}
                />
            </Grid>
            <Grid item xs={3}>
                <SocialIcon
                    url="https://www.twitter.com/spacecareersuk/"
                    fgColor={iconColor}
                    style={iconStyle}
                />
            </Grid>
            <Grid item xs={3}>
                <SocialIcon
                    url="https://www.facebook.com/spacecareersuk/"
                    fgColor={iconColor}
                    style={iconStyle}
                />
            </Grid>
        </Grid>
    );
};

const BannerBox = ({ smallScreen = false }) => {
    const { t, i18n } = useTranslation();
    return (
        <Box
            sx={{
                backgroundImage: "url(/starry-sky.jpg)",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                flexDirection: "column",
                padding: { md: "10vh 15vw", xs: "5px" },
                minHeight: "600px",
                paddingTop: {
                    xl: "0 !important",
                    lg: "30px !important",
                    md: "30px !important",
                    sm: "20px !important",
                    xs: "0px !important",
                },
            }}
        >
            <Box
                component="img"
                src="/logos/New Icon White_1@4x.png"
                sx={{
                    height: "auto",
                    width: "auto",
                    maxHeight: "200px",
                    marginRight: "auto",
                    marginLeft: "auto",
                    paddingBottom: "45px",
                    display: { sm: "block", xs: "none" },
                }}
            />
            <Typography
                color="secondary"
                variant="h1"
                paragraph
                sx={{ fontWeight: 500, fontSize: { md: "6rem", sm: "5rem", xs: "4rem" } }}
            >
                {t("landing.banner_header")}
            </Typography>
            <Typography
                color="secondary"
                variant="h4"
                sx={{ padding: "0px 6vw" }}
                fontFamily="Nunito Sans"
            >
                {t("landing.banner_text")}
            </Typography>
            {smallScreen ? null : <SocialIcons />}
        </Box>
    );
};

const LinkButton = ({ to, text, color }) => {
    const theme = useTheme();
    const external = to.startsWith("http");
    return (
        <>
            {external ? (
                <Button
                    variant="contained"
                    color={color}
                    sx={{
                        width: "200px",
                        margin: "auto",
                        marginBottom: "20px",
                        borderRadius: "18px",
                        mt: 4,
                    }}
                    component={Link}
                    href={to}
                >
                    {text}
                </Button>
            ) : (
                <Button
                    variant="contained"
                    color={color}
                    sx={{
                        width: "200px",
                        margin: "auto",
                        marginBottom: "20px",
                        borderRadius: "18px",
                        mt: 4,
                    }}
                    component={RouterLink}
                    to={to}
                >
                    {text}
                </Button>
            )}
        </>
    );
};

const LinksBox = () => {
    const theme = useTheme();
    const compact = useMediaQuery(theme.breakpoints.down("xl"));
    const { t, i18n } = useTranslation();
    return (
        <Grid
            container
            spacing={compact ? 4 : 16}
            sx={{
                padding: { xl: "30px 200px", md: "30px 100px", xs: "15px" },
            }}
        >
            <Grid item lg={4} xs={12} sx={{ display: "flex" }}>
                <CornerBox
                    color={theme.palette.text.primary}
                    sx={{ maxWidth: "600px", margin: "auto" }}
                >
                    <Typography variant="h5" sx={{ color: "text.primary" }} mb={2}>
                        {t("landing.student_header")}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ color: "text.primary", marginBottom: "auto" }}
                    >
                        {t("landing.student")}
                    </Typography>
                    <LinkButton to="/careers_resources" text="Career Resources" color="highlight" />
                </CornerBox>
            </Grid>
            <Grid item lg={4} xs={12} sx={{ display: "flex" }}>
                <CornerBox
                    color={theme.palette.highlight.main}
                    sx={{ maxWidth: "600px", margin: "auto" }}
                >
                    <Typography variant="h5" sx={{ color: "text.primary" }} mb={2}>
                        {t("landing.professional_header")}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ color: "text.primary", marginBottom: "auto" }}
                    >
                        {t("landing.professional")}
                    </Typography>
                    <LinkButton to="/jobs" text="Jobs Board" color="highlight" />
                </CornerBox>
            </Grid>
            <Grid item lg={4} xs={12} sx={{ display: "flex" }}>
                <CornerBox
                    color={theme.palette.primary.main}
                    sx={{ maxWidth: "600px", margin: "auto" }}
                >
                    <Typography variant="h5" sx={{ color: "text.primary" }} mb={2}>
                        {t("landing.advertise_header")}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ color: "text.primary", marginBottom: "auto" }}
                    >
                        {t("landing.advertise")}
                    </Typography>
                    <LinkButton to="/advertise" text="Advertise" color="highlight" />
                </CornerBox>
            </Grid>
        </Grid>
    );
};

const ImageTextLinkBox = ({
    image,
    color,
    backgroundColor,
    header,
    body,
    linkTo,
    linkText,
    reverse = false,
    containerProps = {},
    hideImage = true,
}) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const ImageItem = ({ hideImage }) => {
        return (
            <Grid
                item
                md={6}
                xs={hideImage ? 0 : 12}
                sx={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    minHeight: "250px",
                }}
            />
        );
    };
    return (
        <Grid
            container
            mt={6}
            sx={{
                minHeight: { md: "500px", xs: "unset" },
            }}
            {...containerProps}
        >
            {reverse ? null : <ImageItem hideImage={hideImage} />}
            <Grid
                item
                md={6}
                xs={12}
                backgroundColor={backgroundColor}
                sx={{
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: { md: "86px", xs: "25px" },
                }}
            >
                <Typography
                    variant="h2"
                    color={color}
                    mb={2}
                    sx={{
                        fontSize: { sm: "3.75rem", xs: "2.5rem" },
                    }}
                >
                    {header}
                </Typography>
                <Typography variant="body1" color={color} sx={{ whiteSpace: "pre-wrap" }}>
                    {body}
                </Typography>
                <LinkButton to={linkTo} text={linkText} color="highlight" />
            </Grid>
            {reverse ? <ImageItem hideImage={hideImage} /> : null}
        </Grid>
    );
};

const JobsBox = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const [jobs, setJobs] = useState([]);
    const [loadingJobs, setLoadingJobs] = useState(true);
    useEffect(() => {
        axiosInstance.get("/api/jobs/?include_expired=false&limit=12").then((response) => {
            setJobs(response.data.results);
            setLoadingJobs(false);
        });
    }, []);
    const smallScreen = useMediaQuery(theme.breakpoints.down("lg"));

    const MiniJobCard = ({ job }) => {
        return (
            <Card
                color="secondary"
                sx={{
                    margin: "40px",
                }}
            >
                {job?.paid ? (
                    <Tooltip title="Promoted">
                        <WorkspacePremiumIcon
                            sx={{
                                position: "absolute",
                                zIndex: 1,
                                backgroundColor: "white",
                                borderRadius: "50%",
                                color: theme.palette.text.primary,
                                padding: "2px",
                                marginLeft: "4px",
                                marginTop: "4px",
                                fontSize: "16px",
                            }}
                        />
                    </Tooltip>
                ) : null}
                <Link
                    component={RouterLink}
                    to={`/jobs/${job.id}`}
                    sx={{ width: "100%" }}
                    underline="none"
                >
                    <CardContent>
                        <Typography
                            align="center"
                            variant="h4"
                            color={theme.palette.text.primary}
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",

                                fontSize: { md: "2.125rem", sm: "1.75rem" },
                            }}
                        >
                            {job?.title}
                        </Typography>
                    </CardContent>
                    <CardMedia
                        component="img"
                        sx={{
                            objectFit: "scale-down",
                            maxWidth: "100%",
                            maxHeight: "200px",
                            height: "100%",
                            width: "unset",
                            margin: "auto",
                        }}
                        image={job?.employer?.logo}
                        alt={job?.title}
                    />
                    <CardContent sx={{ textAlign: "center" }}>
                        <LinkButton to={`/jobs/${job.id}`} text="find out more" color="pink" />
                    </CardContent>
                </Link>
            </Card>
        );
    };

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.text.primary,
                paddingRight: { xs: "0px", md: "35px" },
                paddingLeft: { xs: "0px", md: "15px" },
            }}
            mt={5}
        >
            <Typography
                variant="h2"
                color="secondary"
                align="center"
                p={3}
                sx={{
                    fontSize: { sm: "3.75rem", xs: "2.5rem" },
                    paddingBottom: "0px",
                }}
            >
                {t("landing.jobs_header")}
            </Typography>
            <Swiper
                slidesPerView={smallScreen ? 1 : 3}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination, Navigation, Autoplay]}
                navigation={true}
                loop={true}
                autoplay={{
                    delay: 3000,
                }}
            >
                {jobs.map((job) => (
                    <SwiperSlide>
                        <MiniJobCard job={job} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Box>
    );
};

const LandingPage = () => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
    const { t, i18n } = useTranslation();

    return (
        <PageContainer
            maxWidth="xl"
            sx={{
                padding: "0px !important",
            }}
        >
            <Box sx={{ minHeight: smallScreen ? "0px" : "250px", padding: "0px !important" }}>
                <div
                    style={{
                        width: "100%",
                        position: "relative",
                        backgroundColor: "white",
                        paddingBottom: "48px",
                    }}
                >
                    <BannerBox smallScreen={smallScreen} />
                    <Box
                        sx={{
                            display: "flex",
                            textAlign: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            padding: { md: "50px 25vw", xs: "25px" },
                        }}
                    >
                        <Typography variant="h6" sx={{ color: "text.primary" }}>
                            {t("landing.sub_banner_text")}
                        </Typography>
                    </Box>
                    <LinksBox />
                    <ImageTextLinkBox
                        image="/earth-from-iss.jpg"
                        color="secondary"
                        backgroundColor={theme.palette.primary.main}
                        header={t("landing.create_profile_header")}
                        body={t("landing.create_profile_text")}
                        linkTo="/profile"
                        linkText="Create your profile"
                    />
                    <ImageTextLinkBox
                        image="/table-laptops.jpg"
                        color={theme.palette.text.primary}
                        backgroundColor={theme.palette.secondary.main}
                        header={t("landing.career_resources_header")}
                        body={t("landing.career_resources_text")}
                        linkTo="/careers_resources"
                        linkText="Careers resources"
                        reverse
                        containerProps={{ mt: { xs: 3, md: 6 } }}
                    />

                    <JobsBox />
                    <ImageTextLinkBox
                        image="/ukseds-group.jpeg"
                        color={theme.palette.text.primary}
                        backgroundColor={theme.palette.secondary.main}
                        header={t("landing.ukseds_header")}
                        body={t("landing.ukseds_text")}
                        linkTo="https://ukseds.org/"
                        linkText="Read more"
                        hideImage={false}
                    />
                </div>
            </Box>
        </PageContainer>
    );
};

export default LandingPage;
