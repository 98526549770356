import "survey-core/modern.min.css";
import "survey-core/defaultV2.min.css";

import { Model, StylesManager } from "survey-core";
import { Navigate, useLocation } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { format as formatDate, parse, parseISO } from "date-fns";
import { getAccountType, isLoggedIn } from "../auth/functions";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ArticleCard from "../articles/ArticleCard";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import ComingSoonPanel from "../common/ComingSoonPanel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import DoneIcon from "@mui/icons-material/Done";
import EditableTextField from "../common/EditableTextField";
import EmployerCard from "../employers/EmployerCard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fab from "@mui/material/Fab";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import JobCard from "../jobs/JobCard";
import LinearProgress from "@mui/material/LinearProgress";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MenuItem from "@mui/material/MenuItem";
import NotificationsPanel from "./NotificationsPanel";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import SettingsIcon from "@mui/icons-material/Settings";
import Stack from "@mui/material/Stack";
import { Survey } from "survey-react-ui";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import TabPanel from "../common/TabPanel";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import axiosInstance from "../axiosInstance";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

StylesManager.applyTheme("defaultV2");

const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const AvailableForWorkDatePicker = ({ onChange, onSave, value, dirty }) => {
    return (
        <Grid container sx={{ textAlign: "center" }} spacing={2}>
            <Grid item xs={12} lg={6}>
                <Typography sx={{ mt: 1, mb: 1 }}>I am available for work from:</Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Stack sx={{ mb: 2, justifyContent: "center" }} direction="row">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            views={["year", "month"]}
                            label="Month and Year"
                            minDate={new Date()}
                            value={value ? parse(value, "dd-MM-yyyy", new Date()) : null}
                            onChange={(newValue) => {
                                try {
                                    onChange(formatDate(newValue, "dd-MM-yyyy"));
                                } catch (e) {
                                    onChange(null);
                                }
                            }}
                            renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                    </LocalizationProvider>
                    <Button
                        variant="contained"
                        disabled={!dirty}
                        onClick={onSave}
                        sx={{ margin: 1 }}
                    >
                        Save
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
};

const QualificationAccordion = ({ qualifications, onSave }) => {
    const [qualificationOptions, setQualificationOptions] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [newQualification, setNewQualification] = useState({});

    const onDelete = useCallback(
        (i) => {
            onSave(qualifications.filter((v, j) => i !== j));
        },

        [qualifications, onSave]
    );

    useEffect(() => {
        axiosInstance.get("/api/profiles/qualification_options/").then((response) => {
            setQualificationOptions(response.data);
        });
    }, []);

    return (
        <>
            <Stack spacing={2}>
                {qualifications.map((qualification, i) => (
                    <QualificationRow
                        key={i}
                        qualification={qualification}
                        onDelete={() => onDelete(i)}
                    />
                ))}
            </Stack>
            <Button
                variant="contained"
                onClick={() => {
                    setDialogOpen(true);
                }}
                sx={{ width: "100px", margin: "auto", display: "block", marginTop: "10px" }}
            >
                Add
            </Button>
            <Dialog
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                    setNewQualification({});
                }}
            >
                <DialogTitle>Add Qualification</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please select the qualification you would like to add.
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Select
                                fullWidth
                                value={newQualification?.qualification_type}
                                onChange={(e) =>
                                    setNewQualification({
                                        ...newQualification,
                                        qualification_type: e.target.value,
                                    })
                                }
                            >
                                {qualificationOptions?.qualification_type?.map((v) => (
                                    <MenuItem key={v} value={v}>
                                        {v}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                fullWidth
                                value={newQualification?.subject}
                                onChange={(e) =>
                                    setNewQualification({
                                        ...newQualification,
                                        subject: e.target.value,
                                    })
                                }
                            >
                                {qualificationOptions?.subject?.map((v) => (
                                    <MenuItem key={v} value={v}>
                                        {v}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDialogOpen(false);
                            setNewQualification({});
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            onSave([...qualifications, newQualification]);
                            setDialogOpen(false);
                            setNewQualification({});
                        }}
                        disabled={
                            !(newQualification?.qualification_type && newQualification?.subject)
                        }
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const QualificationRow = ({ qualification, onDelete }) => {
    return (
        <Stack direction="row">
            <Typography color="text">
                {qualification?.qualification_type} in {qualification?.subject}
            </Typography>
            <Button
                variant="contained"
                onClick={onDelete}
                sx={{ position: "absolute", right: "20px" }}
            >
                Delete
            </Button>
        </Stack>
    );
};

const ChipList = ({ currentList, options, onSelect, onDelete }) => {
    const { t, i18n } = useTranslation();

    const selectableOptions = useMemo(() => {
        return options?.filter((value) => !currentList.includes(value));
    }, [currentList, options]);
    return (
        <Paper
            sx={{
                display: "flex",
                flexWrap: "wrap",
                listStyle: "none",
                p: 1.5,
                m: 0,
                borderRadius: "20px",
            }}
            elevation={3}
            component="ul"
        >
            <>
                {currentList?.map((value) => (
                    <ListItem>
                        <Chip
                            label={t(value)}
                            color="primary"
                            onDelete={() => {
                                onDelete(value);
                            }}
                            sx={{
                                height: "40px",
                                borderRadius: "20px",
                                fontSize: "16px",
                            }}
                        />
                    </ListItem>
                ))}
                <TextField
                    label="+ Add"
                    select
                    size="small"
                    sx={{
                        minWidth: "100px",
                        m: "4px",
                        "& .MuiInputBase-root": { borderRadius: "20px" },
                    }}
                    value=""
                    onChange={(event) => onSelect(event.target.value)}
                    disabled={selectableOptions?.length === 0}
                >
                    {selectableOptions?.map((value) => (
                        <MenuItem key={value} value={value}>
                            {t(value)}
                        </MenuItem>
                    ))}
                </TextField>
            </>
        </Paper>
    );
};

const ProfilePercentageBar = ({ user }) => {
    const remainingFields = useMemo(() => {
        const remainingFields = [];
        if (!user?.profile?.available_for_work_date)
            remainingFields.push("Available for work date");
        if (!user?.profile?.ambitions) remainingFields.push("About me");
        if (!user?.profile?.qualifications?.length > 0) remainingFields.push("Qualifications");
        if (!user?.profile?.interests_job_type?.length > 0) remainingFields.push("Job types");
        if (!user?.profile?.interests_duration?.length > 0) remainingFields.push("Job durations");
        if (!user?.profile?.interests_secondary_category?.length > 0)
            remainingFields.push("Job categories");
        if (!user?.profile?.areas_of_interest?.length > 0)
            remainingFields.push("Areas of interest");
        if (!user?.profile?.areas_of_expertise?.length > 0)
            remainingFields.push("Areas of expertise");
        return remainingFields;
    }, [user]);
    const profileFieldCount = 8;

    return (
        <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
            <Typography variant="body2" color="text.secondary">
                Profile Strength
            </Typography>
            <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress
                    variant="determinate"
                    value={((profileFieldCount - remainingFields.length) / profileFieldCount) * 100}
                />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Tooltip
                    title={
                        remainingFields.length
                            ? "Remaining information:\n\n" + remainingFields.join("\n")
                            : ""
                    }
                    PopperProps={{ sx: { whiteSpace: "pre-wrap" } }}
                >
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        ((profileFieldCount - remainingFields.length) / profileFieldCount) * 100
                    )}%`}</Typography>
                </Tooltip>
            </Box>
        </Box>
    );
};

const ProfilePage = ({ user, updateUser, logout }) => {
    const [value, setValue] = React.useState(0);
    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const tabStyle = {
        backgroundColor: "white",
        color: theme.palette.text.primary,
        borderRadius: "6px",
        margin: "0px 12px",
        flexGrow: 1,
        "&.Mui-selected": {
            backgroundColor: theme.palette.highlight.main,
            color: theme.palette.text.primary,
        },
        "&:first-of-type": {
            marginLeft: "0px",
        },
        "&:last-of-type": {
            marginRight: "0px",
        },
    };
    return (
        <PageContainer dark sx={{ paddingTop: 0, marginBottom: { xs: "20px", md: "0px" } }}>
            {(!isLoggedIn() || getAccountType() !== "candidate") && (
                <Navigate to="/signup" replace />
            )}
            {user ? (
                <>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        sx={{
                            padding: "10px 0px",
                            "& .MuiTabs-indicator": {
                                display: "none",
                            },
                            "& .MuiTabScrollButton-root ": {
                                color: "white",
                            },
                        }}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                    >
                        <Tab label="My Profile" sx={tabStyle} />
                        <Tab label="Saved Jobs" sx={tabStyle} />
                        <Tab label="Saved Employers" sx={tabStyle} />
                        <Tab label="Saved Resources" sx={tabStyle} />
                        <Tab label="Notifications" sx={tabStyle} />
                        <Tab label="Survey" sx={tabStyle} />
                        <Tab label="UKSEDS" sx={tabStyle} />
                        <Tab label={<SettingsIcon />} sx={tabStyle} />
                    </Tabs>
                    <Paper sx={{ padding: "20px" }}>
                        <InterestsPanel
                            value={value}
                            index={0}
                            user={user}
                            updateUser={updateUser}
                        />

                        <TabPanel value={value} index={1}>
                            <Stack spacing={3}>
                                {user.saved_jobs.map((job) => (
                                    <JobCard key={job.id} job={job} />
                                ))}
                            </Stack>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Grid container spacing={2}>
                                {user.saved_employers.map((employer) => (
                                    <Grid item md={3} sm={4} xs={6} key={employer.id}>
                                        <EmployerCard
                                            key={employer.id}
                                            employer={employer}
                                            canSave={false}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            {Object.entries(
                                _.groupBy(user.saved_articles, (article) => article.parent_page)
                            ).map((page) => (
                                <Stack>
                                    <Typography variant="h4" color="text" paragraph>
                                        {page[0]}
                                    </Typography>
                                    <Grid container spacing={2}>
                                        {page[1].map((article) => (
                                            <Grid item md={4} sm={6} xs={12} key={article?.id}>
                                                <ArticleCard
                                                    key={article.id}
                                                    article={article}
                                                    canSave={false}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>

                                    <Divider sx={{ mt: 4, mb: 4 }} />
                                </Stack>
                            ))}
                        </TabPanel>
                        <NotificationsPanel
                            value={value}
                            index={4}
                            user={user}
                            updateUser={updateUser}
                        />

                        <SurveyPanel value={value} index={5} user={user} />
                        <UKSEDSPanel value={value} index={6} />
                        <SettingsPanel value={value} index={7} user={user} logout={logout} />
                    </Paper>
                </>
            ) : null}
        </PageContainer>
    );
};

const SettingsPanel = ({ value, index, user, logout }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmationText, setConfirmationText] = useState("");

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
        setConfirmationText("");
    };

    const handleConfirmDialogOpen = () => {
        setConfirmDialogOpen(true);
    };

    const deleteAccount = () => {
        axiosInstance.delete("/api/users/me/").then((response) => {
            enqueueSnackbar("Profile deleted successfully.", { variant: "success" });
            handleConfirmDialogClose();
            logout();
        });
    };

    return (
        <TabPanel value={value} index={index}>
            <Button variant="contained" color="error" onClick={handleConfirmDialogOpen}>
                Delete account
            </Button>
            <Dialog open={confirmDialogOpen} onClose={handleConfirmDialogClose}>
                <DialogTitle>{"Are you sure you want to delete your account?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText mb={2}>
                        Your account will remain recoverable for 30 days
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Type DELETE to confirm"
                        type="text"
                        fullWidth
                        value={confirmationText}
                        onChange={(e) => setConfirmationText(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={deleteAccount}
                        color="primary"
                        autoFocus
                        disabled={confirmationText !== "DELETE"}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </TabPanel>
    );
};
const UKSEDSPanel = ({ value, index }) => {
    return (
        <TabPanel value={value} index={index}>
            <Typography variant="h4" paragraph>
                What is UKSEDS?
            </Typography>
            <Typography paragraph>
                UK Students for the Exploration and Development of Space (UKSEDS) is the national
                student space charity.
            </Typography>
            <Typography paragraph>
                As the original founders of SpaceCareers.uk, we are now partnered with the team to
                further advance our mission: connect UK students and recent graduates with the space
                sector through education, advocacy and outreach.
            </Typography>
            <Typography variant="h4" paragraph>
                Why should I be part of it?
            </Typography>
            <Typography paragraph>
                As a member, you can access our vast network, get advice, learn through webinars,
                workshops and our podcast, get involved in our events, projects and competitions,
                check our opportunities, volunteer with us, and much more!
            </Typography>
            <Typography paragraph>
                Plus, we recently launched the UKSEDS Community Hub, a new all-in-one online
                platform for our membership that encompasses all of UKSEDS offerings! It will allow
                you to connect, collaborate, find opportunities and learn about the latest
                developments in the space sector. Take a quick look now:{" "}
                <a href="https://hub.ukseds.org">hub.ukseds.org</a>
            </Typography>
            <Typography variant="h4" paragraph>
                How do I become a member?
            </Typography>
            <Typography paragraph>
                UKSEDS Membership is free and open to all UK students and recent graduates (up to 3
                years post-graduation). Make the most out of your membership by participating in
                discussions, events and activities with fellow space enthusiasts. If you are
                eligible, simply fill out this form!{" "}
                <a href="https://ukseds.org/join">ukseds.org/join</a>
            </Typography>
            <Typography paragraph>
                UKSEDS also has dozens of branches all over the country. These are university
                societies run by students who love space. Being a UKSEDS Branch gives you access to
                our Project Grants, a ton of resources, networking, and opportunities. Plus, our
                Regional Coordinators are always a click away to support the Branch and provide
                guidance. Register here:{" "}
                <a href="https://ukseds.org/ignition/branch-register">
                    https://ukseds.org/ignition/branch-register
                </a>
            </Typography>
            <Typography paragraph>
                Alternatively, if your student days are behind you, but you'd still like to help us
                inspire and educate the next generation, then we'd love to hear from you. With the
                UKSEDS Alumni & Friends Membership, you can join our network of ex-UKSEDS members,
                gain access to events, become a mentor, and much more. Register now:
                <a href="https://ukseds.org/join">ukseds.org/join</a>
            </Typography>
        </TabPanel>
    );
};

const SurveyPanel = ({ value, index, user }) => {
    const [survey, setSurvey] = useState(null);
    const [responseDate, setResponseDate] = useState(null);
    useEffect(() => {
        axiosInstance.get("/api/surveys/").then((response) => {
            const surveyId = response.data.results[0]?.id;
            let new_survey = new Model(response.data.results[0]?.survey_details);
            new_survey.onComplete.add((sender) => {
                axiosInstance.post("/api/surveys/responses/", {
                    survey: surveyId,
                    response: sender.data,
                    profile: user.id,
                });
            });
            setSurvey(new_survey);
            setResponseDate(response.data.results[0]?.response_date);
        });
    }, [user]);

    return (
        <TabPanel value={value} index={index}>
            <Typography variant="body" color="text" paragraph>
                Help us to improve recruitment in the space sector! These are optional and your
                answers will not be shown on your profile. They will only be used as anonymised
                aggregated information to help us gauge the audience we have, and hence where we
                should focus more effort in our goal to make the space sector accessible and
                inclusive to everyone. You change your answers at any time by responding again.
            </Typography>
            {responseDate && (
                <>
                    <Typography variant="h6" color="text" paragraph>
                        You responded to this survey on{" "}
                        {formatDate(parseISO(responseDate, new Date()), "dd-MM-yyyy")}
                    </Typography>
                    <Typography variant="body" color="text" paragraph>
                        You may submit a new response if you wish.
                    </Typography>
                </>
            )}
            <Survey model={survey} />
        </TabPanel>
    );
};

const InterestsPanel = ({ updateUser, user, ...props }) => {
    const { t, i18n } = useTranslation();

    const [edits, setEdits] = useState({});
    const [interestOptions, setInterestOptions] = useState({});
    const [ambitionsEdit, setAmbitionsEdit] = useState("");
    const [availabilityEdit, setAvailabilityEdit] = useState("");
    const [ambitionsDirty, setAmbitionsDirty] = useState(false);
    const [availabilityDirty, setAvailabilityDirty] = useState("");
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const submitEdits = useCallback(() => {
        let formData = new FormData();
        for (let key in edits) {
            formData.append(`profile.${key}`, edits[key]);
        }
        axiosInstance.patch(`/api/users/${user.id}/`, formData).then((response) => {
            updateUser();
            setEdits({});
        });
    }, [edits]);

    useEffect(() => {
        loadInterestOptions();
    }, []);

    useEffect(() => {
        if (!ambitionsDirty) setAmbitionsEdit(user?.profile?.ambitions);
        if (!availabilityDirty) setAvailabilityEdit(user?.profile?.available_for_work_date);
    }, [user]);

    const loadInterestOptions = () => {
        axiosInstance.get("/api/profile/interests/").then((response) => {
            if (response === undefined) {
                loadInterestOptions();
            } else {
                setInterestOptions(response.data);
            }
        });
    };

    const addInterest = (interestType, interest) => {
        patchValue(interestType, [interest, ...user.profile[interestType]]);
    };
    const removeInterest = (interestType, interest) => {
        patchValue(
            interestType,
            user.profile[interestType].filter((v) => v !== interest)
        );
    };

    const patchValue = (key, value) => {
        let formData = {
            profile: {
                [key]: value,
            },
        };
        submitFormData(formData);
    };

    const submitFormData = (formData) => {
        const notificationId = enqueueSnackbar("Updating profile...", {
            variant: "info",
            persist: true,
        });
        axiosInstance
            .patch(`/api/users/${user.id}/`, formData)
            .then((response) => {
                updateUser();
                closeSnackbar(notificationId);
                enqueueSnackbar("Profile updated successfully.", { variant: "success" });
            })
            .catch(() => {
                closeSnackbar(notificationId);
                enqueueSnackbar("Error updating profile.", { variant: "error" });
            });
    };

    const InterestManagement = useCallback(
        ({ interest }) => {
            return (
                <ChipList
                    currentList={user?.profile?.[interest]}
                    options={interestOptions?.[interest]}
                    onSelect={(value) => {
                        addInterest(interest, value);
                    }}
                    onDelete={(value) => {
                        removeInterest(interest, value);
                    }}
                />
            );
        },
        [user, interestOptions]
    );
    return (
        <TabPanel {...props}>
            <Typography color="text" paragraph>
                {t("profile.interests.body")}
            </Typography>
            <Divider sx={{ marginBottom: "40px" }} />
            <Grid container justifyContent="center" spacing={2} mb={2}>
                <Grid item lg={3} md={6}>
                    <FormGroup sx={{ display: "block", textAlign: "center" }}>
                        <FormControlLabel
                            sx={{ marginLeft: 0, marginBottom: "10px" }}
                            checked={user.profile.looking_for_job}
                            onChange={(e) => {
                                patchValue("looking_for_job", e.target.checked);
                            }}
                            control={<Switch />}
                            label="I'm looking for a job and employers can contact me"
                            labelPlacement="start"
                        />
                    </FormGroup>
                </Grid>
                <Grid item lg={3} md={6}>
                    <FormGroup sx={{ display: "block", textAlign: "center" }}>
                        <FormControlLabel
                            sx={{ marginLeft: 0, marginBottom: "10px", whiteSpace: "pre-wrap" }}
                            checked={user.profile.early_career}
                            onChange={(e) => {
                                patchValue("early_career", e.target.checked);
                            }}
                            control={<Switch />}
                            label="I'm an early careers candidate"
                            labelPlacement="start"
                        />
                    </FormGroup>
                </Grid>
                <Grid item lg={6} md={12}>
                    <AvailableForWorkDatePicker
                        onSave={() => {
                            patchValue("available_for_work_date", availabilityEdit);
                            setAvailabilityDirty(false);
                        }}
                        value={availabilityEdit}
                        onChange={(v) => {
                            setAvailabilityEdit(v);
                            setAvailabilityDirty(true);
                        }}
                        dirty={availabilityDirty}
                    />
                </Grid>
            </Grid>
            <ProfilePercentageBar user={user} />
            <Accordion id="ambition">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>About Me (100 words max)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextField
                        multiline
                        minRows={5}
                        fullWidth
                        sx={{ marginBottom: "6px" }}
                        value={ambitionsEdit}
                        onChange={(e) => {
                            setAmbitionsEdit(e.target.value);
                            setAmbitionsDirty(true);
                        }}
                    />
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            disabled={user?.profile?.ambitions === ambitionsEdit}
                            onClick={() => {
                                patchValue("ambitions", ambitionsEdit);
                                setAmbitionsDirty(false);
                            }}
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            disabled={user?.profile?.ambitions === ambitionsEdit}
                            onClick={() => {
                                setAmbitionsEdit(user?.profile?.ambitions);
                                setAmbitionsDirty(false);
                            }}
                        >
                            Reset
                        </Button>
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Accordion id="qualifications">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Qualifications</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <QualificationAccordion
                        qualifications={user?.profile?.qualifications}
                        onSave={(qualifications) => {
                            patchValue("qualifications", qualifications);
                        }}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion id="type">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Job Types</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <InterestManagement interest="interests_job_type" />
                </AccordionDetails>
            </Accordion>
            <Accordion id="categories">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Job Interests</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={{ marginBottom: "8px" }}>Categories</Typography>
                    <InterestManagement interest="interests_secondary_category" />
                </AccordionDetails>
                <AccordionDetails>
                    <Typography sx={{ marginBottom: "8px" }}>Areas of Interest</Typography>
                    <InterestManagement interest="areas_of_interest" />
                </AccordionDetails>
                <AccordionDetails>
                    <Typography sx={{ marginBottom: "8px" }}>Areas of Expertise</Typography>
                    <InterestManagement interest="areas_of_expertise" />
                </AccordionDetails>
            </Accordion>
            <Accordion id="duration">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Job Duration</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <InterestManagement interest="interests_duration" />
                </AccordionDetails>
            </Accordion>
        </TabPanel>
    );
};
export default ProfilePage;
