import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterSelect from "../common/FilterSelect";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import { CustomInfiniteScroll as InfiniteScroll } from "../common/CustomInfiniteScroll";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import Switch from "@mui/material/Switch";
import TabPanel from "../common/TabPanel";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import axios from "axios";
import axiosInstance from "../axiosInstance";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const ProfileCardAttribute = ({ title, content }) => {
    return (
        <Grid container>
            <Grid item xs={3}>
                <Typography>{title}</Typography>
            </Grid>
            <Grid item xs={9}>
                <Typography sx={{ whiteSpace: "pre-line" }} pr={5}>
                    {content}
                </Typography>
            </Grid>
        </Grid>
    );
};

const ProfileContactForm = ({ profile }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const sendMessage = () => {
        axiosInstance
            .post(`/api/profiles/${profile.user}/send_email/`, { body: message })
            .then(() => {
                enqueueSnackbar("Message sent.", { variant: "success" });
                setMessage("");
                handleClose();
            })
            .catch((err) => {
                enqueueSnackbar("Error sending message.", { variant: "error" });
            });
    };

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                Contact candidate
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Contact candidate</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You may provide a message to initiate contact with this candidate. It will
                        be emailed on your behalf and you will be cc'd so that you may continue the
                        conversation.
                    </DialogContentText>
                    <TextField
                        value={message}
                        onChange={(e) => {
                            setMessage(e.target.value);
                        }}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Message"
                        fullWidth
                        variant="standard"
                        multiline
                        minRows={3}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={sendMessage}>Submit</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const ProfileCard = ({ profile, updateProfile = () => {} }) => {
    const { t, i18n } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    return (
        <>
            <Paper
                sx={{
                    margin: "2px",
                    padding: "20px",
                    background: expanded
                        ? ""
                        : "linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 5%)",
                }}
                elevation={2}
            >
                <Collapse collapsedSize={140} in={expanded}>
                    <Stack sx={{ width: "100%" }} spacing={2}>
                        <Stack direction="row">
                            <Avatar alt={profile.user} src={profile?.image} variant="rounded" />
                            <Typography variant="h5" pl={2} sx={{ lineHeight: "40px" }}>
                                Candidate {profile.user.substring(0, 4)}
                            </Typography>
                            <Tooltip title="Save">
                                <Rating
                                    icon={<StarRoundedIcon fontSize="inherit" />}
                                    emptyIcon={<StarBorderRoundedIcon fontSize="inherit" />}
                                    sx={{ marginLeft: "auto" }}
                                    value={profile?.saved ? 1 : 0}
                                    max={1}
                                    onChange={(e, value) => {
                                        if (value === 1) {
                                            axiosInstance
                                                .post(`/api/profiles/${profile?.user}/save/`)
                                                .then((response) => {
                                                    updateProfile({ ...profile, saved: true });
                                                });
                                        } else {
                                            axiosInstance
                                                .post(`/api/profiles/${profile?.user}/unsave/`)
                                                .then((response) => {
                                                    updateProfile({ ...profile, saved: false });
                                                });
                                        }
                                    }}
                                />
                            </Tooltip>
                        </Stack>
                        <Divider />
                        <ProfileCardAttribute title="About Me" content={profile?.ambitions} />
                        <ProfileCardAttribute
                            title="I have skills in..."
                            content={
                                <>
                                    {_.isEmpty(profile?.areas_of_expertise)
                                        ? null
                                        : profile?.areas_of_expertise.join(", ")}
                                </>
                            }
                        />
                        <ProfileCardAttribute
                            title="I am interested in..."
                            content={
                                <>
                                    {profile?.interests_secondary_category.join(", ")}
                                    {_.isEmpty(profile?.areas_of_interest) ? null : (
                                        <>
                                            <Typography
                                                sx={{ color: "dimgray" }}
                                                variant="subtitle1"
                                            >
                                                AND
                                            </Typography>
                                            {profile?.areas_of_interest.join(", ")}
                                        </>
                                    )}
                                </>
                            }
                        />
                        <ProfileCardAttribute
                            title="For these durations..."
                            content={profile?.interests_duration.map(t).join(", ")}
                        />
                        <ProfileCardAttribute
                            title="And these types..."
                            content={profile?.interests_job_type.join(", ")}
                        />
                        <ProfileCardAttribute
                            title="I have a..."
                            content={profile?.qualifications
                                .map((q) => `${q?.qualification_type} in ${q?.subject}`)
                                .join("\n")}
                        />
                        <ProfileContactForm profile={profile} />
                    </Stack>
                </Collapse>
                <Button
                    onClick={() => setExpanded(!expanded)}
                    sx={{ float: "right", bottom: "26px" }}
                >
                    <Box>{expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</Box>
                </Button>
            </Paper>
        </>
    );
};

const ProfileSearchPage = ({
    employer,
    base_url = "/api/profiles",
    item_name = "candidate",
    CardComponent = ProfileCard,
}) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
    let [searchOptions, setSearchOptions] = useState({});
    let [searchParamsObject, setSearchParamsObject] = useState({});

    const [earlyCareer, setEarlyCareer] = useState(true);
    const [saved, setSaved] = useState(false);
    const [loading, setLoading] = useState(true);
    const [initialLoad, setInitialLoad] = useState(true);

    const queryString = useMemo(() => {
        var queryParts = [];
        Object.keys(searchParamsObject).forEach((key) =>
            searchParamsObject[key].forEach((val) =>
                queryParts.push(`${key}=${encodeURIComponent(val)}`)
            )
        );
        queryParts.push("early_career=" + earlyCareer);
        queryParts.push("saved=" + saved);
        var query = queryParts.join("&");
        if (query) {
            query = "?" + query;
        }
        return query;
    }, [searchParamsObject, earlyCareer, saved]);
    // acts as a handle for aborting requests
    const controller = useRef(new AbortController());

    const [items, setItems] = useState([]);
    // nextData can't be state otherwise it can instantly trigger a load when it's changed
    const nextData = useRef("/api/jobs/" + queryString);
    // must create a specific hasMore state to reflect if nextData is not undefined
    const [hasMore, setHasMore] = useState(false);
    const [totalItems, setTotalItems] = useState(0);

    const updateItem = useCallback(
        (profile) => {
            setItems((oldItems) =>
                oldItems.map((item) => {
                    if (item.user === profile.user) {
                        return { ...item, ...profile };
                    } else {
                        return item;
                    }
                })
            );
        },
        [setItems]
    );

    useEffect(() => {
        if (employer?.premium) {
            axiosInstance.get("/api/profiles/search_options/").then((response) => {
                setSearchOptions(response.data);
            });
        }
    }, [employer]);

    const fetchMoreData = useCallback(() => {
        if (employer?.premium) {
            setLoading(true);
            axiosInstance
                .get(nextData.current, { signal: controller.current.signal })
                .then((response) => {
                    setItems((items) => items.concat(response.data.results));
                    setTotalItems(response.data.count);

                    var next = "";
                    if (response.data.next) {
                        let next_queries = response.data.next.split("?");
                        next = next_queries[next_queries.length - 1];
                        nextData.current = `${base_url}/?` + next;
                        setHasMore(true);
                    } else {
                        nextData.current = undefined;
                        setHasMore(false);
                    }
                    setLoading(false);
                    setInitialLoad(false);
                })
                .catch((error) => {
                    // Handle cancellation
                    if (!axios.isCancel(error)) {
                        console.log("Error", error);
                    }
                });
        }
    }, [employer]);
    useEffect(() => {
        nextData.current = `${base_url}/` + queryString;
        // When the query string changes, the page must be reset
        setHasMore(false);
        setItems([]);
        // previous requests must be cancelled and a fresh controller created
        controller.current.abort();
        controller.current = new AbortController();

        fetchMoreData();
    }, [queryString, fetchMoreData]);

    const clearFilters = () => {
        setEarlyCareer(true);
        setSearchParamsObject({});
    };

    return (
        <div style={{position:'relative'}}>
            {employer?.premium ? undefined : (
                <Typography 
                    variant="h4" 
                    color="#283e72" 
                    textAlign={'center'}
                    sx={{
                        position: 'absolute',
                        top: '150px',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                }}>
                    Upgrade to premium subscription to search and contact candidates
                </Typography>
            )}
        <span
            style={{
                filter: employer?.premium ? undefined : "blur(10px)",
            }}
        >
            <Box>
                <Stack p={4}>
                    <Typography variant="h4" color="primary" mb={1}>
                        About
                    </Typography>
                    <Typography>
                        Search through candidates registered on our website. Only those actively
                        looking for a job are visible, and names are anonymised to reduce
                        unconscious/conscious bias. Contacting a candidate will send an email to you
                        and the candidate, so you can continue the conversation there. Expand the 
                        filters below and save candidates for later by clicking the star.
                    </Typography>
                </Stack>

                <Divider />

                <Stack p={4}>
                    <Typography variant="h4" color="primary">
                        Search
                    </Typography>
                    <TextField
                        label="eg. Rocketry, Python, Data Science"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        onChange={(e) =>
                            setSearchParamsObject((oldSearchParamsObjects) => {
                                if (e.target.value === "") {
                                    const { search, ...rest } = oldSearchParamsObjects;
                                    const updatedParams = { ...rest }; 
                                    return updatedParams; 
                                }
                                return {
                                    ...oldSearchParamsObjects,
                                    "search": [e.target.value], // value of key must be type array
                                };
                            })
                        }
                        value={searchParamsObject["search"] || ""}
                        color="text"
                        margin="dense"
                        size="small"
                    />
                </Stack>

                <Divider />

                <Accordion sx={{ overflow: "auto", border:"none", boxShadow:"none" }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{padding:"16px 32px"}}>
                        <Typography variant="h6">Filter by interests</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "0px 32px 5px 32px" }}>
                        <Grid container spacing={2}>
                            {Object.keys(searchOptions).map((key) => (
                                <Grid item xs={12}>
                                    <FilterSelect
                                        width="100%"
                                        selectedItems={searchParamsObject[key]}
                                        onChange={(selected) =>
                                            setSearchParamsObject((oldSearchParamsObjects) => {
                                                return {
                                                    ...oldSearchParamsObjects,
                                                    [key]: selected,
                                                };
                                            })
                                        }
                                        options={searchOptions[key].map((v) => {
                                            return Array.isArray(v) ? [v?.[0], v?.[1]] : [v, t(v)];
                                        })}
                                        label={t(`profile_search.${key}`)}
                                    />
                                </Grid>
                            ))}
                            <Grid item xs={12}>
                                <FormGroup
                                    sx={{
                                        paddingTop: "5px",
                                        width: "100%",
                                    }}
                                >
                                    <FormControlLabel
                                        checked={earlyCareer}
                                        onChange={(e) => setEarlyCareer(e.target.checked)}
                                        sx={{
                                            justifyContent: "center",
                                        }}
                                        control={<Switch />}
                                        label="Early career candidates"
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <FormGroup
                                    sx={{
                                        paddingTop: "5px",
                                        width: "100%",
                                    }}
                                >
                                    <FormControlLabel
                                        checked={saved}
                                        onChange={(e) => setSaved(e.target.checked)}
                                        sx={{
                                            justifyContent: "center",
                                        }}
                                        control={<Switch />}
                                        label="Search saved candidates"
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Divider sx={{ marginTop: "10px" }} />
                        <Button sx={{ paddingBottom: 0 }} onClick={clearFilters}>
                            Clear filters
                        </Button>
                    </AccordionDetails>
                </Accordion>

                <Divider />

            </Box>
            <Typography
                variant="subtitle"
                color="primary"
                align="center"
                sx={{
                    display: "block",
                    padding: 0,
                    paddingBottom: "20px",
                    paddingTop: "20px",
                }}
            >
                {totalItems} {item_name}s
            </Typography>
            {(loading && !items.length && totalItems !== 0) || initialLoad ? null : (
                <InfiniteScroll dataLength={items.length} next={fetchMoreData} hasMore={hasMore}>
                    <Grid container spacing={2}>
                        {items
                            .filter((item) => !saved || item?.saved)
                            .map((item) => (
                                <Grid item xs={12} key={item.user}>
                                    <CardComponent profile={item} updateProfile={updateItem} />
                                </Grid>
                            ))}
                    </Grid>
                </InfiniteScroll>
            )}
        </span>
        </div>
    );
};

export default ProfileSearchPage;
