import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import ArticleCard, { ArticleCardSkeletons } from "./ArticleCard";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid from "@mui/material/Grid";
import { CustomInfiniteScroll as InfiniteScroll } from "../common/CustomInfiniteScroll";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import Typography from "@mui/material/Typography";
import axiosInstance from "../axiosInstance";
import { getUserId } from "../auth/functions";
import { useSearchParams } from "react-router-dom";

const ParentPageBreadcrumbs = ({ parentPage }) => {
    return (
        <Breadcrumbs color="text.primary" separator={<NavigateNextIcon fontSize="small" />}>
            <Link underline="hover" color="inherit" href="/home">
                Home
            </Link>
            <Link underline="hover" color="inherit" href="/careers_resources/">
                Careers Resources
            </Link>
            <Typography color="inherit">{parentPage}</Typography>
        </Breadcrumbs>
    );
};

const MultiFilter = ({ options, onChange }) => {
    const [selected, setSelected] = useState([]);
    const handleChange = (event, newSelection) => {
        setSelected((prev) => {
            if (prev.includes(newSelection)) {
                return prev.filter((option) => option !== newSelection);
            } else {
                return [...prev, newSelection];
            }
        });
    };
    const selectAll = () => {
        if (selected.length === options.length) {
            setSelected([]);
        } else {
            setSelected(options);
        }
    };

    useEffect(() => {
        onChange(selected);
    }, [selected]);

    return (
        <>
            <ToggleButton
                value="All"
                onChange={selectAll}
                size="small"
                sx={{
                    margin: "10px 0px",
                    padding: "8px 14px",
                }}
            >
                All
            </ToggleButton>
            <Grid container spacing={1}>
                {options?.map((option) => {
                    return (
                        <Grid item lg={6} md={12} sm={4}>
                            <ToggleButton
                                value={option}
                                selected={selected.includes(option)}
                                onChange={handleChange}
                                fullWidth
                                size="small"
                                sx={{ height: "100%" }}
                            >
                                {option}
                            </ToggleButton>
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

const ArticleTagFilter = ({ onChange }) => {
    const [articleTags, setArticleTags] = useState([]);
    useEffect(() => {
        axiosInstance.get(`/api/articles/tags/`).then((response) => {
            setArticleTags(response.data.tags);
        });
    }, []);

    return <MultiFilter options={articleTags} onChange={onChange} />;
};

const ArticleCategoryFilter = ({ onChange }) => {
    const [articleCategories, setArticleCategories] = useState([]);
    useEffect(() => {
        axiosInstance.get(`/api/jobs/categories/`).then((response) => {
            const categoryGroupNamesSet = new Set(response.data.map(item => item.category_group_name));

            setArticleCategories(Array.from(categoryGroupNamesSet))
        });
    }, []);

    return <MultiFilter options={articleCategories} onChange={onChange} />;
};

const ArticleListPage = (props) => {
    const { parentPage = "" } = props;
    const [saved, setSaved] = useState([]);
    const [textSearch, setTextSearch] = useState("");
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [initialLoad, setInitialLoad] = useState(true);
    let [searchParams, setSearchParams] = useSearchParams();

    const search = searchParams.get("search");
    const parent_page = searchParams.getAll("parent_page");
    const tags = searchParams.getAll("tags");
    const jobCategories = searchParams.getAll("job_category");

    const searchParamsObject = useMemo(() => {
        const result = {};

        result["search"] = searchParams.get("search") || "";
        result["parent_page"] = searchParams.getAll("parent_page");
        result["tags"] = searchParams.getAll("tags");
        result["job_category"] = searchParams.getAll("job_category");

        return result;
    }, [searchParams]);

    // Called to update search parameters (filters or search)
    const updateSearchOptions = useCallback(
        (key, value) => {
            setSearchParams(
                {
                    ...searchParamsObject,
                    [key]: value,
                },
                { replace: true }
            );
        },
        [setSearchParams, searchParamsObject]
    );

    const updateSavedArticles = () => {
        const userId = getUserId();
        if (userId) {
            axiosInstance.get(`/api/users/me/saved_articles/`).then((response) => {
                setSaved(response.data.saved_articles);
            });
        }
    };

    // Encodes filters, search, and parent page components into a string to be used in API call
    const queryString = useMemo(() => {
        var queryParts = ["limit=20", "ordering=-posted_date"];
        if (parentPage) {
            if (parentPage === "Advice and Information") {
                queryParts.push(`parent_page=${encodeURIComponent("At University")}`);
                queryParts.push(`parent_page=${encodeURIComponent("At School")}`);
            } else if (parentPage === "Career Stories") {
                queryParts.push(`parent_page=${encodeURIComponent("Interviews")}`);
            }
            queryParts.push(`parent_page=${encodeURIComponent(parentPage)}`);
        }

        if (tags) {
            tags.forEach((tag) => {
                queryParts.push(`tags=${encodeURIComponent(tag)}`);
            });
        }
        if (jobCategories) {
            jobCategories.forEach((jobCategory) => {
                queryParts.push(`job_category=${encodeURIComponent(jobCategory)}`);
            });
        }
        if (search) {
            queryParts.push(`search=${encodeURIComponent(search)}`);
        }
        var query = queryParts.join("&");
        if (query) {
            query = "?" + query;
        }
        return query;
    }, [parentPage, tags, selectedCategories, search]);

    const [articles, setArticles] = useState([]);
    const nextData = useRef("/api/articles/" + queryString);
    const [hasMore, setHasMore] = useState(false);

    const [totalItems, setTotalItems] = useState(0);
    // acts as a handle for aborting requests
    const controller = useRef(new AbortController());

    // API call to fetch initial articles and update articles based on filters or search
    const fetchMoreData = useCallback(() => {
        setLoading(true);
        axiosInstance
            .get(nextData.current, { signal: controller.current.signal })
            .then((response) => {
                setArticles((items) => items.concat(response.data.results));
                setTotalItems(response.data.count);

                var next = "";
                if (response.data.next) {
                    let next_queries = response.data.next.split("?");
                    next = next_queries[next_queries.length - 1];
                    nextData.current = "/api/articles/?" + next;
                    setHasMore(true);
                } else {
                    nextData.current = undefined;
                    setHasMore(false);
                }
                setLoading(false);
                setInitialLoad(false);
            });
        updateSavedArticles();
    }, []);

    useEffect(() => {
        nextData.current = "/api/articles/" + queryString;
        // When the query string changes, the page must be reset
        setHasMore(false);
        setArticles([]);
        // previous requests must be cancelled and a fresh controller created
        controller.current.abort();
        controller.current = new AbortController();

        fetchMoreData();
    }, [fetchMoreData, queryString]);

    return (
        <PageContainer maxWidth="xl">
            <ParentPageBreadcrumbs parentPage={parentPage} />
            <Grid container spacing={4}>
                <Typography
                    variant="h3"
                    paragraph
                    color="text.primary"
                    textAlign="center"
                    sx={{ width: "100%" }}
                >
                    {parentPage}
                </Typography>
                <Grid item xs={12} md={3}>
                    <Paper sx={{ padding: "20px" }} elevation={0}>
                        <Stack spacing={2}>
                            <TextField
                                label="search"
                                variant="outlined"
                                sx={{ width: "100%" }}
                                onChange={(e) =>
                                                    updateSearchOptions("search", e.target.value)
                                                }
                                value={search || ""}
                                color="text"
                                margin="dense"
                                size="small"
                            />
                            <Paper sx={{ padding: "20px" }}>
                                {parentPage === "Advice and Information" ? (
                                    <>
                                        <Typography variant="h6" color="text">
                                            Filter by audience
                                        </Typography>
                                        <ArticleTagFilter onChange={(selected) =>
                                                    updateSearchOptions("tags", selected)
                                                } />
                                    </>
                                ) : (
                                    <>
                                        <Typography variant="h6" color="text">
                                            Filter by category
                                        </Typography>
                                        <ArticleCategoryFilter onChange={(selected) =>
                                                    updateSearchOptions("job_category", selected)
                                                } />
                                    </>
                                )}
                            </Paper>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={9}>
                {/** Displays card skeletons if article fetch is not yet complete */}
                {(loading && !articles.length && totalItems !== 0) || initialLoad ? (
                            <ArticleCardSkeletons />
                        ) : (
                    <Paper sx={{ padding: "20px" }}>
                        <Typography
                            variant="subtitle"
                            color="primary"
                            align="center"
                            sx={{
                                display: "block",
                                padding: 0,
                                paddingBottom: "20px",
                                paddingTop: "20px",
                            }}
                        >
                            {totalItems} articles
                        </Typography>
                        <InfiniteScroll
                            dataLength={articles.length}
                            next={fetchMoreData}
                            hasMore={hasMore}
                        >
                            <Grid container spacing={2}>
                                {articles.map((article) => (
                                    <Grid item lg={4} md={6} xs={12} key={article?.id}>
                                        <ArticleCard
                                            article={article}
                                            saved={saved.includes(article.id)}
                                            onSavedChange={(savedJobs) => {
                                                updateSavedArticles();
                                            }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </InfiniteScroll>
                    </Paper>
                        )}
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default ArticleListPage;
