import React, { useEffect, useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { ResponsiveFunnel } from "@nivo/funnel";
import { ResponsiveTimeRange } from "@nivo/calendar";
import Typography from "@mui/material/Typography";
import axiosInstance from "../axiosInstance";
import { parse } from "date-fns";

const Analytics = ({ job }) => {
    const [data, setData] = useState(null);
    useEffect(() => {
        axiosInstance.get(`/api/jobs/${job.id}/analytics/`).then((response) => {
            setData(response.data);
        });
    }, []);
    const today = new Date();
    return (
        <div>
            {data ? (
                <Container disableGutters sx={{ padding: "10px" }}>
                    <Typography>{data.card_views} people saw your job on the jobs board</Typography>
                    <Typography>{data.page_views} people visited your job advert</Typography>
                    <Typography>{data.applications} people clicked the application link</Typography>

                    <div style={{ height: "400px" }} className="funnel-container">
                        <ResponsiveFunnel
                            data={[
                                {
                                    label: "Saw job card",
                                    value: data.card_views,
                                    id: "card_views",
                                },
                                {
                                    label: "Visited job page",
                                    value: data.page_views,
                                    id: "page_views",
                                },
                                {
                                    label: "Clicked application link",
                                    value: data.applications,
                                    id: "applications",
                                },
                            ]}
                            direction="vertical"
                            margin={{ top: 10, right: 40, bottom: 10, left: 40 }}
                            labelColor="#ffffff"
                            colors={{ scheme: "category10" }}
                            borderWidth={10}
                        />
                    </div>
                    <Typography>Unique page views per day</Typography>
                    <div style={{ height: "400px" }} className="calendar-container">
                        <ResponsiveTimeRange
                            direction="vertical"
                            data={data.per_day}
                            // Set from to one day before job posting to include the posting date
                            from={new Date(
                                parse(job?.posted_date, "dd-MM-yyyy", new Date()) -
                                    24 * 60 * 60 * 1000
                            )
                                .toISOString()
                                .substring(0, 10)}
                            to={today.toISOString().substring(0, 10)}
                            emptyColor="#eeeeee"
                            colors={["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"]}
                            margin={{ top: 80, right: 10, bottom: 10, left: 40 }}
                            weekdayLegendOffset={10}
                        />
                    </div>
                </Container>
            ) : (
                <CircularProgress />
            )}
        </div>
    );
};

export default Analytics;
