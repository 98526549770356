import React, { useEffect, useRef } from "react";
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const SpacecraftsGSAP = () => {
    const sectionRefs = useRef([]);
    sectionRefs.current = [];

    const addToRefs = (el) => {
        if (el && !sectionRefs.current.includes(el)) {
            sectionRefs.current.push(el);
        }
    };

    function getActiveSectionIndex(progress) {
        // Ensure progress is clamped between 0 and 1
        progress = Math.max(0, Math.min(1, progress));

        // Calculate the index based on the progress
        const numberOfElements = sectionRefs.current.length;
        const segmentLength = 1 / numberOfElements;

        for (let i = 0; i < numberOfElements; i++) {
            if (progress < (i + 1) * segmentLength) {
                return i; // Return the index of the active section
            }
        }

        return numberOfElements - 1; // If progress is 1, return the last index
    }

    const getSectionProgress = (progress, section) => {
        const numberOfElements = sectionRefs.current.length;
        const segmentLength = 1 / numberOfElements;

        // Ensure section is within the bounds
        if (section < 0 || section >= numberOfElements) {
            throw new Error('Section index out of bounds');
        }

        // Calculate the start and end of the current section
        const sectionStart = section * segmentLength;
        const sectionEnd = sectionStart + segmentLength;

        // Calculate the section progress
        if (progress < sectionStart) {
            return 0;
        } else if (progress > sectionEnd) {
            return 1;
        } else {
            return (progress - sectionStart) / segmentLength;
        }
    };


    useEffect(() => {
        const sections = sectionRefs.current;

        gsap.set(sections, {autoAlpha: 0});

        const scrollTrigger = gsap.to(sections, {
            scrollTrigger: {
                trigger: "#spacecrafts",
                start: "top top",
                end: "+=1800",
                scrub: 1, pin: true,
                onUpdate: (self) => {
                    sections.forEach((section, index) => {
                        const activeSectionIndex = getActiveSectionIndex(self.progress);
                        const isActive = index === activeSectionIndex;
                        gsap.to(section, {
                            autoAlpha: !isActive && (getSectionProgress(self.progress, index) < 0.2 || getSectionProgress(self.progress, index) > 0.8) ? 0 : 1,
                            duration: 0.3,
                            pointerEvents: isActive ? "auto" : "none",
                        });

                        if (index === 0) { // Spacecraft
                            const sectionProgress = getSectionProgress(self.progress, 0);
                            gsap.to(".spaceship", {
                                autoAlpha: sectionProgress<0.1||sectionProgress>0.85?0:1,
                                x:  window.innerWidth * (sectionProgress),
                                y:  window.innerWidth<720?100 * (sectionProgress):window.innerHeight/2.5 * (sectionProgress),
                                rotation: 13,
                                scaleX: 1 + 0.6 * sectionProgress,
                                scaleY: 1 + 0.6 * sectionProgress,
                                overwrite: true,
                            });
                        } else if (index === 1) { // Telescope
                            const sectionProgress = getSectionProgress(self.progress, 1);
                            gsap.to(".telescope", {
                                rotation: 20 * sectionProgress,
                                scaleX:  1-0.35 * sectionProgress,
                                scaleY:  1-0.35 * sectionProgress,
                                overwrite: true,
                            });
                        } else if (index === 2) { // Rover
                            const sectionProgress = getSectionProgress(self.progress, 2);
                            gsap.to(".rover", {
                                x:  -window.innerWidth/3 * sectionProgress,
                                scaleX: 1 + 1.35 * sectionProgress,
                                scaleY: 1 + 1.35 * sectionProgress,
                                overwrite: true,
                            });
                        }
                    });
                },
            }
        });

        return () => {
            scrollTrigger.scrollTrigger.kill();
            gsap.set(sections, { autoAlpha: 1, duration: 0.5 });
        };
    }, []);

    return (
        <div id="spacecrafts" className="relative overflow-hidden min-h-screen">
            <div
                className="absolute inset-0"
                style={{
                    backgroundImage: `url('landing/Starfield.svg')`,
                    backgroundRepeat: 'repeat-y',
                    backgroundSize: '100% auto',
                    backgroundPosition: 'top left',
                    opacity: 0.2,
                }}
            />

            <div ref={addToRefs} className="section absolute inset-0 flex flex-col gap-16 items-center justify-center">
                <div data-aos="fade-up" className="fredoka-bold text-[24px] text-center px-4">
                    There are also spacecraft travelling to distant planets...
                </div>
                <div className="h-[350px] w-full flex relative justify-start relative">
                    <img
                        className="absolute -start-[30%] opacity-0 spaceship mb-12 lg:my-24 -rotate-20 h-[92px]"
                        src="landing/Spaceship.svg" alt="Spaceship"
                    />
                </div>
            </div>

            <div ref={addToRefs} className="section absolute inset-0 flex flex-col gap-16 items-center justify-center">
                <div data-aos="fade-up" className="fredoka-bold text-[24px] text-center px-4">
                    ...big telescopes being used to understand how the universe and life formed and developed...
                </div>
                <img
                    className="telescope w-full rotate-6 h-[350px]"
                    src="landing/Telescope.svg" alt="Telescope"
                />
            </div>

            <div ref={addToRefs} className="section absolute inset-0 flex flex-col gap-16 items-center justify-center">
                <div data-aos="fade-up" className="fredoka-bold text-[24px] text-center px-4">
                    ...and rovers on Mars
                </div>
                <div className="h-[350px] w-full relative">
                <img
                    className="absolute top-[35%] right-[10%] rover h-[60px] md:h-[150px]"
                    src="landing/Rover.svg" alt="Rover"
                />
                </div>
            </div>

        </div>
    );
};

export default SpacecraftsGSAP;
