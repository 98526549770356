const SpaceInTheUK=()=>{
    return(
        <div id="spaceInTheUk" className="max-w-5xl mx-auto py-20 p-8">
            <div
                data-aos={"fade-up"}
                className="text-start leading-none pb-12  tracking-tight koulen text-[48px] lg:text-[64px] text-landing-yellow">
                Space in the UK
            </div>
            <div className="flex flex-col gap-8">
                <div  data-aos={"fade-up"} className="flex items-center justify-between max-md:flex-col-reverse gap-8">
                    <div

                        className="text-start text-[24px]">
                        The UK Space Agency is the government agency responsible for the UK's civil space programme. It funds and oversees UK space missions to benefit UK citizens, understand the universe, and protect our planet and outer space.
                    </div>
                    <a href="https://www.gov.uk/government/organisations/uk-space-agency" target="_blank" className="bg-white aspect-square h-[133px] rounded-full flex items-center justify-center p-6">
                        <img src="landing/UK_Space.png" alt=""/>
                    </a>
                </div>
                <div  data-aos={"fade-up"} className="flex items-center justify-between max-md:flex-col-reverse gap-8">
                    <div className="text-start text-[24px]">
                        The UK is also a key member of the European Space Agency (ESA) along with 21 other European countries who work together on bigger missions that they could not do by themselves
                    </div>
                    <a href="https://www.esa.int/" target="_blank" className="bg-white aspect-square h-[133px] rounded-full flex items-center justify-center p-6">
                        <img src="landing/ESA.png" alt=""/>
                    </a>
                </div>

                <div  data-aos={"fade-up"} className="flex items-center justify-between max-md:flex-col-reverse gap-8">
                    <div className="text-start text-[24px]">
                        ESA works with other space agencies around the world too, like NASA in the United States and JAXA in Japan. Space is truly an international activity!
                    </div>
                    <a href="https://www.nasa.gov/" target="_blank" className="bg-white aspect-square h-[133px] rounded-full flex items-center justify-center p-2">
                        <img className="pl-2" src="landing/NASA_logo.png" alt=""/>
                    </a>
                </div>
            </div>

        </div>
    )
}
export default SpaceInTheUK;