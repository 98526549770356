import axiosInstance from "../axiosInstance";

export const isLoggedIn = () => {
    return (
        Boolean(localStorage.getItem("access_token")) &&
        Boolean(localStorage.getItem("refresh_token"))
    );
};

export const getTokenParts = () => {
    return JSON.parse(atob(localStorage.getItem("access_token").split(".")[1]));
};

export const getUserId = () => {
    try {
        return getTokenParts().user_id;
    } catch (error) {
        return undefined;
    }
};

export const getAccountType = () => {
    try {
        return getTokenParts().account_type;
    } catch (error) {
        return undefined;
    }
};

export const isStaff = () => {
    try {
        return !!getTokenParts()?.is_staff;
    } catch (error) {
        return false;
    }
};

export const removeTokens = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
};

export const refreshTokens = (afterRefresh = () => {}) => {
    axiosInstance
        .post("/api/auth/jwt/refresh/", { refresh: localStorage.getItem("refresh_token") })
        .then((response) => {
            localStorage.setItem("access_token", response.data.access);
            localStorage.setItem("refresh_token", response.data.refresh);
        })
        .then(afterRefresh)
        .catch((err) => {
            console.log(err);
        });
};
