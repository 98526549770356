const BackgroundImages = () => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="432px"
        height="491.161px"
        viewBox="0 0 432 491.161"
        enableBackground="new 0 0 432 491.161"
        style={{ width: "100%", height: "100%", top: "-20px", position: "relative" }}
    >
        <g id="Background">
            <rect
                id="Sky"
                x="-3.349"
                y="-163.467"
                fill="#B4C8D7"
                width="436.318"
                height="612.242"
            ></rect>
            <g id="Sea">
                <path
                    id="Fish"
                    fill="#FFC80D"
                    d="M164.057,283.936c-0.049,0.055-0.076,0.123-0.134,0.166c-0.004-0.002-0.012-0.008-0.016-0.01
			c0.022-0.064,0.058-0.127,0.068-0.195c0-0.041,0.005-0.098-0.044-0.117c-0.087-0.052-0.195-0.029-0.289-0.01
			c-0.112,0.021-0.216,0.066-0.325,0.104c-0.109,0.045-0.337,0.033-1.58,0.912c-0.459,0.324-0.881,0.691-0.91,0.697
			c-0.352,0.377-1.136,0.344-1.278,0.424c-0.029,0.006,0.004,0.064-0.004,0.072l0,0c0.057,0.076,0.529,0.59,0.605,0.547
			c-0.046,0.089-0.118,0.158-0.171,0.244c-0.09,0.137-0.479,0.895-0.7,1.26c-0.083,0.125-0.191,0.23-0.296,0.34
			c-0.102,0.102-0.224,0.182-0.347,0.256c-0.152,0.088-1.51,0.674-1.483,0.713c0.276,0.261,0.602,0.293,0.943,0.424
			c0.039,0.029,0.087,0.05,0.119,0.091c0.131,0.237,0.083,0.612,0.225,0.854c0.038,0.035,0.057,0.084,0.076,0.131
			c0.047-0.043,0.093-0.086,0.135-0.133c0.084-0.107,0.495-0.824,0.561-0.935c0.063-0.104,0.104-0.224,0.167-0.328
			c0.036-0.062,0.053-0.129,0.091-0.187c0.081-0.108,0.163-0.219,0.268-0.307c0.073-0.063,0.146-0.123,0.226-0.176
			c0.06-0.039,0.494-0.348,0.789-0.498c0.154-0.137,0.925-0.479,0.988-0.52c0.058,0.223,0.144,0.287,0.289,0.471
			c0.046,0.072,0.11,0.139,0.199,0.146c0.035-0.004,0.065-0.031,0.072-0.066c-0.029-0.615,0.176-1.076,0.144-1.137
			c0.2-0.162,0.361-0.366,0.542-0.551c0.976-1.031,1.117-2.265,1.145-2.363c0.025-0.08,0.05-0.17,0.027-0.254
			C164.158,283.954,164.102,283.917,164.057,283.936z M163.429,284.522c-0.058,0.061-0.154,0.063-0.214,0.004
			c-0.061-0.057-0.063-0.152-0.004-0.213c0.058-0.061,0.154-0.063,0.214-0.004C163.486,284.368,163.487,284.463,163.429,284.522z
			 M175.108,297.236c0.005,0.302-0.233,0.548-0.533,0.554l0,0c-0.301,0.006-0.549-0.233-0.555-0.535l0,0
			c-0.005-0.301,0.234-0.547,0.535-0.553l0,0C174.855,296.696,175.103,296.935,175.108,297.236L175.108,297.236z"
                ></path>
                <rect x="3.54" y="287.049" fill="#273553" width="441.579" height="72.471"></rect>
                <g id="Central_Waves">
                    <path
                        fill="#273553"
                        d="M215.06,286.85c-9.53,0-9.53-7.68-19.76-7.721c-10.23,0.041-10.23,7.721-19.76,7.721
				c-9.54,0-9.87-7.42-20.09-7.45c-10.23,0.03-9.9,7.45-19.44,7.45l6.94,4.52l72.76,0.961
				C215.71,292.331,217.09,287.84,215.06,286.85z"
                    ></path>
                    <path
                        fill="#273553"
                        d="M293.779,286.85c-9.539,0-9.539-7.68-19.76-7.721c-10.229,0.041-10.229,7.721-19.77,7.721
				c-9.53,0-9.86-7.42-20.09-7.45c-10.222,0.03-9.892,7.45-19.431,7.45l6.931,4.52l72.771,0.961
				C294.431,292.331,295.811,287.84,293.779,286.85z"
                    ></path>
                </g>
                <path
                    fill="#273553"
                    d="M436.811,286.85c-9.531,0-9.531-7.68-19.761-7.721c-10.221,0.041-10.221,7.721-19.761,7.721
			c-9.539,0-9.869-7.42-20.09-7.45c-10.229,0.03-9.899,7.45-19.431,7.45l6.931,4.52l72.771,0.961
			C437.47,292.331,438.84,287.84,436.811,286.85z"
                ></path>
            </g>
            <g id="Water">
                <g id="Submarine">
                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				186.64,315.85 182.8,310.331 203.17,310.331 200.35,315.65 			"
                    ></polygon>

                    <path
                        fill="#FCA803"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M204.89,307.209l2.12-3.01c0,0,0.35-2.609-5.95-2.609h-10.39c0,0-7.29,0.64-17.82,5.56c-0.1,0.24-0.21,0.41,0,0.461
				c17.37,4.819,19.84,4.319,19.84,4.319h9.271C201.96,311.93,204.64,311.879,204.89,307.209z"
                    ></path>

                    <path
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M174.03,302.9v8.63c0,0,7.711,0.7,7.771-4.419C181.85,301.991,174.03,302.9,174.03,302.9z"
                    ></path>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				200.96,301.491 200.35,294.77 194.2,294.77 190.57,301.59 			"
                    ></polygon>
                    <polygon
                        fill="#FCA803"
                        points="195.71,290.65 195.61,297.78 196.12,297.78 			"
                    ></polygon>
                    <polygon
                        fill="#FFFFFF"
                        points="205.14,308.03 207.06,309.129 208.67,307.42 210.69,306.72 210.23,307.12 210.86,307.27 
				208.87,307.93 207.26,309.831 204.84,308.53 			"
                    ></polygon>
                    <polygon
                        fill="#FFFFFF"
                        points="206.8,303.1 207.71,301.792 206.7,300.491 207.11,300.09 209.12,301.59 208.62,302.19 
				208.22,301.89 207.01,303.6 			"
                    ></polygon>
                    <polygon
                        fill="#FCA803"
                        points="209.73,301.792 208.92,302.799 208.42,302.39 209.33,301.292 			"
                    ></polygon>

                    <line
                        fill="none"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="194.5"
                        y1="304.1"
                        x2="198.33"
                        y2="304.1"
                    ></line>

                    <line
                        fill="none"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="194.55"
                        y1="302.7"
                        x2="198.38"
                        y2="302.7"
                    ></line>

                    <line
                        fill="none"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="194.5"
                        y1="305.411"
                        x2="198.33"
                        y2="305.411"
                    ></line>

                    <line
                        fill="none"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="194.5"
                        y1="306.709"
                        x2="198.33"
                        y2="306.709"
                    ></line>

                    <line
                        fill="none"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="194.55"
                        y1="308.02"
                        x2="198.38"
                        y2="308.02"
                    ></line>

                    <line
                        fill="none"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="176.2"
                        y1="302.85"
                        x2="176.2"
                        y2="311.581"
                    ></line>
                </g>
                <g id="Wave">
                    <rect
                        x="157.6"
                        y="311.049"
                        fill="#273553"
                        width="98.169"
                        height="87.111"
                    ></rect>

                    <path
                        fill="#273553"
                        stroke="#5696EC"
                        strokeWidth="0.72"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M246.569,311.292c-9.539,0-11.05-9.801-21.27-9.842c-10.23,0.041-10.23,7.711-19.76,7.711c-9.54,0-9.87-7.41-20.101-7.449
				c-10.22,0.039-10.45,10.22-19.989,10.22"
                    ></path>
                </g>
            </g>
            <g id="Land_1_">
                <path
                    id="Land"
                    fill="#5696EC"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M446.079,330c-59.761,0-59.761-48.229-123.829-48.469C258.17,281.77,258.17,330,198.41,330c-59.761,0-59.761-84.7-123.83-84.939
			C10.5,245.3,9.21,315.549-50.55,315.549L-8.37,613.68l461.08-1.449C452.71,612.231,458.8,336.241,446.079,330z"
                ></path>

                <path
                    fill="#5696EC"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M182.57,341.52c24.721-0.479,20.881-17.04,42-16.32c21.12,0.721,19.922,16.561,42.48,17.521"
                ></path>

                <path
                    fill="#5696EC"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M196.25,372c24.72-0.479,25.59-15.859,46.71-15.14c21.108,0.72,15.62,12.489,38.18,13.449"
                ></path>

                <path
                    fill="#5696EC"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M312.89,321.84c24.721-0.479,25.58-15.86,46.7-15.141c21.12,0.721,15.62,12.489,38.18,13.449"
                ></path>

                <path
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M246.649,335.042c32.88,0.479,23.762-17.512,44.88-16.791c21.121,0.721,17.04,17.029,39.602,17.99"
                ></path>
            </g>
            <g id="Road">
                <rect
                    x="-20.48"
                    y="423.6"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="456.24"
                    height="32.641"
                ></rect>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="19.76"
                    y1="440.042"
                    x2="94.54"
                    y2="440.042"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="127.05"
                    y1="440.042"
                    x2="201.83"
                    y2="440.042"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="231.2"
                    y1="440.042"
                    x2="305.99"
                    y2="440.042"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="338.49"
                    y1="440.042"
                    x2="413.279"
                    y2="440.042"
                ></line>
            </g>
            <g id="Crack">
                <polyline
                    fill="#263352"
                    points="111.76,455.92 116.08,445.629 113.2,443.95 118,434.37 115.36,431.741 118.24,423.84 
			111.76,431.981 114.64,434.612 108.16,445.151 112.96,447.059 109.6,456.241 		"
                ></polyline>
                <polyline
                    fill="#263352"
                    points="112.627,438.133 106.48,436.542 107.92,431.991 98.32,429.12 105.52,433.43 104.08,437.741 
			110.709,441.252 		"
                ></polyline>
            </g>
            <g id="Clouds">
                <path
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M251.811,144.39c5.759,1.92,8.303,6.002,8.159,10.32c-0.282,8.464-5.28,10.08-7.92,10.08h-94.32
			c0.24-0.72,1.854-13.543,12.48-12.96c1.198,0.066,4.8,0.72,6.24,2.16c4.206-5.722,9.119-2.4,9.84-2.4
			c0.479,0,3.241-14.384,17.585-14.197c6.561,0.086,12.654,4.847,13.135,4.847c0,0,2.88-4.01,6.721-4.08
			c3.207-0.059,4.507,1.307,5.039,1.92c3.602-6,6.961-6.48,11.041-6.48C245.09,133.6,250.61,136.24,251.811,144.39z"
                ></path>

                <path
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M51.18,171.03c0.469,0.105,2.16,0.479,3.36,0.72c0-5.28,3.12-7.44,6.479-7.44c1.44,0,2.022-0.188,3.601,0.96
			c1.92-7.92,5.137-10.559,11.76-10.56c10.912-0.002,10.82,9.592,11.52,11.28c0.495,1.194,6.23,1.924,6.23,9.6
			c0,5.28-4.311,10.32-8.87,10.32c0,0-33.75-0.014-34.56,0C37.6,185.91,40.563,168.643,51.18,171.03z"
                ></path>

                <path
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M335.9,158.91c0.16,0,2.76-0.98,3.399-0.98c3.89,0,6.479,3.42,6.479,6.68c0,0.33-0.489,1.96-0.489,2.28
			c0,0.49,0.171,0.66,0.649,0.82c0.65,0.33,1.78,0.65,2.59,1.14c1.951,1.14,2.761,3.91,2.761,5.22c0,3.09-2.601,6.68-6,6.68h-32.221
			c-5.989-1.31-8.579-5.38-8.579-9.78c0-5.71,4.529-9.62,9.55-9.62c0.489,0,2.75,0.33,4.051,0.49c2.1-5.05,5.51-7.01,9.55-7.01
			C332.5,154.83,335.25,158.91,335.9,158.91z"
                ></path>
            </g>
        </g>
        <g id="Radio_Dishes">
            <g id="Radio_Dish_2">
                <polygon
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			65.144,237.139 68.28,251.9 57.84,251.9 60.976,237.139 		"
                ></polygon>
                <g id="Dish_2">
                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.72"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				63.06,235.722 67.865,231.048 69.185,232.488 64.435,237.12 			"
                    ></polygon>

                    <path
                        fill="#FFC80D"
                        stroke="#273553"
                        strokeWidth="0.72"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M65.446,212.24c-1.38,2.431-2.17,5.24-2.17,8.229c0,9.2,7.45,16.649,16.65,16.649c2.99,0,5.8-0.789,8.22-2.17
				C88.146,234.95,68.406,214.78,65.446,212.24z"
                    ></path>

                    <line
                        fill="none"
                        stroke="#273553"
                        strokeWidth="0.72"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="88.116"
                        y1="234.831"
                        x2="88.116"
                        y2="212.56"
                    ></line>

                    <polyline
                        fill="none"
                        stroke="#273553"
                        strokeWidth="0.72"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				65.566,212.28 88.036,212.28 76.866,223.52 			"
                    ></polyline>

                    <path
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.72"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M88.956,212.64c0-0.66-0.54-1.2-1.2-1.2c-0.67,0-1.2,0.54-1.2,1.2c0,0.67,0.53,1.2,1.2,1.2
				C88.416,213.84,88.956,213.31,88.956,212.64z"
                    ></path>
                </g>

                <path
                    id="Joint_2"
                    fill="#FFC80D"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M65.7,237.14c0-1.46-1.19-2.641-2.641-2.641c-1.46,0-2.64,1.181-2.64,2.641s1.18,2.64,2.64,2.64
			C64.51,239.78,65.7,238.6,65.7,237.14z"
                ></path>
            </g>
            <g id="Radio_Dish_1">
                <polygon
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			99.948,250.12 103.084,264.879 92.644,264.879 95.78,250.12 		"
                ></polygon>
                <g id="Dish_1">
                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.72"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				97.864,248.704 102.668,244.029 103.988,245.468 99.239,250.1 			"
                    ></polygon>

                    <path
                        fill="#FFC80D"
                        stroke="#273553"
                        strokeWidth="0.72"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M100.25,225.22c-1.38,2.431-2.17,5.24-2.17,8.23c0,9.2,7.449,16.649,16.649,16.649c2.99,0,5.8-0.789,8.221-2.17
				C122.95,247.93,103.21,227.76,100.25,225.22z"
                    ></path>

                    <line
                        fill="none"
                        stroke="#273553"
                        strokeWidth="0.72"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="122.92"
                        y1="247.811"
                        x2="122.92"
                        y2="225.541"
                    ></line>

                    <polyline
                        fill="none"
                        stroke="#273553"
                        strokeWidth="0.72"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				100.37,225.26 122.84,225.26 111.67,236.5 			"
                    ></polyline>

                    <path
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.72"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M123.76,225.621c0-0.66-0.54-1.2-1.2-1.2c-0.67,0-1.2,0.54-1.2,1.2c0,0.67,0.53,1.2,1.2,1.2
				C123.22,226.821,123.76,226.291,123.76,225.621z"
                    ></path>
                </g>

                <path
                    id="Joint_1"
                    fill="#FFC80D"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M100.504,250.122c0-1.461-1.19-2.642-2.641-2.642c-1.46,0-2.64,1.181-2.64,2.642c0,1.459,1.18,2.639,2.64,2.639
			C99.313,252.76,100.504,251.581,100.504,250.122z"
                ></path>
            </g>
        </g>
        <g id="Observatory">
            <g id="Observatory_1_">
                <path
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M323.329,267.73v12.72h-6.96v-12.72c-7,0-12.669,5.66-12.669,12.66c3.959-0.06,32.299,0,32.299,0
			C336,273.39,330.329,267.73,323.329,267.73z"
                ></path>

                <rect
                    x="317.199"
                    y="270.049"
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="5.319"
                    height="19.391"
                ></rect>

                <path
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M317.4,270.01c0-0.67,1.05-1.17,2.521-1.17c1.47,0,2.561,0.5,2.561,1.17s-0.951,1.209-2.42,1.209
			C318.59,271.219,317.4,270.68,317.4,270.01z"
                ></path>

                <polygon
                    id="Shutter_Left"
                    fill="#FFC80D"
                    stroke="#283654"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			313.25,267.64 316.369,266.95 316.369,280.69 313.25,280.69 		"
                ></polygon>

                <polygon
                    id="Shutter_Right"
                    fill="#FFC80D"
                    stroke="#283654"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			323.329,266.87 326.449,267.719 326.449,280.69 323.329,280.69 		"
                ></polygon>

                <rect
                    x="297.65"
                    y="280.209"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="44.16"
                    height="12"
                ></rect>
                <rect x="301.85" y="288.969" fill="#273553" width="2.4" height="3.361"></rect>
            </g>
            <g id="Trees">
                <polygon
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			275.689,272.18 270.65,283.09 273.77,283.09 268.97,295.81 274.13,295.81 274.13,301.09 277.73,301.09 277.73,296.049 
			282.29,296.049 277.61,283.09 280.97,283.09 		"
                ></polygon>

                <polygon
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			295.37,276.25 290.33,287.17 293.45,287.17 288.65,299.89 293.811,299.89 293.811,305.17 297.41,305.17 297.41,300.129 
			301.97,300.129 297.29,287.17 300.65,287.17 		"
                ></polygon>

                <polygon
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			286.73,281.532 281.689,292.45 284.811,292.45 280.01,305.17 285.17,305.17 285.17,310.45 288.77,310.45 288.77,305.411 
			293.33,305.411 288.65,292.45 292.01,292.45 		"
                ></polygon>

                <polygon
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			314.09,282.25 309.05,293.17 312.17,293.17 307.369,305.89 312.529,305.89 312.529,311.17 316.13,311.17 316.13,306.129 
			320.689,306.129 316.01,293.17 319.369,293.17 		"
                ></polygon>

                <polygon
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			262.49,285.129 257.45,296.049 260.569,296.049 255.77,308.77 260.931,308.77 260.931,314.049 264.529,314.049 264.529,309.01 
			269.09,309.01 264.41,296.049 267.77,296.049 		"
                ></polygon>

                <polygon
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			352.73,267.37 347.689,278.292 350.811,278.292 346.01,291.01 351.17,291.01 351.17,296.292 354.77,296.292 354.77,291.25 
			359.329,291.25 354.65,278.292 358.01,278.292 		"
                ></polygon>
            </g>
        </g>
        <g id="Farm">
            <g>
                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="16.5"
                    y1="319.81"
                    x2="88.5"
                    y2="319.81"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="16.5"
                    y1="325.57"
                    x2="88.5"
                    y2="325.57"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="16.5"
                    y1="330.85"
                    x2="88.5"
                    y2="330.85"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="16.5"
                    y1="336.129"
                    x2="88.5"
                    y2="336.129"
                ></line>
            </g>
            <g>
                <polygon
                    fill="#273553"
                    points="48.18,282.01 40.98,283.93 35.94,283.93 35.94,284.411 40.98,284.411 48.18,286.57 		"
                ></polygon>

                <polyline
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			29.46,304.81 41.94,314.411 35.06,284.25 26.58,314.411 39.54,304.57 		"
                ></polyline>

                <polygon
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			32.1,295.209 38.58,299.611 29.58,304.69 39.62,304.57 31.14,299.53 37.62,295.209 		"
                ></polygon>

                <path
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M29.7,284.532c0-2.98,2.409-5.4,5.399-5.4c2.98,0,5.4,2.42,5.4,5.4c0,2.988-2.42,5.398-5.4,5.398
			C32.11,289.93,29.7,287.52,29.7,284.532z"
                ></path>

                <circle
                    fill="#FFC80D"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    cx="35.1"
                    cy="284.291"
                    r="1.32"
                ></circle>
                <g id="Blades">
                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				37.62,282.969 42.66,281.049 41.94,282.969 37.86,283.69 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				36.57,282.081 40.26,277.93 40.48,280.02 37.11,282.62 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				35.5,281.629 36.93,276.37 38.05,278.081 36.23,281.89 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				34.15,281.94 33.1,276.44 34.85,277.491 34.91,281.831 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				33.27,282.44 30.02,278.14 32.08,278.32 33.9,282.01 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				32.7,283.69 27.6,281.282 29.61,280.5 33.07,283.02 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				32.56,284.84 26.95,284.959 28.36,283.37 32.6,284.07 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				33.23,286.06 28.09,288.709 28.65,286.68 32.91,285.361 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				33.87,286.89 30.45,291.111 30.11,289.07 33.3,286.379 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				35.23,287.15 34.12,292.629 32.87,290.92 34.49,286.95 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				36.39,287 37.8,292.331 35.96,291.39 35.62,287.15 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				37.41,286.049 41.11,290.331 39.05,290.292 36.8,286.532 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				37.92,285.17 42.93,287.299 41.04,288.17 37.59,285.861 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				37.79,284.02 43.45,283.57 42.1,285.26 37.81,284.799 			"
                    ></polygon>
                </g>
            </g>
            <g>
                <g>
                    <rect
                        x="50.82"
                        y="283.93"
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.72"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        width="10.08"
                        height="31.92"
                    ></rect>

                    <path
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.72"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M50.82,283.93c0-2.78,2.25-5.04,5.04-5.04c2.78,0,5.04,2.26,5.04,5.04c0,2.789-2.26,5.039-5.04,5.039
				C53.07,288.969,50.82,286.719,50.82,283.93z"
                    ></path>
                </g>
                <g>
                    <rect
                        x="64.02"
                        y="279.129"
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.72"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        width="10.08"
                        height="31.92"
                    ></rect>

                    <path
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.72"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M64.02,279.129c0-2.779,2.25-5.029,5.04-5.029c2.78,0,5.04,2.25,5.04,5.029c0,2.791-2.26,5.041-5.04,5.041
				C66.27,284.17,64.02,281.92,64.02,279.129z"
                    ></path>
                </g>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="50.94"
                    y1="292.69"
                    x2="60.78"
                    y2="292.69"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="51.18"
                    y1="294.37"
                    x2="60.78"
                    y2="294.37"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="50.94"
                    y1="300.611"
                    x2="60.78"
                    y2="300.611"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="50.94"
                    y1="302.292"
                    x2="60.78"
                    y2="302.292"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="64.14"
                    y1="287.89"
                    x2="73.98"
                    y2="287.89"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="64.14"
                    y1="289.57"
                    x2="73.98"
                    y2="289.57"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="64.14"
                    y1="295.81"
                    x2="73.74"
                    y2="295.81"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="64.14"
                    y1="297.491"
                    x2="73.98"
                    y2="297.491"
                ></line>
            </g>
            <g id="Tractor">
                <path
                    fill="#273553"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M35.8,322.59c0-3.82,3.11-6.93,6.94-6.93c3.819,0,6.93,3.108,6.93,6.93c0,3.83-3.11,6.938-6.93,6.938
			C38.91,329.53,35.8,326.42,35.8,322.59z"
                ></path>

                <path
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M39.27,322.59c0-1.91,1.55-3.461,3.471-3.461c1.909,0,3.46,1.551,3.46,3.461c0,1.92-1.551,3.47-3.46,3.47
			C40.82,326.06,39.27,324.51,39.27,322.59z"
                ></path>

                <path
                    fill="#273553"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M56.07,325.26c0-2,1.62-3.61,3.61-3.61c2,0,3.62,1.61,3.62,3.61c0,1.988-1.62,3.609-3.62,3.609
			C57.69,328.87,56.07,327.25,56.07,325.26z"
                ></path>

                <rect
                    x="58.01"
                    y="311.37"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="1.7"
                    height="4.051"
                ></rect>

                <rect
                    x="58.24"
                    y="309.73"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="1.18"
                    height="1.64"
                ></rect>

                <polygon
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			41.44,309.78 41.74,314.01 42.91,314.01 42.03,309.78 		"
                ></polygon>

                <path
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M57.54,325.26c0-1.19,0.96-2.149,2.14-2.149c1.19,0,2.15,0.959,2.15,2.149c0,1.18-0.96,2.14-2.15,2.14
			C58.5,327.4,57.54,326.44,57.54,325.26z"
                ></path>

                <polygon
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			48.14,313.37 46.14,311.661 45.79,312.02 47.96,313.78 		"
                ></polygon>
                <g id="Driver">
                    <polygon
                        fill="#FFFAFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				44.43,311.292 45.04,314.161 42.34,314.161 42.61,311.292 			"
                    ></polygon>

                    <path
                        fill="#FFFAFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M45.04,309.77c0-0.88-0.681-1.6-1.521-1.6s-1.52,0.72-1.52,1.6s0.68,1.6,1.52,1.6S45.04,310.65,45.04,309.77z"
                    ></path>

                    <polygon
                        fill="#FFFAFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				46.26,312.65 43.52,311.69 43.22,312.65 45.95,313.611 			"
                    ></polygon>
                </g>

                <path
                    fill="#FFC80D"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M49.14,313.9l-0.71-5.289l0.83-0.819v-1.062h-9.87v7.05l-4.17,3.762c0,0,2.05-0.24,2.17-0.41c5.23-5.58,14.28-0.53,12.811,6.93
			c0,0,5.05-0.061,5.17,0c0.29-0.939,2.109-4.41,6.22-2.699l0.939-1.881v-3.939L49.14,313.9z M47.67,313.542h-7.05v-5.521h7.05
			V313.542z"
                ></path>

                <polygon
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			46.55,310.84 45.09,312.491 45.38,312.72 46.85,311.081 		"
                ></polygon>
            </g>
            <g>
                <polygon
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			38.33,325.7 33.29,336.611 36.41,336.611 31.61,349.331 36.77,349.331 36.77,354.611 40.37,354.611 40.37,349.57 44.93,349.57 
			40.25,336.611 43.61,336.611 		"
                ></polygon>

                <polygon
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			73.85,331.209 68.81,342.129 71.93,342.129 67.13,354.85 72.29,354.85 72.29,360.129 75.89,360.129 75.89,355.09 80.45,355.09 
			75.77,342.129 79.13,342.129 		"
                ></polygon>

                <polygon
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			58.01,335.292 52.97,346.209 56.09,346.209 51.29,358.93 56.45,358.93 56.45,364.209 60.05,364.209 60.05,359.17 64.61,359.17 
			59.93,346.209 63.29,346.209 		"
                ></polygon>

                <polygon
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			109.61,321.129 104.57,332.049 107.69,332.049 102.89,344.77 108.05,344.77 108.05,350.049 111.65,350.049 111.65,345.01 
			116.21,345.01 111.53,332.049 114.89,332.049 		"
                ></polygon>

                <polygon
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			25.13,338.65 20.09,349.57 23.21,349.57 18.41,362.292 23.57,362.292 23.57,367.57 27.17,367.57 27.17,362.53 31.73,362.53 
			27.05,349.57 30.41,349.57 		"
                ></polygon>

                <polygon
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			94.25,335.292 89.21,346.209 92.33,346.209 87.53,358.93 92.69,358.93 92.69,364.209 96.29,364.209 96.29,359.17 100.85,359.17 
			96.17,346.209 99.53,346.209 		"
                ></polygon>
            </g>
        </g>
        <g id="Planetary_Scientists">
            <g>
                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			295.75,335.28 294.84,341.76 296.061,341.76 296.66,339.2 297.27,341.76 298.49,341.76 297.58,335.28 		"
                ></polygon>

                <path
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M295.14,333.76c0-0.881,0.68-1.6,1.521-1.6c0.84,0,1.521,0.719,1.521,1.6c0,0.89-0.681,1.601-1.521,1.601
			C295.819,335.361,295.14,334.65,295.14,333.76z"
                ></path>

                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			294.02,336.411 296.34,335.111 296.84,336.111 294.22,337.28 		"
                ></polygon>
            </g>
            <g>
                <path
                    fill="#283654"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M268.97,340.56c0,0,0.961-1.62,1.92-1.681c0.96-0.061,3.13-0.649,3.521-0.08c0.39,0.57,1.1,0.921,1.278,2
			C275.779,341.34,268.97,340.56,268.97,340.56z"
                ></path>

                <path
                    fill="#283654"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M258.14,338.459c0,0,1.23-2.399,2.19-2.461c0.96-0.06,1.26-0.119,1.649,0.451c0.391,0.569,1.851,1.189,2.029,2.271
			C264.1,339.26,258.14,338.459,258.14,338.459z"
                ></path>

                <path
                    fill="#283654"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M275.66,335.581c0,0,0.93-1.711,1.89-1.771c0.96-0.062,1.72-0.94,3.82-1.33c2.609-0.479,2.21,1.931,2.39,3.011
			C283.85,336.03,275.66,335.581,275.66,335.581z"
                ></path>

                <path
                    fill="#283654"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M284.061,338.7c0,0,1.311-2.479,2.271-2.539s1.149,1.189,1.54,1.76c0.39,0.572,0.239-0.271,0.42,0.811
			C288.38,339.27,284.061,338.7,284.061,338.7z"
                ></path>
            </g>
            <g>
                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			278.14,332.4 279.05,338.879 277.83,338.879 277.22,336.32 276.62,338.879 275.4,338.879 276.311,332.4 		"
                ></polygon>

                <path
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M278.74,330.879c0-0.881-0.68-1.601-1.521-1.601c-0.83,0-1.52,0.72-1.52,1.601c0,0.891,0.688,1.601,1.52,1.601
			C278.061,332.48,278.74,331.77,278.74,330.879z"
                ></path>

                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			280.319,333.35 277.45,333.15 277.39,334.31 280.29,334.35 		"
                ></polygon>
            </g>

            <rect
                x="279.77"
                y="332.64"
                fill="#FCA803"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.722"
                height="1.2"
            ></rect>

            <line
                fill="none"
                stroke="#FCA803"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="281.569"
                y1="333.241"
                x2="281.811"
                y2="333.48"
            ></line>

            <line
                fill="none"
                stroke="#FCA803"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="280.61"
                y1="334.92"
                x2="281.181"
                y2="335.1"
            ></line>

            <line
                fill="none"
                stroke="#FCA803"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="285.65"
                y1="337.081"
                x2="286.22"
                y2="336.9"
            ></line>

            <line
                fill="none"
                stroke="#FCA803"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="287.09"
                y1="338.52"
                x2="287.24"
                y2="338.161"
            ></line>

            <line
                fill="none"
                stroke="#FCA803"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="271.97"
                y1="340.2"
                x2="272.12"
                y2="339.84"
            ></line>

            <line
                fill="none"
                stroke="#FCA803"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="270.931"
                y1="339.48"
                x2="271.33"
                y2="339.28"
            ></line>

            <line
                fill="none"
                stroke="#FCA803"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="273.33"
                y1="339.72"
                x2="273.49"
                y2="339.52"
            ></line>

            <line
                fill="none"
                stroke="#FCA803"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="261.04"
                y1="337.991"
                x2="260.85"
                y2="337.581"
            ></line>

            <line
                fill="none"
                stroke="#FCA803"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="259.71"
                y1="337.9"
                x2="259.83"
                y2="337.43"
            ></line>

            <line
                fill="none"
                stroke="#FCA803"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="261.54"
                y1="336.611"
                x2="261.26"
                y2="336.32"
            ></line>
            <g>
                <g>
                    <path
                        fill="#273553"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M295.13,338.4c1.2,0.75,1.08,3.45,1.08,3.45l-6.24-0.391c0.091-1.949,1.32-3.061,1.32-3.061L295.13,338.4L295.13,338.4z"
                    ></path>

                    <polyline
                        fill="none"
                        stroke="#FCA803"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				295.13,337.92 291.77,337.92 291.05,334.799 			"
                    ></polyline>
                </g>

                <line
                    fill="none"
                    stroke="#FCA803"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="291.17"
                    y1="340.92"
                    x2="291.33"
                    y2="340.5"
                ></line>

                <line
                    fill="none"
                    stroke="#FCA803"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="291.95"
                    y1="339.299"
                    x2="292.52"
                    y2="339.361"
                ></line>

                <line
                    fill="none"
                    stroke="#FCA803"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="293.33"
                    y1="340.2"
                    x2="293.9"
                    y2="339.78"
                ></line>

                <line
                    fill="none"
                    stroke="#FCA803"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="295.46"
                    y1="340.89"
                    x2="295.41"
                    y2="340.44"
                ></line>
            </g>
        </g>
        <g id="Mars_Yard">
            <g id="Rover">
                <polygon
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			256.73,387.57 262.87,393.709 262.15,394.48 256.73,389.111 		"
                ></polygon>

                <polygon
                    id="Rover_Front_Arm"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			257.031,388.638 250.63,393.879 250.431,393.57 251.069,393.06 250.8,392.799 250.03,392.95 256.431,387.818 		"
                ></polygon>

                <rect
                    x="258.89"
                    y="393.82"
                    fill="#FCA803"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="17.75"
                    height="5.28"
                ></rect>

                <path
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M259.85,398.709c0-1.061,0.852-1.92,1.92-1.92c1.062,0,1.92,0.859,1.92,1.92s-0.858,1.92-1.92,1.92
			C260.7,400.629,259.85,399.77,259.85,398.709z"
                ></path>

                <circle
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    cx="267.91"
                    cy="398.709"
                    r="1.92"
                ></circle>

                <circle
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    cx="274.05"
                    cy="398.709"
                    r="1.92"
                ></circle>

                <rect
                    x="265.37"
                    y="380.379"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="13.201"
                ></rect>

                <path
                    fill="#FCA803"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M264.89,380.741c0-0.459,0.37-0.841,0.841-0.841c0.459,0,0.83,0.382,0.83,0.841c0,0.471-0.371,0.84-0.83,0.84
			C265.26,381.581,264.89,381.209,264.89,380.741z"
                ></path>

                <rect
                    x="267.52"
                    y="389.98"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="7.682"
                    height="3.84"
                ></rect>

                <path
                    fill="#FCA803"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M256.01,388.42c0-0.461,0.37-0.84,0.84-0.84c0.461,0,0.84,0.379,0.84,0.84c0,0.47-0.379,0.84-0.84,0.84
			C256.38,389.26,256.01,388.89,256.01,388.42z"
                ></path>
            </g>
            <g>
                <path
                    fill="#283654"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M242.09,401.02c0,0,0.479-2.34,1.439-2.4s2.729-0.088,3.119,0.48c0.392,0.57,1.98,1.08,2.161,2.16
			C248.899,401.799,242.09,401.02,242.09,401.02z"
                ></path>

                <path
                    fill="#283654"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M240.38,396.042c0,0,1.17-4.291,2.13-4.352s1.32,1.771,1.71,2.341c0.391,0.569,0.18,0.81,0.36,1.89
			C244.67,396.459,240.38,396.042,240.38,396.042z"
                ></path>

                <path
                    fill="#283654"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M247.58,398.2c0,0,0.93-1.709,1.89-1.77c0.961-0.061,1.53-2.61,3.63-3c2.602-0.48,2.4,3.602,2.581,4.682
			C255.77,398.651,247.58,398.2,247.58,398.2z"
                ></path>

                <path
                    fill="#283654"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M251.42,401.081c0,0,0.93-1.711,1.891-1.771c0.959-0.061,1.529,0.422,1.92,0.99c0.391,0.57,0.239-0.27,0.42,0.813
			C255.74,401.651,251.42,401.081,251.42,401.081z"
                ></path>
            </g>
            <g>
                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			298.39,392.861 297.48,399.34 298.689,399.34 299.3,396.782 299.91,399.34 301.12,399.34 300.21,392.861 		"
                ></polygon>

                <path
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M297.779,391.34c0-0.881,0.682-1.6,1.521-1.6s1.52,0.719,1.52,1.6c0,0.89-0.68,1.6-1.52,1.6S297.779,392.23,297.779,391.34z"
                ></path>

                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			296.561,394.22 299.3,393.26 299.6,394.22 296.87,395.18 		"
                ></polygon>
            </g>
            <g>
                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			220.58,394.06 221.49,400.542 220.28,400.542 219.67,397.981 219.061,400.542 217.85,400.542 218.76,394.06 		"
                ></polygon>

                <path
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M221.189,392.542c0-0.881-0.68-1.602-1.52-1.602s-1.521,0.721-1.521,1.602c0,0.89,0.682,1.6,1.521,1.6
			S221.189,393.43,221.189,392.542z"
                ></path>

                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			222.41,395.42 219.67,394.459 219.37,395.42 222.1,396.379 		"
                ></polygon>
            </g>
            <g>
                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			235.51,394.299 234.6,400.782 235.811,400.782 236.42,398.219 237.03,400.782 238.25,400.782 237.33,394.299 		"
                ></polygon>

                <path
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M234.899,392.782c0-0.882,0.682-1.602,1.521-1.602s1.52,0.72,1.52,1.602c0,0.889-0.68,1.6-1.52,1.6
			S234.899,393.67,234.899,392.782z"
                ></path>

                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			235.3,397.629 236.11,394.92 237.12,395.26 236.22,398.042 		"
                ></polygon>
            </g>
            <g>
                <rect
                    x="291.76"
                    y="395.5"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="3.84"
                    height="4.08"
                ></rect>

                <polyline
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			295.6,395.02 292.24,395.02 291.52,391.9 		"
                ></polyline>
            </g>

            <path
                fill="none"
                stroke="#273553"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="
		M276.88,396.459c0,0,3.92-3.119,6.96-0.479s7.92,0.479,7.92,0.479"
            ></path>
            <g>
                <polyline
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			227.6,400.542 225.77,394.45 224.561,394.65 222.95,400.542 		"
                ></polyline>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="223.95"
                    y1="397.42"
                    x2="226.76"
                    y2="397.42"
                ></line>

                <polygon
                    fill="#FCA803"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			223.37,391.84 223.37,392.14 222.939,392.14 223.14,393.34 223.37,393.34 223.37,394.06 226.73,394.06 226.73,393.581 
			228.85,393.581 230.33,393.98 230.33,391.84 229.01,392.14 226.97,392.14 226.97,391.9 226.36,391.9 226.51,391.5 
			227.931,390.782 227.931,390.48 226.52,391.26 225.62,390.94 224.49,390.94 224.181,391.98 		"
                ></polygon>

                <path
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M224.25,394.25c0-0.5,0.41-0.899,0.91-0.899c0.51,0,0.92,0.399,0.92,0.899s-0.41,0.899-0.92,0.899
			C224.66,395.15,224.25,394.75,224.25,394.25z"
                ></path>

                <path
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M227.649,390.22h1.75c0.12,0,0.211,0.09,0.211,0.2v0.32c0,0.108-0.091,0.198-0.211,0.198h-1.75c-0.108,0-0.198-0.09-0.198-0.198
			v-0.32C227.45,390.31,227.54,390.22,227.649,390.22z"
                ></path>

                <polyline
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			225.05,395.15 225.05,395.85 221.931,395.45 		"
                ></polyline>
            </g>
        </g>
        <g id="Company">
            <rect
                x="312.88"
                y="387.12"
                fill="#FFFFFF"
                stroke="#283654"
                strokeWidth="0.96"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="104.88"
                height="36.48"
            ></rect>

            <rect
                x="312.88"
                y="350.4"
                fill="#FFFFFF"
                stroke="#283654"
                strokeWidth="0.96"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="104.88"
                height="36.72"
            ></rect>
            <g>
                <rect
                    x="320.67"
                    y="354.042"
                    fill="#FFFFFF"
                    stroke="#2B3957"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="59.989"
                    height="13.438"
                ></rect>

                <rect
                    x="336.27"
                    y="354.042"
                    fill="#FFFFFF"
                    stroke="#2B3957"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="30.24"
                    height="13.438"
                ></rect>
                <g id="Rocket_Diagram">
                    <path
                        fill="#FFFFFF"
                        d="M370.311,362.93l0.6,0.52c1.641-0.52,4.29-3.63,5.261-5c0.3-0.43,0.58-0.869,0.811-1.33
				c0.069-0.158,0.149-0.318,0.199-0.479l0.021-0.051l-0.061,0.021c-0.17,0.069-0.34,0.148-0.51,0.229
				C374.88,357.73,370.789,361.15,370.311,362.93z"
                    ></path>

                    <path
                        fill="none"
                        stroke="#5093EB"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M377.35,356.44c-2.13,0.65-6.85,4.91-7.149,6.53l0.689,0.579C372.6,363.14,376.811,358.44,377.35,356.44z"
                    ></path>
                    <path
                        fill="#5093EB"
                        d="M372.699,361.1c-0.171-0.141-2.529,2.561-2.529,2.561S372.88,361.25,372.699,361.1z"
                    ></path>
                    <path
                        fill="#5093EB"
                        d="M370.439,362.51c0,0-0.64-0.68-1.81-0.16c0,0,1.601-2.42,3.351-1.859"
                    ></path>
                    <path
                        fill="#5093EB"
                        d="M371.38,363.31c0,0,0.779,0.53,0.31,1.66c0,0,2.471-1.681,1.721-3.25"
                    ></path>
                    <polygon
                        fill="#5093EB"
                        points="377.029,356.76 375.96,357.25 374.99,357.911 375.92,358.7 376.6,357.75 			"
                    ></polygon>

                    <line
                        fill="none"
                        stroke="#5093EB"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="374.811"
                        y1="360.129"
                        x2="377.561"
                        y2="360.27"
                    ></line>

                    <line
                        fill="none"
                        stroke="#5093EB"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="372.319"
                        y1="365.75"
                        x2="374.869"
                        y2="362.82"
                    ></line>

                    <line
                        fill="none"
                        stroke="#5093EB"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="372.779"
                        y1="365.78"
                        x2="371.89"
                        y2="365.51"
                    ></line>

                    <line
                        fill="none"
                        stroke="#5093EB"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="375.289"
                        y1="362.861"
                        x2="374.52"
                        y2="362.52"
                    ></line>

                    <line
                        fill="none"
                        stroke="#5093EB"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="372.75"
                        y1="358.22"
                        x2="372.9"
                        y2="359.661"
                    ></line>
                    <rect x="377.561" y="359.6" fill="none" width="2.71" height="1.48"></rect>
                    <text
                        transform="matrix(1 0 0 1 377.5608 360.1528)"
                        fill="#5093EB"
                        fontFamily="ABeeZee"
                        fontSize="0.6"
                    >
                        Fuselage
                    </text>
                    <rect x="374.369" y="363.97" fill="none" width="2.431" height="1.329"></rect>
                    <text
                        transform="matrix(1 0 0 1 374.3694 364.5229)"
                        fill="#5093EB"
                        fontFamily="ABeeZee"
                        fontSize="0.6"
                    >
                        0.5m
                    </text>
                    <rect x="372.081" y="356.59" fill="none" width="2.94" height="1.38"></rect>
                    <text
                        transform="matrix(1 0 0 1 372.0823 357.1431)"
                        fill="#5093EB"
                        fontFamily="ABeeZee"
                        fontSize="0.6"
                    >
                        Outer{" "}
                    </text>
                    <text
                        transform="matrix(1 0 0 1 372.0823 357.8638)"
                        fill="#5093EB"
                        fontFamily="ABeeZee"
                        fontSize="0.6"
                    >
                        Shell
                    </text>
                    <rect x="367.36" y="358.78" fill="none" width="3.25" height="2.221"></rect>
                    <text
                        transform="matrix(1 0 0 1 367.3596 359.3315)"
                        fill="#5093EB"
                        fontFamily="ABeeZee"
                        fontSize="0.6"
                    >
                        Rocket{" "}
                    </text>
                    <text
                        transform="matrix(1 0 0 1 367.3596 360.0532)"
                        fill="#5093EB"
                        fontFamily="ABeeZee"
                        fontSize="0.6"
                    >
                        Design v2
                    </text>
                </g>
                <g id="Flyby_Diagram">
                    <path
                        fill="#FFFFFF"
                        stroke="#5093EB"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M341.449,362.28c0-1.119,0.91-2.039,2.04-2.039c1.12,0,2.039,0.92,2.039,2.039c0,1.131-0.919,2.04-2.039,2.04
				C342.36,364.32,341.449,363.411,341.449,362.28z"
                    ></path>

                    <path
                        fill="none"
                        stroke="#5093EB"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M342.811,361.799c0,0,2.729-8.039,14.479-5.879c5.2,0.959,3.44,6.32,1.279,8.479c-2.398,2.4-9.6,2.561-4.398-2
				c5.199-4.561,8.561-1.76,10.319-0.238"
                    ></path>
                    <path
                        fill="#5093EB"
                        d="M344.329,360.68c0,0-0.88,1-0.239,1.48c0.641,0.479,0.04,1.398,0.521,1.52
				c0.479,0.119,0.68-0.319,0.68-0.319S346.569,361.2,344.329,360.68z"
                    ></path>
                    <path
                        fill="#5093EB"
                        d="M342.529,361.72c0,0-1,0.44-0.358,0.92c0.641,0.479,1.119,0.358,1.119,0.358
				S343.97,361.44,342.529,361.72z"
                    ></path>

                    <line
                        fill="none"
                        stroke="#5093EB"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="359.329"
                        y1="359.161"
                        x2="353.569"
                        y2="358.44"
                    ></line>

                    <line
                        fill="none"
                        stroke="#5093EB"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="345.17"
                        y1="362.042"
                        x2="349.25"
                        y2="362.76"
                    ></line>

                    <line
                        fill="none"
                        stroke="#5093EB"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="347.329"
                        y1="356.52"
                        x2="342.77"
                        y2="356.28"
                    ></line>
                    <rect x="348.4" y="357.145" fill="none" width="6.241" height="2.59"></rect>
                    <text
                        transform="matrix(1 0 0 1 348.4006 358.0649)"
                        fill="#5092EB"
                        fontFamily="ABeeZee"
                        fontSize="1"
                    >
                        Deep Space{" "}
                    </text>
                    <text
                        transform="matrix(1 0 0 1 348.4006 359.2651)"
                        fill="#5092EB"
                        fontFamily="ABeeZee"
                        fontSize="1"
                    >
                        Maneouver
                    </text>
                    <rect x="349.601" y="361.68" fill="none" width="3.84" height="2.59"></rect>
                    <text
                        transform="matrix(1 0 0 1 349.5999 362.6001)"
                        fill="#5092EB"
                        fontFamily="ABeeZee"
                        fontSize="1"
                    >
                        Earth{" "}
                    </text>
                    <text
                        transform="matrix(1 0 0 1 349.5999 363.8003)"
                        fill="#5092EB"
                        fontFamily="ABeeZee"
                        fontSize="1"
                    >
                        Flyby
                    </text>
                    <rect x="337.84" y="354.241" fill="none" width="7.688" height="4.92"></rect>
                    <text
                        transform="matrix(1 0 0 1 337.8401 355.561)"
                        fill="#5092EB"
                        fontFamily="ABeeZee"
                        fontSize="1"
                    >
                        EnRoute to{" "}
                    </text>
                    <text
                        transform="matrix(1 0 0 1 337.8401 356.7612)"
                        fill="#5092EB"
                        fontFamily="ABeeZee"
                        fontSize="1"
                    >
                        Venus
                    </text>
                    <text
                        transform="matrix(1 0 0 1 337.8401 357.9614)"
                        fill="#5092EB"
                        fontFamily="ABeeZee"
                        fontSize="1"
                    >
                        11000km from{" "}
                    </text>
                    <text
                        transform="matrix(1 0 0 1 337.8401 359.1616)"
                        fill="#5092EB"
                        fontFamily="ABeeZee"
                        fontSize="1"
                    >
                        Earth
                    </text>

                    <path
                        fill="none"
                        stroke="#5093EB"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M358.97,359.879c0-0.859,0.689-1.561,1.56-1.561c0.86,0,1.562,0.7,1.562,1.561c0,0.861-0.7,1.561-1.562,1.561
				C359.659,361.44,358.97,360.741,358.97,359.879z"
                    ></path>
                </g>
                <rect x="321.76" y="355.68" fill="none" width="13.921" height="11.1"></rect>
                <text
                    transform="matrix(1 0 0 1 321.76 356.6001)"
                    fill="#5092EB"
                    fontFamily="ABeeZee"
                    fontSize="1"
                >
                    If you’re reading this text{" "}
                </text>
                <text
                    transform="matrix(1 0 0 1 321.76 357.8003)"
                    fill="#5092EB"
                    fontFamily="ABeeZee"
                    fontSize="1"
                >
                    you’ve zoomed in too far!
                </text>
                <text
                    transform="matrix(1 0 0 1 321.76 359.0005)"
                    fill="#5092EB"
                    fontFamily="ABeeZee"
                    fontSize="1"
                ></text>
                <text
                    transform="matrix(1 0 0 1 321.76 360.2007)"
                    fill="#5092EB"
                    fontFamily="ABeeZee"
                    fontSize="1"
                >
                    It looks like you’re pretty{" "}
                </text>
                <text
                    transform="matrix(1 0 0 1 321.76 361.4009)"
                    fill="#5092EB"
                    fontFamily="ABeeZee"
                    fontSize="1"
                >
                    keen on space.{" "}
                </text>
                <text
                    transform="matrix(1 0 0 1 321.76 362.6001)"
                    fill="#5092EB"
                    fontFamily="ABeeZee"
                    fontSize="1"
                ></text>
                <text
                    transform="matrix(1 0 0 1 321.76 363.8003)"
                    fill="#5092EB"
                    fontFamily="ABeeZee"
                    fontSize="1"
                >
                    You should join the team{" "}
                </text>
                <text
                    transform="matrix(1 0 0 1 321.76 365.0005)"
                    fill="#5092EB"
                    fontFamily="ABeeZee"
                    fontSize="1"
                >
                    that makes these graphics:{" "}
                </text>
                <text
                    transform="matrix(1 0 0 1 321.76 366.2007)"
                    fill="#5092EB"
                    fontFamily="ABeeZee"
                    fontSize="1"
                >
                    ukseds.org/volunteer
                </text>
            </g>
            <g>
                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			381.189,380.161 380.279,386.64 381.49,386.64 382.1,384.081 382.71,386.64 383.93,386.64 383.01,380.161 		"
                ></polygon>

                <path
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M380.579,378.64c0-0.88,0.681-1.601,1.521-1.601s1.521,0.721,1.521,1.601s-0.681,1.601-1.521,1.601S380.579,379.52,380.579,378.64
			z"
                ></path>

                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			381.02,383.911 381.829,381.2 382.84,381.532 381.939,384.31 		"
                ></polygon>
            </g>
            <g>
                <rect
                    x="394.49"
                    y="395.282"
                    fill="#FFC80D"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="13.681"
                    height="15.83"
                ></rect>

                <path
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M396.89,400.92c0-1.531,1.229-2.76,2.761-2.76c1.521,0,2.76,1.229,2.76,2.76c0,1.52-1.239,2.76-2.76,2.76
			C398.119,403.68,396.89,402.44,396.89,400.92z"
                ></path>

                <rect
                    x="396.65"
                    y="405.84"
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="1.919"
                    height="3.35"
                ></rect>

                <path
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M383.93,396.72c0.681,0.04,1.92,0,1.92,0v3.36h-1.92C383.93,400.081,385.01,398.282,383.93,396.72z"
                ></path>

                <rect
                    x="404.811"
                    y="403.2"
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="1.92"
                    height="5.99"
                ></rect>

                <rect
                    x="399.77"
                    y="405.84"
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="3.841"
                    height="3.35"
                ></rect>

                <rect
                    x="404.811"
                    y="396.72"
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="1.92"
                    height="1.681"
                ></rect>

                <rect
                    x="404.811"
                    y="399.6"
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="1.92"
                    height="1.682"
                ></rect>

                <path
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M401.21,407.639c0-0.469,0.369-0.84,0.84-0.84c0.46,0,0.84,0.371,0.84,0.84c0,0.461-0.38,0.83-0.84,0.83
			C401.579,408.469,401.21,408.1,401.21,407.639z"
                ></path>

                <path
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M399.289,400.92c0-0.201,0.16-0.361,0.36-0.361c0.189,0,0.359,0.16,0.359,0.361c0,0.189-0.17,0.359-0.359,0.359
			C399.449,401.282,399.289,401.112,399.289,400.92z"
                ></path>

                <polygon
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			397.13,392.879 397.13,395.282 405.77,395.282 405.77,389.042 		"
                ></polygon>

                <polygon
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			385.85,397.92 385.85,398.879 394.49,398.879 394.49,397.2 		"
                ></polygon>
            </g>
            <g>
                <polygon
                    fill="#283654"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			408.409,399.84 408.409,404.639 409.61,404.639 409.61,402.719 413.449,402.719 413.449,387.12 412.25,387.12 412.25,401.52 
			409.61,401.52 409.61,399.84 		"
                ></polygon>

                <polygon
                    fill="#283654"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			394.01,400.321 394.01,405.12 392.811,405.12 392.811,403.2 388.97,403.2 388.97,387.6 390.17,387.6 390.17,402 392.811,402 
			392.811,400.321 		"
                ></polygon>
            </g>
            <g id="Person_on_Lift">
                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			395.46,406.559 396.369,413.032 395.159,413.032 394.55,410.469 393.939,413.032 392.73,413.032 393.64,406.559 		"
                ></polygon>

                <path
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M396.069,405.042c0-0.891-0.68-1.602-1.52-1.602s-1.521,0.711-1.521,1.602c0,0.879,0.682,1.6,1.521,1.6
			S396.069,405.92,396.069,405.042z"
                ></path>

                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			397.27,406.2 394.72,407.35 395.15,408.26 397.85,407.112 		"
                ></polygon>
            </g>
            <g>
                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			337.51,416.87 336.6,423.35 337.811,423.35 338.42,420.792 339.029,423.35 340.25,423.35 339.329,416.87 		"
                ></polygon>

                <path
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M336.9,415.35c0-0.881,0.68-1.602,1.521-1.602c0.84,0,1.52,0.721,1.52,1.602c0,0.891-0.68,1.6-1.52,1.6
			C337.579,416.95,336.9,416.241,336.9,415.35z"
                ></path>

                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			335.84,418.639 338.35,417.35 338.819,418.209 336.22,419.612 		"
                ></polygon>
            </g>
            <g>
                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			321.02,416.87 321.931,423.35 320.71,423.35 320.1,420.792 319.5,423.35 318.279,423.35 319.189,416.87 		"
                ></polygon>

                <path
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M321.619,415.35c0-0.881-0.681-1.602-1.521-1.602s-1.521,0.721-1.521,1.602c0,0.891,0.681,1.6,1.521,1.6
			S321.619,416.241,321.619,415.35z"
                ></path>

                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			321.34,420.112 320.02,417.799 319.079,418.1 320.47,420.629 		"
                ></polygon>
            </g>
            <g id="Scissor_Lift">
                <rect
                    id="Basket"
                    x="391.13"
                    y="409.43"
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="9.359"
                    height="4.08"
                ></rect>

                <rect
                    x="391.13"
                    y="420.95"
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="9.359"
                    height="1.201"
                ></rect>
                <g id="Scissor">
                    <line
                        fill="none"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="391.25"
                        y1="413.629"
                        x2="400.61"
                        y2="417.231"
                    ></line>

                    <line
                        fill="none"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="400.369"
                        y1="413.629"
                        x2="391.01"
                        y2="417.231"
                    ></line>

                    <line
                        fill="none"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="391.25"
                        y1="417.231"
                        x2="400.61"
                        y2="420.831"
                    ></line>

                    <line
                        fill="none"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="400.369"
                        y1="417.231"
                        x2="391.01"
                        y2="420.831"
                    ></line>
                </g>

                <circle
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    cx="392.81"
                    cy="422.15"
                    r="0.96"
                ></circle>

                <circle
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    cx="398.81"
                    cy="422.15"
                    r="0.96"
                ></circle>
            </g>

            <rect
                x="324.89"
                y="419.27"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="14.16"
                height="4.08"
            ></rect>

            <rect
                x="327.05"
                y="416.389"
                fill="#FFC80D"
                stroke="#273553"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="1.681"
                height="2.881"
            ></rect>

            <rect
                x="331.369"
                y="417.59"
                fill="#FFC80D"
                stroke="#273553"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="2.881"
                height="1.68"
            ></rect>
            <g>
                <g>
                    <rect
                        x="395.68"
                        y="378.241"
                        fill="#FFFFFF"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        width="0.96"
                        height="3.12"
                    ></rect>

                    <rect
                        x="400.96"
                        y="378.48"
                        fill="#FFFFFF"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        width="0.96"
                        height="3.12"
                    ></rect>

                    <rect
                        x="406.48"
                        y="378.241"
                        fill="#FFFFFF"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        width="0.959"
                        height="3.12"
                    ></rect>

                    <rect
                        x="398.561"
                        y="375.84"
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        width="6"
                        height="3.359"
                    ></rect>

                    <rect
                        x="404.561"
                        y="375.84"
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        width="4.8"
                        height="3.359"
                    ></rect>

                    <rect
                        x="393.76"
                        y="375.84"
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        width="4.801"
                        height="3.359"
                    ></rect>
                </g>

                <rect
                    x="392.079"
                    y="381.361"
                    fill="none"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="18.239"
                    height="0.72"
                ></rect>
                <g>
                    <g>
                        <rect
                            x="400.96"
                            y="383.52"
                            fill="#FFFFFF"
                            stroke="#283654"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            width="0.96"
                            height="3.12"
                        ></rect>

                        <rect
                            x="399.279"
                            y="378.959"
                            fill="#FFFFFF"
                            stroke="#283654"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            width="4.32"
                            height="4.801"
                        ></rect>

                        <line
                            fill="none"
                            stroke="#283654"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="398.561"
                            y1="386.64"
                            x2="404.52"
                            y2="386.64"
                        ></line>
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            x="392.079"
                            y="382.081"
                            fill="#FFFFFF"
                            stroke="#283654"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            width="4.801"
                            height="1.439"
                        ></rect>
                    </g>
                    <g>
                        <rect
                            x="392.079"
                            y="383.52"
                            fill="#FFFFFF"
                            stroke="#283654"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            width="4.801"
                            height="1.439"
                        ></rect>
                    </g>
                    <g>
                        <rect
                            x="392.079"
                            y="384.959"
                            fill="#FFFFFF"
                            stroke="#283654"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            width="4.801"
                            height="1.439"
                        ></rect>
                    </g>

                    <line
                        fill="none"
                        stroke="#283654"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="393.65"
                        y1="382.68"
                        x2="395.569"
                        y2="382.68"
                    ></line>

                    <line
                        fill="none"
                        stroke="#283654"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="393.65"
                        y1="384.12"
                        x2="395.569"
                        y2="384.12"
                    ></line>

                    <line
                        fill="none"
                        stroke="#283654"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="393.65"
                        y1="385.56"
                        x2="395.569"
                        y2="385.56"
                    ></line>
                </g>
                <g>
                    <g>
                        <rect
                            x="405.52"
                            y="382.081"
                            fill="#FFFFFF"
                            stroke="#283654"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            width="4.8"
                            height="1.439"
                        ></rect>
                    </g>
                    <g>
                        <rect
                            x="405.52"
                            y="383.52"
                            fill="#FFFFFF"
                            stroke="#283654"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            width="4.8"
                            height="1.439"
                        ></rect>
                    </g>
                    <g>
                        <rect
                            x="405.52"
                            y="384.959"
                            fill="#FFFFFF"
                            stroke="#283654"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            width="4.8"
                            height="1.439"
                        ></rect>
                    </g>

                    <line
                        fill="none"
                        stroke="#283654"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="407.09"
                        y1="382.68"
                        x2="409.01"
                        y2="382.68"
                    ></line>

                    <line
                        fill="none"
                        stroke="#283654"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="407.09"
                        y1="384.12"
                        x2="409.01"
                        y2="384.12"
                    ></line>

                    <line
                        fill="none"
                        stroke="#283654"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="407.09"
                        y1="385.56"
                        x2="409.01"
                        y2="385.56"
                    ></line>
                </g>
            </g>
            <g>
                <g>
                    <rect
                        x="322.48"
                        y="378.72"
                        fill="#FFFFFF"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        width="0.959"
                        height="3.12"
                    ></rect>

                    <rect
                        x="327.76"
                        y="378.959"
                        fill="#FFFFFF"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        width="0.96"
                        height="3.121"
                    ></rect>

                    <rect
                        x="333.279"
                        y="378.72"
                        fill="#FFFFFF"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        width="0.961"
                        height="3.12"
                    ></rect>

                    <rect
                        x="325.36"
                        y="376.32"
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        width="6"
                        height="3.36"
                    ></rect>

                    <rect
                        x="331.36"
                        y="376.32"
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        width="4.8"
                        height="3.36"
                    ></rect>

                    <rect
                        x="320.561"
                        y="376.32"
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        width="4.8"
                        height="3.36"
                    ></rect>

                    <rect
                        x="318.88"
                        y="381.84"
                        fill="none"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        width="18.239"
                        height="0.72"
                    ></rect>
                    <g>
                        <rect
                            x="318.88"
                            y="382.56"
                            fill="#FFFFFF"
                            stroke="#283654"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            width="4.801"
                            height="1.438"
                        ></rect>
                    </g>
                    <g>
                        <rect
                            x="318.88"
                            y="384"
                            fill="#FFFFFF"
                            stroke="#283654"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            width="4.801"
                            height="1.439"
                        ></rect>
                    </g>
                    <g>
                        <rect
                            x="318.88"
                            y="385.44"
                            fill="#FFFFFF"
                            stroke="#283654"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            width="4.801"
                            height="1.439"
                        ></rect>
                    </g>

                    <line
                        fill="none"
                        stroke="#283654"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="320.449"
                        y1="383.161"
                        x2="322.369"
                        y2="383.161"
                    ></line>

                    <line
                        fill="none"
                        stroke="#283654"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="320.449"
                        y1="384.6"
                        x2="322.369"
                        y2="384.6"
                    ></line>

                    <line
                        fill="none"
                        stroke="#283654"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="320.449"
                        y1="386.042"
                        x2="322.369"
                        y2="386.042"
                    ></line>
                    <g>
                        <rect
                            x="332.319"
                            y="382.56"
                            fill="#FFFFFF"
                            stroke="#283654"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            width="4.801"
                            height="1.438"
                        ></rect>
                    </g>
                    <g>
                        <rect
                            x="332.319"
                            y="384"
                            fill="#FFFFFF"
                            stroke="#283654"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            width="4.801"
                            height="1.439"
                        ></rect>
                    </g>
                    <g>
                        <rect
                            x="332.319"
                            y="385.44"
                            fill="#FFFFFF"
                            stroke="#283654"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            width="4.801"
                            height="1.439"
                        ></rect>
                    </g>

                    <line
                        fill="none"
                        stroke="#283654"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="333.89"
                        y1="383.161"
                        x2="335.811"
                        y2="383.161"
                    ></line>

                    <line
                        fill="none"
                        stroke="#283654"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="333.89"
                        y1="384.6"
                        x2="335.811"
                        y2="384.6"
                    ></line>

                    <line
                        fill="none"
                        stroke="#283654"
                        strokeWidth="0.24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="333.89"
                        y1="386.042"
                        x2="335.811"
                        y2="386.042"
                    ></line>
                </g>
                <g>
                    <rect
                        x="326.8"
                        y="383.76"
                        fill="#FFFFFF"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        width="0.96"
                        height="3.119"
                    ></rect>

                    <rect
                        x="328.72"
                        y="383.76"
                        fill="#FFFFFF"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        width="0.961"
                        height="3.119"
                    ></rect>

                    <path
                        fill="#FFFAFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M326.579,378.64c0-0.88,0.681-1.601,1.521-1.601s1.521,0.721,1.521,1.601s-0.681,1.601-1.521,1.601
				S326.579,379.52,326.579,378.64z"
                    ></path>
                </g>
                <g>
                    <g>
                        <rect
                            x="327.76"
                            y="384"
                            fill="#FFFFFF"
                            stroke="#283654"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            width="0.96"
                            height="3.119"
                        ></rect>

                        <rect
                            x="326.079"
                            y="379.44"
                            fill="#FFFFFF"
                            stroke="#283654"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            width="4.32"
                            height="4.801"
                        ></rect>

                        <line
                            fill="none"
                            stroke="#283654"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="325.36"
                            y1="387.12"
                            x2="331.319"
                            y2="387.12"
                        ></line>
                    </g>
                </g>
            </g>

            <rect
                x="366.05"
                y="338.292"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.72"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="41.511"
                height="12.229"
            ></rect>
            <rect x="357.52" y="340.042" fill="none" width="58.8" height="11.229"></rect>
            <text
                transform="matrix(1 0 0 1 369.0999 347.1284)"
                fill="#263452"
                fontFamily="'Miso'"
                fontWeight="bold"
                fontSize="8"
            >
                TELFER TECH
            </text>

            <rect
                x="380.67"
                y="354.042"
                fill="#FFFFFF"
                stroke="#2B3957"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="30.239"
                height="13.438"
            ></rect>

            <text
                id="Countdown"
                transform="matrix(1 0 0 1 386.4905 362.6694)"
                fill="#5092EB"
                fontFamily="ABeeZee"
                fontSize="2"
            >
                5 : 13 : 25 : 57 : 891
            </text>
            <text
                transform="matrix(1 0 0 1 388.2727 360.0288)"
                fill="#5092EB"
                fontFamily="ABeeZee"
                fontSize="2"
            >
                Time to Launch:
            </text>
            <g id="Small_Radio_Dish">
                <polygon
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			344.656,342.986 346.107,349.816 341.276,349.816 342.728,342.986 		"
                ></polygon>
                <g id="Small_Dish">
                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.72"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				343.246,342.848 341.044,340.667 341.699,340.043 343.884,342.2 			"
                    ></polygon>

                    <path
                        fill="#FFC80D"
                        stroke="#273553"
                        strokeWidth="0.72"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M332.364,341.948c1.137,0.618,2.443,0.959,3.826,0.932c4.256-0.08,7.639-3.592,7.557-7.848c-0.024-1.382-0.414-2.676-1.073-3.783
				C342.673,331.249,333.515,340.557,332.364,341.948z"
                    ></path>

                    <line
                        fill="none"
                        stroke="#273553"
                        strokeWidth="0.72"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="342.617"
                        y1="331.264"
                        x2="332.314"
                        y2="331.458"
                    ></line>

                    <polyline
                        fill="none"
                        stroke="#273553"
                        strokeWidth="0.72"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				332.382,341.893 332.187,331.499 337.482,336.566 			"
                    ></polyline>

                    <path
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.72"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M332.345,331.069c-0.308,0.006-0.549,0.26-0.545,0.564c0.006,0.313,0.26,0.551,0.565,0.545c0.311-0.006,0.549-0.254,0.543-0.565
				C332.904,331.308,332.655,331.065,332.345,331.069z"
                    ></path>
                </g>

                <path
                    id="Small_Joint"
                    fill="#FFC80D"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M344.913,342.987c0-0.676-0.552-1.222-1.222-1.222c-0.676,0-1.222,0.546-1.222,1.222s0.546,1.221,1.222,1.221
			C344.362,344.208,344.913,343.663,344.913,342.987z"
                ></path>
            </g>
        </g>
        <g id="University">
            <rect
                x="122.69"
                y="382.92"
                fill="#FFFFFF"
                stroke="#283654"
                strokeWidth="0.72"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="84"
                height="40.549"
            ></rect>

            <rect
                x="122.69"
                y="360.361"
                fill="#FFFFFF"
                stroke="#283654"
                strokeWidth="0.72"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="84"
                height="22.56"
            ></rect>
            <g>
                <rect
                    x="134.57"
                    y="371.28"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="1.92"
                ></rect>

                <rect
                    x="139.13"
                    y="371.28"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="1.2"
                    height="1.92"
                ></rect>

                <rect
                    x="136.73"
                    y="371.28"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="1.92"
                ></rect>

                <rect
                    x="137.21"
                    y="371.28"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="137.93"
                    y="371.28"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="135.53"
                    y="371.042"
                    fill="#FFFFFF"
                    stroke="#2C3957"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="2.158"
                ></rect>

                <rect
                    x="139.85"
                    y="370.799"
                    fill="#FFC810"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.4"
                ></rect>

                <rect
                    x="138.41"
                    y="371.042"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.158"
                ></rect>

                <rect
                    x="138.89"
                    y="371.042"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.158"
                ></rect>

                <rect
                    x="136.01"
                    y="370.56"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.64"
                ></rect>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="134.09"
                    y1="373.32"
                    x2="142.01"
                    y2="373.32"
                ></line>
            </g>

            <rect
                x="146.81"
                y="384.72"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="39.12"
                height="18.479"
            ></rect>
            <g id="Rocket_Slide">
                <path
                    fill="#FFFFFF"
                    d="M160.54,394.7l0.649,1.24c2.99,0.16,9.311-3.38,11.771-5.061c0.76-0.52,1.51-1.08,2.17-1.709
			c0.229-0.211,0.45-0.44,0.649-0.672l0.07-0.078h-0.11c-0.329,0-0.659,0.029-0.989,0.07
			C171.33,388.879,162.47,392.032,160.54,394.7z"
                ></path>

                <path
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M176.19,388.26c-3.88-0.25-14.31,3.91-15.85,6.43l0.729,1.4C164.12,396.48,174,391.27,176.19,388.26z"
                ></path>
                <path
                    fill="#273553"
                    d="M165.61,393.14c-0.18-0.351-5.77,2.69-5.77,2.69S165.8,393.5,165.61,393.14z"
                ></path>
                <path
                    fill="#273553"
                    d="M161.02,394.07c0,0-0.59-1.528-2.83-1.39c0,0,4.16-3.04,6.641-1.01"
                ></path>
                <path
                    fill="#273553"
                    d="M162.02,395.991c0,0,0.92,1.379-0.56,2.971c0,0,5.09-1.262,4.899-4.351"
                ></path>
                <polygon
                    fill="#273553"
                    points="176.19,388.26 173.759,388.429 171.4,388.986 172.568,391.15 174.597,389.825 		"
                ></polygon>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="169.18"
                    y1="391.899"
                    x2="174.04"
                    y2="394.799"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="162.14"
                    y1="400.542"
                    x2="168.02"
                    y2="397.37"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="162.7"
                    y1="401"
                    x2="161.43"
                    y2="399.991"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="168.66"
                    y1="397.7"
                    x2="167.65"
                    y2="396.64"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="167.56"
                    y1="388.361"
                    x2="166.87"
                    y2="390.54"
                ></line>
                <rect x="174.561" y="394.235" fill="none" width="4.46" height="3.189"></rect>
                <text
                    transform="matrix(1 0 0 1 174.5603 395.1226)"
                    fill="#273553"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="1"
                >
                    Fuselage
                </text>
                <rect x="165.45" y="399.1" fill="none" width="4.46" height="3.23"></rect>
                <text
                    transform="matrix(1 0 0 1 165.45 399.9858)"
                    fill="#273553"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="1"
                >
                    0.5m
                </text>
                <rect x="165.575" y="386.644" fill="none" width="4.47" height="3.211"></rect>
                <text
                    transform="matrix(1 0 0 1 165.575 387.5298)"
                    fill="#273553"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="1"
                >
                    Outer Shell
                </text>
                <rect x="157.42" y="388.42" fill="none" width="4.72" height="2.51"></rect>
                <text
                    transform="matrix(1 0 0 1 157.4197 389.3071)"
                    fill="#273553"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="1"
                >
                    Rocket Design{" "}
                </text>
                <text
                    transform="matrix(1 0 0 1 157.4197 390.5063)"
                    fill="#273553"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="1"
                >
                    v2
                </text>
            </g>
            <g id="Flyby_Slide" display="none">
                <rect
                    x="153.188"
                    y="385.149"
                    display="inline"
                    fill="#FFFFFF"
                    width="28.25"
                    height="16.406"
                ></rect>

                <path
                    display="inline"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M157.5,395.924c0-1.119,0.91-2.039,2.04-2.039c1.12,0,2.039,0.92,2.039,2.039c0,1.131-0.919,2.039-2.039,2.039
			C158.41,397.963,157.5,397.055,157.5,395.924z"
                ></path>

                <path
                    display="inline"
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M158.86,395.445c0,0,2.729-8.04,14.479-5.88c5.2,0.96,3.439,6.32,1.279,8.479c-2.399,2.4-9.601,2.563-4.399-2
			c5.2-4.56,8.561-1.76,10.32-0.238"
                ></path>
                <path
                    display="inline"
                    fill="#273553"
                    d="M160.38,394.325c0,0-0.88,1-0.24,1.48c0.641,0.479,0.04,1.398,0.521,1.52
			c0.479,0.121,0.68-0.318,0.68-0.318S162.619,394.844,160.38,394.325z"
                ></path>
                <path
                    display="inline"
                    fill="#273553"
                    d="M158.579,395.366c0,0-1,0.439-0.359,0.92c0.641,0.479,1.12,0.358,1.12,0.358
			S160.02,395.084,158.579,395.366z"
                ></path>

                <line
                    display="inline"
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="175.38"
                    y1="392.805"
                    x2="169.619"
                    y2="392.084"
                ></line>

                <line
                    display="inline"
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="161.22"
                    y1="395.685"
                    x2="165.3"
                    y2="396.405"
                ></line>

                <line
                    display="inline"
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="163.38"
                    y1="390.165"
                    x2="158.819"
                    y2="389.924"
                ></line>
                <rect
                    x="164.45"
                    y="390.79"
                    display="inline"
                    fill="none"
                    width="6.24"
                    height="2.59"
                ></rect>

                <text
                    transform="matrix(1 0 0 1 164.4504 391.7104)"
                    display="inline"
                    fill="#273553"
                    fontFamily="ABeeZee"
                    fontSize="1"
                >
                    Deep Space{" "}
                </text>

                <text
                    transform="matrix(1 0 0 1 164.4504 392.9097)"
                    display="inline"
                    fill="#273553"
                    fontFamily="ABeeZee"
                    fontSize="1"
                >
                    Maneouver
                </text>
                <rect
                    x="165.65"
                    y="395.325"
                    display="inline"
                    fill="none"
                    width="3.84"
                    height="2.59"
                ></rect>

                <text
                    transform="matrix(1 0 0 1 165.6506 396.2446)"
                    display="inline"
                    fill="#273553"
                    fontFamily="ABeeZee"
                    fontSize="1"
                >
                    Earth{" "}
                </text>

                <text
                    transform="matrix(1 0 0 1 165.6506 397.4448)"
                    display="inline"
                    fill="#273553"
                    fontFamily="ABeeZee"
                    fontSize="1"
                >
                    Flyby
                </text>
                <rect
                    x="153.89"
                    y="387.885"
                    display="inline"
                    fill="none"
                    width="7.689"
                    height="4.92"
                ></rect>

                <text
                    transform="matrix(1 0 0 1 153.8894 388.8062)"
                    display="inline"
                    fill="#273553"
                    fontFamily="ABeeZee"
                    fontSize="1"
                >
                    EnRoute to{" "}
                </text>

                <text
                    transform="matrix(1 0 0 1 153.8894 390.0054)"
                    display="inline"
                    fill="#273553"
                    fontFamily="ABeeZee"
                    fontSize="1"
                >
                    Venus
                </text>

                <text
                    transform="matrix(1 0 0 1 153.8894 391.2056)"
                    display="inline"
                    fill="#273553"
                    fontFamily="ABeeZee"
                    fontSize="1"
                >
                    11000km from{" "}
                </text>

                <text
                    transform="matrix(1 0 0 1 153.8894 392.4058)"
                    display="inline"
                    fill="#273553"
                    fontFamily="ABeeZee"
                    fontSize="1"
                >
                    Earth
                </text>

                <path
                    display="inline"
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M175.02,393.525c0-0.86,0.69-1.562,1.561-1.562c0.859,0,1.561,0.701,1.561,1.562s-0.7,1.56-1.561,1.56
			C175.71,395.084,175.02,394.385,175.02,393.525z"
                ></path>
            </g>
            <g>
                <path
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M153.82,397.85c0-0.631,0.521-1.15,1.15-1.15c0.64,0,1.15,0.521,1.15,1.15c0,0.641-0.511,1.16-1.15,1.16
			C154.34,399.01,153.82,398.491,153.82,397.85z"
                ></path>

                <path
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M153.82,399.01c0,0-1.869,1.238,0,2.299v5.381h2.301v-6.92c0,0,2.399-1.1,3.08-1.529v-0.771c0,0-2.931,1.49-3.08,1.541
			C155.98,399.049,153.82,399.01,153.82,399.01z"
                ></path>
            </g>

            <polygon
                fill="#FFC80D"
                stroke="#273553"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
		153.82,400.542 157.66,400.542 156.89,407.45 154.59,407.45 	"
            ></polygon>
            <g>
                <circle
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    cx="144.22"
                    cy="404.379"
                    r="1.92"
                ></circle>

                <circle
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    cx="188.76"
                    cy="404.379"
                    r="1.92"
                ></circle>

                <circle
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    cx="180.32"
                    cy="404.379"
                    r="1.92"
                ></circle>

                <circle
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    cx="171.1"
                    cy="404.379"
                    r="1.92"
                ></circle>

                <circle
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    cx="161.88"
                    cy="404.379"
                    r="1.92"
                ></circle>

                <circle
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    cx="153.44"
                    cy="404.379"
                    r="1.92"
                ></circle>

                <path
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M140,408.612h7.68c0,0,0.09-2.313-2.31-2.313h-3.07C142.3,406.299,140.48,406.59,140,408.612z"
                ></path>

                <path
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M148.44,408.612h7.681c0,0,0.1-2.313-2.301-2.313h-3.069C150.75,406.299,148.92,406.59,148.44,408.612z"
                ></path>

                <path
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M157.66,408.612h7.68c0,0,0.1-2.313-2.3-2.313h-3.08C159.96,406.299,158.14,406.59,157.66,408.612z"
                ></path>

                <path
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M166.88,408.612h7.68c0,0,0.09-2.313-2.31-2.313h-3.07C169.18,406.299,167.36,406.59,166.88,408.612z"
                ></path>

                <path
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M176.09,408.612h7.68c0,0,0.101-2.313-2.3-2.313h-3.07C178.4,406.299,176.57,406.59,176.09,408.612z"
                ></path>

                <path
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M185.31,408.612h7.68c0,0,0.091-2.313-2.31-2.313h-3.07C187.61,406.299,185.79,406.59,185.31,408.612z"
                ></path>
            </g>

            <rect
                x="139.01"
                y="408.59"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.72"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="54.72"
                height="14.879"
            ></rect>

            <rect
                x="122.68"
                y="347.879"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.72"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="84"
                height="12.24"
            ></rect>

            <polygon
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.72"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
		164.56,331.92 206.56,347.879 122.56,347.879 	"
            ></polygon>
            <rect x="127.84" y="349.64" fill="none" width="73.68" height="11.229"></rect>

            <text
                transform="matrix(1.0718 0 0 1 126.4485 356.6001)"
                fill="#263452"
                fontFamily="'Miso'"
                fontWeight="bold"
                fontSize="8"
                letterSpacing="0.933"
            >
                BOWMAN UNIVERSITY
            </text>
            <g>
                <g>
                    <polyline
                        fill="none"
                        stroke="#273553"
                        strokeWidth="0.72"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				199.61,377.042 198.41,377.282 199.85,376.32 199.13,382.32 191.21,382.32 			"
                    ></polyline>
                    <g id="Runner_b" display="none">
                        <polygon
                            display="inline"
                            fill="#FFFAFF"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            points="
					197.04,375.43 197.344,381.024 196.35,381.211 195.35,379.09 194.87,380.111 194.69,380.51 193.969,381.805 192.75,381.586 
					193.7,379.48 195.3,375.06 				"
                        ></polygon>

                        <path
                            display="inline"
                            fill="#FFFAFF"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            d="
					M197.93,374.07c0.18-0.858-0.33-1.68-1.13-1.84c-0.79-0.149-1.58,0.41-1.76,1.25c-0.181,0.851,0.31,1.681,1.109,1.84
					C196.95,375.491,197.75,374.93,197.93,374.07z"
                        ></path>

                        <polygon
                            display="inline"
                            fill="#FFFAFF"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            points="
					199.23,376.6 196.48,376.42 196.35,377.67 199.2,377.611 				"
                        ></polygon>
                    </g>
                    <g id="Runner_a">
                        <polygon
                            fill="#FFFAFF"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            points="
					197.04,375.43 196.57,382.049 195.39,381.77 195.35,379.09 194.87,380.111 194.69,380.51 192.75,381.18 191.6,380.9 
					193.7,379.48 195.3,375.06 				"
                        ></polygon>

                        <path
                            fill="#FFFAFF"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            d="
					M197.93,374.07c0.18-0.858-0.33-1.68-1.13-1.84c-0.79-0.149-1.58,0.41-1.76,1.25c-0.181,0.851,0.31,1.681,1.109,1.84
					C196.95,375.491,197.75,374.93,197.93,374.07z"
                        ></path>

                        <polygon
                            fill="#FFFAFF"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            points="
					199.23,376.6 196.48,376.42 196.35,377.67 199.2,377.611 				"
                        ></polygon>
                    </g>
                </g>
                <g>
                    <polygon
                        fill="#FFFAFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				182.43,376.081 183.34,382.56 182.12,382.56 181.52,380 180.91,382.56 179.69,382.56 180.6,376.081 			"
                    ></polygon>

                    <path
                        fill="#FFFAFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M183.04,374.56c0-0.88-0.681-1.601-1.521-1.601S180,373.68,180,374.56s0.68,1.601,1.52,1.601S183.04,375.44,183.04,374.56z"
                    ></path>

                    <polygon
                        fill="#FFFAFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				184.25,377.44 181.52,376.48 181.21,377.44 183.95,378.4 			"
                    ></polygon>
                </g>
                <g>
                    <rect
                        x="183.77"
                        y="378.48"
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        width="3.84"
                        height="4.08"
                    ></rect>

                    <polyline
                        fill="none"
                        stroke="#FFC80D"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				183.77,378 187.13,378 187.85,374.879 			"
                    ></polyline>
                </g>

                <path
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M195.65,373.68c0,0-3.3,0.602-3.72,3.84c-0.52,3.99-4.32,1.92-4.32,1.92"
                ></path>

                <path
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M195.35,377.94c0,0-2.68-0.391-3.42,0.602"
                ></path>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="201.41"
                    y1="367.56"
                    x2="204.29"
                    y2="368.52"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="204.29"
                    y1="368.52"
                    x2="204.29"
                    y2="382.92"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="202.37"
                    y1="373.32"
                    x2="204.29"
                    y2="374.76"
                ></line>

                <line
                    fill="none"
                    stroke="#FFC80D"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="202.13"
                    y1="370.92"
                    x2="202.13"
                    y2="375.959"
                ></line>

                <line
                    fill="none"
                    stroke="#FFC80D"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="201.89"
                    y1="369.959"
                    x2="200.21"
                    y2="365.161"
                ></line>
            </g>

            <line
                fill="none"
                stroke="#273553"
                strokeWidth="0.72"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="167.81"
                y1="360.361"
                x2="167.81"
                y2="382.68"
            ></line>

            <rect
                x="124.01"
                y="376.32"
                fill="#FFFFFF"
                stroke="#293755"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="3.6"
                height="5.521"
            ></rect>

            <rect
                x="124.49"
                y="381.84"
                fill="#FFFFFF"
                stroke="#293755"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.72"
                height="0.959"
            ></rect>

            <rect
                x="126.65"
                y="381.84"
                fill="#FFFFFF"
                stroke="#293755"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.721"
                height="0.959"
            ></rect>

            <polygon
                fill="#FFFFFF"
                stroke="#293755"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
		125.93,373.92 125.93,376.32 125.93,373.68 126.55,373.68 126.65,373.92 	"
            ></polygon>

            <rect
                x="127.85"
                y="375.84"
                fill="#FFFFFF"
                stroke="#293755"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="2.16"
                height="0.24"
            ></rect>
            <g>
                <polygon
                    fill="#FFFFFF"
                    stroke="#293755"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			131.69,377.282 131.93,377.282 131.93,378.76 131.69,379.07 		"
                ></polygon>
                <g>
                    <path
                        fill="#FFFFFF"
                        stroke="#293755"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M128.68,378.98c0-0.14,0.11-0.26,0.25-0.26h2.57c0.149,0,0.26,0.12,0.26,0.26v-0.04c0,0.141-0.11,0.26-0.26,0.26h-2.57
				c-0.14,0-0.25-0.119-0.25-0.26V378.98z"
                    ></path>
                </g>

                <rect
                    x="130.01"
                    y="379.44"
                    fill="#FFFFFF"
                    stroke="#293755"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.479"
                    height="3.12"
                ></rect>

                <polygon
                    fill="#FFFFFF"
                    stroke="#293755"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			129.19,382.32 131.35,382.32 131.56,382.56 128.99,382.56 		"
                ></polygon>

                <path
                    fill="#FFFFFF"
                    stroke="#293755"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M131.92,374.34c0.14,0,0.25,0.109,0.25,0.26v2.57c0,0.14-0.11,0.25-0.25,0.25h0.03c-0.141,0-0.261-0.11-0.261-0.25v-2.57
			c0-0.15,0.12-0.26,0.261-0.26H131.92z"
                ></path>
            </g>

            <rect
                x="124.01"
                y="376.32"
                fill="#FFFFFF"
                stroke="#293755"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="5.76"
                height="0.722"
            ></rect>

            <rect
                x="126.65"
                y="372"
                fill="#FFFFFF"
                stroke="#FFC80F"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.24"
                height="3.36"
            ></rect>

            <rect
                x="153.29"
                y="376.081"
                fill="#FFFFFF"
                stroke="#293755"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="3.6"
                height="5.521"
            ></rect>

            <rect
                x="153.77"
                y="381.6"
                fill="#FFFFFF"
                stroke="#293755"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.721"
                height="0.96"
            ></rect>

            <rect
                x="155.93"
                y="381.6"
                fill="#FFFFFF"
                stroke="#293755"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.72"
                height="0.96"
            ></rect>

            <polygon
                fill="#FFFFFF"
                stroke="#293755"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
		155.21,373.68 155.21,376.081 155.21,373.44 155.83,373.44 155.93,373.68 	"
            ></polygon>

            <rect
                x="157.13"
                y="375.6"
                fill="#FFFFFF"
                stroke="#293755"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="2.16"
                height="0.24"
            ></rect>
            <g>
                <polygon
                    fill="#FFFFFF"
                    stroke="#293755"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			164.33,377.042 164.57,377.042 164.57,378.52 164.33,378.831 		"
                ></polygon>
                <g>
                    <path
                        fill="#FFFFFF"
                        stroke="#293755"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M161.32,378.741c0.11,0,0.25-0.15,0.25-0.261h2.57c0,0.17,0.09,0.261,0.26,0.261V378.7c-0.17,0-0.26,0.092-0.26,0.262h-2.57
				c0-0.11-0.14-0.262-0.25-0.262V378.741z"
                    ></path>
                </g>

                <rect
                    x="162.65"
                    y="379.2"
                    fill="#FFFFFF"
                    stroke="#293755"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.48"
                    height="3.12"
                ></rect>

                <polygon
                    fill="#FFFFFF"
                    stroke="#293755"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			161.83,382.081 163.99,382.081 164.2,382.32 161.62,382.32 		"
                ></polygon>

                <path
                    fill="#FFFFFF"
                    stroke="#293755"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M164.56,374.1c0.141,0,0.25,0.109,0.25,0.261v2.569c0,0.14-0.109,0.25-0.25,0.25h0.03c-0.14,0-0.26-0.11-0.26-0.25v-2.569
			c0-0.149,0.12-0.261,0.26-0.261H164.56z"
                ></path>
            </g>

            <rect
                x="153.29"
                y="376.081"
                fill="#FFFFFF"
                stroke="#293755"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="5.76"
                height="0.721"
            ></rect>

            <rect
                x="155.93"
                y="371.76"
                fill="#FFFFFF"
                stroke="#FFC80F"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.24"
                height="3.359"
            ></rect>
            <g>
                <rect
                    x="149.69"
                    y="376.081"
                    fill="#FFFFFF"
                    stroke="#293755"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="3.601"
                    height="5.521"
                ></rect>

                <rect
                    x="152.09"
                    y="381.6"
                    fill="#FFFFFF"
                    stroke="#293755"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="0.96"
                ></rect>

                <rect
                    x="149.93"
                    y="381.6"
                    fill="#FFFFFF"
                    stroke="#293755"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="0.96"
                ></rect>

                <polygon
                    fill="#FFFFFF"
                    stroke="#293755"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			151.37,373.68 151.37,376.081 151.37,373.44 150.76,373.44 150.65,373.68 		"
                ></polygon>

                <rect
                    x="147.29"
                    y="375.6"
                    fill="#FFFFFF"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="2.16"
                    height="0.24"
                ></rect>

                <polygon
                    fill="#FFFFFF"
                    stroke="#293755"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			143.93,377.042 143.69,377.042 143.69,378.52 143.93,378.831 		"
                ></polygon>
                <g>
                    <path
                        fill="#FFFFFF"
                        stroke="#293755"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M146.95,378.741c0-0.141-0.11-0.261-0.261-0.261h-2.569c-0.141,0-0.261,0.12-0.261,0.261V378.7c0,0.141,0.12,0.262,0.261,0.262
				h2.569c0.15,0,0.261-0.121,0.261-0.262V378.741z"
                    ></path>
                </g>

                <rect
                    x="145.13"
                    y="379.2"
                    fill="#FFFFFF"
                    stroke="#293755"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.479"
                    height="3.12"
                ></rect>

                <polygon
                    fill="#FFFFFF"
                    stroke="#293755"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			146.44,382.081 144.28,382.081 144.07,382.32 146.64,382.32 		"
                ></polygon>

                <path
                    fill="#FFFFFF"
                    stroke="#293755"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M143.71,374.1c-0.141,0-0.26,0.109-0.26,0.261v2.569c0,0.14,0.119,0.25,0.26,0.25h-0.03c0.14,0,0.25-0.11,0.25-0.25v-2.569
			c0-0.149-0.11-0.261-0.25-0.261H143.71z"
                ></path>

                <rect
                    x="147.53"
                    y="376.081"
                    fill="#FFFFFF"
                    stroke="#293755"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="5.761"
                    height="0.721"
                ></rect>

                <rect
                    x="150.41"
                    y="371.76"
                    fill="#FFFFFF"
                    stroke="#FFC80F"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.239"
                    height="3.359"
                ></rect>
            </g>

            <polygon
                fill="#FFFAFF"
                stroke="#273553"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
		146.67,374.879 147.12,377.241 148.44,377.361 148.56,381.879 147.16,381.76 147.04,378.241 144.56,378.241 144.84,374.879 	"
            ></polygon>

            <path
                fill="#FFFAFF"
                stroke="#273553"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="
		M147.28,373.361c0-0.881-0.68-1.601-1.52-1.601s-1.521,0.72-1.521,1.601c0,0.88,0.681,1.601,1.521,1.601
		C146.6,374.959,147.28,374.241,147.28,373.361z"
            ></path>

            <polygon
                fill="#FFFAFF"
                stroke="#273553"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
		148.45,374.89 145.5,374.9 145.48,375.799 148.46,375.95 	"
            ></polygon>
        </g>
        <g id="Legal">
            <rect
                x="9.04"
                y="384.72"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.72"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="94.319"
                height="38.88"
            ></rect>
            <g>
                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			52.87,397.67 51.96,404.151 53.17,404.151 53.78,401.59 54.39,404.151 55.61,404.151 54.69,397.67 		"
                ></polygon>

                <path
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M52.26,396.15c0-0.88,0.68-1.601,1.52-1.601c0.841,0,1.521,0.721,1.521,1.601c0,0.892-0.68,1.601-1.521,1.601
			C52.94,397.75,52.26,397.042,52.26,396.15z"
                ></path>

                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			51.02,399.071 53.58,398.1 53.94,399.09 51.29,400.161 		"
                ></polygon>
            </g>

            <line
                fill="none"
                stroke="#283654"
                strokeWidth="0.72"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="9.4"
                y1="404.52"
                x2="103"
                y2="404.52"
            ></line>

            <rect
                x="44.56"
                y="407.52"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="6.72"
                height="15.842"
            ></rect>
            <g>
                <rect
                    x="47.08"
                    y="421.081"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="1.199"
                    height="1.92"
                ></rect>

                <rect
                    x="44.92"
                    y="417.959"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="45.4"
                    y="417.959"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="1.92"
                ></rect>

                <rect
                    x="48.04"
                    y="417.959"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="45.88"
                    y="417.959"
                    fill="#FFFFFF"
                    stroke="#2C3957"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.16"
                ></rect>

                <rect
                    x="46.84"
                    y="417.481"
                    fill="#FFC810"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.398"
                ></rect>

                <rect
                    x="47.56"
                    y="417.719"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.16"
                ></rect>

                <rect
                    x="46.36"
                    y="417.719"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="2.16"
                ></rect>

                <rect
                    x="48.52"
                    y="417.481"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.639"
                ></rect>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="44.57"
                    y1="420"
                    x2="51.53"
                    y2="420"
                ></line>
            </g>
            <g>
                <rect
                    x="66.88"
                    y="401.081"
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="3.119"
                ></rect>

                <rect
                    x="68.8"
                    y="401.081"
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="3.119"
                ></rect>

                <path
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M66.66,395.92c0-0.881,0.68-1.602,1.52-1.602s1.521,0.721,1.521,1.602c0,0.879-0.681,1.6-1.521,1.6S66.66,396.799,66.66,395.92z"
                ></path>
            </g>
            <g>
                <g>
                    <g>
                        <rect
                            x="92.8"
                            y="396"
                            fill="#FFFFFF"
                            stroke="#283654"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            width="0.96"
                            height="3.119"
                        ></rect>

                        <rect
                            x="90.89"
                            y="394.799"
                            fill="#FFC80D"
                            stroke="#283654"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            width="4.8"
                            height="3.361"
                        ></rect>
                    </g>

                    <rect
                        x="80.08"
                        y="399.12"
                        fill="none"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        width="18.239"
                        height="0.721"
                    ></rect>
                    <g>
                        <g>
                            <rect
                                x="88.96"
                                y="401.282"
                                fill="#FFFFFF"
                                stroke="#283654"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                width="0.96"
                                height="3.119"
                            ></rect>

                            <rect
                                x="87.28"
                                y="396.72"
                                fill="#FFFFFF"
                                stroke="#283654"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                width="4.32"
                                height="4.8"
                            ></rect>

                            <line
                                fill="none"
                                stroke="#283654"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="86.56"
                                y1="404.401"
                                x2="92.52"
                                y2="404.401"
                            ></line>
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect
                                x="80.08"
                                y="399.84"
                                fill="#FFFFFF"
                                stroke="#283654"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                width="4.8"
                                height="1.439"
                            ></rect>
                        </g>
                        <g>
                            <rect
                                x="80.08"
                                y="401.282"
                                fill="#FFFFFF"
                                stroke="#283654"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                width="4.8"
                                height="1.438"
                            ></rect>
                        </g>
                        <g>
                            <rect
                                x="80.08"
                                y="402.719"
                                fill="#FFFFFF"
                                stroke="#283654"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                width="4.8"
                                height="1.441"
                            ></rect>
                        </g>

                        <line
                            fill="none"
                            stroke="#283654"
                            strokeWidth="0.24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="81.65"
                            y1="400.44"
                            x2="83.57"
                            y2="400.44"
                        ></line>

                        <line
                            fill="none"
                            stroke="#283654"
                            strokeWidth="0.24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="81.65"
                            y1="401.879"
                            x2="83.57"
                            y2="401.879"
                        ></line>

                        <line
                            fill="none"
                            stroke="#283654"
                            strokeWidth="0.24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="81.65"
                            y1="403.321"
                            x2="83.57"
                            y2="403.321"
                        ></line>
                    </g>
                    <g>
                        <g>
                            <rect
                                x="93.52"
                                y="399.84"
                                fill="#FFFFFF"
                                stroke="#283654"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                width="4.8"
                                height="1.439"
                            ></rect>
                        </g>
                        <g>
                            <rect
                                x="93.52"
                                y="401.282"
                                fill="#FFFFFF"
                                stroke="#283654"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                width="4.8"
                                height="1.438"
                            ></rect>
                        </g>
                        <g>
                            <rect
                                x="93.52"
                                y="402.719"
                                fill="#FFFFFF"
                                stroke="#283654"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                width="4.8"
                                height="1.441"
                            ></rect>
                        </g>

                        <line
                            fill="none"
                            stroke="#283654"
                            strokeWidth="0.24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="95.09"
                            y1="400.44"
                            x2="97.01"
                            y2="400.44"
                        ></line>

                        <line
                            fill="none"
                            stroke="#283654"
                            strokeWidth="0.24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="95.09"
                            y1="401.879"
                            x2="97.01"
                            y2="401.879"
                        ></line>

                        <line
                            fill="none"
                            stroke="#283654"
                            strokeWidth="0.24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="95.09"
                            y1="403.321"
                            x2="97.01"
                            y2="403.321"
                        ></line>
                    </g>
                </g>

                <line
                    fill="none"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="82.6"
                    y1="398.639"
                    x2="84.52"
                    y2="398.639"
                ></line>

                <path
                    fill="#FFC80D"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M85.94,395.02c-0.189-0.659,0.261-1.3,0.94-1.439c0.64-0.119,1.27,0.229,1.46,0.789C88.53,394.94,86.13,395.69,85.94,395.02z"
                ></path>

                <path
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M85.94,395.02c-0.189-0.659,0.261-1.3,0.94-1.439c0.64-0.119,1.27,0.229,1.46,0.789C87.28,394.111,86.77,394.14,85.94,395.02z"
                ></path>
            </g>
            <g>
                <rect
                    x="47.56"
                    y="421.081"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="1.92"
                ></rect>

                <rect
                    x="49.24"
                    y="421.081"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="1.199"
                    height="1.92"
                ></rect>

                <rect
                    x="46.12"
                    y="421.081"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="46.6"
                    y="421.081"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="48.04"
                    y="421.081"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="44.92"
                    y="420.84"
                    fill="#FFFFFF"
                    stroke="#2C3957"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.158"
                ></rect>

                <rect
                    x="50.2"
                    y="420.6"
                    fill="#FFC810"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.398"
                ></rect>

                <rect
                    x="48.52"
                    y="420.84"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="2.158"
                ></rect>

                <rect
                    x="49"
                    y="420.84"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.158"
                ></rect>

                <rect
                    x="45.4"
                    y="420.362"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.639"
                ></rect>
            </g>
            <g>
                <rect
                    x="46.12"
                    y="414.84"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="1.92"
                ></rect>

                <rect
                    x="49"
                    y="414.84"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="46.84"
                    y="414.84"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="47.8"
                    y="414.84"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="45.64"
                    y="414.6"
                    fill="#FFFFFF"
                    stroke="#2C3957"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.16"
                ></rect>

                <rect
                    x="48.28"
                    y="414.6"
                    fill="#FFC810"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.961"
                    height="2.398"
                ></rect>

                <rect
                    x="47.32"
                    y="414.6"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="2.16"
                ></rect>

                <rect
                    x="50.44"
                    y="414.6"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="2.16"
                ></rect>

                <rect
                    x="44.92"
                    y="414.12"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.641"
                ></rect>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="44.57"
                    y1="416.879"
                    x2="51.53"
                    y2="416.879"
                ></line>
            </g>
            <g>
                <rect
                    x="46.12"
                    y="411.719"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="1.92"
                ></rect>

                <rect
                    x="47.32"
                    y="411.719"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="1.92"
                ></rect>

                <rect
                    x="47.8"
                    y="411.719"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="48.64"
                    y="411.362"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.398"
                ></rect>

                <rect
                    x="46.84"
                    y="411.481"
                    fill="#FFFFFF"
                    stroke="#2C3957"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.158"
                ></rect>

                <rect
                    x="49.24"
                    y="411.241"
                    fill="#FFC810"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.398"
                ></rect>

                <rect
                    x="49.96"
                    y="411.481"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.158"
                ></rect>

                <rect
                    x="50.44"
                    y="411.481"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="2.158"
                ></rect>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="44.57"
                    y1="413.76"
                    x2="51.53"
                    y2="413.76"
                ></line>
            </g>
            <g>
                <rect
                    x="44.92"
                    y="408.6"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="1.92"
                ></rect>

                <rect
                    x="49.24"
                    y="408.6"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="1.199"
                    height="1.92"
                ></rect>

                <rect
                    x="46.84"
                    y="408.6"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="47.32"
                    y="408.6"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="1.92"
                ></rect>

                <rect
                    x="47.8"
                    y="408.6"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="45.64"
                    y="408.362"
                    fill="#FFFFFF"
                    stroke="#2C3957"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.158"
                ></rect>

                <rect
                    x="50.2"
                    y="408.12"
                    fill="#FFC810"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.4"
                ></rect>

                <rect
                    x="48.28"
                    y="408.362"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="2.158"
                ></rect>

                <rect
                    x="48.76"
                    y="408.362"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.158"
                ></rect>

                <rect
                    x="46.12"
                    y="407.879"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.641"
                ></rect>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="44.57"
                    y1="410.639"
                    x2="51.53"
                    y2="410.639"
                ></line>
            </g>

            <rect
                x="49.48"
                y="414.84"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.721"
                height="1.92"
            ></rect>

            <rect
                x="49.96"
                y="414.84"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.72"
                height="1.92"
            ></rect>

            <rect
                x="49.72"
                y="417.481"
                fill="#FFC810"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.96"
                height="2.398"
            ></rect>

            <rect
                x="49.24"
                y="417.959"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.72"
                height="1.92"
            ></rect>

            <rect
                x="50.44"
                y="417.959"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.721"
                height="1.92"
            ></rect>

            <rect
                x="48.16"
                y="411.362"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.72"
                height="2.398"
            ></rect>

            <rect
                x="45.64"
                y="411.481"
                fill="#FFFFFF"
                stroke="#2C3957"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.72"
                height="2.158"
            ></rect>

            <rect
                x="44.8"
                y="411.6"
                fill="#FFFFFF"
                stroke="#2C3957"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.96"
                height="2.16"
            ></rect>

            <polygon
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
		36.4,401.042 36.4,404.161 35.44,404.161 35.44,400.081 44.32,400.081 44.32,404.161 43.36,404.161 43.36,401.042 	"
            ></polygon>

            <rect
                x="36.88"
                y="398.879"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="2.88"
                height="0.721"
            ></rect>

            <rect
                x="36.64"
                y="398.401"
                fill="#FFC80D"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="2.88"
                height="0.719"
            ></rect>

            <rect
                x="36.64"
                y="399.362"
                fill="#FFC80D"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="2.88"
                height="0.719"
            ></rect>
            <g>
                <polyline
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			49.24,397.44 49.24,403.92 49.24,401.639 46.36,401.639 		"
                ></polyline>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="46.84"
                    y1="401.639"
                    x2="46.84"
                    y2="404.042"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="46.84"
                    y1="402.84"
                    x2="49"
                    y2="402.84"
                ></line>
            </g>
            <g>
                <polyline
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			30.76,397.68 30.76,404.161 30.76,401.879 33.64,401.879 		"
                ></polyline>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="33.16"
                    y1="401.879"
                    x2="33.16"
                    y2="404.282"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="33.16"
                    y1="403.081"
                    x2="31"
                    y2="403.081"
                ></line>
            </g>

            <rect
                x="18.16"
                y="388.32"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="6.72"
                height="15.841"
            ></rect>
            <g>
                <rect
                    x="20.68"
                    y="401.879"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="1.2"
                    height="1.92"
                ></rect>

                <rect
                    x="18.52"
                    y="398.76"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="1.92"
                ></rect>

                <rect
                    x="19"
                    y="398.76"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="21.64"
                    y="398.76"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="19.48"
                    y="398.76"
                    fill="#FFFFFF"
                    stroke="#2C3957"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="2.16"
                ></rect>

                <rect
                    x="20.44"
                    y="398.282"
                    fill="#FFC810"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.398"
                ></rect>

                <rect
                    x="21.16"
                    y="398.52"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.16"
                ></rect>

                <rect
                    x="19.96"
                    y="398.52"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.16"
                ></rect>

                <rect
                    x="22.12"
                    y="398.282"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.639"
                ></rect>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="18.17"
                    y1="400.799"
                    x2="25.13"
                    y2="400.799"
                ></line>
            </g>
            <g>
                <rect
                    x="21.16"
                    y="401.879"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="1.92"
                ></rect>

                <rect
                    x="22.84"
                    y="401.879"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="1.2"
                    height="1.92"
                ></rect>

                <rect
                    x="19.72"
                    y="401.879"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="20.2"
                    y="401.879"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="21.64"
                    y="401.879"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="18.52"
                    y="401.639"
                    fill="#FFFFFF"
                    stroke="#2C3957"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="2.16"
                ></rect>

                <rect
                    x="23.8"
                    y="401.401"
                    fill="#FFC810"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.398"
                ></rect>

                <rect
                    x="22.12"
                    y="401.639"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.16"
                ></rect>

                <rect
                    x="22.6"
                    y="401.639"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.16"
                ></rect>

                <rect
                    x="19"
                    y="401.161"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.641"
                ></rect>
            </g>
            <g>
                <rect
                    x="19.72"
                    y="395.64"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="1.919"
                ></rect>

                <rect
                    x="22.6"
                    y="395.64"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.919"
                ></rect>

                <rect
                    x="20.44"
                    y="395.64"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="1.919"
                ></rect>

                <rect
                    x="21.4"
                    y="395.64"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="1.919"
                ></rect>

                <rect
                    x="19.24"
                    y="395.4"
                    fill="#FFFFFF"
                    stroke="#2C3957"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.159"
                ></rect>

                <rect
                    x="21.88"
                    y="395.4"
                    fill="#FFC810"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.399"
                ></rect>

                <rect
                    x="20.92"
                    y="395.4"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.159"
                ></rect>

                <rect
                    x="24.04"
                    y="395.4"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.159"
                ></rect>

                <rect
                    x="18.52"
                    y="394.92"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.639"
                ></rect>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="18.17"
                    y1="397.68"
                    x2="25.13"
                    y2="397.68"
                ></line>
            </g>
            <g>
                <rect
                    x="19.72"
                    y="392.52"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="1.92"
                ></rect>

                <rect
                    x="20.92"
                    y="392.52"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="21.4"
                    y="392.52"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="1.92"
                ></rect>

                <rect
                    x="22.24"
                    y="392.161"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.399"
                ></rect>

                <rect
                    x="20.44"
                    y="392.282"
                    fill="#FFFFFF"
                    stroke="#2C3957"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="2.158"
                ></rect>

                <rect
                    x="22.84"
                    y="392.042"
                    fill="#FFC810"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.398"
                ></rect>

                <rect
                    x="23.56"
                    y="392.282"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.158"
                ></rect>

                <rect
                    x="24.04"
                    y="392.282"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.158"
                ></rect>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="18.17"
                    y1="394.56"
                    x2="25.13"
                    y2="394.56"
                ></line>
            </g>
            <g>
                <rect
                    x="18.52"
                    y="389.4"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="1.92"
                ></rect>

                <rect
                    x="22.84"
                    y="389.4"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="1.2"
                    height="1.92"
                ></rect>

                <rect
                    x="20.44"
                    y="389.4"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="1.92"
                ></rect>

                <rect
                    x="20.92"
                    y="389.4"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="21.4"
                    y="389.4"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="1.92"
                ></rect>

                <rect
                    x="19.24"
                    y="389.161"
                    fill="#FFFFFF"
                    stroke="#2C3957"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.159"
                ></rect>

                <rect
                    x="23.8"
                    y="388.92"
                    fill="#FFC810"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.398"
                ></rect>

                <rect
                    x="21.88"
                    y="389.161"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.159"
                ></rect>

                <rect
                    x="22.36"
                    y="389.161"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="2.159"
                ></rect>

                <rect
                    x="19.72"
                    y="388.68"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.64"
                ></rect>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="18.17"
                    y1="391.44"
                    x2="25.13"
                    y2="391.44"
                ></line>
            </g>

            <rect
                x="23.08"
                y="395.64"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.72"
                height="1.919"
            ></rect>

            <rect
                x="23.56"
                y="395.64"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.72"
                height="1.919"
            ></rect>

            <rect
                x="23.32"
                y="398.282"
                fill="#FFC810"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.96"
                height="2.398"
            ></rect>

            <rect
                x="22.84"
                y="398.76"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.72"
                height="1.92"
            ></rect>

            <rect
                x="24.04"
                y="398.76"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.72"
                height="1.92"
            ></rect>

            <rect
                x="21.76"
                y="392.161"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.72"
                height="2.399"
            ></rect>

            <rect
                x="19.24"
                y="392.282"
                fill="#FFFFFF"
                stroke="#2C3957"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.72"
                height="2.158"
            ></rect>

            <rect
                x="18.4"
                y="392.4"
                fill="#FFFFFF"
                stroke="#2C3957"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.96"
                height="2.16"
            ></rect>

            <polyline
                fill="none"
                stroke="#283654"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
		83.56,398.52 82.42,394.44 85.48,392.22 86.92,393.48 	"
            ></polyline>

            <polyline
                fill="none"
                stroke="#283654"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
		65.8,393.241 65.56,392.042 61.48,393.241 62.44,398.282 	"
            ></polyline>
            <g>
                <g>
                    <g>
                        <rect
                            x="71.68"
                            y="395.76"
                            fill="#FFFFFF"
                            stroke="#283654"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            width="0.96"
                            height="3.119"
                        ></rect>

                        <rect
                            x="69.77"
                            y="394.56"
                            fill="#FFC80D"
                            stroke="#283654"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            width="4.8"
                            height="3.36"
                        ></rect>
                    </g>

                    <rect
                        x="58.96"
                        y="398.879"
                        fill="none"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        width="18.24"
                        height="0.721"
                    ></rect>
                    <g>
                        <g>
                            <rect
                                x="67.84"
                                y="401.042"
                                fill="#FFFFFF"
                                stroke="#283654"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                width="0.96"
                                height="3.119"
                            ></rect>

                            <rect
                                x="66.16"
                                y="396.48"
                                fill="#FFFFFF"
                                stroke="#283654"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                width="4.319"
                                height="4.802"
                            ></rect>

                            <line
                                fill="none"
                                stroke="#283654"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="65.44"
                                y1="404.161"
                                x2="71.4"
                                y2="404.161"
                            ></line>
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect
                                x="58.96"
                                y="399.6"
                                fill="#FFFFFF"
                                stroke="#283654"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                width="4.8"
                                height="1.439"
                            ></rect>
                        </g>
                        <g>
                            <rect
                                x="58.96"
                                y="401.042"
                                fill="#FFFFFF"
                                stroke="#283654"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                width="4.8"
                                height="1.439"
                            ></rect>
                        </g>
                        <g>
                            <rect
                                x="58.96"
                                y="402.481"
                                fill="#FFFFFF"
                                stroke="#283654"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                width="4.8"
                                height="1.439"
                            ></rect>
                        </g>

                        <line
                            fill="none"
                            stroke="#283654"
                            strokeWidth="0.24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="60.53"
                            y1="400.2"
                            x2="62.45"
                            y2="400.2"
                        ></line>

                        <line
                            fill="none"
                            stroke="#283654"
                            strokeWidth="0.24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="60.53"
                            y1="401.639"
                            x2="62.45"
                            y2="401.639"
                        ></line>

                        <line
                            fill="none"
                            stroke="#283654"
                            strokeWidth="0.24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="60.53"
                            y1="403.081"
                            x2="62.45"
                            y2="403.081"
                        ></line>
                    </g>
                    <g>
                        <g>
                            <rect
                                x="72.4"
                                y="399.6"
                                fill="#FFFFFF"
                                stroke="#283654"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                width="4.801"
                                height="1.439"
                            ></rect>
                        </g>
                        <g>
                            <rect
                                x="72.4"
                                y="401.042"
                                fill="#FFFFFF"
                                stroke="#283654"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                width="4.801"
                                height="1.439"
                            ></rect>
                        </g>
                        <g>
                            <rect
                                x="72.4"
                                y="402.481"
                                fill="#FFFFFF"
                                stroke="#283654"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                width="4.801"
                                height="1.439"
                            ></rect>
                        </g>

                        <line
                            fill="none"
                            stroke="#283654"
                            strokeWidth="0.24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="73.97"
                            y1="400.2"
                            x2="75.89"
                            y2="400.2"
                        ></line>

                        <line
                            fill="none"
                            stroke="#283654"
                            strokeWidth="0.24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="73.97"
                            y1="401.639"
                            x2="75.89"
                            y2="401.639"
                        ></line>

                        <line
                            fill="none"
                            stroke="#283654"
                            strokeWidth="0.24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="73.97"
                            y1="403.081"
                            x2="75.89"
                            y2="403.081"
                        ></line>
                    </g>
                </g>

                <line
                    fill="none"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="61.48"
                    y1="398.401"
                    x2="63.4"
                    y2="398.401"
                ></line>

                <path
                    fill="#FFC80D"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M64.82,394.782c-0.189-0.662,0.261-1.302,0.94-1.441c0.64-0.119,1.27,0.229,1.46,0.791C67.41,394.7,65.01,395.45,64.82,394.782z"
                ></path>

                <path
                    fill="#FFFFFF"
                    stroke="#283654"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M64.82,394.782c-0.189-0.662,0.261-1.302,0.94-1.441c0.64-0.119,1.27,0.229,1.46,0.791C66.16,393.87,65.65,393.9,64.82,394.782z"
                ></path>
            </g>
            <g>
                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			90.79,416.87 89.88,423.35 91.09,423.35 91.7,420.792 92.31,423.35 93.53,423.35 92.61,416.87 		"
                ></polygon>

                <path
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M90.18,415.35c0-0.881,0.68-1.602,1.521-1.602c0.84,0,1.52,0.721,1.52,1.602c0,0.891-0.68,1.6-1.52,1.6
			C90.86,416.95,90.18,416.241,90.18,415.35z"
                ></path>

                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			88.94,418.27 91.5,417.299 91.86,418.292 89.21,419.362 		"
                ></polygon>
            </g>
            <g>
                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			87.26,416.87 88.17,423.35 86.95,423.35 86.34,420.792 85.74,423.35 84.52,423.35 85.43,416.87 		"
                ></polygon>

                <path
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M87.86,415.35c0-0.881-0.68-1.602-1.52-1.602s-1.521,0.721-1.521,1.602c0,0.891,0.681,1.6,1.521,1.6S87.86,416.241,87.86,415.35z"
                ></path>

                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			89.12,418.362 86.52,417.77 86.15,418.68 88.96,419.362 		"
                ></polygon>
            </g>
            <g>
                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			59.9,417.35 60.4,419.491 61.48,420.209 61.45,423.209 60.52,423.151 60.46,420.481 57.88,420.6 58.07,417.35 		"
                ></polygon>

                <path
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M60.5,415.831c0-0.881-0.681-1.6-1.521-1.6s-1.52,0.719-1.52,1.6c0,0.891,0.68,1.6,1.52,1.6S60.5,416.719,60.5,415.831z"
                ></path>

                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			61.76,418.84 59.16,418.25 58.79,419.161 61.6,419.84 		"
                ></polygon>
            </g>

            <polygon
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
		62.8,420.241 62.8,423.362 61.84,423.362 61.84,419.282 70.72,419.282 70.72,423.362 69.76,423.362 69.76,420.241 	"
            ></polygon>

            <polygon
                fill="#FFC80D"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
		64.48,416.879 64.96,417.6 62.32,419.042 62.08,418.321 	"
            ></polygon>
            <g>
                <polyline
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			75.64,416.639 75.64,423.12 75.64,420.84 72.76,420.84 		"
                ></polyline>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="73.24"
                    y1="420.84"
                    x2="73.24"
                    y2="423.241"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="73.24"
                    y1="422.042"
                    x2="75.4"
                    y2="422.042"
                ></line>
            </g>
            <g>
                <polyline
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			57.16,416.879 57.16,423.362 57.16,421.081 60.04,421.081 		"
                ></polyline>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="59.56"
                    y1="421.081"
                    x2="59.56"
                    y2="423.481"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="59.56"
                    y1="422.282"
                    x2="57.4"
                    y2="422.282"
                ></line>
            </g>

            <polygon
                fill="#FFC80D"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
		63.76,418.321 64.24,417.84 64.24,419.042 63.76,419.042 	"
            ></polygon>
            <g>
                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			90.79,416.87 89.88,423.35 91.09,423.35 91.7,420.792 92.31,423.35 93.53,423.35 92.61,416.87 		"
                ></polygon>

                <path
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M90.18,415.35c0-0.881,0.68-1.602,1.521-1.602c0.84,0,1.52,0.721,1.52,1.602c0,0.891-0.68,1.6-1.52,1.6
			C90.86,416.95,90.18,416.241,90.18,415.35z"
                ></path>

                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			88.94,418.27 91.5,417.299 91.86,418.292 89.21,419.362 		"
                ></polygon>
            </g>
            <g>
                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			87.26,416.87 88.17,423.35 86.95,423.35 86.34,420.792 85.74,423.35 84.52,423.35 85.43,416.87 		"
                ></polygon>

                <path
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M87.86,415.35c0-0.881-0.68-1.602-1.52-1.602s-1.521,0.721-1.521,1.602c0,0.891,0.681,1.6,1.521,1.6S87.86,416.241,87.86,415.35z"
                ></path>

                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			89.12,418.362 86.52,417.77 86.15,418.68 88.96,419.362 		"
                ></polygon>
            </g>
            <g>
                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			59.9,417.35 60.4,419.491 61.48,420.209 61.45,423.209 60.52,423.151 60.46,420.481 57.88,420.6 58.07,417.35 		"
                ></polygon>

                <path
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M60.5,415.831c0-0.881-0.681-1.6-1.521-1.6s-1.52,0.719-1.52,1.6c0,0.891,0.68,1.6,1.52,1.6S60.5,416.719,60.5,415.831z"
                ></path>

                <polygon
                    fill="#FFFAFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			61.76,418.84 59.16,418.25 58.79,419.161 61.6,419.84 		"
                ></polygon>
            </g>

            <polygon
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
		62.8,420.241 62.8,423.362 61.84,423.362 61.84,419.282 70.72,419.282 70.72,423.362 69.76,423.362 69.76,420.241 	"
            ></polygon>

            <polygon
                fill="#FFC80D"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
		64.48,416.879 64.96,417.6 62.32,419.042 62.08,418.321 	"
            ></polygon>
            <g>
                <polyline
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			75.64,416.639 75.64,423.12 75.64,420.84 72.76,420.84 		"
                ></polyline>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="73.24"
                    y1="420.84"
                    x2="73.24"
                    y2="423.241"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="73.24"
                    y1="422.042"
                    x2="75.4"
                    y2="422.042"
                ></line>
            </g>
            <g>
                <polyline
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			57.16,416.879 57.16,423.362 57.16,421.081 60.04,421.081 		"
                ></polyline>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.72"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="59.56"
                    y1="421.081"
                    x2="59.56"
                    y2="423.481"
                ></line>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="59.56"
                    y1="422.282"
                    x2="57.4"
                    y2="422.282"
                ></line>
            </g>

            <polygon
                fill="#FFC80D"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
		63.76,418.321 64.24,417.84 64.24,419.042 63.76,419.042 	"
            ></polygon>

            <rect
                x="44.56"
                y="407.52"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="6.72"
                height="15.842"
            ></rect>
            <g>
                <rect
                    x="47.08"
                    y="421.081"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="1.199"
                    height="1.92"
                ></rect>

                <rect
                    x="44.92"
                    y="417.959"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="45.4"
                    y="417.959"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="1.92"
                ></rect>

                <rect
                    x="48.04"
                    y="417.959"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="45.88"
                    y="417.959"
                    fill="#FFFFFF"
                    stroke="#2C3957"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.16"
                ></rect>

                <rect
                    x="46.84"
                    y="417.481"
                    fill="#FFC810"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.398"
                ></rect>

                <rect
                    x="47.56"
                    y="417.719"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.16"
                ></rect>

                <rect
                    x="46.36"
                    y="417.719"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="2.16"
                ></rect>

                <rect
                    x="48.52"
                    y="417.481"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.639"
                ></rect>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="44.57"
                    y1="420"
                    x2="51.53"
                    y2="420"
                ></line>
            </g>
            <g>
                <rect
                    x="47.56"
                    y="421.081"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="1.92"
                ></rect>

                <rect
                    x="49.24"
                    y="421.081"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="1.199"
                    height="1.92"
                ></rect>

                <rect
                    x="46.12"
                    y="421.081"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="46.6"
                    y="421.081"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="48.04"
                    y="421.081"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="44.92"
                    y="420.84"
                    fill="#FFFFFF"
                    stroke="#2C3957"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.158"
                ></rect>

                <rect
                    x="50.2"
                    y="420.6"
                    fill="#FFC810"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.398"
                ></rect>

                <rect
                    x="48.52"
                    y="420.84"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="2.158"
                ></rect>

                <rect
                    x="49"
                    y="420.84"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.158"
                ></rect>

                <rect
                    x="45.4"
                    y="420.362"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.639"
                ></rect>
            </g>
            <g>
                <rect
                    x="46.12"
                    y="414.84"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="1.92"
                ></rect>

                <rect
                    x="49"
                    y="414.84"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="46.84"
                    y="414.84"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="47.8"
                    y="414.84"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="45.64"
                    y="414.6"
                    fill="#FFFFFF"
                    stroke="#2C3957"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.16"
                ></rect>

                <rect
                    x="48.28"
                    y="414.6"
                    fill="#FFC810"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.961"
                    height="2.398"
                ></rect>

                <rect
                    x="47.32"
                    y="414.6"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="2.16"
                ></rect>

                <rect
                    x="50.44"
                    y="414.6"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="2.16"
                ></rect>

                <rect
                    x="44.92"
                    y="414.12"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.641"
                ></rect>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="44.57"
                    y1="416.879"
                    x2="51.53"
                    y2="416.879"
                ></line>
            </g>
            <g>
                <rect
                    x="46.12"
                    y="411.719"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="1.92"
                ></rect>

                <rect
                    x="47.32"
                    y="411.719"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="1.92"
                ></rect>

                <rect
                    x="47.8"
                    y="411.719"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="48.64"
                    y="411.362"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.398"
                ></rect>

                <rect
                    x="46.84"
                    y="411.481"
                    fill="#FFFFFF"
                    stroke="#2C3957"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.158"
                ></rect>

                <rect
                    x="49.24"
                    y="411.241"
                    fill="#FFC810"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.398"
                ></rect>

                <rect
                    x="49.96"
                    y="411.481"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.158"
                ></rect>

                <rect
                    x="50.44"
                    y="411.481"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="2.158"
                ></rect>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="44.57"
                    y1="413.76"
                    x2="51.53"
                    y2="413.76"
                ></line>
            </g>
            <g>
                <rect
                    x="44.92"
                    y="408.6"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="1.92"
                ></rect>

                <rect
                    x="49.24"
                    y="408.6"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="1.199"
                    height="1.92"
                ></rect>

                <rect
                    x="46.84"
                    y="408.6"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="47.32"
                    y="408.6"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="1.92"
                ></rect>

                <rect
                    x="47.8"
                    y="408.6"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="1.92"
                ></rect>

                <rect
                    x="45.64"
                    y="408.362"
                    fill="#FFFFFF"
                    stroke="#2C3957"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.158"
                ></rect>

                <rect
                    x="50.2"
                    y="408.12"
                    fill="#FFC810"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.4"
                ></rect>

                <rect
                    x="48.28"
                    y="408.362"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.721"
                    height="2.158"
                ></rect>

                <rect
                    x="48.76"
                    y="408.362"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.72"
                    height="2.158"
                ></rect>

                <rect
                    x="46.12"
                    y="407.879"
                    fill="#FFC80F"
                    stroke="#293755"
                    strokeWidth="0.24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="0.96"
                    height="2.641"
                ></rect>

                <line
                    fill="none"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="44.57"
                    y1="410.639"
                    x2="51.53"
                    y2="410.639"
                ></line>
            </g>

            <rect
                x="49.48"
                y="414.84"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.721"
                height="1.92"
            ></rect>

            <rect
                x="49.96"
                y="414.84"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.72"
                height="1.92"
            ></rect>

            <rect
                x="49.72"
                y="417.481"
                fill="#FFC810"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.96"
                height="2.398"
            ></rect>

            <rect
                x="49.24"
                y="417.959"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.72"
                height="1.92"
            ></rect>

            <rect
                x="50.44"
                y="417.959"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.721"
                height="1.92"
            ></rect>

            <rect
                x="48.16"
                y="411.362"
                fill="#FFFFFF"
                stroke="#273553"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.72"
                height="2.398"
            ></rect>

            <rect
                x="45.64"
                y="411.481"
                fill="#FFFFFF"
                stroke="#2C3957"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.72"
                height="2.158"
            ></rect>

            <rect
                x="44.8"
                y="411.6"
                fill="#FFFFFF"
                stroke="#2C3957"
                strokeWidth="0.24"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                width="0.96"
                height="2.16"
            ></rect>
            <g>
                <rect
                    x="23.44"
                    y="372.375"
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.96"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    width="68.4"
                    height="12.24"
                ></rect>
                <rect x="16.48" y="373.775" fill="none" width="81.84" height="11.229"></rect>
                <text
                    transform="matrix(1 0 0 1 27.5129 380.9995)"
                    fill="#263352"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    GARNER &amp; ASSOCIATES
                </text>
            </g>
        </g>
        <g id="Lorry">
            <g>
                <polygon
                    fill="#FFFFFF"
                    points="203.36,419.44 211.12,419.52 211.12,425.282 201.44,425.2 		"
                ></polygon>

                <path
                    fill="#FFC80D"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="
			M252.399,431.161v-12.84c-0.238-0.48-0.718-0.721-1.438-0.959H213.4c-0.96,0-1.199,3.598-1.199,4.318c0,0.42,0.21,3.9,0.119,9.391
			c-0.01,0.811-0.39,0.299-0.479,0.211V413.04h-1.2v5.4h-5.76c-2.88,0-3.72,5.039-3.96,5.76c-1.44,0.721-4.8,1.439-4.32,3.6
			c0,0.482,0,1.201-0.24,1.682c0,0.959-0.239,2.52-0.239,3c0,0.719,0,0.959,0.479,1.439h4.32c0.24-2.639,1.68-3.24,3.359-3.24
			c1.681,0,3.12,1.318,3.36,3.24h6.48c0.238-2.639,1.679-3.361,3.359-3.361c1.68,0,3.119,1.32,3.359,3.24
			c0.241-0.479,0.361-3.359,3.6-3.24c1.44,0,2.88,0.721,3.36,3.361h8.64c0.241-2.641,1.44-3.121,3.121-3.121
			c3.121,0,3.36,2.76,3.601,3c0.238-2.639,1.679-3,3.358-3c1.682,0,2.94,0.75,3.12,3.121h2.641c0.48-0.721,0.841-0.721,0.841-1.439
			C253.12,432.481,252.879,431.639,252.399,431.161z M210.16,424.319h-7.801c0.48-4.078,2.521-4.32,4.681-4.32
			c1.439,0.002,3.12,0.002,3.12,1.922V424.319z"
                ></path>
                <circle fill="#273553" cx="204.16" cy="434.162" r="2.64"></circle>
                <circle fill="#273553" cx="217.36" cy="434.162" r="2.64"></circle>
                <path
                    fill="#273553"
                    d="M221.681,433.92c0-1.461,1.18-2.641,2.639-2.641c1.461,0,2.642,1.18,2.642,2.641
			c0,1.459-1.18,2.639-2.642,2.639C222.86,436.559,221.681,435.379,221.681,433.92z"
                ></path>
                <path
                    fill="#273553"
                    d="M237.04,434.161c0-1.461,1.18-2.641,2.641-2.641c1.459,0,2.639,1.18,2.639,2.641
			c0,1.459-1.18,2.641-2.639,2.641C238.22,436.799,237.04,435.62,237.04,434.161z"
                ></path>
                <path
                    fill="#273553"
                    d="M243.76,434.161c0-1.461,1.18-2.641,2.64-2.641c1.461,0,2.642,1.18,2.642,2.641
			c0,1.459-1.181,2.641-2.642,2.641C244.939,436.799,243.76,435.62,243.76,434.161z"
                ></path>
                <path
                    fill="#FFFFFF"
                    d="M202.96,434.161c0-0.66,0.54-1.199,1.2-1.199c0.659,0,1.199,0.539,1.199,1.199s-0.54,1.201-1.199,1.201
			C203.5,435.362,202.96,434.821,202.96,434.161z"
                ></path>
                <path
                    fill="#FFFFFF"
                    d="M216.16,434.161c0-0.66,0.54-1.199,1.199-1.199s1.2,0.539,1.2,1.199s-0.541,1.201-1.2,1.201
			S216.16,434.821,216.16,434.161z"
                ></path>
                <path
                    fill="#FFFFFF"
                    d="M223.12,433.92c0-0.66,0.54-1.201,1.198-1.201c0.661,0,1.2,0.541,1.2,1.201s-0.539,1.199-1.2,1.199
			C223.66,435.12,223.12,434.581,223.12,433.92z"
                ></path>
                <path
                    fill="#FFFFFF"
                    d="M238.48,434.161c0-0.66,0.539-1.199,1.2-1.199c0.659,0,1.199,0.539,1.199,1.199s-0.54,1.201-1.199,1.201
			C239.02,435.362,238.48,434.821,238.48,434.161z"
                ></path>
                <path
                    fill="#FFFFFF"
                    d="M245.2,434.161c0-0.66,0.539-1.199,1.198-1.199c0.661,0,1.2,0.539,1.2,1.199s-0.539,1.201-1.2,1.201
			C245.74,435.362,245.2,434.821,245.2,434.161z"
                ></path>
                <rect x="217.36" y="419.042" fill="none" width="31.2" height="11.809"></rect>
                <text
                    transform="matrix(1 0 0 1 215.032 426.1284)"
                    fill="#283654"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="6"
                >
                    MCGRATH GLOBAL
                </text>
            </g>
        </g>
        <g id="Space">
            <path
                fill="#263452"
                d="M483.915-129.625H-56.544l1.18,352.52c41.11-59.42,147.24-101.74,271.69-101.74
		c124.448,0,230.589,42.32,271.689,101.74C499.796,239.924,483.915-129.625,483.915-129.625z"
            ></path>
            <g id="Stars">
                <polygon
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			136.96,14.88 137.88,16.74 139.93,17.04 138.44,18.48 138.79,20.52 136.96,19.56 135.13,20.52 135.48,18.48 133.99,17.04 
			136.04,16.74 		"
                ></polygon>

                <polygon
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			216.16,41.28 217.08,43.14 219.13,43.44 217.64,44.88 217.99,46.92 216.16,45.96 214.33,46.92 214.68,44.88 213.19,43.44 
			215.24,43.14 		"
                ></polygon>

                <polygon
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			390.64,29.52 391.561,31.38 393.61,31.68 392.119,33.12 392.47,35.16 390.64,34.2 388.811,35.16 389.159,33.12 387.67,31.68 
			389.72,31.38 		"
                ></polygon>

                <polygon
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			284.561,23.76 285.48,25.62 287.529,25.92 286.04,27.36 286.39,29.4 284.561,28.44 282.73,29.4 283.08,27.36 281.59,25.92 
			283.64,25.62 		"
                ></polygon>

                <polygon
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			406.24,96.48 407.159,98.34 409.21,98.64 407.72,100.08 408.069,102.12 406.24,101.16 404.409,102.12 404.76,100.08 403.27,98.64 
			405.319,98.34 		"
                ></polygon>

                <polygon
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			182.32,84 183.24,85.86 185.29,86.16 183.8,87.6 184.15,89.64 182.32,88.68 180.49,89.64 180.84,87.6 179.35,86.16 181.4,85.86 		
			"
                >
                    {" "}
                </polygon>

                <polygon
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			69.52,14.88 70.44,16.74 72.49,17.04 71,18.48 71.35,20.52 69.52,19.56 67.69,20.52 68.04,18.48 66.55,17.04 68.6,16.74 		"
                ></polygon>

                <polygon
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			21.52,-5.52 22.44,-3.66 24.49,-3.36 23,-1.92 23.35,0.12 21.52,-0.84 19.69,0.12 20.04,-1.92 18.55,-3.36 20.6,-3.66 		"
                ></polygon>

                <polygon
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			33.76,59.52 34.68,61.38 36.73,61.68 35.24,63.12 35.59,65.16 33.76,64.2 31.93,65.16 32.28,63.12 30.79,61.68 32.84,61.38 		"
                ></polygon>
            </g>
            <g id="Rocket">
                <polygon
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			196.92,181.04 199.49,181.8 211.43,204.081 208.05,205.88 196.13,183.59 		"
                ></polygon>

                <polygon
                    fill="#5696EC"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			209.62,200.21 212.2,205.01 214.13,205.59 217.73,212.3 211.39,215.69 207.8,208.97 208.4,207.041 205.83,202.23 206.22,200.95 
			208.33,199.82 		"
                ></polygon>

                <polygon
                    fill="#FFFFFF"
                    stroke="#273553"
                    strokeWidth="0.48"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
			217.73,212.3 219.35,212.791 210.9,217.3 211.39,215.69 		"
                ></polygon>
                <polygon
                    fill="#283654"
                    points="201.95,186.43 198.59,188.22 199.81,190.51 203.18,188.72 		"
                ></polygon>
                <polygon
                    fill="#FFFFFF"
                    points="209.6,211.831 215.52,208.67 214.28,206.37 208.37,209.52 		"
                ></polygon>

                <rect
                    x="207.193"
                    y="202.469"
                    transform="matrix(0.8821 -0.4711 0.4711 0.8821 -71.3372 122.5289)"
                    fill="#283654"
                    width="3.822"
                    height="2.608"
                ></rect>
                <polygon
                    fill="#5696EC"
                    points="202.97,188.83 200.02,190.4 201.25,192.68 204.19,191.12 		"
                ></polygon>
                <polygon
                    fill="#FCFCFC"
                    points="216.46,215.3 226.17,235.991 215.02,216.081 		"
                ></polygon>
                <g>
                    <polygon
                        fill="#FCFCFC"
                        points="213.64,216.991 223.05,232.541 212.32,217.67 			"
                    ></polygon>
                    <polygon
                        fill="#FCFCFC"
                        points="217.91,214.69 225.1,231.44 219.08,214.06 			"
                    ></polygon>
                </g>
            </g>
            <g id="ISS">
                <g>
                    <g>
                        <polygon
                            fill="#FFFFFF"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            points="
					63.67,80.88 64.13,80.77 65.05,84.4 64.6,84.51 				"
                        ></polygon>

                        <polygon
                            fill="#FFFFFF"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            points="
					70.13,79.27 70.58,79.16 71.51,82.79 71.05,82.91 				"
                        ></polygon>

                        <polygon
                            fill="#5696EC"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            points="
					57.3,64.37 62.17,63.16 66.65,80.74 61.78,81.96 				"
                        ></polygon>

                        <polygon
                            fill="#5696EC"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            points="
					63.53,62.82 68.4,61.6 72.88,79.19 68.01,80.41 				"
                        ></polygon>

                        <polygon
                            fill="#5696EC"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            points="
					62.41,84.45 67.28,83.24 71.77,100.83 66.9,102.04 				"
                        ></polygon>

                        <polygon
                            fill="#5696EC"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            points="
					68.64,82.9 73.51,81.69 78,99.28 73.13,100.49 				"
                        ></polygon>
                        <g>
                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="67.41"
                                y1="73.56"
                                x2="70.24"
                                y2="72.85"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="66.83"
                                y1="71.29"
                                x2="69.66"
                                y2="70.58"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="67.99"
                                y1="75.83"
                                x2="70.82"
                                y2="75.12"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="68.56"
                                y1="78.09"
                                x2="71.4"
                                y2="77.39"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="65.67"
                                y1="66.75"
                                x2="68.5"
                                y2="66.04"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="65.09"
                                y1="64.48"
                                x2="67.93"
                                y2="63.77"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="66.25"
                                y1="69.02"
                                x2="69.08"
                                y2="68.31"
                            ></line>
                        </g>
                        <g>
                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="61.21"
                                y1="75.22"
                                x2="64.04"
                                y2="74.52"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="60.63"
                                y1="72.95"
                                x2="63.46"
                                y2="72.25"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="61.78"
                                y1="77.49"
                                x2="64.62"
                                y2="76.79"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="62.36"
                                y1="79.76"
                                x2="65.19"
                                y2="79.05"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="59.47"
                                y1="68.41"
                                x2="62.3"
                                y2="67.71"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="58.89"
                                y1="66.14"
                                x2="61.72"
                                y2="65.44"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="60.05"
                                y1="70.68"
                                x2="62.88"
                                y2="69.98"
                            ></line>
                        </g>
                        <g>
                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="72.52"
                                y1="93.64"
                                x2="75.36"
                                y2="92.94"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="71.95"
                                y1="91.37"
                                x2="74.78"
                                y2="90.67"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="73.1"
                                y1="95.91"
                                x2="75.93"
                                y2="95.21"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="73.68"
                                y1="98.18"
                                x2="76.51"
                                y2="97.47"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="70.79"
                                y1="86.83"
                                x2="73.62"
                                y2="86.13"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="70.21"
                                y1="84.56"
                                x2="73.04"
                                y2="83.86"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="71.37"
                                y1="89.1"
                                x2="74.2"
                                y2="88.4"
                            ></line>
                        </g>
                        <g>
                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="66.32"
                                y1="95.31"
                                x2="69.16"
                                y2="94.6"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="65.75"
                                y1="93.04"
                                x2="68.58"
                                y2="92.33"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="66.9"
                                y1="97.58"
                                x2="69.73"
                                y2="96.87"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="67.48"
                                y1="99.84"
                                x2="70.31"
                                y2="99.14"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="64.59"
                                y1="88.5"
                                x2="67.42"
                                y2="87.79"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="64.01"
                                y1="86.23"
                                x2="66.84"
                                y2="85.52"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="65.17"
                                y1="90.77"
                                x2="68"
                                y2="90.06"
                            ></line>
                        </g>
                    </g>
                    <g>
                        <polygon
                            fill="#FFFFFF"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            points="
					115.21,68.05 115.66,67.94 116.59,71.57 116.13,71.68 				"
                        ></polygon>

                        <polygon
                            fill="#FFFFFF"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            points="
					121.67,66.44 122.12,66.33 123.04,69.96 122.59,70.07 				"
                        ></polygon>

                        <polygon
                            fill="#5696EC"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            points="
					108.83,51.53 113.7,50.32 118.19,67.91 113.32,69.12 				"
                        ></polygon>

                        <polygon
                            fill="#5696EC"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            points="
					115.06,49.98 119.93,48.77 124.42,66.36 119.55,67.57 				"
                        ></polygon>

                        <polygon
                            fill="#5696EC"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            points="
					113.95,71.62 118.82,70.41 123.3,88 118.43,89.21 				"
                        ></polygon>

                        <polygon
                            fill="#5696EC"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            points="
					120.18,70.07 125.05,68.86 129.53,86.44 124.66,87.66 				"
                        ></polygon>
                        <g>
                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="118.94"
                                y1="60.72"
                                x2="121.78"
                                y2="60.02"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="118.37"
                                y1="58.45"
                                x2="121.2"
                                y2="57.75"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="119.52"
                                y1="62.99"
                                x2="122.35"
                                y2="62.29"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="120.1"
                                y1="65.26"
                                x2="122.93"
                                y2="64.56"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="117.21"
                                y1="53.91"
                                x2="120.04"
                                y2="53.21"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="116.63"
                                y1="51.64"
                                x2="119.46"
                                y2="50.94"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="117.79"
                                y1="56.18"
                                x2="120.62"
                                y2="55.48"
                            ></line>
                        </g>
                        <g>
                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="112.74"
                                y1="62.39"
                                x2="115.58"
                                y2="61.68"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="112.17"
                                y1="60.12"
                                x2="115"
                                y2="59.41"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="113.32"
                                y1="64.66"
                                x2="116.15"
                                y2="63.95"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="113.9"
                                y1="66.93"
                                x2="116.73"
                                y2="66.22"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="111.01"
                                y1="55.58"
                                x2="113.84"
                                y2="54.87"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="110.43"
                                y1="53.31"
                                x2="113.26"
                                y2="52.6"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="111.59"
                                y1="57.85"
                                x2="114.42"
                                y2="57.14"
                            ></line>
                        </g>
                        <g>
                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="124.06"
                                y1="80.81"
                                x2="126.89"
                                y2="80.1"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="123.48"
                                y1="78.54"
                                x2="126.32"
                                y2="77.83"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="124.64"
                                y1="83.08"
                                x2="127.47"
                                y2="82.37"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="125.22"
                                y1="85.35"
                                x2="128.05"
                                y2="84.64"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="122.33"
                                y1="74"
                                x2="125.16"
                                y2="73.29"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="121.75"
                                y1="71.73"
                                x2="124.58"
                                y2="71.02"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="122.91"
                                y1="76.27"
                                x2="125.74"
                                y2="75.56"
                            ></line>
                        </g>
                        <g>
                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="117.86"
                                y1="82.47"
                                x2="120.69"
                                y2="81.77"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="117.28"
                                y1="80.2"
                                x2="120.11"
                                y2="79.5"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="118.44"
                                y1="84.74"
                                x2="121.27"
                                y2="84.04"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="119.02"
                                y1="87.01"
                                x2="121.85"
                                y2="86.31"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="116.13"
                                y1="75.66"
                                x2="118.96"
                                y2="74.96"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="115.55"
                                y1="73.39"
                                x2="118.38"
                                y2="72.69"
                            ></line>

                            <line
                                fill="none"
                                stroke="#273553"
                                strokeWidth="0.48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                x1="116.7"
                                y1="77.93"
                                x2="119.54"
                                y2="77.23"
                            ></line>
                        </g>
                    </g>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				63.25,82.07 122.95,67.21 123.35,68.8 63.66,83.66 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				77.02,74.18 78.72,73.76 79.79,77.95 78.09,78.38 			"
                    ></polygon>

                    <polygon
                        fill="#5696EC"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				77.02,74.18 78.72,73.76 79.79,77.95 78.09,78.38 			"
                    ></polygon>

                    <polygon
                        fill="#5696EC"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				78.5,79.97 80.19,79.54 81.26,83.74 79.57,84.16 			"
                    ></polygon>

                    <polygon
                        fill="#5696EC"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				105.79,67.01 107.49,66.59 108.56,70.79 106.86,71.21 			"
                    ></polygon>

                    <polygon
                        fill="#5696EC"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				107.27,72.8 108.97,72.38 110.04,76.58 108.34,77 			"
                    ></polygon>

                    <polygon
                        fill="#5696EC"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				81.09,65.08 81.35,66.1 89.14,64.52 81.46,66.56 81.69,67.46 89.51,65.52 81.84,68.03 82.07,68.94 90.05,66.23 89.39,63.62 			
				"
                    >
                        {" "}
                    </polygon>

                    <polygon
                        fill="#5696EC"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				99.1,60.6 99.36,61.62 91.74,63.88 99.47,62.07 99.7,62.98 91.89,64.93 99.85,63.55 100.08,64.45 91.75,65.8 91.09,63.19 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				89.24,63.05 90.94,62.63 92.36,68.19 90.66,68.61 			"
                    ></polygon>

                    <polygon
                        fill="#FFC80D"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				88.73,69.09 94.17,67.74 96.17,75.57 90.73,76.92 			"
                    ></polygon>

                    <polygon
                        fill="#FFC80D"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				84.5,78.47 103.64,73.7 104.71,77.9 85.57,82.67 			"
                    ></polygon>

                    <polygon
                        fill="#FFC80D"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				97.96,70.78 101.47,69.9 102.51,73.99 99,74.86 			"
                    ></polygon>

                    <polygon
                        fill="#FFC80D"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				93.24,80.64 92.31,81.71 93.93,88.07 99.14,86.77 97.61,80.76 95.96,79.96 			"
                    ></polygon>

                    <path
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M90.97,78.79c-0.42-1.63,0.56-3.28,2.19-3.68c1.619-0.41,3.279,0.59,3.699,2.22c0.41,1.62-0.569,3.27-2.199,3.68
				C93.04,81.41,91.38,80.42,90.97,78.79z"
                    ></path>

                    <path
                        fill="#FFC80D"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M92.34,78.51c-0.23-0.91,0.31-1.83,1.22-2.05c0.91-0.23,1.83,0.32,2.061,1.23c0.239,0.91-0.311,1.83-1.221,2.06
				C93.49,79.97,92.57,79.42,92.34,78.51z"
                    ></path>

                    <path
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M99.72,72.51c-0.08-0.32,0.11-0.63,0.42-0.71c0.32-0.08,0.64,0.11,0.72,0.43c0.08,0.31-0.109,0.63-0.43,0.7
				C100.12,73.01,99.8,72.82,99.72,72.51z"
                    ></path>
                    <g>
                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="91.78"
                            y1="73.16"
                            x2="93.59"
                            y2="72.71"
                        ></line>

                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="91.61"
                            y1="72.48"
                            x2="93.42"
                            y2="72.03"
                        ></line>

                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="91.96"
                            y1="73.84"
                            x2="93.77"
                            y2="73.39"
                        ></line>

                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="91.26"
                            y1="71.12"
                            x2="93.07"
                            y2="70.66"
                        ></line>

                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="91.09"
                            y1="70.43"
                            x2="92.9"
                            y2="69.98"
                        ></line>

                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="91.44"
                            y1="71.8"
                            x2="93.24"
                            y2="71.35"
                        ></line>
                    </g>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				85.98,79.07 88.36,78.48 88.62,79.5 86.24,80.09 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				88.08,80.23 90.46,79.64 90.72,80.66 88.34,81.26 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				97.76,76.14 100.14,75.54 100.4,76.56 98.02,77.16 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				101.38,75.23 102.51,74.95 102.77,75.97 101.64,76.25 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				100.65,77.1 101.79,76.82 102.05,77.84 100.91,78.12 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				93.73,82.57 94.87,82.29 95.13,83.31 93.99,83.59 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				96.41,85.04 97.54,84.76 97.8,85.78 96.67,86.06 			"
                    ></polygon>
                </g>
            </g>
            <g id="Satellite">
                <g>
                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				258.37,40.37 259.47,39.04 302.13,67.81 300.96,68.69 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				261.99,35.1 263.09,33.76 305.87,62.61 304.689,63.49 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				323.09,81.89 324.181,80.55 368.74,110.28 367.569,111.16 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				300.61,69.41 309.97,70.85 311.65,69.17 306.61,61.01 305.65,61.97 309.97,69.41 301.17,68.13 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				326.96,77.14 328.05,75.81 373.3,105.24 372.13,106.12 			"
                    ></polygon>
                    <polygon
                        fill="#5696EC"
                        points="300.27,70.75 308.74,59.4 294.939,50.2 286.42,61.84 			"
                    ></polygon>
                    <polygon
                        fill="#5696EC"
                        points="283.96,59.95 292.439,48.6 278.63,39.4 270.11,51.04 			"
                    ></polygon>
                    <polygon
                        fill="#5696EC"
                        points="268.069,49.74 276.55,38.39 262.74,29.19 254.23,40.83 			"
                    ></polygon>
                    <polygon
                        fill="#5696EC"
                        points="366.64,114.62 375.11,103.27 361.311,94.07 352.789,105.71 			"
                    ></polygon>
                    <polygon
                        fill="#5696EC"
                        points="350.4,103.77 358.88,92.42 345.069,83.22 336.55,94.86 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				327.73,74.93 318.369,73.49 316.689,75.17 321.73,83.32 322.689,82.37 318.369,74.93 327.17,76.21 			"
                    ></polygon>
                    <polygon
                        fill="#5696EC"
                        points="334.51,93.56 342.99,82.21 329.181,73.01 320.659,84.65 			"
                    ></polygon>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				303.67,74.39 314.67,82.56 324.061,70 313.159,61.7 			"
                    ></polygon>

                    <path
                        fill="#FFFFFF"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M312.659,65.9c-0.479,0.64-0.341,1.54,0.301,2.02c0.63,0.48,1.529,0.35,2.01-0.29c0.47-0.63,0.341-1.53-0.3-2.01
				C314.039,65.14,313.13,65.26,312.659,65.9z"
                    ></path>

                    <path
                        fill="#FFFFFF"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M316.02,68.3c-0.479,0.64-0.339,1.54,0.3,2.02c0.631,0.48,1.53,0.35,2.011-0.29c0.471-0.63,0.341-1.53-0.301-2.01
				C317.4,67.54,316.49,67.66,316.02,68.3z"
                    ></path>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				306.029,74.13 307.569,75.29 312.311,69.38 310.77,68.21 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				316.65,74.25 318.17,75.39 321.74,70.63 320.21,69.48 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				308.64,76.04 311.699,78.35 313.699,75.68 310.64,73.36 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				311.51,72.44 314.579,74.76 316.579,72.08 313.52,69.76 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				313.189,79.63 314.72,80.77 315.72,79.44 314.189,78.3 			"
                    ></polygon>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				314.9,77.34 316.431,78.49 317.431,77.15 315.9,76 			"
                    ></polygon>

                    <path
                        fill="#FFFFFF"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M310.909,75.62c-0.279,0.37-0.199,0.9,0.17,1.18c0.37,0.28,0.891,0.21,1.171-0.16c0.27-0.37,0.199-0.9-0.171-1.18
				C311.71,75.18,311.181,75.25,310.909,75.62z"
                    ></path>

                    <polygon
                        fill="#FFFFFF"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				307.279,79.97 308.71,78.05 310.069,79.08 306.35,84.04 			"
                    ></polygon>

                    <path
                        fill="#FFC80D"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M298.58,80c1.108-0.63,2.399-0.99,3.77-0.99c4.211,0,7.62,3.41,7.62,7.62c0,1.37-0.359,2.66-0.989,3.77
				C308.98,90.4,299.74,81.36,298.58,80z"
                    ></path>

                    <line
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="307.65"
                        y1="89.08"
                        x2="298.73"
                        y2="90.62"
                    ></line>

                    <polyline
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				300.13,81.41 298.6,90.59 303.75,85.47 			"
                    ></polyline>

                    <path
                        fill="#FFC80D"
                        stroke="#FFFFFF"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M298.76,91c-0.3,0-0.55-0.24-0.55-0.55c0-0.3,0.25-0.55,0.55-0.55c0.31,0,0.551,0.25,0.551,0.55
				C299.311,90.76,299.069,91,298.76,91z"
                    ></path>
                    <g>
                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="263.569"
                            y1="34.49"
                            x2="259.73"
                            y2="39.77"
                        ></line>

                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="267.17"
                            y1="36.89"
                            x2="263.33"
                            y2="42.17"
                        ></line>

                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="271.01"
                            y1="39.29"
                            x2="267.17"
                            y2="44.57"
                        ></line>
                    </g>
                    <g>
                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="279.17"
                            y1="44.81"
                            x2="275.33"
                            y2="50.09"
                        ></line>

                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="282.77"
                            y1="47.21"
                            x2="278.931"
                            y2="52.49"
                        ></line>

                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="286.61"
                            y1="49.61"
                            x2="282.77"
                            y2="54.89"
                        ></line>
                    </g>
                    <g>
                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="295.73"
                            y1="55.61"
                            x2="291.89"
                            y2="60.89"
                        ></line>

                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="299.33"
                            y1="58.01"
                            x2="295.49"
                            y2="63.29"
                        ></line>

                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="303.17"
                            y1="60.41"
                            x2="299.33"
                            y2="65.69"
                        ></line>
                    </g>
                    <g>
                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="330.05"
                            y1="78.41"
                            x2="326.21"
                            y2="83.68"
                        ></line>

                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="333.65"
                            y1="80.81"
                            x2="329.811"
                            y2="86.08"
                        ></line>

                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="337.49"
                            y1="83.2"
                            x2="333.65"
                            y2="88.48"
                        ></line>
                    </g>
                    <g>
                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="345.65"
                            y1="88.48"
                            x2="341.811"
                            y2="93.76"
                        ></line>

                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="349.25"
                            y1="90.88"
                            x2="345.409"
                            y2="96.16"
                        ></line>

                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="353.09"
                            y1="93.28"
                            x2="349.25"
                            y2="98.56"
                        ></line>
                    </g>
                    <g>
                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="362.21"
                            y1="99.52"
                            x2="358.369"
                            y2="104.8"
                        ></line>

                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="365.811"
                            y1="101.92"
                            x2="361.97"
                            y2="107.2"
                        ></line>

                        <line
                            fill="none"
                            stroke="#273553"
                            strokeWidth="0.48"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            x1="369.65"
                            y1="104.32"
                            x2="365.811"
                            y2="109.6"
                        ></line>
                    </g>
                </g>
            </g>
        </g>
        <g id="Labels">
            <g id="Robotics_Engineer">
                <g>
                    <path
                        fill="#283654"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M295.12,409.2c0-2.25,1.83-4.08,4.08-4.08s4.078,1.83,4.078,4.08s-1.828,4.082-4.078,4.082S295.12,411.45,295.12,409.2z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				299.2,406.081 300.12,407.94 302.17,408.241 300.681,409.68 301.029,411.719 299.2,410.76 297.37,411.719 297.72,409.68 
				296.23,408.241 298.279,407.94 			"
                    ></polygon>
                </g>
                <rect x="243.52" y="404.161" fill="none" width="48.961" height="11.811"></rect>
                <text
                    transform="matrix(1 0 0 1 243.5198 411.2485)"
                    fill="#263452"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Robotics Engineer
                </text>
            </g>
            <g id="Science_Communicator">
                <g>
                    <path
                        fill="#283654"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M218.08,378.879c0-2.25,1.83-4.08,4.08-4.08s4.08,1.83,4.08,4.08s-1.83,4.08-4.08,4.08S218.08,381.129,218.08,378.879z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				222.16,375.76 223.08,377.62 225.13,377.92 223.64,379.361 223.99,381.4 222.16,380.44 220.33,381.4 220.681,379.361 
				219.189,377.92 221.24,377.62 			"
                    ></polygon>
                </g>
                <rect x="227.439" y="371" fill="none" width="31.682" height="18.41"></rect>
                <text
                    transform="matrix(1 0 0 1 227.4397 378.0884)"
                    fill="#273553"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Science
                </text>
                <text
                    transform="matrix(1 0 0 1 227.4397 386.0884)"
                    fill="#273553"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Communicator
                </text>
            </g>
            <g id="Lecturer">
                <g>
                    <path
                        fill="#283654"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M148.24,416.161c0-2.25,1.829-4.08,4.079-4.08s4.08,1.83,4.08,4.08s-1.83,4.08-4.08,4.08S148.24,418.411,148.24,416.161z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				152.32,413.042 153.24,414.901 155.29,415.2 153.8,416.639 154.15,418.68 152.32,417.719 150.49,418.68 150.84,416.639 
				149.35,415.2 151.4,414.901 			"
                    ></polygon>
                </g>
                <rect x="157.6" y="410.879" fill="none" width="23.521" height="11.811"></rect>
                <text
                    transform="matrix(1 0 0 1 157.5999 417.9692)"
                    fill="#273553"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Lecturer
                </text>
            </g>
            <g id="Lawyer">
                <g>
                    <path
                        fill="#283654"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M33.52,414.959c0-2.25,1.83-4.08,4.08-4.08s4.08,1.83,4.08,4.08s-1.83,4.08-4.08,4.08S33.52,417.209,33.52,414.959z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				37.6,411.84 38.52,413.7 40.57,414 39.08,415.44 39.43,417.481 37.6,416.52 35.77,417.481 36.12,415.44 34.63,414 36.68,413.7 
							"
                    >
                        {" "}
                    </polygon>
                </g>
                <rect x="12.64" y="409.44" fill="none" width="20.16" height="11.811"></rect>
                <text
                    transform="matrix(1 0 0 1 12.6399 416.5278)"
                    fill="#263452"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Lawyer
                </text>
            </g>
            <g id="Insurer">
                <g>
                    <path
                        fill="#283654"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M28.24,391.2c0-2.25,1.829-4.08,4.079-4.08s4.08,1.83,4.08,4.08s-1.83,4.082-4.08,4.082S28.24,393.45,28.24,391.2z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				32.32,388.081 33.24,389.94 35.29,390.241 33.8,391.68 34.15,393.72 32.32,392.76 30.49,393.72 30.84,391.68 29.35,390.241 
				31.4,389.94 			"
                    ></polygon>
                </g>
                <rect x="37.6" y="385.92" fill="none" width="20.4" height="11.811"></rect>
                <text
                    transform="matrix(1 0 0 1 37.5999 393.0083)"
                    fill="#263452"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Insurer
                </text>
            </g>
            <g id="Doctor">
                <g>
                    <path
                        fill="#283654"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M171.04,366.48c0-2.25,1.83-4.08,4.08-4.08s4.08,1.83,4.08,4.08s-1.83,4.08-4.08,4.08S171.04,368.73,171.04,366.48z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				175.12,363.361 176.04,365.22 178.09,365.52 176.6,366.959 176.95,369 175.12,368.042 173.29,369 173.64,366.959 172.15,365.52 
				174.2,365.22 			"
                    ></polygon>
                </g>
                <rect x="180.4" y="361.2" fill="none" width="18.48" height="11.812"></rect>
                <text
                    transform="matrix(1 0 0 1 180.3997 368.2896)"
                    fill="#273553"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Doctor
                </text>
            </g>
            <g id="Agricultural_Data_Analyst">
                <g>
                    <path
                        fill="#283654"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M75.76,312.959c0-2.25,1.83-4.08,4.08-4.08s4.08,1.83,4.08,4.08s-1.83,4.08-4.08,4.08S75.76,315.209,75.76,312.959z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				79.84,309.84 80.76,311.7 82.81,312 81.32,313.44 81.67,315.48 79.84,314.52 78.01,315.48 78.36,313.44 76.87,312 78.92,311.7 
							"
                    >
                        {" "}
                    </polygon>
                </g>
                <rect x="84.88" y="308.161" fill="none" width="67.2" height="11.811"></rect>
                <text
                    transform="matrix(1 0 0 1 84.8796 315.2485)"
                    fill="#263452"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Agricultural Data Analyst
                </text>
            </g>
            <g id="Navigational_Scientist">
                <g>
                    <path
                        fill="#283654"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M258.16,432.481c0-2.25,1.829-4.08,4.079-4.08s4.079,1.83,4.079,4.08s-1.829,4.078-4.079,4.078S258.16,434.731,258.16,432.481z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				262.24,429.362 263.16,431.219 265.21,431.52 263.72,432.959 264.069,435 262.24,434.042 260.41,435 260.76,432.959 
				259.27,431.52 261.319,431.219 			"
                    ></polygon>
                </g>
                <rect x="267.52" y="427.44" fill="none" width="58.8" height="11.811"></rect>
                <text
                    transform="matrix(1 0 0 1 267.5198 434.5278)"
                    fill="#273553"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Navigational Scientist
                </text>
            </g>
            <g id="Earth_Observation_Scientist">
                <g>
                    <path
                        fill="#283654"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M348.4,259.799c0-2.25,1.83-4.08,4.08-4.08s4.08,1.83,4.08,4.08s-1.83,4.08-4.08,4.08S348.4,262.049,348.4,259.799z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				352.48,256.68 353.4,258.542 355.449,258.84 353.96,260.282 354.311,262.32 352.48,261.361 350.65,262.32 351,260.282 
				349.51,258.84 351.561,258.542 			"
                    ></polygon>
                </g>
                <rect x="358.24" y="252" fill="none" width="90.96" height="20.449"></rect>
                <text
                    transform="matrix(1 0 0 1 358.2405 259.0884)"
                    fill="#263452"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Earth Observation{" "}
                </text>
                <text
                    transform="matrix(1 0 0 1 358.2405 268.689)"
                    fill="#263452"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Scientist
                </text>
            </g>
            <g id="Disaster_Response_Coordinator">
                <g>
                    <path
                        fill="#283654"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M118.72,448.321c0-2.25,1.83-4.08,4.08-4.08s4.08,1.83,4.08,4.08s-1.83,4.08-4.08,4.08S118.72,450.571,118.72,448.321z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				122.8,445.2 123.72,447.059 125.77,447.362 124.28,448.799 124.63,450.84 122.8,449.879 120.97,450.84 121.32,448.799 
				119.83,447.362 121.88,447.059 			"
                    ></polygon>
                </g>
                <rect x="128.32" y="443.76" fill="none" width="82.801" height="11.811"></rect>
                <text
                    transform="matrix(1 0 0 1 128.3196 450.8481)"
                    fill="#263452"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Disaster Response Coordinator
                </text>
            </g>
            <g id="Oceanographer">
                <g>
                    <path
                        fill="#283654"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M152.32,270c0-2.25,1.83-4.08,4.08-4.08s4.08,1.83,4.08,4.08s-1.83,4.08-4.08,4.08S152.32,272.25,152.32,270z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				156.4,266.879 157.32,268.741 159.37,269.042 157.88,270.48 158.23,272.52 156.4,271.56 154.57,272.52 154.92,270.48 
				153.43,269.042 155.48,268.741 			"
                    ></polygon>
                </g>
                <rect x="161.44" y="264.719" fill="none" width="40.561" height="11.813"></rect>
                <text
                    transform="matrix(1 0 0 1 161.4397 271.8091)"
                    fill="#273553"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Oceanographer
                </text>
            </g>
            <g id="Propulsion_Engineer">
                <g>
                    <path
                        fill="#283654"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M222.399,215.52c0-2.25,1.831-4.08,4.081-4.08s4.08,1.83,4.08,4.08s-1.83,4.08-4.08,4.08S222.399,217.77,222.399,215.52z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				226.48,212.401 227.399,214.26 229.45,214.56 227.96,216 228.311,218.041 226.48,217.081 224.649,218.041 225,216 223.51,214.56 
				225.561,214.26 			"
                    ></polygon>
                </g>
                <rect x="231.76" y="210.241" fill="none" width="54.479" height="11.81"></rect>
                <text
                    transform="matrix(1 0 0 1 231.76 217.3286)"
                    fill="#273553"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Propulsion Engineer
                </text>
            </g>
            <g id="Aerodynamicist">
                <g>
                    <path
                        fill="#283654"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M186.88,189.6c0-2.25,1.83-4.08,4.08-4.08s4.08,1.83,4.08,4.08s-1.83,4.08-4.08,4.08S186.88,191.85,186.88,189.6z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				190.96,186.48 191.88,188.34 193.93,188.64 192.44,190.08 192.79,192.12 190.96,191.16 189.13,192.12 189.48,190.08 
				187.99,188.64 190.04,188.34 			"
                    ></polygon>
                </g>
                <rect x="142.96" y="184.32" fill="none" width="42.24" height="11.81"></rect>
                <text
                    transform="matrix(1 0 0 1 145.8328 191.4087)"
                    fill="#263452"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Aerodynamicist
                </text>
            </g>
            <g id="Planetary_Scientist">
                <g>
                    <path
                        fill="#283654"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M282.16,348.959c0-2.25,1.829-4.08,4.079-4.08s4.079,1.83,4.079,4.08s-1.829,4.08-4.079,4.08S282.16,351.209,282.16,348.959z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				286.24,345.84 287.16,347.7 289.21,348 287.72,349.44 288.069,351.48 286.24,350.52 284.41,351.48 284.76,349.44 283.27,348 
				285.319,347.7 			"
                    ></polygon>
                </g>
                <rect x="229.12" y="344.161" fill="none" width="51.359" height="11.811"></rect>
                <text
                    transform="matrix(1 0 0 1 229.1194 351.2485)"
                    fill="#273553"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Planetary Scientist
                </text>
            </g>
            <g id="Astronomer">
                <g>
                    <path
                        fill="#283654"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M301.84,262.56c0-2.25,1.83-4.08,4.08-4.08s4.079,1.83,4.079,4.08s-1.829,4.08-4.079,4.08S301.84,264.81,301.84,262.56z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				305.92,259.44 306.84,261.299 308.89,261.6 307.4,263.042 307.75,265.081 305.92,264.12 304.09,265.081 304.439,263.042 
				302.95,261.6 305,261.299 			"
                    ></polygon>
                </g>
                <rect x="268.24" y="257.52" fill="none" width="32.641" height="11.811"></rect>
                <text
                    transform="matrix(1 0 0 1 268.2405 264.6079)"
                    fill="#273553"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Astronomer
                </text>
            </g>
            <g id="Software_Engineer">
                <g>
                    <path
                        fill="#283654"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M350.119,327.241c0-2.25,1.83-4.08,4.08-4.08s4.079,1.83,4.079,4.08s-1.829,4.079-4.079,4.079S350.119,329.491,350.119,327.241z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				354.199,324.12 355.119,325.98 357.17,326.28 355.681,327.72 356.029,329.76 354.199,328.799 352.369,329.76 352.72,327.72 
				351.23,326.28 353.279,325.98 			"
                    ></polygon>
                </g>
                <rect x="359.48" y="321.959" fill="none" width="49.92" height="11.811"></rect>
                <text
                    transform="matrix(1 0 0 1 359.4788 329.0483)"
                    fill="#263452"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Software Engineer
                </text>
            </g>
            <g id="Spacecraft_Operator">
                <g>
                    <path
                        fill="#283654"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M340.24,376.56c0-2.25,1.829-4.08,4.079-4.08s4.081,1.83,4.081,4.08s-1.831,4.08-4.081,4.08S340.24,378.81,340.24,376.56z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				344.319,373.44 345.24,375.299 347.289,375.6 345.8,377.042 346.15,379.081 344.319,378.12 342.49,379.081 342.84,377.042 
				341.35,375.6 343.4,375.299 			"
                    ></polygon>
                </g>
                <rect x="349.84" y="368.68" fill="none" width="28.8" height="21.89"></rect>
                <text
                    transform="matrix(1 0 0 1 349.8401 375.769)"
                    fill="#273553"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Spacecraft
                </text>
                <text
                    transform="matrix(1 0 0 1 349.8401 383.769)"
                    fill="#273553"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Operator
                </text>
            </g>
            <g id="Assembly_Engineer">
                <g>
                    <path
                        fill="#283654"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M373.36,398.161c0-2.25,1.829-4.08,4.079-4.08s4.08,1.83,4.08,4.08s-1.83,4.08-4.08,4.08S373.36,400.411,373.36,398.161z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				377.439,395.042 378.36,396.9 380.409,397.2 378.92,398.639 379.27,400.68 377.439,399.719 375.61,400.68 375.96,398.639 
				374.47,397.2 376.52,396.9 			"
                    ></polygon>
                </g>
                <rect x="320.079" y="393.12" fill="none" width="51.841" height="11.811"></rect>
                <text
                    transform="matrix(1 0 0 1 320.0803 400.2095)"
                    fill="#263452"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Assembly Engineer
                </text>
            </g>
            <g id="Radio_Engineer">
                <g>
                    <path
                        fill="#283654"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M94,215.52c0-2.25,1.83-4.08,4.08-4.08s4.08,1.83,4.08,4.08s-1.83,4.08-4.08,4.08S94,217.77,94,215.52z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				98.08,212.401 99,214.26 101.05,214.56 99.56,216 99.91,218.041 98.08,217.081 96.25,218.041 96.6,216 95.11,214.56 
				97.16,214.26 			"
                    ></polygon>
                </g>
                <rect x="103.36" y="210.241" fill="none" width="40.801" height="11.81"></rect>
                <text
                    transform="matrix(1 0 0 1 103.3596 217.3286)"
                    fill="#273553"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Radio Engineer
                </text>
            </g>
            <g id="Meteorologist">
                <g>
                    <path
                        fill="#283654"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M261.04,142.08c0-2.25,1.83-4.08,4.08-4.08s4.08,1.83,4.08,4.08s-1.83,4.08-4.08,4.08S261.04,144.33,261.04,142.08z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				265.12,138.96 266.04,140.82 268.09,141.12 266.6,142.56 266.95,144.6 265.12,143.64 263.29,144.6 263.64,142.56 262.15,141.12 
				264.2,140.82 			"
                    ></polygon>
                </g>
                <rect x="270.4" y="137.385" fill="none" width="37.439" height="11.81"></rect>
                <text
                    transform="matrix(1 0 0 1 270.3987 144.4731)"
                    fill="#273553"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Meteorologist
                </text>
            </g>
            <g id="Astrobiologist">
                <g>
                    <path
                        fill="#273553"
                        stroke="#5696EC"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M202.72,293.52c0-2.25,1.83-4.08,4.08-4.08s4.08,1.83,4.08,4.08s-1.83,4.08-4.08,4.08S202.72,295.77,202.72,293.52z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				206.8,290.4 207.72,292.26 209.77,292.56 208.28,294 208.63,296.042 206.8,295.081 204.97,296.042 205.32,294 203.83,292.56 
				205.88,292.26 			"
                    ></polygon>
                </g>
                <rect x="212.08" y="288.241" fill="none" width="38.399" height="11.811"></rect>
                <text
                    transform="matrix(1 0 0 1 212.0798 295.3286)"
                    fill="#5596EB"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Astrobiologist
                </text>
            </g>
            <g id="Technician">
                <g>
                    <path
                        fill="#283654"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M346.72,414.959c0-2.25,1.83-4.08,4.08-4.08s4.08,1.83,4.08,4.08s-1.83,4.08-4.08,4.08S346.72,417.209,346.72,414.959z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				350.8,411.84 351.72,413.7 353.77,414 352.279,415.44 352.63,417.481 350.8,416.52 348.97,417.481 349.319,415.44 347.829,414 
				349.88,413.7 			"
                    ></polygon>
                </g>
                <rect x="355.84" y="410.639" fill="none" width="28.319" height="11.811"></rect>
                <text
                    transform="matrix(1 0 0 1 355.8401 417.728)"
                    fill="#263452"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Technician
                </text>
            </g>
            <g id="Researcher">
                <g>
                    <path
                        fill="#283654"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M125.44,366.48c0-2.25,1.83-4.08,4.08-4.08s4.08,1.83,4.08,4.08s-1.83,4.08-4.08,4.08S125.44,368.73,125.44,366.48z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#283654"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				129.52,363.361 130.44,365.22 132.49,365.52 131,366.959 131.35,369 129.52,368.042 127.69,369 128.04,366.959 126.55,365.52 
				128.6,365.22 			"
                    ></polygon>
                </g>
                <rect x="134.8" y="361.2" fill="none" width="30.72" height="11.812"></rect>
                <text
                    transform="matrix(1 0 0 1 134.7996 368.2896)"
                    fill="#273553"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Researcher
                </text>
            </g>
            <g id="Systems_Engineer">
                <g>
                    <path
                        fill="#273553"
                        stroke="#5696EC"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M345.279,75.36c0-2.25,1.831-4.08,4.081-4.08s4.079,1.83,4.079,4.08s-1.829,4.08-4.079,4.08S345.279,77.61,345.279,75.36z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				349.36,72.24 350.279,74.1 352.329,74.4 350.84,75.84 351.189,77.88 349.36,76.92 347.529,77.88 347.88,75.84 346.39,74.4 
				348.439,74.1 			"
                    ></polygon>
                </g>
                <rect x="354.64" y="70.08" fill="none" width="48.721" height="11.81"></rect>
                <text
                    transform="matrix(1 0 0 1 354.6409 77.1685)"
                    fill="#5596EB"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Systems Engineer
                </text>
            </g>
            <g id="Astrophysicist">
                <g>
                    <path
                        fill="#273553"
                        stroke="#5696EC"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M307.6,16.08c0-2.25,1.831-4.08,4.081-4.08s4.079,1.83,4.079,4.08s-1.829,4.08-4.079,4.08S307.6,18.33,307.6,16.08z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				311.681,12.96 312.6,14.82 314.65,15.12 313.159,16.56 313.51,18.6 311.681,17.64 309.85,18.6 310.199,16.56 308.71,15.12 
				310.76,14.82 			"
                    ></polygon>
                </g>
                <rect x="316.96" y="10.8" fill="none" width="39.358" height="11.81"></rect>
                <text
                    transform="matrix(1 0 0 1 316.9592 17.8892)"
                    fill="#5596EB"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Astrophysicist
                </text>
            </g>
            <g id="Project_Manager">
                <g>
                    <path
                        fill="#273553"
                        stroke="#5696EC"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M286.72,84.96c0-2.25,1.83-4.08,4.08-4.08s4.08,1.83,4.08,4.08s-1.83,4.08-4.08,4.08S286.72,87.21,286.72,84.96z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				290.8,81.84 291.72,83.7 293.77,84 292.279,85.44 292.63,87.48 290.8,86.52 288.97,87.48 289.319,85.44 287.83,84 289.88,83.7 
							"
                    >
                        {" "}
                    </polygon>
                </g>
                <rect x="239.2" y="79.92" fill="none" width="43.68" height="11.81"></rect>
                <text
                    transform="matrix(1 0 0 1 241.8088 87.0083)"
                    fill="#5496EB"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Project Manager
                </text>
            </g>
            <g id="Electrical_Engineer">
                <g>
                    <path
                        fill="#273553"
                        stroke="#5696EC"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M124,46.32c0-2.25,1.83-4.08,4.08-4.08s4.08,1.83,4.08,4.08s-1.83,4.08-4.08,4.08S124,48.57,124,46.32z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				128.08,43.2 129,45.06 131.05,45.36 129.56,46.8 129.91,48.84 128.08,47.88 126.25,48.84 126.6,46.8 125.11,45.36 127.16,45.06 
							"
                    >
                        {" "}
                    </polygon>
                </g>
                <rect x="133.36" y="41.04" fill="none" width="50.4" height="11.81"></rect>
                <text
                    transform="matrix(1 0 0 1 133.3596 48.1284)"
                    fill="#5596EB"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Electrical Engineer
                </text>
            </g>
            <g id="Astronaut">
                <g>
                    <path
                        fill="#273553"
                        stroke="#5696EC"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="
				M94,97.44c0-2.25,1.83-4.08,4.08-4.08s4.08,1.83,4.08,4.08s-1.83,4.08-4.08,4.08S94,99.69,94,97.44z"
                    ></path>

                    <polygon
                        fill="#FFC80D"
                        stroke="#273553"
                        strokeWidth="0.48"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="
				98.08,94.32 99,96.18 101.05,96.48 99.56,97.92 99.91,99.96 98.08,99 96.25,99.96 96.6,97.92 95.11,96.48 97.16,96.18 			"
                    ></polygon>
                </g>
                <rect x="103.36" y="92.16" fill="none" width="27.36" height="11.81"></rect>
                <text
                    transform="matrix(1 0 0 1 103.3596 99.2485)"
                    fill="#5596EB"
                    fontFamily="'Miso'"
                    fontWeight="bold"
                    fontSize="8"
                >
                    Astronaut
                </text>
            </g>
        </g>
    </svg>
);

export default BackgroundImages;
