import React, { useEffect, useMemo, useState } from "react";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import axiosInstance from "../axiosInstance";
import { useTranslation } from "react-i18next";

const AnalysisBox = ({ title, children, reference, ...props }) => {
    return (
        <Box
            sx={{
                border: "1px solid",
                borderColor: "text.primary",
                padding: "14px",
                borderRadius: "6px",
                textAlign: "left",
                mb: 2,
                ...props,
            }}
        >
            <Typography variant="h6" color="primary" mb={2}>
                {title}
            </Typography>
            {children}
            {reference ? (
                <Link
                    variant="body2"
                    color="text.secondary"
                    mt={2}
                    href={reference}
                    sx={{ textAlign: "right" }}
                >
                    Source
                </Link>
            ) : null}
        </Box>
    );
};

const JobAnalysis = ({ job }) => {
    const { t } = useTranslation();
    const [analysis, setAnalysis] = useState({});
    const headerReport = useMemo(() => {
        let report = "";
        const headerCounts = analysis?.header_counts;
        if (headerCounts) {
            const usesHeaders = Object.values(headerCounts).some((count) => count > 0);
            const usesMultipleHeadersOfSameType = Object.values(headerCounts).some(
                (count) => count > 1
            );
            if (usesMultipleHeadersOfSameType) {
                report +=
                    "✅ The description uses multiple headers of the same type. This provides consistent structure\n\n";
            } else if (!usesHeaders) {
                report += "❌ The description does not use headers. This lacks structure\n\n";
            } else {
                report +=
                    "❌ The description does not use multiple headers of the same type. This provides inconsistent structure\n\n";
            }
            const maxParagraphLength = Math.max(...(analysis?.paragraph_lengths || []));
            const upperMax = 300;
            if (maxParagraphLength < upperMax) {
                report += "✅ Your paragraphs are a good size!\n\n";
            } else {
                report += `❌ Your biggest paragraph is ${maxParagraphLength} words while we don't recommend you exceed ${upperMax} words\n\n`;
            }
        } else {
            report = "No data";
        }
        return <Typography sx={{ whiteSpace: "pre-wrap" }}>{report}</Typography>;
    }, [analysis]);

    const contentReport = useMemo(() => {
        let report = "";
        if (job.salary_range_upper && job.salary_range_lower) {
            report +=
                "✅ Salary is included\n\nYour job is likely to get more and higher quality applicants\n";
        } else {
            report +=
                "❌ Salary is not included\n\nInclude the salary to get more and higher quality applicants\n";
        }
        const contentMessage =
            "Have you included the following?\n\n⭐A clear title\n⭐A section on your organisation focusing on people and purpose\n⭐A carefully considered list of desirable and required skills, not years of experience\n⭐An inclusive list of employee benefits\n⭐An explanation of the selection process\n⭐A diversity statement";
        return (
            <>
                <AnalysisBox title="Content" reference="https://spaceskills.org/job-advert-quality">
                    <Typography sx={{ whiteSpace: "pre-wrap" }}>{contentMessage}</Typography>
                </AnalysisBox>
                <AnalysisBox
                    title="Salary"
                    reference="https://web.archive.org/web/20140701170213/http://www.innovantage.co.uk/news/theneglecteddetailssalaryinformationinrecruitmentads"
                >
                    <Typography sx={{ whiteSpace: "pre-wrap" }}>{report}</Typography>
                </AnalysisBox>
            </>
        );
    }, [job]);

    const equalityReport = useMemo(() => {
        const coding_result = analysis?.gender_coding?.result;
        const coding_display = coding_result?.includes("masculine")
            ? "Masculine"
            : coding_result?.includes("feminine")
            ? "Feminine"
            : " Neutral";
        const coding_color =
            coding_display === "Masculine"
                ? "highlight.main"
                : coding_display === "Feminine"
                ? "primary.main"
                : "#e3e3e3";
        const font_color = coding_display === "Feminine" ? "white" : "black";
        let report =
            JSON.stringify(analysis?.gendered_pronouns, null, 2) +
            "\n" +
            JSON.stringify(analysis?.gender_coding, null, 2);

        let pronouns_report = "";
        if (
            analysis?.gendered_pronouns?.masculine.length > 0 ||
            analysis?.gendered_pronouns?.feminine.length > 0
        ) {
            pronouns_report +=
                "❌ Gendered pronouns are used: " +
                _.uniq(
                    _.concat(
                        analysis?.gendered_pronouns?.masculine,
                        analysis?.gendered_pronouns?.feminine
                    )
                ).join("; ") +
                '\n\nIf "you/your" does not work in the sentence, we recommend you use the gender neutral pronouns they/them/theirs.';
        } else {
            pronouns_report = "✅ You have not used any gendered pronouns\n";
        }

        return (
            <>
                <AnalysisBox
                    title="Language"
                    reference="https://gender-decoder.katmatfield.com/about#faq6"
                >
                    <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                        <Typography variant="h6">Gender coding</Typography>
                        <Typography
                            sx={{
                                borderRadius: "11px",
                                backgroundColor: coding_color,
                                padding: "6px",
                                color: font_color,
                            }}
                        >
                            {coding_display}
                        </Typography>
                    </Stack>
                    <Typography sx={{ whiteSpace: "pre-wrap", mt: 2, mb: 2 }}>
                        {analysis?.gender_coding?.explanation}
                    </Typography>
                    <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                        <Typography variant="h6">Masculine-coded</Typography>
                        <Typography
                            sx={{
                                borderRadius: "11px",
                                backgroundColor: "highlight.main",
                                padding: "6px 16px",
                                color: "black",
                            }}
                        >
                            {_.uniq(analysis.gender_coding?.masculine_coded_words)?.length}
                        </Typography>
                    </Stack>
                    <Typography sx={{ whiteSpace: "pre-wrap", mt: 2, mb: 2 }}>
                        {_.uniq(analysis.gender_coding?.masculine_coded_words)?.join("; ")}
                    </Typography>
                    <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                        <Typography variant="h6">Feminine-coded</Typography>
                        <Typography
                            sx={{
                                borderRadius: "11px",
                                backgroundColor: "primary.main",
                                padding: "6px 16px",
                                color: "white",
                            }}
                        >
                            {_.uniq(analysis.gender_coding?.feminine_coded_words)?.length}
                        </Typography>
                    </Stack>
                    <Typography sx={{ whiteSpace: "pre-wrap", mt: 2, mb: 2 }}>
                        {_.uniq(analysis.gender_coding?.feminine_coded_words)?.join("; ")}
                    </Typography>

                    <Typography variant="subtitle2" color="text" mb={2} sx={{ fontSize: "10px" }}>
                        {t("job.analysis.explanation")}
                    </Typography>
                </AnalysisBox>
                {!!analysis?.referring_to_candidate_message && (
                    <AnalysisBox title="Referring to the candidate" mt={2}>
                        <Typography sx={{ whiteSpace: "pre-wrap" }}>
                            {"❌ " + analysis?.referring_to_candidate_message}
                        </Typography>
                    </AnalysisBox>
                )}
                <AnalysisBox title="Gendered pronouns" mt={2}>
                    <Typography sx={{ whiteSpace: "pre-wrap" }}>{pronouns_report}</Typography>
                </AnalysisBox>
            </>
        );
    }, [analysis]);

    useEffect(() => {
        axiosInstance.post(`/api/jobs/${job.id}/analyse/`).then((response) => {
            setAnalysis(response.data);
        });
    }, [job]);

    return (
        <>
            <Typography variant="h5" mb={2} mt={2}>
                Job Description Analysis
            </Typography>
            {contentReport}
            {equalityReport}
        </>
    );
};

export default JobAnalysis;
