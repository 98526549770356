import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
} from "react-share";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import Analytics from "./Analytics";
import AuthorCard from "./AuthorCard";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Dropzone from "../common/Dropzone";
import EditIcon from "@mui/icons-material/Edit";
import EditableTextField from "../common/EditableTextField";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import SaveIcon from "@mui/icons-material/Save";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import UndoIcon from "@mui/icons-material/Undo";
import axiosInstance from "../axiosInstance";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useParams } from "react-router-dom";
import { usePrompt } from "../common/ReactRouterBlocker";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";

const ArticleImageWithCaption = ({ article }) => {
    return article?.image ? (
        <Box
            disableGutters
            sx={{
                maxWidth: "100%",
                position: "relative",
                display: "flow-root",
                "& span": {
                    display: "none",
                },
                "&:hover span": {
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    display: "block",
                    color: "#fff",
                    textDecoration: "none",
                    background: "rgba(70, 82, 152, .4)",
                    padding: "2px",
                },
                "&:hover a": {
                    color: "inherit",
                },
            }}
        >
            <img
                style={{
                    maxWidth: "100%",
                    float: "right",
                }}
                src={article.image}
                alt="Article"
            />
            {article?.image_credit ? (
                <span>
                    Credit:{" "}
                    <a href={article?.image_credit_url || undefined}>{article.image_credit}</a>
                </span>
            ) : null}
        </Box>
    ) : null;
};

const ArticlePage = ({ addBannerNotification }) => {
    const theme = useTheme();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    let params = useParams();
    const [article, setArticle] = useState([]);
    const [edits, setEdits] = useState({});
    const [error404, setError404] = useState(false);
    const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const isDirty = useMemo(() => {
        return (
            Object.keys(edits).length > 0 &&
            Object.keys(edits).some((key) => edits[key] !== article[key])
        );
    }, [edits]);

    usePrompt(
        "Your changes have not been saved. Are you sure you want to leave this page?",
        isDirty
    );

    useEffect(() => {
        axiosInstance
            .get(`/api/articles/${params.articleId}/`)
            .then((response) => {
                setArticle(response.data);
                window.posthog.capture("Saw article page", {
                    articleId: params.articleId,
                });
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    setError404(true);
                }
            });
    }, []);

    const submitEdits = useCallback(() => {
        let formData = new FormData();
        for (let key in edits) {
            formData.append(key, edits[key]);
        }

        axiosInstance
            .patch(`/api/articles/${params.articleId}/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                setArticle(response.data);
                setEdits({});
                enqueueSnackbar("Article updated successfully.", { variant: "success" });
            })
            .catch((err) => {
                enqueueSnackbar("Error updating article.", { variant: "error" });
            });
    }, [edits]);

    const makeArticlePublic = () => {
        axiosInstance
            .patch(`/api/articles/${params.articleId}/`, { private: false })
            .then((response) => {
                setArticle(response.data);
                enqueueSnackbar("Article updated successfully.", { variant: "success" });
            })
            .catch((err) => {
                enqueueSnackbar("Error updating article.", { variant: "error" });
            });
    };
    const makeArticlePrivate = () => {
        axiosInstance
            .patch(`/api/articles/${params.articleId}/`, { private: true })
            .then((response) => {
                setArticle(response.data);
                enqueueSnackbar("Article updated successfully.", { variant: "success" });
            })
            .catch((err) => {
                enqueueSnackbar("Error updating article.", { variant: "error" });
            });
    };

    useEffect(() => {
        addBannerNotification(
            article?.can_edit ? "You can edit this article." : undefined,
            "ownership"
        );
        return () => {
            addBannerNotification(undefined, "ownership");
        };
    }, [article]);

    const makePublicAction = (snackbarId) => (
        <>
            <Button size="small" color="secondary" onClick={makeArticlePublic}>
                Make public
            </Button>
        </>
    );

    useEffect(() => {
        if (article?.private) {
            const key = enqueueSnackbar("This article is private.", {
                action: makePublicAction,
                preventDuplicate: false,
                persist: true,
                variant: "warning",
            });
            return () => {
                closeSnackbar(key);
            };
        }
    }, [article]);

    useEffect(() => {
        addBannerNotification(
            isDirty ? (
                <Typography align="center">
                    You have unsaved changes.
                    <Link
                        onClick={submitEdits}
                        sx={{
                            marginLeft: "5px",
                            cursor: "pointer",
                            color: theme.palette.highlight.main,
                        }}
                    >
                        Save changes
                    </Link>
                </Typography>
            ) : undefined,
            "dirty"
        );
        return () => {
            addBannerNotification(undefined, "dirty");
        };
    }, [isDirty, submitEdits]);

    useEffect(() => {
        // remove any keys from edits where the value is undefined
        if (Object.values(edits).some((e) => e === undefined)) {
            setEdits((oldEdits) => {
                let newEdits = { ...oldEdits };
                Object.keys(newEdits).forEach((key) => {
                    if (newEdits[key] === undefined) {
                        delete newEdits[key];
                    }
                });
                return newEdits;
            });
        }
    }, [edits]);

    return (
        <PageContainer error404={error404}>
            {article?.can_edit ? (
                <SpeedDial
                    sx={{ position: "fixed", bottom: 20, right: 20, zIndex: 10 }}
                    ariaLabel="SpeedDial"
                    icon={<EditIcon />}
                >
                    <SpeedDialAction
                        key={"save"}
                        icon={<SaveIcon />}
                        tooltipTitle={"Save"}
                        onClick={submitEdits}
                        disabled={!isDirty}
                    />

                    {article.private ? (
                        <SpeedDialAction
                            key={"public"}
                            icon={<LockOpenIcon />}
                            tooltipTitle={"Make public"}
                            onClick={makeArticlePublic}
                        />
                    ) : (
                        <SpeedDialAction
                            key={"private"}
                            icon={<LockIcon />}
                            tooltipTitle={"Make private"}
                            onClick={makeArticlePrivate}
                        />
                    )}
                    <SpeedDialAction
                        key={"reset"}
                        icon={<UndoIcon />}
                        tooltipTitle={"Reset edits"}
                        onClick={() => setEdits({})}
                        disabled={!isDirty}
                    />
                </SpeedDial>
            ) : null}
            <Breadcrumbs
                color={theme.palette.text.primary}
                separator={<NavigateNextIcon fontSize="small" />}
            >
                <Link underline="hover" color="inherit" href="/home">
                    Home
                </Link>
                <Link underline="hover" color="inherit" href="/careers_resources/">
                    Careers Resources
                </Link>
                <Link
                    underline="hover"
                    color="inherit"
                    href={`/articles/${(article?.parent_page || "")
                        .replaceAll(" ", "_")
                        .toLowerCase()}`}
                >
                    {article?.parent_page}
                </Link>
                <Typography color="inherit">{article.title}</Typography>
            </Breadcrumbs>
            <Box>
                <Paper sx={{ padding: "20px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Typography variant="h6" color="text">
                                {article.parent_page}
                            </Typography>

                            <EditableTextField
                                value={"title" in edits ? edits.title : article.title}
                                onChange={(value) => {
                                    setEdits({ ...edits, title: value });
                                }}
                                dirty={edits?.title !== article.title && edits?.title !== undefined}
                                variant="h4"
                                color="primary"
                                editable={article?.can_edit}
                                sx={{ paddingBottom: "16px" }}
                                tooltip="Article Title"
                            />
                            {smallScreen ? (
                                <Grid item xs={12}>
                                    {article?.can_edit ? (
                                        <Dropzone
                                            imageProps={{
                                                style: {
                                                    maxWidth: "100%",
                                                    float: "right",
                                                },
                                                src: article.image,
                                                alt: "Article",
                                            }}
                                            onChange={(file) => {
                                                setEdits({ ...edits, image: file });
                                            }}
                                        />
                                    ) : (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                flexDirection: "column",
                                                padding: "10px",
                                            }}
                                        >
                                            <ArticleImageWithCaption article={article} />
                                        </div>
                                    )}
                                </Grid>
                            ) : null}
                            <EditableTextField
                                value={"summary" in edits ? edits.summary : article.summary}
                                onChange={(value) => {
                                    setEdits({ ...edits, summary: value });
                                }}
                                dirty={
                                    edits?.summary !== article.summary &&
                                    edits?.summary !== undefined
                                }
                                variant="h6"
                                color="text"
                                editable={article?.can_edit}
                                tooltip="Summary"
                            />
                        </Grid>
                        {smallScreen ? null : (
                            <Grid item xs={4}>
                                {article?.can_edit ? (
                                    <Dropzone
                                        imageProps={{
                                            style: {
                                                maxWidth: "100%",
                                                float: "right",
                                            },
                                            src: article.image,
                                            alt: "Article",
                                        }}
                                        onChange={(file) => {
                                            setEdits({ ...edits, image: file });
                                        }}
                                    />
                                ) : (
                                    <ArticleImageWithCaption article={article} />
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </Box>
            <Grid container spacing={2}>
                <Grid item md={9} sm={12} xs={12}>
                    <Paper sx={{ padding: "20px" }}>
                        <EditableTextField
                            value={"content" in edits ? edits.content : article.content}
                            onChange={(value) => {
                                setEdits({ ...edits, content: value });
                            }}
                            dirty={
                                edits?.content !== article.content && edits?.content !== undefined
                            }
                            markdown
                            editable={article?.can_edit}
                            tooltip="Content"
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Stack spacing={2}>
                        {article.posted_date ? (
                            <Card sx={{ padding: "15px" }}>
                                <Typography variant="h7" color="text">
                                    Posted on <b>{article.posted_date}</b>
                                </Typography>
                            </Card>
                        ) : null}
                        {article.author ? <AuthorCard author={article.author} /> : null}
                        <Card>
                            <Stack
                                spacing={5}
                                direction="row"
                                sx={{
                                    placeContent: "center",
                                    padding: "10px",
                                }}
                            >
                                <LinkedinShareButton url={window.location.href}>
                                    <LinkedinIcon size={32} round />
                                </LinkedinShareButton>
                                <TwitterShareButton
                                    url={window.location.href}
                                    title={article.title}
                                >
                                    <TwitterIcon size={32} round />
                                </TwitterShareButton>
                                <FacebookShareButton
                                    url={window.location.href}
                                    quote={article.title}
                                >
                                    <FacebookIcon size={32} round />
                                </FacebookShareButton>
                            </Stack>
                        </Card>
                        {article?.can_edit && article.posted_date !== null ? (
                            <Card>
                                <Analytics article={article} />
                            </Card>
                        ) : null}
                    </Stack>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default ArticlePage;
