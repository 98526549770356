import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import LockIcon from "@mui/icons-material/Lock";
import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export const ContentCardSkeletons = ({ count = 5 }) => {
    return (
        <Grid container spacing={4}>
            {Array.from({ length: count }).map(() => (
                <Grid item md={4} sm={6} xs={12} sx={{ paddingLeft: "36px" }}>
                    <Skeleton variant="rectangular" height={196} />
                </Grid>
            ))}
        </Grid>
    );
};

const ContentImage = ({ content, shadow = false, sx = {} }) => {
    return (
        <Box
            disableGutters
            sx={{
                maxWidth: "100%",
                position: "relative",
                display: "flow-root",
                "& span": {
                    display: "none",
                },
                "&:hover span": {
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    display: "block",
                    color: "#fff",
                    textDecoration: "none",
                    background: "rgba(70, 82, 152, .4)",
                    padding: "2px",
                },
                "&:hover a": {
                    color: "inherit",
                },
            }}
        >
            <CardMedia
                component="img"
                sx={{
                    objectFit: "scale-down",
                    maxWidth: "100%",
                    height: "100%",
                    maxHeight: "240px",
                    boxShadow: shadow ? "inset 0px 0px 85px -20px #b4c8d7" : undefined,
                    ...sx,
                }}
                image={content?.image}
                alt={content?.title}
            />
            {content?.image_credit ? (
                <span>
                    Credit:{" "}
                    <a href={content?.image_credit_url || undefined}>{content.image_credit}</a>
                </span>
            ) : null}
        </Box>
    );
};

const ContentTitle = ({ content }) => {
    const theme = useTheme();
    return (
        <Typography
            color={theme.palette.text.primary}
            variant="h5"
            sx={{ padding: "20px", paddingBottom: "0px" }}
        >
            {content?.title}
        </Typography>
    );
};

const ContentDescription = ({ content }) => {
    const theme = useTheme();
    return (
        <Typography
            variant="subtitle2"
            color={theme.palette.text.primary}
            sx={{ padding: "20px", paddingTop: "5px" }}
        >
            {content?.description}
        </Typography>
    );
};

const ContentShell = ({ content, children, saved, onSavedChange, canSave }) => {
    const theme = useTheme();
    return (
        <Card
            sx={{
                display: "flex",
                height: "100%",
                position: "sticky",
                border: content?.private ? `3px solid ${theme.palette.highlight.main}` : "",
            }}
        >
            <Link component={Link} href={content?.url} sx={{ width: "100%" }} underline={"none"}>
                {children}
            </Link>
        </Card>
    );
};

const ExternalContentCard = ({
    content,
    compact = false,
    saved,
    onSavedChange,
    canSave = true,
}) => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("lg"));
    return (
        <ContentShell
            content={content}
            saved={saved}
            onSavedChange={onSavedChange}
            canSave={canSave}
        >
            <Stack sx={{ position: "absolute", zIndex: 1 }} direction="row">
                {content?.private ? (
                    <Tooltip title="The content is private - candidates can't see it">
                        <LockIcon
                            sx={{
                                backgroundColor: "white",
                                borderRadius: "50%",
                                color: theme.palette.text.primary,
                            }}
                        />
                    </Tooltip>
                ) : null}
            </Stack>
            {content?.image && (!compact || smallScreen) ? (
                <ContentImage content={content} shadow sx={{ height: "180px" }} />
            ) : null}

            <Grid container>
                <Grid item lg={compact && content?.image ? 8 : 12} md={12}>
                    <ContentTitle content={content} />
                    <ContentDescription content={content} />
                </Grid>
                {content?.image && compact && !smallScreen ? (
                    <Grid item lg={4}>
                        <ContentImage content={content} />
                    </Grid>
                ) : null}
            </Grid>
        </ContentShell>
    );
};

export default ExternalContentCard;
