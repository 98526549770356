import gsap from "gsap";
import {useEffect} from "react";
const JobOpportunity = ({handleSelect, job, active, descriptionLeft, needsDelay}) => {
    useEffect(() => {
        if(active && window.innerWidth >= 1280) {
            gsap.to('.job-opportunity-description', {
                display: 'flex',
                duration: 0.5,
                ease: "power2.inOut",
                delay: needsDelay ? 1 : 0
            });
            gsap.to('.job-opportunity-description', {
                opacity: 1,
                duration: 1,
                ease: "power2.inOut",
                delay: needsDelay ? 1 : 0
            });
        }

    }, [active]);

    return (
        <div
            onClick={handleSelect}
            id={job.title}
            className={`scroll-mt-3 z-50 relative flex max-xl:flex-col max-xl:items-center max-xl:justify-center ${descriptionLeft ? 'flex-row-reverse max-xl:flex-col' : 'flex-row max-lg:flex-col'}`}>
            <div
                className={`job-opportunity-circle shrink-0 cursor-pointer p-8 h-[150px] w-[150px] flex items-center justify-center flex-col gap-1.5 border-2 border-landing-yellow !aspect-square rounded-full ${active ? 'bg-landing-yellow' : ''}`}>
                <div
                    className={`text-center text-[16px] fredoka-bold  ${active ? 'text-landing-blue' : 'text-landing-yellow'}`}>{job.title}</div>
                <img className=" h-[50px] object-contain aspect-square" src={active ? job.active_icon : job.icon} alt=""/>
            </div>
            {active ? <div
                className={`job-opportunity-description opacity-0 hidden absolute top-1/2  -translate-y-1/2  flex items-center ${descriptionLeft ? 'flex-row-reverse right-[140px]' : 'flex-row left-[140px]'}`}>
                <div className="shrink-0 h-0.5 w-10 bg-landing-yellow"></div>
                <div
                    className="w-[calc(100vw-520px)] p-6 bg-landing-yellow rounded-md flex max-xl:flex-wrap  max-xl:justify-center justify-between gap-6 items-center ">
                    <div className="text-landing-blue max-lg:text-lg text-xl flex flex-col gap-2" dangerouslySetInnerHTML={{__html: job.description}}></div>
                    <div className="shrink-0 flex flex-col gap-4 justify-center items-center">
                        <img className="h-[150px] border-4 border-landing-blue w-[150px] object-cover rounded-full aspect-square" src={job.img} alt=""/>
                        <a target="_blank" href={job.href} className="text-landing-blue text-[18px] font-bold underline ">Read {job.worker}'s story!</a>
                    </div>
                </div>
            </div> : ''}
            {active ? <div
                className={`xl:hidden flex flex-col items-center ${descriptionLeft ? 'flex-row-reverse right-[200px]' : 'flex-row left-[200px]'}`}>
            <div className="shrink-0 h-10 w-0.5 bg-landing-yellow"></div>
                <div
                    className=" p-6 bg-landing-yellow rounded-md flex max-md:flex-wrap  max-md:justify-center gap-6 items-center ">
                    <div className="flex flex-col gap-4 text-landing-blue max-lg:text-lg text-xl" dangerouslySetInnerHTML={{__html: job.description}}></div>
                    <div className="shrink-0 flex flex-col gap-4 justify-center items-center">
                        <img className="h-[174px]  w-[174px] object-cover rounded-full aspect-square border-4 border-landing-blue" src={job.img}
                             alt=""/>
                        <a href={job.href}
                           className="text-landing-blue text-[18px] font-bold underline ">Read {job.worker}'s story!</a>
                    </div>
                </div>
            </div> : ''}
        </div>
    )
}
export default JobOpportunity;