import Hero from "./Partials/Hero";
import ScrollToTop from "./Partials/ScrollToTop";
import LikeSpace from "./Partials/LikeSpace";
import HowDoWeUseSpace from "./Partials/HowDoWeUseSpace";
import Astronaut from "./Partials/Astronaut";
import ThePeopleBehindTheseActivities from "./Partials/ThePeopleBehindTheseActivities";
import JobOpportunities from "./Partials/JobOpportunities";
import StarfieldBg from "./Partials/StarfieldBg";
import Satellites from "./Partials/Satellites";
import FindYourPlaceInSpace from "./Partials/FindYourPlaceInSpace";
import SolarPanels from "./Partials/SolarPanels";
import Salaries from "./Partials/Salaries";
import AfterSchoolTexts from "./Partials/AfterSchoolTexts";
import SpaceInTheUK from "./Partials/SpaceInTheUK";
import FindOutMore from "./Partials/FindOutMore";
import LandingFooter from "./Partials/LandingFooter";
import UKMap from "./Partials/UKMap";
import React from "react";
import SpacecraftsGSAP from "./Partials/SpacecraftsGSAP";
import EarthMars from "./Partials/EarthMars";
import ShootingStar from "./Partials/ShootingStar";
import SatellitesGSAP from "./Partials/SatellitesGSAP";
import LikeSpaceProfessional from "./Partials/LikeSpaceProfessional";
import UKMapProfessional from "./Partials/UKMapProfessional";
import HowDoWeUseSpaceProfessional from "./Partials/WhatWeUseSpaceForProfessional";
import SectorOverview from "./Partials/SectorOverview";
import JobOpportunitiesProfessional from "./Partials/JobOpportunitiesProfessional";
import UpcomingAreas from "./Partials/UpcomingAreas";
import SpaceInTheUKProfessional from "./Partials/SpaceInTheUKProfessional";
import StartYourPath from "./Partials/StartYourPath";
import WhoUsesThisPageModal from "./Partials/WhoUsesThisPageModal";
import Footer from "../v2/Footer";
import PathTracker from "./Partials/PathTracker";


const UkSpace101 = () => {
    const milestones = [
        {name: "Start", href: "#start"},
        {name: "Uses of space", href: "#space"},
        {name: "Sector Overview", href: "#sectorOverview"},
        {name: "Jobs", href: "#jobs"},
        {name: "Upcoming areas", href: "#upcomingAreas"},
        {name: "In the UK", href: "#spaceInTheUk"},
        {name: "Find your path", href: "#findYourPath"},
    ];
    return (
        <div
            className=" min-h-screen max-w-screen overflow-hidden   bg-gradient-to-t from-landing-blue to-landing-dark-blue text-white fredoka">
            <PathTracker milestones={milestones}/>
            <WhoUsesThisPageModal/>
            <Hero/>
            <LikeSpaceProfessional/>
            <UKMapProfessional/>
            <div className="relative h-20 ">
                <ShootingStar className="absolute bottom-40 left-[15%]"/>
            </div>
            <HowDoWeUseSpaceProfessional/>
            <SectorOverview/>
            <StarfieldBg opacity={0.2}>
                <JobOpportunitiesProfessional/>
            </StarfieldBg>
            <Salaries></Salaries>
            <div className="relative h-20 ">
                <ShootingStar className="absolute bottom-40 left-[15%]"/>
            </div>
            <StarfieldBg opacity={0.2}>
                <UpcomingAreas/>
                <SpaceInTheUKProfessional/>
            </StarfieldBg>
            <StartYourPath/>
            <FindOutMore floatingText={false}/>
            <Footer bgLanding/>


        </div>
    );
};
export default UkSpace101;
