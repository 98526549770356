const StartYourPath = () => {
    return (
        <div id="findYourPath" className="max-w-5xl lg:px-16 xl:px-8 mx-auto mt-20 flex flex-col p-8 gap-12">
            <div className="flex max-lg:flex-col gap-8 lg:justify-between items-center">
                <div
                    
                    className="text-start leading-none tracking-tight koulen text-[48px] lg:text-[64px] text-landing-yellow">
                    Find your path
                </div>
                <img  className="w-[280px] slow-floating" src="landing/Rover.svg" alt=""/>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 ">
                <div
                    className="w-full text-landing-blue h-full rounded-lg bg-landing-yellow hover:bg-white transition-colors duration-300 ease-in-out py-10 px-10 flex flex-col gap-3">
                    <div className="fredoka-bold text-[36px]"> Discover Jobs</div>
                    <p className="text-[22px] leading-tight">
                        Check out our <a className={"underline"} target={"_blank"} href="https://spacecareers.uk/articles/job_profiles">Job Profiles</a> to learn about different roles across the space sector and our
                        <a className={"underline"} href="https://spacecareers.uk/articles/career_stories" target={"_blank"}> Careers Stories</a> to hear from the people actually working in the space sector.</p>
                </div>
                <div
                    className="w-full text-landing-blue h-full rounded-lg bg-landing-yellow hover:bg-white transition-colors duration-300 ease-in-out py-10 px-10 flex flex-col gap-3">
                    <div className="fredoka-bold text-[36px]">Get advice</div>
                    <p className="leading-tight text-[22px]">See our <a target={"_blank"} className="underline"
                                                                        href={"https://spacecareers.uk/articles/advice_and_information"}>Advice
                        and Information</a> articles for how you can develop your skills or change careers into the sector.</p>
                </div>
                <div

                    className="w-full text-landing-blue h-full rounded-lg bg-landing-yellow hover:bg-white transition-colors duration-300 ease-in-out py-10 px-10 flex flex-col gap-3">
                    <div className="fredoka-bold text-[36px]">Find companies</div>
                    <p className="leading-tight text-[22px]">Visit our <a className="underline" href="https://spacecareers.uk/employers" target={"_blank"}>Company A-Z</a> page to discover the variety of space activities and what is happening near you.</p>
                </div>
                <div

                    className="w-full text-landing-blue h-full rounded-lg bg-landing-yellow hover:bg-white transition-colors duration-300 ease-in-out py-10 px-10 flex flex-col gap-3">
                    <div className="fredoka-bold text-[36px]">Find your next role

                        </div>
                    <p className=" leading-tight text-[22px]">Head over to our <a className="underline" target={"_blank"} href="https://spacecareers.uk/jobs">Jobs Board</a> to see the range of roles on offer right now, from internships and graduate roles to senior positions.</p>
                </div>
                <div

                    className="w-full text-landing-blue h-full rounded-lg bg-landing-yellow hover:bg-white transition-colors duration-300 ease-in-out py-10 px-10 flex flex-col gap-3">
                    <div className="fredoka-bold text-[36px]">See the numbers</div>
                    <p className=" leading-tight text-[22px]">Check out the <a target={"_blank"} className={"underline"} href="https://www.gov.uk/government/publications/the-size-and-health-of-the-uk-space-industry-2023">Size and Health of the UK Space Industry 2023</a> for more facts and figures on the space sector.</p>
                </div>
                <div

                    className="w-full text-landing-blue h-full rounded-lg bg-landing-yellow hover:bg-white transition-colors duration-300 ease-in-out py-10 px-10 flex flex-col gap-3">
                    <div className="fredoka-bold text-[36px]">Read about skills</div>
                    <p className=" leading-tight text-[22px]">Take a look at the <a target={"_blank"} className={"underline"} href="https://www.gov.uk/government/publications/space-sector-skills-survey-2023/space-sector-skills-survey-2023-report">Space Sector Skills Survey 2023</a> for more information on what skills are in demand.</p>
                </div>
            </div>

            <div className="flex max-lg:flex-col gap-8 lg:justify-between items-center">
                <div

                    className="text-start pb-12 text-[24px] fredoka-bold ">
                    Please let us know if there’s anything else you want to know about the UK space sector at
                    <a target={"_blank"}
                       className="text-landing-yellow hover:underline"
                       href={'mailto:hello@SpaceCareers.uk'}> hello@SpaceCareers.uk</a>
                </div>
                <img className="w-[180px] slow-floating" src="landing/SpaceStation.svg" alt=""/>
            </div>
        </div>
    );
}

export default StartYourPath;
