import React, { useCallback, useEffect, useRef, useState } from "react";

import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { CustomInfiniteScroll as InfiniteScroll } from "../common/CustomInfiniteScroll";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import axiosInstance from "../axiosInstance";
import moment from "moment";
import { useTheme } from "@mui/material/styles";

const NotificationsPopover = ({ unseenChanged, ...props }) => {
    const [notifications, setNotifications] = useState([]);
    const nextData = useRef("/api/users/me/notifications/?limit=3");
    const [hasMore, setHasMore] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const fetchMoreData = useCallback(() => {
        axiosInstance.get(nextData.current).then((response) => {
            setNotifications((items) => items.concat(response.data.results));
            setTotalItems(response.data.count);
            unseenChanged(response.data.unseen);

            var next = "";
            if (response.data.next) {
                let next_queries = response.data.next.split("?");
                next = next_queries[next_queries.length - 1];
                nextData.current = "/api/users/me/notifications/?" + next;
                setHasMore(true);
            } else {
                nextData.current = undefined;
                setHasMore(false);
            }
        });
    }, []);
    useEffect(() => {
        fetchMoreData();
    }, [fetchMoreData]);

    return (
        <Popover {...props}>
            <InfiniteScroll
                dataLength={notifications.length}
                next={fetchMoreData}
                hasMore={hasMore}
                textColor="primary"
                endText="No more notifications"
                height={200}
            >
                <Grid container spacing={2} sx={{ margin: 0, width: "200px" }}>
                    {notifications.map((notification) => (
                        <Button
                            href={notification?.link}
                            key={notification?.id}
                            sx={{ textAlign: "left" }}
                        >
                            <Stack>
                                <Typography
                                    variant="caption"
                                    sx={{ color: !notification?.seen ? undefined : "gray" }}
                                >
                                    {notification?.content}
                                </Typography>
                                <Typography variant="caption" sx={{ fontSize: "0.7rem" }}>
                                    {moment(notification?.notification_time).fromNow()}
                                </Typography>
                            </Stack>
                        </Button>
                    ))}
                </Grid>
            </InfiniteScroll>
        </Popover>
    );
};

const Notifications = (props) => {
    const theme = useTheme();
    const [unseenNotifications, setUnseenNotifications] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    useEffect(() => {
        axiosInstance.get("/api/users/me/notifications/?markSeen=false").then((response) => {
            setUnseenNotifications(response.data.unseen);
        });
    }, []);

    return (
        <React.Fragment>
            <Badge
                badgeContent={unseenNotifications}
                onClick={handleClick}
                variant="contained"
                color="secondary"
                {...props}
            >
                <IconButton
                    onClick={handleClick}
                    variant="contained"
                    sx={{ maxHeight: "40px", margin: "auto" }}
                >
                    <NotificationsIcon sx={{ color: theme.palette.text.primary }} />
                </IconButton>
            </Badge>
            {open ? (
                <NotificationsPopover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    unseenChanged={(unseen) => setUnseenNotifications(unseen)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                ></NotificationsPopover>
            ) : null}
        </React.Fragment>
    );
};
export default Notifications;
