import {useEffect} from "react";
import gsap from "gsap";

const SpaceInTheUK = () => {


    useEffect(() => {
        // Select all images inside the #images container
        const image = document.querySelector(".earth2");

        gsap.fromTo(
            image,
            {
                opacity: 0,
                x: -100,
            },
            {
                opacity: 1,
                x: 0,
                duration: 3,
                ease: "power2.out",
                scrollTrigger: {
                    trigger: image,
                    start: "top 100%",
                    end: "top 50%",
                    scrub: true,
                },
            }
        );
    }, []);
    return (
        <div id="spaceInTheUk" className="max-w-5xl  mx-auto mt-20 p-8">
            <div
                data-aos={"fade-up"}
                className="text-start leading-none pb-12  tracking-tight koulen text-[48px] lg:text-[64px] text-landing-yellow">
                Space in the UK
            </div>
            <div className="flex max-lg:flex-col h-full  gap-4">
                <div data-aos={"fade-up"} className="w-full lg:w-1/4 flex flex-col gap-4 text-[20px]">
                    <p>Space activity is closer than you might think. Every region of the UK is home to the headquarters
                        of at least one space company and there are academics at universities across the country doing
                        research for space.</p>
                    <p className="fredoka-bold text-landing-yellow">Here are some of the key organisations supporting the
                        sector.</p>
                    <div className="relative h-full flex justify-center max-lg:mb-16 w-full mt-10 ">

                        <div  className="slow-floating lg:absolute  lg:w-[200px] xl:w-[360px] top-0 xl:-left-48">
                            <img
                                className=" rotate-[-10deg] w-full object-contain"
                                src="landing/Satellite.svg"
                                alt=""
                            />
                        </div>
                        <img
                            className="earth2 max-lg:hidden block xl:mr-12 lg:absolute lg:w-[215px] bottom-0 object-contain"
                            src="landing/SatellitePath.svg"
                            alt=""
                        />
                    </div>
                </div>
                <div className="w-full text-[20px] lg:w-3/4 flex flex-col gap-8">
                    <div data-aos={"fade-up"}
                         className="flex items-center justify-between max-md:flex-col gap-8">
                        <a href="https://www.gov.uk/government/organisations/uk-space-agency" target="_blank"
                            className="cursor-pointer bg-white aspect-square h-[146px] rounded-full flex items-center justify-center p-6">
                            <img src="landing/UK_Space.png" alt=""/>
                        </a>
                        <div
                            className="text-start lg:pr-8 xl:pr-0">
                            Originally created as the British National Space Centre in 1985, the <span
                            className="fredoka-bold text-landing-yellow">UK Space Agency (UKSA)</span> was formally established in 2010 and now has offices across the UK. It funds and oversees UK missions, supports organisations in the space sector and promotes space in the UK .
                        </div>

                    </div>
                    <div data-aos={"fade-up"}
                         className="flex items-center justify-between max-md:flex-col gap-8">
                        <a href={"https://www.gov.uk/government/organisations/department-for-science-innovation-and-technology"}
                           target="_blank"
                            className="bg-white aspect-square h-[146px] rounded-full flex items-center justify-center p-1">
                            <img className="pl-2" src="landing/DepartmentScience.svg" alt=""/>
                        </a>
                        <div className="text-start lg:pr-8 xl:pr-0">
                            The Government’s <span className="fredoka-bold text-landing-yellow">Department for Science, Innovation and Technology</span> is
                            the
                            parent department for the UK Space Agency and ultimately oversees the UK’s civil space
                            activities. It worked with UKSA to develop the first National Space Strategy in 2021.
                        </div>

                    </div>
                    <div data-aos={"fade-up"}
                         className="flex items-center justify-between max-md:flex-col gap-8">
                        <a href="https://www.esa.int/" target="_blank"
                            className="bg-white aspect-square h-[146px] rounded-full flex items-center justify-center  p-6">
                            <img src="landing/ESA.png" alt=""/>
                        </a>
                        <div className="text-start lg:pr-8 xl:pr-0 ">
                            The UK was one of the founding members of the <span
                            className="fredoka-bold text-landing-yellow">European Space Agency</span> in 1975. ESA now
                            comprises 22 member states from across Europe and enables nations to work together to do
                            bigger projects than they could do alone. ESA has sites across Europe, including one at the
                            Harwell campus in the UK.
                        </div>
                    </div>
                    <div data-aos={"fade-up"}
                         className="flex items-center justify-between max-md:flex-col gap-8">
                        <a href={"https://www.raf.mod.uk/what-we-do/uk-space-command/"} target="_blank"
                            className="bg-white aspect-square h-[146px] rounded-full flex items-center justify-center p-8 pb-10">
                            <img src="landing/badge_uk.svg" alt=""/>
                        </a>
                        <div className="text-start lg:pr-8 xl:pr-0 ">
                            <span className="fredoka-bold text-landing-yellow">UK Space Command</span> is a new joint
                            command of the British Armed forces setup in 2021 to protect the UK’s interest in space.
                            Space is increasingly of military concern and the first Defence Space Strategy was published
                            in 2022.
                        </div>
                    </div>
                    <div data-aos={"fade-up"}
                         className="flex items-center justify-between max-md:flex-col gap-8">
                        <a href={"https://sa.catapult.org.uk/"} target="_blank"
                            className="bg-white aspect-square h-[146px] rounded-full flex items-center justify-center p-4">
                            <img src="landing/catapult.svg" alt=""/>
                        </a>
                        <div className="text-start lg:pr-8 xl:pr-0 ">
                            The <span
                            className="fredoka-bold text-landing-yellow">Satellite Applications Catapult</span> is a
                            not-for-profit organisation that aims to support the UK Space Sector. It was founded along
                            with various other industry Catapults in 2010 and now has over 150 employees primarily based
                            out of Harwell.
                        </div>
                    </div>
                    <div data-aos={"fade-up"}
                         className="flex items-center justify-between max-md:flex-col gap-8">
                        <a href={"https://www.caa.co.uk/space/"} target="_blank"
                            className="bg-white aspect-square h-[146px] rounded-full flex items-center justify-center p-4">
                            <img src="landing/ukCAA.svg" alt=""/>
                        </a>
                        <div className="text-start lg:pr-8 xl:pr-0 ">
                            The <span className="fredoka-bold text-landing-yellow">Civil Aviation Authority</span>, the
                            regulator for aircraft, became the regulator for spacecraft under the Space Industry Act
                            2019 and now regulates and licenses all UK satellites and launches.
                        </div>
                    </div>
                    <div data-aos={"fade-up"}
                         className="flex items-center justify-between max-md:flex-col gap-8">
                        <a href={"https://www.ralspace.stfc.ac.uk/Pages/Space-at-UKRI.aspx"} target="_blank"
                            className="bg-white aspect-square h-[146px] rounded-full flex items-center justify-center p-4">
                            <img src="landing/ukri.svg" alt=""/>
                        </a>
                        <div className="text-start lg:pr-8 xl:pr-0 ">
                            <span className="fredoka-bold text-landing-yellow">UK Research and Innovation</span> is a public body that supports the space research and innovation ecosystem through nine councils, such as Innovate UK, the Science and Technology Facilities Council, and the Natural Environment Research Council.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SpaceInTheUK;