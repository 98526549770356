import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import PageContainer from "./common/PageContainer";
import Paper from "@mui/material/Paper";

const PrivacyPolicyDialog = ({ open, onClose }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{ "& .MuiDialog-paper": { width: "80vw", maxWidth: "830px" } }}
        >
            <DialogContent sx={{ padding: 0 }}>
                <iframe
                    src="/PrivacyPolicy.html"
                    style={{ width: "100%", height: "80vh", border: "none" }}
                />
            </DialogContent>
        </Dialog>
    );
};

export const PrivacyPolicyPage = () => {
    return (
        <PageContainer>
            <Paper>
                <iframe
                    src="/PrivacyPolicy.html"
                    style={{ width: "100%", height: "80vh", border: "none" }}
                />
            </Paper>
        </PageContainer>
    );
};

export default PrivacyPolicyDialog;
