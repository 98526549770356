const UKMap = () => {
    return (
        <div  id="UKMap" className="min-h-screen justify-center p-8 mx-auto w-full max-w-5xl flex flex-col gap-4 max-lg:text-[28px] text-[40px] fredoka-bold pb-28">
            <div data-aos="fade-up"
                 className=" flex max-md:gap-8 max-md:flex-wrap max-md:justify-center items-center  ">
                <div className="flex flex-col gap-4">
                    <div
                        className=" leading-none  text-start  tracking-tight koulen text-[48px] lg:text-[64px] text-landing-yellow">There’s
                        over 1700 in the uk, and lots more being
                        created every year!
                    </div>
                    <div className="leading-tight  max-w-md text-start text-[24px] fredoka">And they all need
                        someone like you to work on a variety of jobs!
                    </div>
                </div>
                <div className="shrink-0 h-[473px] w-[334px] relative">
                    <img className=" shrink-0 w-full h-full object-contain" src="landing/MapOutline.svg" alt=""/>
                    <img data-aos="fade-down" data-aos-delay="300" data-aos-duration="400"
                         className=" h-[44px] absolute top-[50px] right-[187px]" src="landing/Pin.svg" alt=""/>
                    <img data-aos="fade-down" data-aos-delay="300" data-aos-duration="400"
                         className=" h-[44px] absolute top-[100px] right-[150px]" src="landing/Pin.svg" alt=""/>
                    <img data-aos="fade-down" data-aos-delay="300" data-aos-duration="400"
                         className=" h-[44px] absolute top-[174px] right-[165px]" src="landing/Pin.svg" alt=""/>
                    <img data-aos="fade-down" data-aos-delay="300" data-aos-duration="400"
                         className=" h-[44px] absolute top-[210px] right-[260px]" src="landing/Pin.svg" alt=""/>
                    <img data-aos="fade-down" data-aos-delay="300" data-aos-duration="400"
                         className=" h-[44px] absolute top-[230px] right-[115px]" src="landing/Pin.svg" alt=""/>
                    <img data-aos="fade-down" data-aos-delay="300" data-aos-duration="400"
                         className=" h-[44px] absolute top-[260px] right-[70px]" src="landing/Pin.svg" alt=""/>
                    <img data-aos="fade-down" data-aos-delay="300" data-aos-duration="400"
                         className=" h-[44px] absolute top-[318px] right-[20px]" src="landing/Pin.svg" alt=""/>
                    <img data-aos="fade-down" data-aos-delay="300" data-aos-duration="400"
                         className=" h-[44px] absolute top-[308px] right-[150px]" src="landing/Pin.svg" alt=""/>
                    <img data-aos="fade-down" data-aos-delay="300" data-aos-duration="400"
                         className=" h-[44px] absolute top-[370px] right-[64px]" src="landing/Pin.svg" alt=""/>

                    <img data-aos="fade-down" data-aos-delay="300" data-aos-duration="400"
                         className=" h-[44px] absolute top-[325px] right-[100px]" src="landing/Pin.svg" alt=""/>

                    <img data-aos="fade-down" data-aos-delay="300" data-aos-duration="400"
                         className=" h-[44px] absolute top-[390px] right-[170px]" src="landing/Pin.svg" alt=""/>
                </div>
            </div>
        </div>
    );
}
export default UKMap;