import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { DataGrid } from "@mui/x-data-grid";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import TabPanel from "../common/TabPanel";
import Tooltip from "@mui/material/Tooltip";
import axiosInstance from "../axiosInstance";
import { useSnackbar } from "notistack";

const columns = [
    {
        field: "id",
        headerName: "User id",
        width: 200,
    },
    {
        field: "name",
        headerName: "User name",
        width: 250,
        sortable: false,
    },
    {
        field: "email",
        headerName: "email",
        width: 300,
        sortable: false,
    },
];

const UserAdminPanel = ({ employer, ...props }) => {
    const [registrationLink, setRegistrationLink] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        axiosInstance.get(`/api/employers/${employer.id}/users/`).then((response) => {
            const responseData = response.data;
            setData(responseData);
            setLoading(false);
        });
    }, []);

    const getRegistrationLink = () => {
        axiosInstance.get("/api/users/me/registration_token/").then((response) => {
            setRegistrationLink(window.location.origin + "/signup/" + response.data);
        });
    };

    return (
        <TabPanel {...props}>
            <Stack>
                <Button onClick={getRegistrationLink}>Generate registration link</Button>

                {registrationLink ? (
                    <FormControl variant="outlined">
                        <InputLabel>Registration link</InputLabel>
                        <OutlinedInput
                            value={registrationLink}
                            disabled
                            endAdornment={
                                <InputAdornment position="end">
                                    <Tooltip title="Copy to clipboard">
                                        <IconButton
                                            onClick={() => {
                                                navigator.clipboard.writeText(registrationLink);
                                                enqueueSnackbar("Link copied", {
                                                    variant: "success",
                                                });
                                            }}
                                            edge="end"
                                        >
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            }
                            label="Registration link"
                        />
                    </FormControl>
                ) : null}
            </Stack>
            <DataGrid
                color="primary"
                rows={data?.users}
                columns={columns}
                autoHeight
                disableColumnFilter
                loading={loading}
                rowHeight={80}
                sx={{
                    "& .MuiDataGrid-cell": {
                        whiteSpace: "pre-wrap",
                    },
                }}
            />
        </TabPanel>
    );
};

export default UserAdminPanel;
