import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const SectorOverview = () => {
    const rocketRef = useRef(null);

    useEffect(() => {
        // Create a GSAP animation for the rocket image
        gsap.fromTo(
            rocketRef.current,
            {
                opacity: 1,
                x: 0,
            },
            {
                opacity: 0,
                x: 250,
                duration: 3,
                ease: "power2.out",
                scrollTrigger: {
                    trigger: rocketRef.current,
                    start: "top 50%",
                    end: "top 0%",
                    scrub: true,
                },
            }
        );
    }, []);

    return (
        <div id="sectorOverview" className="max-w-5xl lg:pr-16 xl:pr-8 mx-auto pt-40 flex flex-col gap-8 p-8">
            <div className=" flex max-md:flex-col gap-6 items-center justify-between">
                <h1
                    className="tracking-tight koulen text-[48px] lg:text-[64px] text-landing-yellow"
                >
                    Sector overview
                </h1>
                <img
                    ref={rocketRef}  // Reference to the rocket image
                    className="h-10"
                    src="landing/Spaceship.svg"
                    alt="Rocket"
                />
            </div>
            <div  className="fredoka-bold text-[24px]">
                The space sector is split into ‘upstream’, ‘midstream’ and ‘downstream’.
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 text-[20px]">
                <p >
                    <span className="fredoka-bold text-landing-yellow">Upstream</span> refers to all the work related to
                    putting something into space. This includes designing, manufacturing, and testing spacecraft,
                    rockets, and ground segment equipment, which come from a diverse supply chain across the country.
                </p>
                <p >
                    <span className="fredoka-bold text-landing-yellow">Midstream</span> relates to operating satellites
                    in orbit and managing the orbital environment to track objects and prevent collisions.
                </p>
                <p >
                    <span className="fredoka-bold text-landing-yellow">Downstream</span> refers to all the work related
                    to providing products and services from satellites, such as Earth observation data and
                    communications. The majority of space sector revenue and employment is in downstream activities.
                </p>
            </div>
            <p  className="text-[20px]">
                There are also <span className={"text-landing-yellow fredoka-bold"}>other services</span> like regulation, financial, legal, and business support that enable the
                sector to operate successfully.
            </p>
            <p className="text-[20px]">
                <span className={"text-landing-yellow fredoka-bold"}>Academia</span> in the UK supports the whole
                range of activities, from developing new satellite technologies
                and data analytics algorithms to helping policymakers and diplomats.
            </p>
        </div>
    );
};

export default SectorOverview;
