import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import _ from "lodash";
import axiosInstance from "../axiosInstance";

class UploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return this.loader.file.then((uploadedFile) => {
            return new Promise((resolve, reject) => {
                const data = new FormData();
                console.log("using my thing");
                data.append("upload", uploadedFile);

                axiosInstance({
                    url: "/api/upload/media/",
                    method: "post",
                    data,
                    headers: {
                        "Content-Type": "multipart/form-data;",
                    },
                    withCredentials: false,
                })
                    .then((response) => {
                        if (response.data.result == "success") {
                            resolve({
                                default: response.data.url,
                            });
                        } else {
                            reject(response.data.message);
                        }
                    })
                    .catch((response) => {
                        reject("Upload failed");
                    });
            });
        });
    }

    abort() {}
}
function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new UploadAdapter(loader);
    };
}
const MarkdownEditor = (props) => {
    return (
        <div style={{ display: "contents" }}>
            <CKEditor
                editor={Editor}
                config={{
                    language: "en-gb",
                    image: {
                        toolbar: [],
                    },
                    extraPlugins: [MyCustomUploadAdapterPlugin],
                    toolbar: {
                        items: _.filter(
                            [
                                "heading",
                                "|",
                                "bold",
                                "italic",
                                "strikethrough",
                                "underline",
                                "|",
                                "blockQuote",
                                "bulletedList",
                                "numberedList",
                                "horizontalLine",
                                "|",
                                "link",
                                "imageInsert",
                                "undo",
                                "redo",
                            ],
                            (v) => !props?.exclude?.includes(v)
                        ),
                    },
                }}
                {...props}
            />
        </div>
    );
};
export default MarkdownEditor;
