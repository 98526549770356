import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const HowDoWeUseSpace = () => {
    const imagesRef = useRef(null);

    useEffect(() => {
        // Select all images inside the #images container
        const images = gsap.utils.toArray("#images img");

        images.forEach((image) => {
            gsap.fromTo(
                image,
                {
                    opacity: 0,
                    x: 150,
                },
                {
                    opacity: 1,
                    x: 0,
                    duration: 3,
                    ease: "power2.out",
                    scrollTrigger: {
                        trigger: image,
                        start: "top 100%",
                        end: "top 10%",
                        scrub: true,
                    },
                }
            );
        });
    }, []);

    return (
        <div className="relative">
            <img
                className="z-10 absolute top-0 object-top object-cover max-lg:h-[100px] h-[207px] w-full"
                src="landing/Starfield.svg"
                alt=""
            />
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mx-auto p-8 max-w-5xl gap-4">
                <div

                    className="flex items-center justify-center col-span-1 z-20 leading-none text-center lg:text-start tracking-tight koulen text-[48px] lg:text-[64px] text-landing-yellow"
                >
                    What we use space for
                </div>
                <div
                    id="images"
                    className="flex justify-center max-lg:py-12 lg:justify-between items-center w-full gap-6 col-span-2"
                >
                    <img className="h-20 lg:h-[200px]" src="landing/Climate.svg" alt="" />
                    <img className="h-20 lg:h-[200px]" src="landing/GPS.svg" alt="" />
                    <img className="h-20 lg:h-[200px]" src="landing/Weather.svg" alt="" />
                </div>
            </div>
            <div className="lg:pr-16 xl:pr-8 px-8 max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-3 gap-6 text-[20px]">
                <p data-aos="fade-up">
                    The space sector grew from the Space Race in the 1950-60s and
                    historically has only been affordable for large governments. However,
                    technological advances in satellite manufacturing and rocket launch
                    in the last few decades have dramatically lowered the barriers to
                    accessing space, enabling commercial companies to support an
                    increasingly diverse range of applications with space-derived data and
                    services.
                </p>
                <p data-aos="fade-up">
                    Today, space supports 16% <a  target={"_blank"} className="underline hover:text-landing-yellow mr-1"
                                                 href="https://www.gov.uk/government/publications/the-size-and-health-of-the-uk-space-industry-2023">¹</a> of
                    the UK economy and is classed as a
                    critical national infrastructure. With financial transactions,
                    transportation networks and weather monitoring all reliant on
                    satellites, one day without space would cost over £1bn <a
                    target={"_blank"}
                    className="underline hover:text-landing-yellow mr-1"
                    href="https://www.gov.uk/guidance/space-based-pnt-programme">²</a>!
                    <br/>
                    <br/>
                    Earth observation satellites allow us to monitor a variety of changes
                    across our planet, including deforestation and air quality. More than
                    half the measurements we need to make to track climate change can only
                    be made from space <a
                    target={"_blank"}
                    className="underline hover:text-landing-yellow"
                    href="https://climate.esa.int/en/about-us-new/climate-change-initiative/what-are-ecvs/#:~:text=The%20Global%20Climate%20Observing%20System,time%20series%20of%20consistent%20observation">³</a>, and satellite imagery supports sustainable urban
                    planning initiatives, agriculture and more.
                </p>
                <p data-aos="fade-up">
                    Away from Earth, there are spacecraft travelling to distant planets,
                    rovers and helicopters on Mars, and space telescopes being used to
                    understand how the universe and life formed and developed. Astronauts
                    living on space stations do science and technology experiments to
                    prepare for long missions to the Moon and Mars and develop medicines
                    and materials for use on Earth.
                </p>
            </div>
        </div>
    );
};

export default HowDoWeUseSpace;
