import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import React from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

const SideCard = ({ title, body, sx = {}, action = null }) => {
    const theme = useTheme();

    return (
        <>
            <Card sx={{ padding: "15px", ...sx }}>
                <Typography variant="h6" color="primary">
                    {title}
                </Typography>
                <Divider sx={{ margin: "10px 0px" }} />
                <Typography variant="subtitle">{body}</Typography>
                {action
                    ? React.cloneElement(action, {
                          variant: "contained",
                          size: "small",
                          sx: {
                              margin: "auto",
                              display: "table",
                              fontSize: "12px",
                              marginTop: "20px",
                          },
                      })
                    : null}
            </Card>
        </>
    );
};
export default SideCard;
