import React, { useEffect, useRef } from "react";
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
const ThePeopleBehindTheseActivities = () => {
    const sectionRefs = useRef([]);
    sectionRefs.current = [];

    const addToRefs = (el) => {
        if (el && !sectionRefs.current.includes(el)) {
            sectionRefs.current.push(el);
        }
    };
    useEffect(() => {
        if(window.innerWidth < 1024) return;
        const sections = sectionRefs.current;

        gsap.set(sections, { autoAlpha: 0 });
        gsap.to(sections[0], { autoAlpha: 1 }); // Start with the first section visible

        const scrollTrigger = gsap.to(sections, {
            scrollTrigger: {
                trigger: "#peopleGSAP",
                start: "top top",
                end: "+=1000",
                scrub: 1,
                pin: true,
                snap: 1 / (sections.length - 1),
                onUpdate: (self) => {
                    const progress = self.progress * (sections.length - 1);
                    sections.forEach((section, index) => {
                        const isActive = Math.abs(index - progress) < 0.5;
                        gsap.to(section, {
                            autoAlpha: isActive ? 1 : 0,
                            duration: 0.5,
                        });
                    });
                },
            }
        });

        return () => {
            scrollTrigger.scrollTrigger.kill();
            gsap.set(sections, { autoAlpha: 1 });
        };
    }, []);
    return (
        <div className={"pt-16 "} id="people">
        <div
            data-aos={"fade-up"}
            className=" mx-auto leading-none  text-center  tracking-tight koulen text-[48px] lg:text-[64px] text-landing-yellow">
            The People behind these activities
        </div>

    <div
        id="peopleGSAP"
        className="relative px-8  max-lg:text-[28px] text-[40px]  flex flex-col justify-center  min-h-screen "
    >
        <div
            className="absolute inset-0"
                style={{
                    backgroundImage: `url('landing/Starfield.svg')`,
                    backgroundRepeat: 'repeat-y',
                    backgroundSize: '100% auto',
                    backgroundPosition: 'top left',
                    opacity: 0.2,
                }}
            />
        <div data-aos={"fade-up"} className="max-w-5xl mx-auto flex flex-col gap-16 py-12 lg:py-8 justify-between overflow-hidden">

            <div className="grid px-2 mx-auto max-w-4xl max-md:grid-cols-1 mt-6 grid-cols-3 gap-8">
                <a className="" target="_blank" href="https://spacecareers.uk/articles/3655c8e7-6a9d-4474-a12f-0d5f2f826624">
                    <img className="cursor-pointer rounded-full h-48 w-48 object-cover ring ring-white hover:ring-landing-yellow" src="landing/anisha.png" alt=""/>
                </a>
                <a className="" target="_blank" href="https://spacecareers.uk/articles/37c9b24d-5658-496e-be8f-9bccbebddf68">
                    <img className="cursor-pointer rounded-full h-48 w-48 object-cover ring ring-white hover:ring-landing-yellow" src="landing/person2.png" alt=""/>
                </a>
                <a className="" target="_blank" href="https://spacecareers.uk/articles/db7d032c-ccb6-439f-9ecd-0c6e58689a73">
                    <img className="cursor-pointer rounded-full h-48 w-48 object-cover ring ring-white hover:ring-landing-yellow" src="landing/person3.png" alt=""/>
                </a>

            </div>

            <div ref={addToRefs} className="max-w-xl text-[24px]">Lots of people working in the space sector do so
                because they want to
                make an impact
            </div>
            <div ref={addToRefs} className="max-w-xl text-[24px]">Some of them always knew they wanted a space career,
                some only
                discovered it was possible later in life
            </div>
            <div ref={addToRefs} className="max-w-xl text-[24px]">No matter what you’re interested in and what you’re
                good or bad at,
                there is a job for you in the space sector!
            </div>
        </div>
    </div>
        </div>
    );
}
export default ThePeopleBehindTheseActivities;