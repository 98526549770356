import React, { useState } from "react";

import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axiosInstance from "../axiosInstance";

const PasswordResetRequestPage = (props) => {
    const [email, setEmail] = useState("");
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        axiosInstance
            .post("/api/auth/users/reset_password/", {
                email: email,
            })
            .then((response) => {
                setSubmitted(true);
            })
            .catch((error) => {
                throw error;
            });
    };

    return (
        <PageContainer maxWidth="sm">
            {submitted ? (
                <Paper>
                    <Typography variant="h6">
                        If an account exists with the email address you have entered, an email has
                        been sent to you with instructions on how to reset your password.
                    </Typography>
                </Paper>
            ) : (
                <Paper>
                    <Typography variant="h4" paragraph>
                        Reset password
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={1}>
                            <TextField
                                type="email"
                                placeholder="email"
                                fullWidth
                                name="email"
                                variant="outlined"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                required
                                autoFocus
                            />
                            <Button variant="contained" color="primary" type="submit">
                                Submit
                            </Button>
                        </Stack>
                    </form>
                    <Link to="/signup" color="primary">
                        Haven't signed up?
                    </Link>
                </Paper>
            )}
        </PageContainer>
    );
};
export default PasswordResetRequestPage;
