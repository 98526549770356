import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import { Trans } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const team = [
    {
        name: "Jacob Smith",
        title: "Director",
        image: "/team/Jacob.jpg",
        bio: "Passionate about improving access to the space sector, Jacob is a former Chair of UKSEDS and also the founder of SpaceProjects.uk. He has worked in engineering, training, and coordination roles across the sector and enjoys learning new things, the great outdoors, and live music.",
    },
    {
        name: "Joanne Oliver",
        title: "Director",
        image: "/team/Joanne.jpg",
        bio: "Curious, analytical and adventurous, Joanne is passionate about helping others find their place within the space industry and identifying how SpaceCareers.uk can support the sector for decades to come. Full time, she is the business engagement lead at the Satellite Applications Catapult supporting business growth and connecting the space ecosystem.",
    },
    {
        name: "Ewan Wright",
        title: "Director",
        image: "/team/Ewan.jpg",
        bio: "Ewan is a volunteer with SpaceCareers.uk in the leadership team. He is currently studying for a PhD at the University of British Columbia, Vancouver, focusing on the sustainability of outer space. Outside of work he enjoys being on the sides of (preferably snowy) mountains.",
    },
    {
        name: "Rachel Gould",
        title: "Team Lead",
        image: "/team/Rachel.jpeg",
        bio: "Rachel is a fourth year Manufacturing Engineering student at the University of Cambridge and a Prospero Space Fellow. She is passionate about increasing the diversity of people and skills within the space sector. Outside of work she is the president of Jesus College Boat Club where she coxes, and fills her free time with books, art and running.",
    }, 
    {
        name: "Shifa Shaikh",
        title: "Vice-Team Lead",
        image: "/team/Shifa.jpg",
        bio: "Modern day version of a nerd, Shifa is a Space Engineer working in the Aerospace sector. When she’s not working or volunteering, you will either find her nose deep in a thriller novel or baking. She also enjoys talking about Space Sustainability, literature and stargazing.",
    },   
    {
        name: "Agne Zalaite",
        title: "Event Organiser",
        image: "/team/Agne.jpg",
        bio: "Meet Agne, a Physics with Astrophysics student at Northumbria University and a dedicated SpaceCareers.uk volunteer for events. Agne is committed to making space accessible to everyone and is excited to continue their journey as a volunteer with SpaceCareers.uk.",
    },
    {
        name: "Ananya Ganapathy",
        title: "Event Organiser",
        image: "/team/Ananya_Ganapathy.jpeg",
        bio: "Ananya is currently a second year student studying Astrophysics at the University of Edinburgh. Having recently joined SpaceCareers.uk, she looks for avenues in which she can contribute towards the growing space sector. Besides academics, she is a passionate Indian classical dancer, Veena (Indian string instrument) player and loves a clear starry night sky!",
    },
    {
        name: "Isabella Bowen-Lowe",
        title: "Event Organiser",
        image: "/team/Izzy.jpg",
        bio: "Izzy is a third year Biological Sciences student at the University of Warwick with a particular interest in researching the crossover between astrobiology and synthetic biology. In her role as an event organiser for SpaceCareers.uk she is passionate about increasing accessibility into the space sector.",
    },
    {
        name: "Beatrice Rodriguez-Caresia",
        title: "Marketing Creator",
        image: "/space-suit_3.png",
        bio: "Beatrice started volunteering for SpaceCareers.uk in her final year of Aerospace Engineering at Swansea University, which she has recently completed and is now working towards finding her place in the space industry! In her ideal world she’d own a sustainable rocket ship to replace her car with.",
    },
    {
        name: "Vito Latorre",
        title: "Operations Coordinator",
        image: "/team/Vito.jpg",
        bio: "A visionary and aspirational Environmental Scientist with a keen interest in space exploration Vito is a recently graduated student that aims to work in the space industry. After having collected both experience in GIS technology and Astrobiological projects now Vito is volunteering at SpaceCareers.uk.",
    },
    {
        name: "Daniel Bhuglah",
        title: "Content Writer",
        image: "/team/DanB.jpg",
        bio: "After 31 years in Financial Services Technology, it was time for a change. Daniel is looking to build a new career in the space sector and has recently started an MSc in Space Science and Technology at the Open University.",
    },
    {
        name: "Evelina Sakalauskaitė",
        title: "Content Writer",
        image: "/team/Evelina_Sakalauskaite.jpg",
        bio: "Evelina is a volunteer Content Writer and a final- year Aerospace Engineering student at the University of Sheffield. She is passionate about reading and discussing new ideas and is keen to share her insights through volunteering at SpaceCareers.uk.",
    },
    {
        name: "Rosie Braunholtz",
        title: "Content Writer",
        image: "/team/Rosie.jpg",
        bio: "Rosie is an astronomer and science communicator who has joined the content writing team at SpaceCareers.co.uk She's passionate about making science accessible to everyone and is excited to be able to help others start a career in the space sector.",
    },
    {
        name: "Kim Welkin",
        title: "Content Writer",
        image: "/team/Kim_Welkin.jpg",
        bio: "Kim is a creative with no prior STEM experience. But, realising how the space sector’s contributions hold the potential to improve life here on Earth, she decided she wants to support the creation of a narrative that will guarantee us a better future. She is particularly interested in feminist space history.",
    },
    {
        name: "Jack Crane",
        title: "Web Developer",
        image: "/team/Jack.jpg",
        bio: "Jack has always wanted to be an astronaut and that developed their love for space and space technology. They are currently a 3rd year Aeronautics student at Imperial and want to continue on to a PhD in electric propulsion.",
    },
    {
        name: "Liam Kerr",
        title: "Web Developer",
        image: "/team/Liam_K.jpeg",
        bio: "Liam is a fourth year Integrated Masters student at the University of St Andrews, studying Computer Science. He is interested in systems engineering, web development, and machine learning, especially in space applications.",
    },
];

const AboutUsPage = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const imageSize = {
        xs: "100px",
        sm: "126px",
    };
    const teamBoxSize = "240px";
    return (
        <PageContainer maxWidth="md">
            <Paper
                sx={{
                    padding: { sm: "20px 50px", xs: "20px 20px" },
                    paddingBottom: 0,
                }}
            >
                <Typography variant="h3" paragraph color="text" textAlign="center" mb={5}>
                    About Us
                </Typography>
                <Box mb={2}>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <Typography
                                variant="body"
                                paragraph
                                color="text"
                                sx={{ whiteSpace: "pre-line" }}
                            >
                                <Trans i18nKey="about.why_body" />
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container mb={5} mt={3} spacing={2}>
                    <Grid item xs={6} md={3}>
                        <Typography variant="h5" color="primary" textAlign="center" paragraph>
                            Connecting
                        </Typography>

                        <Box
                            sx={{
                                backgroundImage: `url(/connecting.png)`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                height: imageSize,
                                width: imageSize,

                                margin: "auto",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="h5" color="primary" textAlign="center" paragraph>
                            Educating
                        </Typography>

                        <Box
                            sx={{
                                backgroundImage: `url(/educating.png)`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                height: imageSize,
                                width: imageSize,

                                margin: "auto",
                                marginTop: "10px",
                                marginBottom: { md: "20px", xs: "30px" },
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="h5" color="primary" textAlign="center">
                            Supporting
                        </Typography>

                        <Box
                            sx={{
                                backgroundImage: `url(/supporting.png)`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                height: imageSize,
                                width: imageSize,

                                margin: "auto",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="h5" color="primary" textAlign="center">
                            Advocating
                        </Typography>

                        <Box
                            sx={{
                                backgroundImage: `url(/advocating.png)`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                height: imageSize,
                                width: imageSize,

                                margin: "auto",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        />
                    </Grid>
                </Grid>
                <Box px={{ md: 4, sm: 0, xs: 2 }}>
                    <Typography
                        variant="h4"
                        paragraph
                        color="primary"
                        textAlign="center"
                        mt={6}
                        mb={3}
                    >
                        Helping you find your place in space
                    </Typography>
                    <Typography
                        variant="body"
                        paragraph
                        color="text"
                        sx={{ whiteSpace: "pre-line" }}
                    >
                        <Trans i18nKey="about.helping_body" />
                    </Typography>
                </Box>
                <Box
                    sx={{
                        backgroundColor: theme.palette.text.primary,
                        "& a": {
                            color: theme.palette.secondary.main,
                        },
                        margin: { sm: "30px -50px", xs: "30px -20px" },
                    }}
                >
                    <Grid container>
                        <Grid item md={6} xs={12} p={{ xs: 5, sm: 6, md: 5 }}>
                            <Typography variant="h4" paragraph color="secondary" textAlign="center">
                                Our Journey
                            </Typography>
                            <Typography
                                variant="body"
                                paragraph
                                color="secondary"
                                sx={{
                                    whiteSpace: "pre-line",
                                }}
                            >
                                <Trans i18nKey="about.journey_body" components={{ a: <a /> }} />
                            </Typography>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <Box
                                sx={{
                                    backgroundImage: `url(/sc-presentation-photo.jpg)`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    height: "100%",
                                    width: "100%",
                                    minHeight: "260px",
                                    backgroundPositionX: { md: "-128px", xs: "unset" },
                                    backgroundPositionY: { md: "center", xs: "center" },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Typography variant="h4" paragraph color="text" textAlign="center" mt={4}>
                    Our Values
                </Typography>
                <Grid container mt={2} px={{ md: 4, xs: 0 }} spacing={5}>
                    <Grid item sm={8} xs={12}>
                        <Typography variant="h5" color="primary" textAlign="left" paragraph>
                            Accessibility
                        </Typography>
                        <Typography variant="body" color="text" textAlign="left" paragraph>
                            {t("about.accessibility_body")}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        sm={4}
                        xs={0}
                        sx={{
                            alignSelf: "center",
                            display: { xs: "none", sm: "block" },
                        }}
                    >
                        <Box
                            sx={{
                                backgroundImage: `url(/accessibility.png)`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPositionY: "center",
                                height: "126px",
                                maxWidth: "126px",

                                margin: "auto",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={4}
                        xs={0}
                        sx={{
                            alignSelf: "center",
                            display: { xs: "none", sm: "block" },
                        }}
                    >
                        <Box
                            sx={{
                                backgroundImage: `url(/openness.png)`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPositionY: "center",
                                height: "100px",
                                maxWidth: "180px",

                                margin: "auto",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        />
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <Typography
                            variant="h5"
                            color="primary"
                            textAlign={{ sm: "right", xs: "left" }}
                            paragraph
                        >
                            Openness and Collaboration
                        </Typography>
                        <Typography
                            variant="body"
                            color="text"
                            textAlign={{ sm: "right", xs: "left" }}
                            paragraph
                        >
                            {t("about.openness_body")}
                        </Typography>
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <Typography variant="h5" color="primary" textAlign="left" paragraph>
                            Commitment and Quality
                        </Typography>
                        <Typography variant="body" color="text" textAlign="left" paragraph>
                            {t("about.commitment_body")}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        sm={4}
                        xs={0}
                        sx={{
                            alignSelf: "center",
                            display: { xs: "none", sm: "block" },
                        }}
                    >
                        <Box
                            sx={{
                                backgroundImage: `url(/quality.png)`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPositionY: "center",
                                height: "116px",
                                maxWidth: "116px",

                                margin: "auto",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        />
                    </Grid>
                </Grid>

                <Box
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        margin: { sm: "20px -50px 0px -50px", xs: "20px -20px 0px -20px" },
                    }}
                    p={5}
                >
                    <Typography variant="h4" paragraph color="secondary" textAlign="center">
                        Our Team
                    </Typography>
                    <Typography
                        variant="body"
                        color="secondary"
                        textAlign="center"
                        paragraph
                        mb={3}
                    >
                        {t("about.team_body")}
                    </Typography>
                    <Divider color="secondary" />
                    <Grid container spacing={2} mt={2}>
                        {team.map((teamMember) => (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                sx={{
                                    display: "flex",
                                    position: "relative",
                                    justifyContent: "center",

                                    "&:hover .MuiBox-root.text": {
                                        height: teamBoxSize,
                                        backgroundColor: "rgba(150,150,150,0.8)",
                                    },
                                    "& .bio": {
                                        display: "none",
                                    },
                                    "&:hover .summary": {
                                        display: "none",
                                    },
                                    "&:hover .bio": {
                                        display: "block",
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundPosition: "center",
                                        backgroundImage: `url(${teamMember.image})`,
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        height: teamBoxSize,
                                        width: teamBoxSize,

                                        margin: "auto",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                    }}
                                />
                                <Box
                                    className="text"
                                    sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        margin: "10px",
                                        width: `calc(${teamBoxSize} - 30px)`,
                                        backgroundColor: "rgba(215,215,215,0.8)",
                                        fontSize: "1.2rem",
                                        padding: "0px 15px",
                                    }}
                                >
                                    <Typography
                                        color={theme.palette.text.primary}
                                        className="summary"
                                    >
                                        {teamMember.name}
                                    </Typography>
                                    <Typography
                                        color={theme.palette.primary.main}
                                        className="summary"
                                    >
                                        {teamMember.title}
                                    </Typography>
                                    <Typography
                                        color={theme.palette.secondary.main}
                                        className="bio"
                                        fontSize="0.75rem"
                                        p={1}
                                    >
                                        {teamMember.bio}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Paper>
        </PageContainer>
    );
};

export default AboutUsPage;
