import React, {useEffect} from "react";
import gsap from "gsap";

const UpcomingAreas = () => {

    useEffect(() => {
        // Select all images inside the #images container
        const image = document.querySelector(".earth");

            gsap.fromTo(
                image,
                {
                    opacity: 0,
                    x: 100,
                },
                {
                    opacity: 1,
                    x: 0,
                    duration: 3,
                    ease: "power2.out",
                    scrollTrigger: {
                        trigger: image,
                        start: "top 100%",
                        end: "top 50%",
                        scrub: true,
                    },
                }
            );
    }, []);


    return (
        <div id="upcomingAreas" className="max-w-7xl lg:pl-20 xl:pl-40 mx-auto p-8">
            <div data-aos={"fade-up"}
                 className="tracking-tight koulen text-[48px] lg:text-[64px] text-landing-yellow ">Upcoming areas
            </div>
            <div className="flex flex-col lg:flex-row gap-6 text-[20px] pt-12">
                <p data-aos={"fade-up"} className="w-full ">
                    Recent technological advancements such as <span className="text-landing-yellow fredoka-bold">AI and quantum computing</span> are
                    already starting to be
                    integrated into satellites to improve performance and are expected to become more important in the
                    future. As larger quantities and more valuable data are being sent via satellites, space
                    organisations are also looking for people with expertise in <span
                    className={"text-landing-yellow fredoka-bold"}>cybersecurity</span>.
                    <br/>
                    <br/>
                    <span className={"text-landing-yellow fredoka-bold"}>Reusable satellite technology</span> will also unlock the ability to manufacture materials and
                    pharmaceuticals in the microgravity and vacuum of space, which makes them more pure and therefore
                    more effective. This could include materials for computer chips, drugs for cancer treatment and 3D
                    printing organs for transplant.
                </p>
                <p data-aos={"fade-up"} className="w-full">
                    With more satellites being launched, the issues of <span className={"text-landing-yellow fredoka-bold"}>space debris</span> and potential collisions are
                    becoming more prevalent. The UK has recently opened a National Space Operations Centre to track
                    space objects in space and multiple UK companies are developing spacecraft to capture and remove old
                    satellites from orbit. Ideas for <span className={"text-landing-yellow fredoka-bold"}>servicing spacecraft</span> and orbital fuel depots are also being
                    developed to fix or refuel satellites in orbit rather than having to launch replacements.
                    <br/>
                    <br/>
                    There will be many challenges to overcome to provide more and continued benefits to people around
                    the world!
                </p>
                <div data-aos={"fade-up"} className=" max-lg:pt-20 w-full flex flex-col items-center gap-32 ">
                    <div className="slow-floating">
                        <img className=" rotate-[72deg] w-[280px] shrink-0" src="landing/Telescope.svg" alt=""/>
                    </div>
                    <img className="earth shrink-0 w-[210px]" src="landing/Earth.svg" alt=""/>
                </div>


            </div>
        </div>
    );
}
export default UpcomingAreas;