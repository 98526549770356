import EmployerCard, { EmployerCardSkeleton } from "./EmployerCard";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterSelect from "../common/FilterSelect";
import Grid from "@mui/material/Grid";
import { CustomInfiniteScroll as InfiniteScroll } from "../common/CustomInfiniteScroll";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axiosInstance from "../axiosInstance";
import { getUserId } from "../auth/functions";

const EmployerListPage = () => {
    const [saved, setSaved] = useState([]);
    const [employers, setEmployers] = useState([]);
    const [search, setSearch] = useState("");
    const [totalItems, setTotalItems] = useState(0);
    // acts as a handle for aborting requests
    const controller = useRef(new AbortController());
    const [loading, setLoading] = useState(true);
    const [initialLoad, setInitialLoad] = useState(true);
    const [employerCountries, setEmployerCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [orgTypes, setOrgTypes] = useState([]);
    const [activities, setActivities] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);

    const [searchOptions, setSearchOptions] = useState([]);

    const [hasMore, setHasMore] = useState(false);
    const updateSavedEmployers = () => {
        const userId = getUserId();
        if (userId) {
            axiosInstance.get(`/api/users/me/saved_employers/`).then((response) => {
                setSaved(response.data.saved_employers);
            });
        }
    };

    const queryString = useMemo(() => {
        // Need to set a higher limit to activate scrolling since the tiles are small
        var queryParts = ["limit=20", "ordering=name"];
        if (search) {
            queryParts.push(`search=${encodeURIComponent(search)}`);
        }
        if (regions) {
            regions.forEach((region) => {
                queryParts.push(`region=${encodeURIComponent(region)}`);
            });
        }
        if (orgTypes) {
            orgTypes.forEach((orgType) => {
                queryParts.push(`org_type=${encodeURIComponent(orgType)}`);
            });
        }
        if (activities) {
            activities.forEach((activity) => {
                queryParts.push(`activity=${encodeURIComponent(activity)}`);
            });
        }
        if (employerCountries) {
            employerCountries.forEach((employerCountry) => {
                queryParts.push(`country=${encodeURIComponent(employerCountry)}`);
            });
        }
        var query = queryParts.join("&");
        if (query) {
            query = "?" + query;
        }
        return query;
    }, [search, regions, orgTypes, activities, employerCountries]);
    const nextData = useRef("/api/employers/" + queryString);

    const fetchMoreData = useCallback(() => {
        setLoading(true);
        axiosInstance
            .get(nextData.current, { signal: controller.current.signal })
            .then((response) => {
                setEmployers((items) => items.concat(response.data.results));
                setTotalItems(response.data.count);

                var next = "";
                if (response.data.next) {
                    let next_queries = response.data.next.split("?");
                    next = next_queries[next_queries.length - 1];
                    nextData.current = "/api/employers/?" + next;
                    setHasMore(true);
                } else {
                    nextData.current = undefined;
                    setHasMore(false);
                }
                setLoading(false);
                setInitialLoad(false);
            });
        updateSavedEmployers();
    }, []);
    useEffect(() => {
        nextData.current = "/api/employers/" + queryString;
        // When the query string changes, the page must be reset
        setHasMore(false);
        setEmployers([]);
        // previous requests must be cancelled and a fresh controller created
        controller.current.abort();
        controller.current = new AbortController();

        fetchMoreData();
    }, [queryString, fetchMoreData]);

    useEffect(() => {
        axiosInstance.get("/api/employers/search_options/").then((response) => {
            setSearchOptions(response.data);
        });

        axiosInstance.get("/api/employers/countries/").then((response) => {
            setCountryOptions(response.data);
        });
        updateSavedEmployers();
    }, []);

    const clearFilters = () => {
        setSearch("");
        setRegions([]);
        setEmployerCountries([]);
        setActivities([]);
        setOrgTypes([]);
    };

    return (
        <PageContainer>
            <Box>
                <Paper sx={{ padding: "20px" }}>
                    <Typography variant="h4" color="primary">
                        Search
                    </Typography>
                    <TextField
                        label="Search employers"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                        color="text"
                        margin="dense"
                        size="small"
                    />
                </Paper>

                <Accordion sx={{ margin: "16px 0px", overflow: "auto" }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">Filter</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingBottom: "5px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FilterSelect
                                    width="100%"
                                    selectedItems={employerCountries}
                                    onChange={setEmployerCountries}
                                    options={countryOptions}
                                    label="Country"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FilterSelect
                                    width="100%"
                                    selectedItems={regions}
                                    onChange={setRegions}
                                    options={searchOptions?.region?.map((c) => [c, c])}
                                    label="Region"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FilterSelect
                                    width="100%"
                                    selectedItems={activities}
                                    onChange={setActivities}
                                    options={searchOptions?.activity?.map((c) => [c, c])}
                                    label="Activity"
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <FilterSelect
                                    width="100%"
                                    selectedItems={orgTypes}
                                    onChange={setOrgTypes}
                                    options={searchOptions?.org_type?.map((c) => [c, c])}
                                    label="Organisation Type"
                                />
                            </Grid>
                        </Grid>
                        <Divider sx={{ marginTop: "10px" }} />
                        <Button sx={{ paddingBottom: 0 }} onClick={clearFilters}>
                            Clear filters
                        </Button>
                    </AccordionDetails>
                </Accordion>
            </Box>

            <Typography
                variant="subtitle"
                color="primary"
                align="center"
                sx={{ display: "block", padding: 0 }}
            >
                {totalItems} employers
            </Typography>
            {(loading && !employers.length && totalItems !== 0) || initialLoad ? (
                <Grid container spacing={2}>
                    {Array.from(Array(16).keys()).map((i) => (
                        <Grid item md={3} sm={4} xs={6} key={i}>
                            <EmployerCardSkeleton />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <InfiniteScroll
                    dataLength={employers.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                >
                    <Grid container spacing={2}>
                        {employers.map((employer) => (
                            <Grid item md={3} sm={4} xs={6} key={employer.id}>
                                <EmployerCard
                                    saved={saved.includes(employer.id)}
                                    employer={employer}
                                    onSavedChange={(savedJobs) => {
                                        updateSavedEmployers();
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </InfiniteScroll>
            )}
        </PageContainer>
    );
};

export default EmployerListPage;
