import Hero from "./Partials/Hero";
import ScrollToTop from "./Partials/ScrollToTop";
import LikeSpace from "./Partials/LikeSpace";
import HowDoWeUseSpace from "./Partials/HowDoWeUseSpace";
import Astronaut from "./Partials/Astronaut";
import ThePeopleBehindTheseActivities from "./Partials/ThePeopleBehindTheseActivities";
import JobOpportunities from "./Partials/JobOpportunities";
import StarfieldBg from "./Partials/StarfieldBg";
import Satellites from "./Partials/Satellites";
import FindYourPlaceInSpace from "./Partials/FindYourPlaceInSpace";
import SolarPanels from "./Partials/SolarPanels";
import Salaries from "./Partials/Salaries";
import AfterSchoolTexts from "./Partials/AfterSchoolTexts";
import SpaceInTheUK from "./Partials/SpaceInTheUK";
import FindOutMore from "./Partials/FindOutMore";
import LandingFooter from "./Partials/LandingFooter";
import UKMap from "./Partials/UKMap";
import React from "react";
import SpacecraftsGSAP from "./Partials/SpacecraftsGSAP";
import EarthMars from "./Partials/EarthMars";
import ShootingStar from "./Partials/ShootingStar";
import SatellitesGSAP from "./Partials/SatellitesGSAP";
import WhoUsesThisPageModal from "./Partials/WhoUsesThisPageModal";
import AfterSchoolGSAP from "./Partials/AfterSchoolGSAP";
import Footer from "../v2/Footer";
import InactivityModal from "./Partials/InactivityModal";
import PathTracker from "./Partials/PathTracker";
import StartYourPath from "./Partials/StartYourPath";

const UkSpace101 = () => {
    const milestones = [
        {name: "Start", href: "#start"},
        {name: "Uses of space", href: "#space"},
        {name: "People", href: "#people"},
        {name: "Jobs", href: "#jobs"},
        {name: "The future", href: "#future"},
        {name: "Career routes", href: "#career"},
        {name: "In the UK", href: "#spaceInTheUk"},
        {name: "More", href: "#more"},
    ];


    return (
        <div
            className=" min-h-screen max-w-screen overflow-none  bg-gradient-to-t from-landing-blue to-landing-dark-blue text-white fredoka">
            <PathTracker milestones={milestones}/>
            <WhoUsesThisPageModal/>
            <Hero/>
            <LikeSpace/>
            <UKMap/>
            <div className="relative h-20 ">
                <ShootingStar className="absolute bottom-40 left-[15%]"/>
            </div>
            <HowDoWeUseSpace/>
            <SpacecraftsGSAP/>
            <Astronaut/>
            <div className="relative h-20 ">
                <ShootingStar className="absolute bottom-40 left-[15%]"/>
            </div>
            <EarthMars/>
            <ThePeopleBehindTheseActivities/>
            <StarfieldBg opacity={0.2}>
                <JobOpportunities/>
            </StarfieldBg>
            <SatellitesGSAP/>
            <div className="relative h-20 ">
                <ShootingStar className="absolute bottom-40 left-[15%]"/>
            </div>
            {/*<StarfieldBg opacity={1}>*/}
            {/*    <SolarPanels/>*/}
            {/*</StarfieldBg>*/}
            <FindYourPlaceInSpace/>
            <Salaries/>
            <AfterSchoolGSAP/>
            <StarfieldBg>
                <div className="relative h-[210px]"></div>
                {/*shooting star*/}
            </StarfieldBg>
            <div className="relative h-20 ">
                <ShootingStar className="absolute bottom-40 left-[15%]"/>
            </div>
            <SpaceInTheUK/>
            <StartYourPath/>
            <FindOutMore floatingText={true}/>
            <Footer bgLanding/>
        </div>
    );
};
export default UkSpace101;
