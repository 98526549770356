import * as React from "react";

import Box from "@mui/material/Box";
import BugReportIcon from "@mui/icons-material/BugReport";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import TabPanel from "../common/TabPanel";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import axiosInstance from "../axiosInstance";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";

const Feedback = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();

    const [title, setTitle] = React.useState("");
    const [body, setBody] = React.useState("");
    const [suggestion, setSuggestion] = React.useState("");
    const [value, setValue] = React.useState(0);
    const [anonymous, setAnonymous] = React.useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const submit = () => {
        if (value === 0) {
            axiosInstance
                .post("/api/feedback/issue/", {
                    title,
                    body,
                    anonymous,
                })
                .then(() => {
                    enqueueSnackbar("Issue submitted", { variant: "success" });
                    setTitle("");
                    setBody("");
                });
        } else if (value === 1) {
            axiosInstance
                .post("/api/feedback/suggestion/", {
                    suggestion,
                    anonymous,
                })
                .then(() => {
                    enqueueSnackbar("Suggestion submitted", { variant: "success" });
                    setSuggestion("");
                });
        }

        handleClose();
    };

    return (
        <React.Fragment>
            {props.textButton ? (
                <Button onClick={handleClickOpen} {...props}>
                    Submit feedback
                </Button>
            ) : (
                <Tooltip title="Report an issue or make a suggestion">
                    <IconButton
                        sx={{ maxHeight: "40px", margin: "auto" }}
                        onClick={handleClickOpen}
                        {...props}
                    >
                        <BugReportIcon sx={{ color: theme.palette.text.primary }} />
                    </IconButton>
                </Tooltip>
            )}
            <Dialog open={open} onClose={handleClose}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Report an issue" />
                        <Tab label="Make a suggestion" />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <DialogTitle>Report an issue</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please describe the issue you are experiencing. Include as much detail
                            as may be necessary to reproduce it. Thank you for your help in
                            improving SpaceCareers.uk!
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="title"
                            label="Title"
                            fullWidth
                            variant="standard"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            id="body"
                            label="Description"
                            fullWidth
                            variant="standard"
                            multiline
                            rows={5}
                            value={body}
                            onChange={(e) => setBody(e.target.value)}
                        />
                    </DialogContent>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <DialogTitle>Make a suggestion</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please provide any feedback or suggestions you have for improving
                            SpaceCareers.uk.
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            id="body"
                            label="Suggestion"
                            fullWidth
                            variant="standard"
                            multiline
                            rows={5}
                            value={suggestion}
                            onChange={(e) => setSuggestion(e.target.value)}
                        />
                    </DialogContent>
                </TabPanel>
                <Stack direction="row" sx={{ padding: "0px 44px" }}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch color="primary" />}
                            label="Report anonymously"
                            checked={anonymous}
                            onChange={(e) => setAnonymous(e.target.checked)}
                        />
                    </FormGroup>
                    <Tooltip
                        title="If you're logged in, submitting will by default
                        include your info so we can follow up with you"
                    >
                        <HelpOutlineIcon fontSize="small" sx={{ alignSelf: "center" }} />
                    </Tooltip>
                </Stack>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={submit}>Submit</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};
export default Feedback;
