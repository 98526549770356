import React, { useEffect, useRef } from "react";
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const LikeSpace = () => {
    const sectionRefs = useRef([]);
    sectionRefs.current = [];

    const addToRefs = (el) => {
        if (el && !sectionRefs.current.includes(el)) {
            sectionRefs.current.push(el);
        }
    };

    useEffect(() => {
        const sections = sectionRefs.current;

        gsap.set(sections, { autoAlpha: 0 });
        gsap.set(sections[0], { autoAlpha: 1 });

        const scrollTrigger = gsap.to(sections, {
            scrollTrigger: {
                trigger: "#space",
                start: "top top",
                end: "+=800",
                scrub: 1,
                pin: true,
                snap: 1 / (sections.length - 1),
                onUpdate: (self) => {
                    const progress = self.progress * (sections.length - 1);
                    sections.forEach((section, index) => {
                        const isActive = Math.abs(index - progress) < 0.5;
                        gsap.to(section, {
                            autoAlpha: isActive ? 1 : 0,
                            duration: 0.5,
                        });
                    });
                },
            }
        });

        return () => {
            scrollTrigger.scrollTrigger.kill();
            gsap.set(sections, { autoAlpha: 1 });
        };
    }, []);

    return (
        <div
            id="space"
            className="relative px-8 mx-auto max-w-5xl flex flex-col justify-center gap-12 max-lg:text-[28px] text-[40px] fredoka-bold min-h-screen py-[15vh] "
        >
            <div ref={addToRefs} className="section">
                <h1 className="md:text-start">Like space?</h1>
            </div>

            <div ref={addToRefs} className="section">
                <h2 className="md:text-center">Want a career with meaning?</h2>
            </div>

            <div ref={addToRefs} className="section">
                <h2 className="md:text-end">The space sector needs you!</h2>
            </div>

            <div ref={addToRefs} className="section">
                <p className="text-start pt-10">
                    How many space companies can you think of?
                </p>
            </div>
        </div>
    );
};

export default LikeSpace;