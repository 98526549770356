import React, { useEffect } from "react";

import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import AlarmIcon from "@mui/icons-material/Alarm";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Box from "@mui/material/Box";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import LockIcon from "@mui/icons-material/Lock";
import PlaceIcon from "@mui/icons-material/Place";
import Rating from "@mui/material/Rating";
import { Link as RouterLink } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import axiosInstance from "../axiosInstance";
import { parse } from "date-fns";
import { useTheme } from "@mui/material/styles";

var pluralize = require("pluralize");

export const JobCardSkeletons = ({ count = 5 }) => {
    return (
        <Grid container spacing={2}>
            {Array.from({ length: count }).map(() => (
                <Grid item xs={12} sx={{ paddingLeft: "36px" }}>
                    <Skeleton variant="rectangular" height={196} />
                </Grid>
            ))}
        </Grid>
    );
};

const EllipsisOverflowTypography = (props) => {
    return (
        <Typography {...props} sx={{ textOverflow: "ellipsis", overflow: "hidden" }}></Typography>
    );
};

const JobCard = ({ job, canSave = false, saved = false, onSavedChange = () => {} }) => {
    const theme = useTheme();
    useEffect(() => {
        if (!job?.expired && !job?.private) {
            window.posthog.capture("Saw job card", {
                jobId: job?.id,
                employerId: job?.employer?.id,
            });
        }
    }, []);
    const pluralizeOrOmit = (noun, count, addTrailingSpace = false) => {
        if (!count) {
            return "";
        } else {
            return pluralize(noun, count, true) + (addTrailingSpace ? " " : "");
        }
    };
    return (
        <Card
            sx={{
                display: "flex",
                border: job?.private ? `3px solid ${theme.palette.highlight.main}` : "",
                borderRadius: "20px",
            }}
            color={job?.expired ? "disabled" : "primary"}
        >
            <Stack sx={{ position: "absolute", zIndex: 1 }} direction="row" ml={1} mt={1}>
                {job?.expired ? (
                    <Tooltip title="The deadline for this job has passed">
                        <AccessTimeFilledIcon
                            sx={{
                                backgroundColor: "white",
                                borderRadius: "50%",
                                padding: "2px",
                                border: `1px ${theme.palette.text.primary} solid`,
                            }}
                        />
                    </Tooltip>
                ) : null}
                {job?.private ? (
                    <Tooltip title="The job is private - candidates can't see it">
                        <LockIcon
                            sx={{
                                backgroundColor: "white",
                                borderRadius: "50%",
                                color: theme.palette.text.primary,
                                padding: "2px",
                                border: `1px ${theme.palette.text.primary} solid`,
                            }}
                        />
                    </Tooltip>
                ) : null}
                {job?.paid ? (
                    <Tooltip title="Promoted">
                        <WorkspacePremiumIcon
                            sx={{
                                backgroundColor: "white",
                                borderRadius: "50%",
                                color: theme.palette.text.primary,
                                padding: "2px",
                                border: `1px ${theme.palette.text.primary} solid`,
                            }}
                        />
                    </Tooltip>
                ) : null}
            </Stack>
            <Grid container>
                <Grid
                    item
                    lg={4}
                    md={12}
                    sx={{
                        overflow: "hidden",
                        width: "100%",
                        padding: "10px",
                    }}
                >
                    <RouterLink to={`/jobs/${job?.id}`} style={{ height: "100%", display: "flex" }}>
                        <CardMedia
                            component="img"
                            sx={{
                                maxHeight: 180,
                                height: "100%",
                                margin: "auto",
                                objectFit: "scale-down",
                                filter: job?.expired ? "saturate(0.2)" : undefined,
                                backgroundColor: "white",
                                borderRadius: "10px",
                            }}
                            image={job?.employer?.logo}
                        />
                    </RouterLink>
                </Grid>
                <Grid item lg={8} md={12}>
                    <Box>
                        <CardContent>
                            <Stack spacing={0.5}>
                                <Grid container>
                                    <Grid item xs={11}>
                                        <Stack>
                                            <Link
                                                underline="hover"
                                                color="secondary"
                                                variant="h6"
                                                component={RouterLink}
                                                to={`/jobs/${job?.id}`}
                                            >
                                                {job?.title}
                                            </Link>
                                            <Link
                                                underline="hover"
                                                color="secondary"
                                                variant="subtitle1"
                                                component={RouterLink}
                                                to={`/employers/${job?.employer?.id}`}
                                            >
                                                {job?.employer?.name}
                                            </Link>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={1}>
                                        {canSave ? (
                                            <Tooltip title="Save">
                                                <Rating
                                                    icon={<StarRoundedIcon fontSize="inherit" />}
                                                    emptyIcon={
                                                        <StarBorderRoundedIcon
                                                            fontSize="inherit"
                                                            color="secondary"
                                                        />
                                                    }
                                                    sx={{ float: "right" }}
                                                    value={saved ? 1 : 0}
                                                    max={1}
                                                    onChange={(e, value) => {
                                                        if (value === 1) {
                                                            axiosInstance
                                                                .post(`/api/jobs/${job?.id}/save/`)
                                                                .then((response) => {
                                                                    onSavedChange(
                                                                        response.data.saved_jobs
                                                                    );
                                                                });
                                                        } else {
                                                            axiosInstance
                                                                .post(
                                                                    `/api/jobs/${job?.id}/unsave/`
                                                                )
                                                                .then((response) => {
                                                                    onSavedChange(
                                                                        response.data.saved_jobs
                                                                    );
                                                                });
                                                        }
                                                    }}
                                                />
                                            </Tooltip>
                                        ) : null}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item md={5} sm={12}>
                                        <Stack direction="row" spacing={1}>
                                            <Tooltip title="Job Type">
                                                <AssignmentIcon color="secondary" />
                                            </Tooltip>
                                            <EllipsisOverflowTypography color="secondary">
                                                {job?.job_type || "-"}
                                            </EllipsisOverflowTypography>
                                        </Stack>
                                    </Grid>
                                    <Grid item md={7} sm={12}>
                                        <Stack direction="row" spacing={1}>
                                            <Tooltip title="Job Location">
                                                <PlaceIcon color="secondary" />
                                            </Tooltip>
                                            <EllipsisOverflowTypography color="secondary">
                                                {job?.city}, {job?.country_display}
                                            </EllipsisOverflowTypography>
                                        </Stack>
                                    </Grid>
                                    <Grid item md={5} sm={12}>
                                        <Stack direction="row" spacing={1}>
                                            <Tooltip title="Job Duration">
                                                <CalendarMonthIcon color="secondary" />
                                            </Tooltip>
                                            <Typography color="secondary">
                                                {job?.duration?.permanent
                                                    ? "Permanent"
                                                    : pluralizeOrOmit(
                                                          "year",
                                                          job?.duration?.years,
                                                          true
                                                      ) +
                                                      " " +
                                                      pluralizeOrOmit(
                                                          "month",
                                                          job?.duration?.months,
                                                          true
                                                      ) +
                                                      " " +
                                                      pluralizeOrOmit("week", job?.duration?.weeks)}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item md={7} sm={12}>
                                        <Stack direction="row" spacing={1}>
                                            <Tooltip title="Application Deadline">
                                                <AlarmIcon color="secondary" />
                                            </Tooltip>
                                            <Typography color="secondary">
                                                {job?.rolling_deadline
                                                    ? "Rolling deadline"
                                                    : parse(
                                                          job?.application_deadline,
                                                          "dd-MM-yyyy",
                                                          new Date()
                                                      )
                                                          .toDateString()
                                                          .slice(4)}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </CardContent>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
};

export default JobCard;
