import {useEffect, useState} from "react";
import Cookies from 'js-cookie';
import axiosInstance from "../../axiosInstance";

const WhoUsesThisPageModal = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [visitor, setVisitor] = useState(null);
    useEffect(() => {
        // Retrieve cookie
        const visitorInfo = Cookies.get('spacecareers-visitor');

        if (!visitorInfo) {
            setModalOpen(true); // Open modal if no cookie
        } else {
            setModalOpen(false);
        }
    }, []);

    useEffect(() => {
        if (!visitor) return;
        const visitorId = Cookies.get('spacecareers-visitor-id');
        if (visitorId) {
            axiosInstance
                .put(`/api/survey101/${visitorId}/`, {
                    response: visitor,
                })
                .then((response) => {
                    Cookies.set('spacecareers-visitor', visitor);
                    setModalOpen(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            axiosInstance
                .post(`/api/survey101/`, {
                    response: visitor,
                })
                .then((response) => {
                    Cookies.set('spacecareers-visitor', visitor);
                    Cookies.set('spacecareers-visitor-id', response.data.id);
                    setModalOpen(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [visitor]);

    const closeModal = () => {
        setModalOpen(false);
    }


    return (
        <>
            {modalOpen && (
                <div
                    className="p-8 z-[100] modal-wrapper fixed w-screen backdrop-blur-sm h-screen bg-black/50 bg-blur flex items-center justify-center">
                    <div
                        className="rounded-lg bg-landing-yellow max-w-[530px] p-8 lg:p-12 py-12 flex flex-col gap-8 text-landing-blue relative">
                        <div onClick={closeModal} className="absolute cursor-pointer top-4 right-4">
                            <img src="landing/x-mark.svg" alt="close"/>
                        </div>
                        <h1 className="text-[24px] font-bold text-center ">Help us understand who’s using <br
                            className={"hidden lg:block"}/>
                            our page</h1>
                        <div className="flex max-lg:flex-col max-lg:justify-center gap-4 max-lg:items-center ">
                            <p className="w-fit lg:w-1/4  text-[16px] lg:text-end">I am...</p>
                            <div className="w-full max-lg:justify-center flex gap-2 flex-wrap">
                                <button
                                    onClick={() => setVisitor('Age 5-10')}
                                    className={`${visitor === 'Age 5-10' ? 'bg-landing-blue text-landing-yellow' : ''} hover:bg-landing-blue hover:text-landing-yellow border border-landing-blue rounded-lg py-1 px-3`}
                                >Age 5-10
                                </button>
                                <button
                                    onClick={() => setVisitor('Age 11-16')}
                                    className={`${visitor === 'Age 11-16' ? 'bg-landing-blue text-landing-yellow' : ''} hover:bg-landing-blue hover:text-landing-yellow border border-landing-blue rounded-lg py-1 px-3`}
                                >Age 11-16
                                </button>
                                <button
                                    onClick={() => setVisitor('Age 17-18')}
                                    className={`${visitor === 'Age 17-18' ? 'bg-landing-blue text-landing-yellow' : ''} hover:bg-landing-blue hover:text-landing-yellow border border-landing-blue rounded-lg py-1 px-3`}
                                >Age 17-18
                                </button>
                                <button
                                    onClick={() => setVisitor('A teacher')}
                                    className={`${visitor === 'A teacher' ? 'bg-landing-blue text-landing-yellow' : ''} hover:bg-landing-blue hover:text-landing-yellow border border-landing-blue rounded-lg py-1 px-3`}
                                >A teacher
                                </button>
                                <button
                                    onClick={() => setVisitor('A university student')}
                                    className={`${visitor === 'A university student' ? 'bg-landing-blue text-landing-yellow' : ''} hover:bg-landing-blue hover:text-landing-yellow border border-landing-blue rounded-lg py-1 px-3`}
                                >A university student
                                </button>
                                <button
                                    onClick={() => setVisitor('Interested in changing career')}
                                    className={`${visitor === 'Interested in changing career' ? 'bg-landing-blue text-landing-yellow' : ''} hover:bg-landing-blue hover:text-landing-yellow border border-landing-blue rounded-lg py-1 px-3`}
                                >Interested in changing career
                                </button>
                                <button
                                    onClick={() => setVisitor('Other - generally interested in space')}
                                    className={`${visitor === 'Other - generally interested in space' ? 'bg-landing-blue text-landing-yellow' : ''} hover:bg-landing-blue hover:text-landing-yellow border border-landing-blue rounded-lg py-1 px-3`}
                                >Other - generally interested in space
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            )}
        </>
    )
}

export default WhoUsesThisPageModal;