import React, { useCallback, useEffect, useMemo, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DoneIcon from "@mui/icons-material/Done";
import Tab from "@mui/material/Tab";
import TabPanel from "../common/TabPanel";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import axiosInstance from "../axiosInstance";
import { useSnackbar } from "notistack";

const renderBooleanField = (params) =>
    params.value ? <DoneIcon color="success" /> : <CloseIcon sx={{ color: "red" }} />;

const renderBooleanFieldInverted = (params) =>
    !params.value ? <DoneIcon color="success" /> : <CloseIcon sx={{ color: "red" }} />;

const columns = [
    {
        field: "id",
        headerName: "Job Link",
        width: 150,
        sortable: false,
        renderCell: (params) => (
            <strong>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 16 }}
                    href={`/jobs/${params.value}`}
                >
                    Go to job
                </Button>
            </strong>
        ),
    },
    {
        field: "title",
        headerName: "Job Title",
        width: 200,
        sortable: true,
    },
    {
        field: "job_type",
        headerName: "Type",
        width: 150,
        sortable: true,
    },
    {
        field: "application_deadline",
        headerName: "Application Deadline",
        width: 200,
        sortable: true,
    },
    {
        field: "private",
        headerName: "Public",
        width: 100,
        sortable: false,
        renderCell: renderBooleanFieldInverted,
    },
    {
        field: "expired",
        headerName: "Expired",
        width: 100,
        sortable: false,
        renderCell: renderBooleanField,
    },
    {
        field: "paid",
        headerName: "Premium",
        width: 100,
        sortable: false,
        renderCell: renderBooleanField,
    },
    {
        field: "candidate_matches",
        headerName: "Number of candidate matches",
        width: 200,
        sortable: false,
    },
    {
        field: "emails_sent",
        headerName: "Job pushed to candidates?",
        width: 200,
        sortable: false,
        renderCell: renderBooleanField,
    },
];

const PushToCandidatesButton = ({ selectedJobs, data, refreshData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [loadingDryRun, setLoadingDryRun] = useState(true);
    const [dryRunCount, setDryRunCount] = useState(0);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const canPushJobs = useMemo(
        () =>
            !(
                selectedJobs.length === 0 ||
                selectedJobs.some((job_id) => {
                    const job = data.results.filter((r) => r.id === job_id)[0];
                    return job.private || job.expired || job.emails_sent > 0 || !job.paid;
                })
            ),
        [selectedJobs]
    );

    useEffect(() => {
        if (open) {
            setLoadingDryRun(true);
            axiosInstance
                .post(`/api/users/me/email_matching_candidates/`, {
                    jobs: selectedJobs,
                    dry_run: true,
                })
                .then((response) => {
                    setDryRunCount(response.data.email_count);
                    setLoadingDryRun(false);
                })
                .catch((err) => {
                    enqueueSnackbar(err.response.data, { variant: "error" });
                    handleClose();
                });
        }
    }, [open]);

    const sendEmails = useCallback(() => {
        axiosInstance
            .post(`/api/users/me/email_matching_candidates/`, {
                jobs: selectedJobs,
            })
            .then((response) => {
                let emailsSent = response.data.email_count;
                enqueueSnackbar(emailsSent + " emails sent successfully.", {
                    variant: "success",
                });
                handleClose();
                refreshData();
            })
            .catch((err) => {
                enqueueSnackbar(err.response.data, { variant: "error" });
                handleClose();
            });
    }, [selectedJobs]);

    return (
        <>
            <Tooltip
                title={
                    canPushJobs ? "" : "Jobs can only be pushed once and each job must be premium"
                }
            >
                <span>
                    <Button
                        variant="contained"
                        sx={{ marginBottom: "20px", marginLeft: "20px" }}
                        onClick={handleClickOpen}
                        disabled={!canPushJobs}
                    >
                        Push job to candidates
                    </Button>
                </span>
            </Tooltip>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{"Email candidates"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This will send emails to the{" "}
                        {loadingDryRun ? <CircularProgress size={16} /> : dryRunCount} matching
                        candidates. Note that you may only send emails once per job.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={sendEmails} disabled={loadingDryRun || dryRunCount === 0}>
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const JobAdminDataGrid = ({ employerId, additionQueryParam = "", canBuy = false }) => {
    const [data, setData] = React.useState({});
    const pageSize = 10;
    const [pageNumber, setPageNumber] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [ordering, setOrdering] = React.useState(undefined);

    const refreshData = useCallback(() => {
        setLoading(true);
        const order = ordering ? `&ordering=${ordering}` : "";
        axiosInstance
            .get(
                `/api/jobs/?employer=${employerId}&limit=${pageSize}&offset=${
                    pageNumber * pageSize
                }${additionQueryParam ? `&${additionQueryParam}${order}` : ""}`
            )
            .then((response) => {
                setData(response.data);
                setLoading(false);
            });
    }, [pageNumber, employerId, pageSize, additionQueryParam, ordering]);

    useEffect(() => {
        refreshData();
    }, [pageNumber, ordering]);

    useEffect(() => {
        setSelectionModel([]);
    }, [data, pageNumber]);

    const handleSortModelChange = useCallback((sortModel) => {
        let orderField = sortModel[0].field;
        if (orderField === "job_type") {
            orderField = "job_type__name";
        }
        if (sortModel[0].sort === "desc") {
            orderField = "-" + orderField;
        }
        setOrdering(orderField);
    }, []);

    const canBuyJobs = useMemo(
        () =>
            !(
                selectionModel.length === 0 ||
                selectionModel.some((job_id) => data.results.filter((r) => r.id === job_id)[0].paid)
            ),
        [selectionModel, data]
    );

    return (
        <div style={{ height: "910px", maxHeight: "calc(100vh - 200px)", width: "100%" }}>
            {canBuy ? (
                <>
                    <Tooltip
                        title={
                            canBuyJobs
                                ? ""
                                : "Select one or more non-premium jobs to upgrade them to premium"
                        }
                    >
                        <span>
                            <Button
                                variant="contained"
                                sx={{ marginBottom: "20px" }}
                                onClick={() => {
                                    axiosInstance
                                        .post(`/api/stripe/create-checkout-session/job/`, {
                                            jobs: selectionModel,
                                        })
                                        .then((response) => {
                                            window.location = response.data.url;
                                        });
                                }}
                                disabled={!canBuyJobs}
                            >
                                Buy
                            </Button>
                        </span>
                    </Tooltip>
                    <PushToCandidatesButton
                        refreshData={refreshData}
                        selectedJobs={selectionModel}
                        data={data}
                    />
                </>
            ) : null}
            <DataGrid
                color="primary"
                rows={data.results}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[pageSize]}
                checkboxSelection
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                }}
                // autoHeight
                selectionModel={selectionModel}
                disableSelectionOnClick
                disableColumnFilter
                paginationMode="server"
                filterMode="server"
                rowCount={data.count}
                onPageChange={setPageNumber}
                loading={loading}
                rowHeight={80}
                sx={{
                    "& .MuiDataGrid-cell": {
                        whiteSpace: "pre-wrap",
                    },
                }}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
            />
        </div>
    );
};

const JobAdminPanel = ({ employer, ...props }) => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <TabPanel {...props}>
            <Button
                variant="contained"
                startIcon={<AddIcon />}
                sx={{ float: "right", "& .MuiButton-startIcon": { paddingBottom: "3px" } }}
                href="/jobs/new"
            >
                Post a job
            </Button>
            <Tabs value={value} onChange={handleChange}>
                <Tab label="Active jobs" />
                <Tab label="All jobs" />
            </Tabs>

            <TabPanel value={value} index={0}>
                <JobAdminDataGrid
                    employerId={employer.id}
                    additionQueryParam="include_expired=false&include_private=false"
                    canBuy
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <JobAdminDataGrid employerId={employer.id} />
            </TabPanel>
        </TabPanel>
    );
};

export default JobAdminPanel;
