import React, { useEffect, useState } from "react";

import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { DataGrid } from "@mui/x-data-grid";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { ResponsiveChoropleth } from "@nivo/geo";
import Typography from "@mui/material/Typography";
import axiosInstance from "../axiosInstance";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

const columns = [
    {
        field: "id",
        headerName: "Job Link",
        width: 150,
        renderCell: (params) => (
            <strong>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 16 }}
                    href={`/jobs/${params.value}`}
                >
                    Go to job
                </Button>
            </strong>
        ),
    },
    {
        field: "title",
        headerName: "Job Title",
        width: 200,
    },
    {
        field: "impressions",
        headerName: "Impressions",
        width: 150,
    },
    {
        field: "views",
        headerName: "Views",
        width: 150,
    },
    {
        field: "ctr",
        headerName: "CTR*",
        width: 150,
        valueFormatter: (params) => {
            return `${params.value?.toPrecision(2)}%`;
        },
    },
    {
        field: "posted_date",
        headerName: "Posted Date",
        width: 150,
    },
];

const Analytics = ({ employer }) => {
    const theme = useTheme();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [features, setFeatures] = useState(null);
    useEffect(() => {
        if (employer?.premium) {
            setLoading(true);
            axiosInstance
                .get(`/api/employers/${employer.id}/analytics/`)
                .then((response) => {
                    const responseData = response.data;
                    setData(responseData);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, [employer]);

    const downloadAnalytics = () => {
        axiosInstance
            .get(`/api/employers/${employer.id}/analytics/?csv=true`, {
                responseType: "blob",
            })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                const contentDisposition = res.headers["content-disposition"];
                const filename = contentDisposition.split("filename=")[1];
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
            });
    };

    return (
        <div style={{position:'relative'}}>
            {employer?.premium ? undefined : (
                <Typography 
                    variant="h4" 
                    color="#283e72" 
                    textAlign={'center'}
                    sx={{
                        position: 'absolute',
                        top: '150px',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                }}>
                    Upgrade to premium subscription to see job analytics
                </Typography>
            )}
            <div
                style={{
                    minHeight: 400,
                    width: "100%",
                    filter: employer?.premium ? undefined : "blur(10px)",
                }}
                >
                <Button variant="contained" onClick={downloadAnalytics} sx={{ float: "right" }}>
                    Download analytics CSV
                </Button>
                <Typography sx={{ marginBottom: "20px" }}>
                    For more detailed analytics, contact us at team@spacecareers.uk
                </Typography>
                <Grid container spacing={2} sx={{ paddingBottom: "15px", textAlign: "center" }}>
                    <Grid item md={3} xs={12} sm={6}>
                        <Card sx={{ padding: "10px" }}>
                            <Typography variant="h5">Active Jobs</Typography>
                            <Typography variant="h6">{data?.active_jobs}</Typography>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12} sm={6}>
                        <Card sx={{ padding: "10px" }}>
                            <Typography variant="h5">Page Views Today</Typography>
                            <Typography variant="h6">{data?.page_views_today}</Typography>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12} sm={6}>
                        <Card sx={{ padding: "10px" }}>
                            <Typography variant="h5">Impressions Today</Typography>
                            <Typography variant="h6">{data?.card_views_today}</Typography>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12} sm={6}>
                        <Card sx={{ padding: "10px" }}>
                            <Typography variant="h5">Total page views</Typography>
                            <Typography variant="h6">{data?.page_views_total}</Typography>
                        </Card>
                    </Grid>
                </Grid>
                <DataGrid
                    color="primary"
                    rows={data?.per_job_stats}
                    columns={columns}
                    autoHeight
                    disableColumnFilter
                    loading={loading}
                    rowHeight={80}
                    sx={{
                        "& .MuiDataGrid-cell": {
                            whiteSpace: "pre-wrap",
                        },
                    }}
                    pagination
                    pageSize={10}
                    />
                <Typography variant="caption">
                    * CTR (Click-through rate) = page views/clicks on 'apply' link
                </Typography>

                <Typography variant="h4" my={2}>
                    Page views by country
                </Typography>

                <DataGrid
                    color="primary"
                    rows={data?.page_views_by_country.filter((row) => row.id !== null)}
                    columns={[
                        {
                            field: "value",
                            headerName: "Page views",
                            width: 200,
                        },
                        {
                            field: "id",
                            headerName: "Country Code",
                            width: 200,
                        },
                    ]}
                    autoHeight
                    loading={loading}
                    rowHeight={80}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "value", sort: "desc" }],
                        },
                    }}
                    />
            </div>
        </div>
    );
};
export default Analytics;
