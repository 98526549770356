import React, { useEffect, useMemo, useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { ResponsiveCalendar } from "@nivo/calendar";
import { ResponsiveTimeRange } from "@nivo/calendar";
import Typography from "@mui/material/Typography";
import axiosInstance from "../axiosInstance";
import { parse } from "date-fns";

const Analytics = ({ article }) => {
    const [data, setData] = useState(null);
    useEffect(() => {
        axiosInstance.get(`/api/articles/${article.id}/analytics/`).then((response) => {
            setData(response.data);
        });
    }, []);
    const postedOverAYearAgo = useMemo(() => {
        return (
            new Date() - parse(article?.posted_date, "dd-MM-yyyy", new Date()) >
            365 * 1000 * 60 * 60 * 24
        );
    }, [article]);

    const today = new Date();
    return (
        <div>
            {data ? (
                <Container disableGutters sx={{ padding: "10px" }}>
                    <Typography>{data.page_views} people visited your article page</Typography>

                    <Typography>Unique page views per day</Typography>
                    <div
                        style={{ height: postedOverAYearAgo ? "400px" : "1000px" }}
                        className="calendar-container"
                    >
                        {postedOverAYearAgo ? (
                            <ResponsiveCalendar
                                data={data.per_day}
                                // Set from to one day before article posting
                                // to include the posting date
                                from={new Date(
                                    parse(article?.posted_date, "dd-MM-yyyy", new Date()) -
                                        24 * 60 * 60 * 1000
                                )
                                    .toISOString()
                                    .substring(0, 10)}
                                to={today.toISOString().substring(0, 10)}
                                emptyColor="#eeeeee"
                                colors={["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"]}
                                margin={{ top: 80, right: 10, bottom: 10, left: 40 }}
                                weekdayLegendOffset={10}
                                monthBorderColor="#ffffff"
                                dayBorderWidth={2}
                                dayBorderColor="#ffffff"
                            />
                        ) : (
                            <ResponsiveTimeRange
                                direction="vertical"
                                data={data.per_day}
                                // Set from to one day before article posting
                                // to include the posting date
                                from={new Date(
                                    parse(article?.posted_date, "dd-MM-yyyy", new Date()) -
                                        24 * 60 * 60 * 1000
                                )
                                    .toISOString()
                                    .substring(0, 10)}
                                to={today.toISOString().substring(0, 10)}
                                square={false}
                                emptyColor="#eeeeee"
                                colors={["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"]}
                                margin={{ top: 80, right: 10, bottom: 10, left: 40 }}
                                weekdayLegendOffset={10}
                            />
                        )}
                    </div>
                </Container>
            ) : (
                <CircularProgress />
            )}
        </div>
    );
};

export default Analytics;
