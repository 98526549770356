import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";

import Button from "@mui/material/Button";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axiosInstance from "../axiosInstance";

const PasswordResetPage = (props) => {
    let params = useParams();
    let navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [rePassword, setRePassword] = useState("");
    const [errors, setErrors] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        axiosInstance
            .post("/api/auth/users/reset_password_confirm/", {
                uid: params.uid,
                token: params.token,
                new_password: password,
                re_new_password: rePassword,
            })
            .then((response) => {
                navigate("/login");
            })
            .catch((error) => {
                setErrors(error.response.data);
            });
    };

    return (
        <PageContainer maxWidth="sm">
            <Paper>
                <Typography variant="h4" paragraph>
                    Reset password
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={1}>
                        <TextField
                            size="small"
                            error={errors.new_password}
                            helperText={errors.new_password}
                            type="password"
                            placeholder="Password"
                            fullWidth
                            name="password"
                            variant="outlined"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            required
                        />
                        <TextField
                            size="small"
                            error={errors.new_re_password}
                            helperText={errors.new_re_password}
                            type="password"
                            placeholder="Retype password"
                            fullWidth
                            name="rePassword"
                            variant="outlined"
                            value={rePassword}
                            onChange={(event) => setRePassword(event.target.value)}
                            required
                        />
                        <Button variant="contained" color="primary" type="submit">
                            Submit
                        </Button>
                    </Stack>
                </form>
                <Link to="/signup" color="primary">
                    Haven't signed up?
                </Link>
            </Paper>
        </PageContainer>
    );
};
export default PasswordResetPage;
