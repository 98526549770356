import JobCard, { JobCardSkeletons } from "./JobCard";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getAccountType, getUserId, isLoggedIn } from "../auth/functions";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fab from "@mui/material/Fab";
import Feedback from "../common/Feedback";
import FilterSelect from "../common/FilterSelect";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import { CustomInfiniteScroll as InfiniteScroll } from "../common/CustomInfiniteScroll";
import Link from "@mui/material/Link";
import Map from "../common/Map";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import { Link as RouterLink } from "react-router-dom";
import SideCard from "../common/SideCard";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import axios from "axios";
import axiosInstance from "../axiosInstance";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSearchParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const JobSearchPage = ({ user }) => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
    let [searchParams, setSearchParams] = useSearchParams();
    const [saved, setSaved] = useState([]);

    const search = searchParams.get("search");
    const onSiteRemote = searchParams.getAll("on_site_remote");
    const jobTypes = searchParams.getAll("job_type");
    const regions = searchParams.getAll("region");
    const jobCountries = searchParams.getAll("country");
    const employers = searchParams.getAll("employer");
    const primaryCategories = searchParams.getAll("primary_category");
    const secondaryCategories = searchParams.getAll("category");
    const includeExpired = searchParams.get("include_expired");
    const partTimeOnly = searchParams.get("part_time_only");
    const earlyCareers = searchParams.get("early_careers");
    const orderField = searchParams.get("ordering");

    const [canAddJob, setCanAddJob] = useState(false);
    const [loading, setLoading] = useState(true);
    const [initialLoad, setInitialLoad] = useState(true);

    const [onSiteRemoteOptions, setOnSiteRemoteOptions] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [employerOptions, setEmployerOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [jobTypeOptions, setJobTypeOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);

    const searchParamsObject = useMemo(() => {
        const result = {};

        result["search"] = searchParams.get("search") || "";
        result["on_site_remote"] = searchParams.getAll("on_site_remote");
        result["job_type"] = searchParams.getAll("job_type");
        result["region"] = searchParams.getAll("region");
        result["country"] = searchParams.getAll("country");
        result["employer"] = searchParams.getAll("employer");
        result["primary_category"] = searchParams.getAll("primary_category");
        result["category"] = searchParams.getAll("category");
        result["include_expired"] = searchParams.get("include_expired") || false;
        result["part_time_only"] = searchParams.get("part_time_only") || false;
        result["early_careers"] = searchParams.get("early_careers") || true;
        result["ordering"] = searchParams.get("ordering") || "-posted_date";

        return result;
    }, [searchParams]);

    const updateSearchOptions = useCallback(
        (key, value) => {
            setSearchParams(
                {
                    ...searchParamsObject,
                    [key]: value,
                },
                { replace: true }
            );
        },
        [setSearchParams, searchParamsObject]
    );

    const queryString = useMemo(() => {
        var queryParts = [];
        if (search) {
            queryParts.push(`search=${encodeURIComponent(search)}`);
        }
        if (jobTypes) {
            jobTypes.forEach((jobType) => {
                queryParts.push(`job_type=${encodeURIComponent(jobType)}`);
            });
        }
        if (regions) {
            regions.forEach((region) => {
                queryParts.push(`region=${encodeURIComponent(region)}`);
            });
        }
        if (onSiteRemote) {
            onSiteRemote.forEach((onSiteRemoteChoice) => {
                queryParts.push(`on_site_remote=${encodeURIComponent(onSiteRemoteChoice)}`);
            });
        }
        if (jobCountries) {
            jobCountries.forEach((jobCountry) => {
                queryParts.push(`country=${encodeURIComponent(jobCountry)}`);
            });
        }
        if (primaryCategories) {
            primaryCategories.forEach((category) => {
                queryParts.push(`primary_category=${encodeURIComponent(category)}`);
            });
        }
        if (secondaryCategories) {
            secondaryCategories.forEach((category) => {
                queryParts.push(`category=${encodeURIComponent(category)}`);
            });
        }
        if (employers) {
            employers.forEach((employer) => {
                queryParts.push(`employer=${encodeURIComponent(employer)}`);
            });
        }
        if (!(includeExpired === "true")) {
            queryParts.push(`include_expired=false`);
        }
        if (partTimeOnly === "true") {
            queryParts.push(`part_time_only=true`);
        }
        if (earlyCareers) {
            queryParts.push(`early_careers=${earlyCareers}`);
        } else {
            queryParts.push(`early_careers=true`);
        }

        let ordering = "ordering=-job_is_premium";
        if (orderField) {
            queryParts.push(
                orderField === "application_deadline"
                    ? `${ordering},rolling_deadline,${orderField}`
                    : `${ordering},${orderField}`
            );
        } else {
            queryParts.push(`${ordering},-posted_date`);
        }

        var query = queryParts.join("&");
        if (query) {
            query = "?" + query;
        }
        return query;
    }, [
        search,
        jobTypes,
        regions,
        jobCountries,
        employers,
        includeExpired,
        primaryCategories,
        secondaryCategories,
        onSiteRemote,
        earlyCareers,
        orderField,
        partTimeOnly,
    ]);
    // acts as a handle for aborting requests
    const controller = useRef(new AbortController());

    const [jobs, setJobs] = useState([]);
    // nextData can't be state otherwise it can instantly trigger a load when it's changed
    const nextData = useRef("/api/jobs/" + queryString);
    // must create a specific hasMore state to reflect if nextData is not undefined
    const [hasMore, setHasMore] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    useEffect(() => {
        axiosInstance
            .get(`/api/users/me/has_perm/`, { params: { perm: "web.add_job" } })
            .then((response) => {
                setCanAddJob(true);
            });
    }, []);
    const savedEmployers = useMemo(() => {
        if (user) {
            return user?.saved_employers?.map((employer) => employer.id);
        }
        return [];
    }, [user]);

    const fetchMoreData = useCallback(() => {
        setLoading(true);
        axiosInstance
            .get(nextData.current, { signal: controller.current.signal })
            .then((response) => {
                setJobs((items) => items.concat(response.data.results));
                setTotalItems(response.data.count);

                var next = "";
                if (response.data.next) {
                    let next_queries = response.data.next.split("?");
                    next = next_queries[next_queries.length - 1];
                    nextData.current = "/api/jobs/?" + next;
                    setHasMore(true);
                } else {
                    nextData.current = undefined;
                    setHasMore(false);
                }
                setLoading(false);
                setInitialLoad(false);
            })
            .catch((error) => {
                // Handle cancellation
                if (!axios.isCancel(error)) {
                    console.log("Error", error);
                }
            });
    }, []);
    useEffect(() => {
        nextData.current = "/api/jobs/" + queryString;
        // When the query string changes, the page must be reset
        setHasMore(false);
        setJobs([]);
        // previous requests must be cancelled and a fresh controller created
        controller.current.abort();
        controller.current = new AbortController();

        fetchMoreData();
    }, [queryString, fetchMoreData]);

    useEffect(() => {
        axiosInstance.get("/api/jobs/countries/").then((response) => {
            setCountryOptions(response.data.map(({country_code, country, has_jobs}) => [
                country_code, country, has_jobs
            ]));
        });
        axiosInstance.get("/api/jobs/types/").then((response) => {
            setJobTypeOptions(response.data.map(({job_type_id, job_type_name, has_jobs}) => [
                job_type_id, job_type_name, has_jobs
            ]));
        });
        axiosInstance.get("/api/jobs/regions/").then((response) => {
            setRegionOptions(response.data.map(({region_id, region_name, has_jobs}) => [
                region_id, region_name, has_jobs
            ]));
        });
        axiosInstance.get("/api/jobs/categories/").then((response) => {
            setCategoryOptions(response.data.map(({category_id, category_name, category_group_name, has_jobs}) => [
                category_id, `${category_group_name} - ${category_name}`, has_jobs
            ]));
        });

        axiosInstance.get("/api/jobs/on_site_remotes/").then((response) => {
            setOnSiteRemoteOptions(response.data.map(({on_site_remote, on_site_remote_verbose, has_jobs}) => [
                on_site_remote, on_site_remote_verbose, has_jobs
            ]));
        });
        axiosInstance.get("/api/jobs/employers/").then((response) => {
            setEmployerOptions(response.data.map(({employer_id, employer_name, has_jobs}) => [
                employer_id, employer_name, has_jobs
            ]));
        });
        updateSavedJobs();
    }, []);

    const updateSavedJobs = () => {
        const userId = getUserId();
        if (userId) {
            axiosInstance.get(`/api/users/me/saved_jobs/`).then((response) => {
                setSaved(response.data.saved_jobs);
            });
        }
    };

    const clearFilters = () => {
        setSearchParams({});
    };

    return (
        <>
            {canAddJob ? (
                <Link component={RouterLink} to={`/jobs/new`}>
                    <Tooltip title="Add a new job">
                        <Fab
                            color="primary"
                            sx={{
                                position: "fixed",
                                bottom: "20px",
                                right: "40px",
                                zIndex: 10,
                            }}
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                </Link>
            ) : null}
            <PageContainer maxWidth="lg">
                <Grid container>
                    <Grid item md={9} xs={12}>
                        <Box>
                            <Paper sx={{ padding: "20px" }}>
                                <Typography variant="h4" color="primary">
                                    Search
                                </Typography>
                                <TextField
                                    label="eg. Business Developer"
                                    variant="outlined"
                                    sx={{ width: "100%" }}
                                    onChange={(e) => updateSearchOptions("search", e.target.value)}
                                    value={search || ""}
                                    color="text"
                                    margin="dense"
                                    size="small"
                                />
                            </Paper>
                            <Accordion sx={{ margin: "16px 0px", overflow: "auto" }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">Filter</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ paddingBottom: "5px" }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <FilterSelect
                                                width="100%"
                                                selectedItems={jobTypes}
                                                onChange={(selected) =>
                                                    updateSearchOptions("job_type", selected)
                                                }
                                                options={jobTypeOptions}
                                                label="Job Type"
                                                allowDisabling={includeExpired !== "true"}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FilterSelect
                                                width="100%"
                                                selectedItems={onSiteRemote}
                                                onChange={(selected) =>
                                                    updateSearchOptions("on_site_remote", selected)
                                                }
                                                options={onSiteRemoteOptions}
                                                label="On-site/Remote"
                                                allowDisabling={includeExpired !== "true"}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FilterSelect
                                                width="100%"
                                                selectedItems={jobCountries}
                                                onChange={(selected) =>
                                                    updateSearchOptions("country", selected)
                                                }
                                                options={countryOptions}
                                                label="Country"
                                                allowDisabling={includeExpired !== "true"}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FilterSelect
                                                width="100%"
                                                selectedItems={employers}
                                                onChange={(selected) => {
                                                    if (selected.includes("saved")) {
                                                        updateSearchOptions(
                                                            "employer",
                                                            _.uniq([
                                                                ..._.without(selected, "saved"),
                                                                ...savedEmployers,
                                                            ])
                                                        );
                                                    } else {
                                                        updateSearchOptions("employer", selected);
                                                    }
                                                }}
                                                options={[
                                                    [
                                                        "saved",
                                                        "My Saved Employers",
                                                        !!savedEmployers && !!savedEmployers.length,
                                                    ],
                                                    ...employerOptions,
                                                ]}
                                                label="Employer"
                                                allowDisabling={includeExpired !== "true"}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FilterSelect
                                                width="100%"
                                                selectedItems={secondaryCategories}
                                                onChange={(selected) =>
                                                    updateSearchOptions("category", selected)
                                                }
                                                options={categoryOptions}
                                                label="Category"
                                                allowDisabling={includeExpired !== "true"}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FilterSelect
                                                width="100%"
                                                selectedItems={regions}
                                                onChange={(selected) =>
                                                    updateSearchOptions("region", selected)
                                                }
                                                options={regionOptions}
                                                label="Employer Region"
                                                allowDisabling={includeExpired !== "true"}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormGroup
                                                sx={{
                                                    paddingTop: "5px",
                                                    width: "100%",
                                                }}
                                            >
                                                <FormControlLabel
                                                    checked={partTimeOnly === "true"}
                                                    onChange={(e) =>
                                                        updateSearchOptions(
                                                            "part_time_only",
                                                            e.target.checked
                                                        )
                                                    }
                                                    sx={{
                                                        justifyContent: "left",
                                                    }}
                                                    control={<Switch />}
                                                    label="Part time only"
                                                />
                                            </FormGroup>
                                            <FormGroup
                                                sx={{
                                                    paddingTop: "5px",
                                                    width: "100%",
                                                }}
                                            >
                                                <FormControlLabel
                                                    checked={includeExpired === "true"}
                                                    onChange={(e) =>
                                                        updateSearchOptions(
                                                            "include_expired",
                                                            e.target.checked
                                                        )
                                                    }
                                                    sx={{
                                                        justifyContent: "left",
                                                    }}
                                                    control={<Switch />}
                                                    label="Include expired"
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ marginTop: "10px" }} />
                                    <Button sx={{ paddingBottom: 0 }} onClick={clearFilters}>
                                        Clear filters
                                    </Button>
                                </AccordionDetails>
                            </Accordion>
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    fontSize: "1.25rem",
                                    color: "#283E72",
                                    fontFamily: "Nunito Sans",
                                }}
                                pl={2}
                                pr={2}
                            >
                                <Grid item md={7} xs={12}>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        sx={{ justifyContent: { xs: "center", md: "left" } }}
                                    >
                                        <Typography
                                            variant="body1"
                                            color="text"
                                            sx={{
                                                alignSelf: "center",
                                                fontWeight:
                                                    earlyCareers === "true" || !earlyCareers
                                                        ? "bold"
                                                        : "normal",
                                            }}
                                        >
                                            Early careers jobs
                                        </Typography>
                                        <FormGroup>
                                            <FormControlLabel
                                                checked={earlyCareers && earlyCareers !== "true"}
                                                onChange={(e) =>
                                                    updateSearchOptions(
                                                        "early_careers",
                                                        !e.target.checked
                                                    )
                                                }
                                                sx={{
                                                    justifyContent: "right",
                                                    "& .Mui-checked": {
                                                        color: "white",
                                                    },
                                                    "& .MuiSwitch-track ": {
                                                        backgroundColor: "#1A82c5",
                                                    },
                                                    "& .MuiSwitch-switchBase ": {
                                                        color: "#1A82c5",
                                                    },
                                                    "& .MuiFormControlLabel-label": {
                                                        fontWeight:
                                                            earlyCareers && earlyCareers !== "true"
                                                                ? "bold"
                                                                : "normal",
                                                    },
                                                }}
                                                control={<Switch />}
                                                label="Beyond early careers jobs"
                                            />
                                        </FormGroup>
                                    </Stack>
                                </Grid>
                                <Grid item md={5} xs={12}>
                                    <Stack
                                        direction="row"
                                        m="12px"
                                        sx={{
                                            justifyContent: { xs: "center", md: "right" },
                                            fontSize: "1rem",
                                        }}
                                    >
                                        Order by:
                                        <Link
                                            onClick={() => {
                                                updateSearchOptions("ordering", "-posted_date");
                                            }}
                                            mr={1}
                                            ml={1}
                                            sx={{
                                                fontWeight:
                                                    !orderField || orderField === "-posted_date"
                                                        ? "bold"
                                                        : "normal",
                                                color: theme.palette.text.primary,
                                                textDecoration: "none",
                                                cursor: "pointer",
                                                "&:hover": {
                                                    textDecoration: "underline",
                                                },
                                            }}
                                        >
                                            Latest
                                        </Link>
                                        {" | "}
                                        <Link
                                            onClick={() => {
                                                updateSearchOptions(
                                                    "ordering",
                                                    "application_deadline"
                                                );
                                            }}
                                            ml={1}
                                            sx={{
                                                fontWeight:
                                                    orderField &&
                                                    orderField === "application_deadline"
                                                        ? "bold"
                                                        : "normal",
                                                color: theme.palette.text.primary,

                                                textDecoration: "none",
                                                cursor: "pointer",
                                                "&:hover": {
                                                    textDecoration: "underline",
                                                },
                                            }}
                                        >
                                            Deadline
                                        </Link>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                        {(loading && !jobs.length && totalItems !== 0) || initialLoad ? (
                            <JobCardSkeletons />
                        ) : (
                            <>
                                <Typography
                                    variant="h5"
                                    color="primary"
                                    align="center"
                                    sx={{
                                        display: "block",
                                        padding: 0,
                                        paddingBottom: "20px",
                                        paddingTop: "20px",
                                    }}
                                >
                                    {totalItems} jobs
                                </Typography>
                                <InfiniteScroll
                                    dataLength={jobs.length}
                                    next={fetchMoreData}
                                    hasMore={hasMore}
                                >
                                    <Grid container spacing={2}>
                                        {jobs.map((job) => (
                                            <Grid item xs={12} key={job.id}>
                                                <JobCard
                                                    job={job}
                                                    saved={saved.includes(job.id)}
                                                    onSavedChange={(savedJobs) => {
                                                        updateSavedJobs();
                                                    }}
                                                    canSave={
                                                        isLoggedIn() &&
                                                        getAccountType() === "candidate"
                                                    }
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </InfiniteScroll>
                            </>
                        )}
                    </Grid>
                    {smallScreen ? null : (
                        <Grid
                            item
                            md={3}
                            xs={12}
                            sx={{
                                paddingLeft: "20px",
                            }}
                        >
                            <Stack spacing={2}>
                                <SideCard
                                    title="Issues and Suggestions"
                                    body="We have a new website! If you spot any issues or have suggestions on things that might be useful to add or change then we’d love to hear it."
                                    action={<Feedback textButton color="highlight" />}
                                />

                                <SideCard
                                    title="Space Employers"
                                    body={`From small startups to large multinationals and innovative universities, the space sector has it all. Right now ${
                                        employerOptions?.length || 0
                                    } employers of all sizes are represented on SpaceCareers.uk.`}
                                    action={
                                        <Button href="/employers/" color="highlight">
                                            See all employers
                                        </Button>
                                    }
                                />
                                <SideCard
                                    title="Careers Resources"
                                    body="Want some advice on how to set yourself up for landing 
                                    our dream job, or even trying to work out what job would suit
                                    you? Check out our advice articles and interviews with people
                                    working in the space sector."
                                    action={
                                        <Button href="/careers_resources/" color="highlight">
                                            Explore careers resources
                                        </Button>
                                    }
                                />
                            </Stack>
                        </Grid>
                    )}
                </Grid>
            </PageContainer>
        </>
    );
};

export default JobSearchPage;