import { Trans, useTranslation } from "react-i18next";

import Divider from "@mui/material/Divider";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const AdvertisePage = () => {
    const { t, i18n } = useTranslation();
    return (
        <PageContainer>
            <Paper>
                <Typography paragraph variant="h4" color="primary">
                    Contact us
                </Typography>
                <Typography paragraph>
                    <Trans i18nKey="contact.intro" />
                </Typography>
                <Divider sx={{ marginBottom: "20px" }} />
                <Typography paragraph variant="h5">
                    <Trans i18nKey="contact.advice.title" />
                </Typography>
                <Typography paragraph sx={{ whiteSpace: "pre-line" }}>
                    <Trans i18nKey="contact.advice.body" components={{ a: <a /> }} />
                </Typography>
                <Typography paragraph variant="h5">
                    <Trans i18nKey="contact.apply.title" />
                </Typography>
                <Typography paragraph sx={{ whiteSpace: "pre-line" }}>
                    <Trans i18nKey="contact.apply.body" components={{ a: <a /> }} />
                </Typography>
                <Typography paragraph variant="h5">
                    <Trans i18nKey="contact.post_question.title" />
                </Typography>
                <Typography paragraph sx={{ whiteSpace: "pre-line" }}>
                    <Trans i18nKey="contact.post_question.body" components={{ a: <a /> }} />
                </Typography>
                <Typography paragraph variant="h5">
                    <Trans i18nKey="contact.post_a_job.title" />
                </Typography>
                <Typography paragraph sx={{ whiteSpace: "pre-line" }}>
                    <Trans i18nKey="contact.post_a_job.body" components={{ a: <a /> }} />
                </Typography>
                <Typography paragraph variant="h5">
                    <Trans i18nKey="contact.join.title" />
                </Typography>
                <Typography paragraph sx={{ whiteSpace: "pre-line" }}>
                    <Trans i18nKey="contact.join.body" components={{ a: <a /> }} />
                </Typography>
            </Paper>
        </PageContainer>
    );
};

export default AdvertisePage;
