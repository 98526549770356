import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const ImageLink = ({ imageUrl, title, url }) => {
    const theme = useTheme();
    return (
        <Grid item xs={12} lg={6}>
            <Card
                sx={{
                    display: "flex",
                    height: "400px",
                    position: "sticky",
                    "&:hover .MuiBox-root": {
                        minHeight: "130px",
                    },
                }}
            >
                <Link component={RouterLink} to={url} sx={{ width: "100%" }} underline="none">
                    <CardMedia
                        component="img"
                        sx={{
                            objectFit: "cover",
                            maxWidth: "100%",
                            height: "100%",
                        }}
                        image={imageUrl}
                    />
                    <Box
                        sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            color: "#283E72",
                            backgroundColor: alpha(theme.palette.text.primary, 0.7),
                            minHeight: "120px",
                            transition: "all 0.1s ease-in-out",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Typography align="center" variant="h4" color="secondary" m="15px">
                            {title}
                        </Typography>
                    </Box>
                </Link>
            </Card>
        </Grid>
    );
};

const ResourcesPage = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    return (
        <PageContainer maxWidth="md">
            <Paper
                pl={4}
                pr={4}
                sx={{
                    padding: "20px 50px",
                }}
            >
                <Typography variant="h3" paragraph color="text" textAlign="center">
                    {t("resources.title")}
                </Typography>
                <Grid container spacing={5} sx={{ justifyContent: "center" }}>
                    <ImageLink
                        imageUrl="/rover.jpg"
                        title="Advice & Information"
                        url="/articles/advice_and_information"
                    />
                    <ImageLink
                        imageUrl="/astronaut.jpg"
                        title="Job Profiles"
                        url="/articles/job_profiles"
                    />
                    <ImageLink
                        imageUrl="/woman-in-space-tunnel.jpg"
                        title="Career Stories"
                        url="/articles/career_stories"
                    />
                    <ImageLink
                        imageUrl="/starry-sky-trails.jpg"
                        title="Company A-Z"
                        url="/employers"
                    />
                </Grid>
            </Paper>
        </PageContainer>
    );
};

export default ResourcesPage;
