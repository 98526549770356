import React, { useCallback, useEffect, useMemo, useState } from "react";

import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import Container from "@mui/material/Container";
import Dropzone from "../common/Dropzone";
import EditIcon from "@mui/icons-material/Edit";
import EditableTextField from "../common/EditableTextField";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import SaveIcon from "@mui/icons-material/Save";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import UndoIcon from "@mui/icons-material/Undo";
import axiosInstance from "../axiosInstance";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useParams } from "react-router-dom";
import { usePrompt } from "../common/ReactRouterBlocker";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";

const SkillEventEditableTextField = ({ field, edits, skillevent, setEdits, ...rest }) => {
    return (
        <EditableTextField
            value={field in edits ? edits[field] : skillevent[field]}
            onChange={(value) => {
                setEdits({ ...edits, [field]: value });
            }}
            dirty={edits?.[field] !== skillevent[field] && edits?.[field] !== undefined}
            variant="h4"
            color="primary"
            sx={{ paddingBottom: "16px" }}
            editable={skillevent?.can_edit}
            {...rest}
        />
    );
};

const SkillEventImage = ({ skillevent, edits, setEdits }) => {
    return (
        <Dropzone
            imageProps={{
                style: {
                    maxWidth: "100%",
                    height: 200,
                    objectFit: "scale-down",
                    margin: "auto",
                    display: "block",
                },
                src: skillevent.image,
                alt: "Skill Event image",
            }}
            editable={skillevent?.can_edit}
            onChange={(file) => {
                setEdits({ ...edits, image: file });
            }}
        />
    );
};

const SkillEventPage = ({ addBannerNotification }) => {
    const theme = useTheme();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    let params = useParams();
    const [skillevent, setSkillEvent] = useState([]);
    const [edits, setEdits] = useState({});
    const [error404, setError404] = useState(false);
    const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState({});

    useEffect(() => {
        if (skillevent?.can_edit) {
            axiosInstance.options("/api/skillevents/").then((response) => {
                setOptions(response.data.actions.POST);
            });
        }
    }, [skillevent]);

    const isDirty = useMemo(() => {
        return (
            Object.keys(edits).length > 0 &&
            Object.keys(edits).some((key) => edits[key] !== skillevent[key])
        );
    }, [edits, skillevent]);

    usePrompt(
        "Your changes have not been saved. Are you sure you want to leave this page?",
        isDirty
    );

    useEffect(() => {
        axiosInstance
            .get(`/api/skillevents/${params.eventId}/`)
            .then((response) => {
                setSkillEvent(response.data);
                setLoading(false);
                window.posthog.capture("Saw skill event page", {
                    eventId: params.eventId,
                });
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    setError404(true);
                }
            });
    }, [params.eventId]);

    const submitEdits = useCallback(() => {
        let formData = new FormData();
        for (let key in edits) {
            formData.append(key, edits[key]);
        }

        axiosInstance
            .patch(`/api/skillevents/${params.eventId}/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                setSkillEvent(response.data);
                setEdits({});
                enqueueSnackbar("Skill event updated successfully.", { variant: "success" });
            })
            .catch((err) => {
                enqueueSnackbar("Error updating Skill event.", { variant: "error" });
            });
    }, [edits, enqueueSnackbar, params.eventId]);

    useEffect(() => {
        addBannerNotification(
            skillevent?.can_edit ? "You can edit this skill event." : undefined,
            "ownership"
        );
        return () => {
            addBannerNotification(undefined, "ownership");
        };
    }, [skillevent, addBannerNotification]);

    useEffect(() => {
        addBannerNotification(
            isDirty ? (
                <Typography align="center">
                    You have unsaved changes.
                    <Link
                        onClick={submitEdits}
                        sx={{
                            marginLeft: "5px",
                            cursor: "pointer",
                            color: theme.palette.highlight.main,
                        }}
                    >
                        Save changes
                    </Link>
                </Typography>
            ) : undefined,
            "dirty"
        );
        return () => {
            addBannerNotification(undefined, "dirty");
        };
    }, [isDirty, submitEdits, addBannerNotification]);

    useEffect(() => {
        addBannerNotification(
            skillevent?.can_edit ? "You can edit this skill event." : undefined,
            "ownership"
        );
        return () => {
            addBannerNotification(undefined, "ownership");
        };
    }, [skillevent, addBannerNotification]);
    const makeSkillEventPublic = () => {
        axiosInstance
            .patch(`/api/skillevents/${params.eventId}/`, { private: false })
            .then((response) => {
                setSkillEvent(response.data);
                enqueueSnackbar("Skill Event updated successfully.", { variant: "success" });
            })
            .catch((err) => {
                enqueueSnackbar("Error updating skill event.", { variant: "error" });
            });
    };

    const makeSkillEventPrivate = () => {
        axiosInstance
            .patch(`/api/skillevents/${params.eventId}/`, { private: true })
            .then((response) => {
                setSkillEvent(response.data);
                enqueueSnackbar("Skill Event updated successfully.", { variant: "success" });
            })
            .catch((err) => {
                enqueueSnackbar("Error updating skill event.", { variant: "error" });
            });
    };

    const makePublicAction = (snackbarId) => (
        <>
            <Button size="small" color="secondary" onClick={makeSkillEventPublic}>
                Make public
            </Button>
        </>
    );
    useEffect(() => {
        if (skillevent?.private) {
            const key = enqueueSnackbar("This skill event is private.", {
                action: makePublicAction,
                preventDuplicate: false,
                persist: true,
                variant: "warning",
            });
            return () => {
                closeSnackbar(key);
            };
        }
    }, [skillevent, closeSnackbar, enqueueSnackbar]);

    useEffect(() => {
        // remove any keys from edits where the value is undefined
        if (Object.values(edits).some((e) => e === undefined)) {
            setEdits((oldEdits) => {
                let newEdits = { ...oldEdits };
                Object.keys(newEdits).forEach((key) => {
                    if (newEdits[key] === undefined) {
                        delete newEdits[key];
                    }
                });
                return newEdits;
            });
        }
    }, [edits]);

    return (
        <PageContainer error404={error404}>
            {skillevent?.can_edit ? (
                <SpeedDial
                    sx={{ position: "fixed", bottom: 20, right: 20, zIndex: 10 }}
                    ariaLabel="SpeedDial"
                    icon={<EditIcon />}
                >
                    <SpeedDialAction
                        key={"save"}
                        icon={<SaveIcon />}
                        tooltipTitle={"Save"}
                        onClick={submitEdits}
                        disabled={!isDirty}
                    />

                    {skillevent.private ? (
                        <SpeedDialAction
                            key={"public"}
                            icon={<LockOpenIcon />}
                            tooltipTitle={"Make public"}
                            onClick={makeSkillEventPublic}
                        />
                    ) : (
                        <SpeedDialAction
                            key={"private"}
                            icon={<LockIcon />}
                            tooltipTitle={"Make private"}
                            onClick={makeSkillEventPrivate}
                        />
                    )}
                    <SpeedDialAction
                        key={"reset"}
                        icon={<UndoIcon />}
                        tooltipTitle={"Reset edits"}
                        onClick={() => setEdits({})}
                        disabled={!isDirty}
                    />
                </SpeedDial>
            ) : null}
            <Breadcrumbs
                color={theme.palette.text.primary}
                separator={<NavigateNextIcon fontSize="small" />}
            >
                <Link underline="hover" color="inherit" href="/home">
                    Home
                </Link>
                <Link underline="hover" color="inherit" href="/skills/">
                    Skills
                </Link>
                <Typography color="inherit">{skillevent.name}</Typography>
            </Breadcrumbs>
            <Box>
                <Paper sx={{ padding: "20px" }}>
                    <Grid container>
                        {smallScreen ? (
                            <Grid item xs={12} sx={{ marginBottom: "20px" }}>
                                <SkillEventImage skillevent={skillevent} edits={edits} setEdits={setEdits} />
                            </Grid>
                        ) : null}
                        <Grid item md={8} xs={12}>
                            <EditableTextField
                                value={
                                    skillevent?.can_edit
                                        ? "type" in edits
                                            ? edits.type
                                            : skillevent.type
                                        : skillevent?.type_display
                                }
                                onChange={(value) => {
                                    setEdits({ ...edits, type: value });
                                }}
                                dirty={edits?.type !== skillevent.type && edits?.type !== undefined}
                                variant="h6"
                                color="text"
                                editable={skillevent?.can_edit}
                                selectValues={options?.type?.choices?.map((option) => [
                                    option.value,
                                    option.display_name,
                                ])}
                                tooltip="Skill Event Type"
                            />
                            <SkillEventEditableTextField
                                edits={edits}
                                field="name"
                                setEdits={setEdits}
                                skillevent={skillevent}
                                tooltip="Skill Event Name"
                            />
                            <Typography variant="h6" color="text">
                                organised by
                            </Typography>

                            <SkillEventEditableTextField
                                field="organiser"
                                edits={edits}
                                setEdits={setEdits}
                                skillevent={skillevent}
                                tooltip="Skill Event Organiser"
                                variant="h5"
                                color="text"
                                paragraph
                            />

                            <Stack direction="row" spacing={1}>
                                <Tooltip title="Ticket Price">
                                    <ConfirmationNumberIcon color="text" />
                                </Tooltip>
                                <SkillEventEditableTextField
                                    field="price"
                                    edits={edits}
                                    setEdits={setEdits}
                                    skillevent={skillevent}
                                    tooltip="Ticket Price"
                                    variant="h6"
                                    color="text"
                                    sx={{ lineHeight: "26px" }}
                                />
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <SkillEventEditableTextField
                                    field="date"
                                    edits={edits}
                                    setEdits={setEdits}
                                    skillevent={skillevent}
                                    tooltip="Skill Event date"
                                    variant="h6"
                                    color="text"
                                    datePicker
                                />
                                <Typography variant="h6" color="text">
                                    in
                                </Typography>
                                {skillevent.city || skillevent.can_edit ? (
                                    <SkillEventEditableTextField
                                        field="city"
                                        edits={edits}
                                        setEdits={setEdits}
                                        skillevent={skillevent}
                                        tooltip="Skill Event City"
                                        variant="h6"
                                        color="text"
                                    />
                                ) : null}
                                {skillevent.city || skillevent.can_edit ? (
                                    <Typography
                                        variant="h6"
                                        color="text"
                                        sx={{ marginLeft: "0 !important" }}
                                    >
                                        ,
                                    </Typography>
                                ) : null}
                                <EditableTextField
                                    value={
                                        skillevent?.can_edit
                                            ? "country" in edits
                                                ? edits.country
                                                : skillevent.country
                                            : skillevent?.country_display
                                    }
                                    onChange={(value) => {
                                        setEdits({ ...edits, country: value });
                                    }}
                                    dirty={
                                        edits?.country !== skillevent.country &&
                                        edits?.country !== undefined
                                    }
                                    variant="h6"
                                    color="text"
                                    editable={skillevent?.can_edit}
                                    selectValues={options?.country?.choices?.map((option) => [
                                        option.value,
                                        option.display_name,
                                    ])}
                                    tooltip="Skill Event Country"
                                />
                            </Stack>
                        </Grid>
                        {smallScreen ? null : (
                            <Grid item xs={4}>
                                <SkillEventImage skillevent={skillevent} edits={edits} setEdits={setEdits} />
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </Box>
            <Grid container spacing={2}>
                <Grid item sm={9} xs={12}>
                    <Paper sx={{ padding: "20px" }}>
                        <EditableTextField
                            value={"description" in edits ? edits.description : skillevent.description}
                            onChange={(value) => {
                                setEdits({ ...edits, description: value });
                            }}
                            dirty={
                                edits?.description !== skillevent.description &&
                                edits?.description !== undefined
                            }
                            markdown
                            editable={skillevent?.can_edit}
                            tooltip="Description"
                        />
                    </Paper>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Stack spacing={2}>
                        {skillevent.link ? (
                            <Card>
                                <Container disableGutters sx={{ padding: "10px" }}>
                                    <Button
                                        variant="contained"
                                        color="highlight"
                                        href={skillevent.link}
                                        target="_blank"
                                        sx={{
                                            marginRight: "30px !important",
                                            marginLeft: "30px !important",
                                            width: "calc(100% - 60px)",
                                        }}
                                    >
                                        Find out more
                                    </Button>
                                </Container>
                            </Card>
                        ) : null}
                    </Stack>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default SkillEventPage;
