import { useEffect, useMemo } from "react";

import { useLocation } from "react-router";

// Resets zoom when location changes
const ScrollToTop = (props) => {
    const location = useLocation();
    const pathName = useMemo(() => location.pathname, [location]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathName]);

    return <>{props.children}</>;
};

export default ScrollToTop;
