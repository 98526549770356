import React, { useState, useEffect } from 'react';

function InactivityModal() {
    const [isInactive, setIsInactive] = useState(false);
    const [showModal, setShowModal] = useState(false);

    let inactivityTimer = null;
    const resetInactivityTimer = () => {
        clearTimeout(inactivityTimer);
        setShowModal(false);
        inactivityTimer = setTimeout(() => {
            setIsInactive(true);
            setTimeout(() => setShowModal(true), 100); 
        }, 5000); // 5 seconds
    };

    useEffect(() => {
        window.addEventListener('keydown', resetInactivityTimer);
        window.addEventListener('scroll', resetInactivityTimer);
        window.addEventListener('mousemove', resetInactivityTimer);

        resetInactivityTimer();

        return () => {
            clearTimeout(inactivityTimer);
            window.removeEventListener('keydown', resetInactivityTimer);
            window.removeEventListener('scroll', resetInactivityTimer);
            window.removeEventListener('mousemove', resetInactivityTimer);
        };
    }, []);

    return (
        isInactive ? (
            <div
                className={`pointer-events-none z-50 fixed backdrop-blur-md bg-black bg-opacity-30 w-screen h-screen top-1 flex items-center justify-center transition-opacity duration-500 ${
                    showModal ? 'opacity-100' : 'opacity-0'
                }`}
            >
                <div className="slow-floating flex flex-wrap max-w-md gap-2 items-center justify-center">
                    <img className="h-20" src="landing/scroll.png" alt="" />
                    <p className="text-[24px] fredoka-bold">Scroll to see more!</p>
                </div>
            </div>
        ) : null
    );
}

export default InactivityModal;