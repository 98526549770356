import React, {useEffect, useRef} from "react";
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const SatellitesGSAP = () => {
    const sectionRefs = useRef([]);
    sectionRefs.current = [];

    const addToRefs = (el) => {
        if (el && !sectionRefs.current.includes(el)) {
            sectionRefs.current.push(el);
        }
    };

    function getActiveSectionIndex(progress) {
        // Ensure progress is clamped between 0 and 1
        progress = Math.max(0, Math.min(1, progress));

        // Calculate the index based on the progress
        const numberOfElements = sectionRefs.current.length;
        const segmentLength = 1 / numberOfElements;

        for (let i = 0; i < numberOfElements; i++) {
            if (progress < (i + 1) * segmentLength) {
                return i; // Return the index of the active section
            }
        }

        return numberOfElements - 1; // If progress is 1, return the last index
    }

    const getSectionProgress = (progress, section) => {
        const numberOfElements = sectionRefs.current.length;
        const segmentLength = 1 / numberOfElements;

        // Ensure section is within the bounds
        if (section < 0 || section >= numberOfElements) {
            throw new Error('Section index out of bounds');
        }

        // Calculate the start and end of the current section
        const sectionStart = section * segmentLength;
        const sectionEnd = sectionStart + segmentLength;

        // Calculate the section progress
        if (progress < sectionStart) {
            return 0;
        } else if (progress > sectionEnd) {
            return 1;
        } else {
            return (progress - sectionStart) / segmentLength;
        }
    };


    useEffect(() => {
        const sections = sectionRefs.current;

        gsap.set(sections, {autoAlpha: 0});

        const scrollTrigger = gsap.to(sections, {
            scrollTrigger: {
                trigger: "#satellites",
                start: "top top",
                end: "+=5000",
                scrub: 1,
                pin: true,
                onUpdate: (self) => {
                    sections.forEach((section, index) => {
                        const activeSectionIndex = getActiveSectionIndex(self.progress);
                        const isActive = index === activeSectionIndex;
                        gsap.to(section, {
                            autoAlpha: !isActive && (getSectionProgress(self.progress, index) < 0.2 || getSectionProgress(self.progress, index) > 0.8) ? 0 : 1,
                            duration: 0.3,
                            pointerEvents: isActive ? "auto" : "none",
                        });


                        if (activeSectionIndex === 0) {
                            const sectionProgress = getSectionProgress(self.progress, 0);
                            gsap.to(".satellites-title", {
                                y: -25 * (sectionProgress),
                                scaleX: 1 + 0.25 * sectionProgress,
                                scaleY: 1 + 0.25 * sectionProgress,
                                overwrite: true,
                            });

                        } else if (activeSectionIndex === 2) {
                            const sectionProgress = getSectionProgress(self.progress, 2);
                            gsap.to(".satellites-2", {
                                x: window.innerWidth * sectionProgress,
                                y: -200 * sectionProgress,
                                scaleX: 1 + 2 * (sectionProgress),
                                scaleY: 1 + 2 * (sectionProgress),
                                overwrite: true,
                            });
                            gsap.to(".section-text-2", {
                                scaleX: 1 + 0.25 * sectionProgress, scaleY: 1 + 0.25 * sectionProgress,
                            });
                        } else if (activeSectionIndex === 3) {
                            const sectionProgress = getSectionProgress(self.progress, 3);
                            gsap.to(".satellites-3", {
                                rotate: 180 * sectionProgress,
                            });
                            gsap.to(".satellites-3-wrapper", {
                                scaleX: 1 + 0.05 * (sectionProgress), scaleY: 1 + 0.05 * (sectionProgress),
                            });
                            gsap.to(".section-text-3", {
                                scaleX: 1 + 0.25 * sectionProgress, scaleY: 1 + 0.25 * sectionProgress,
                            });
                        } else if (activeSectionIndex === 4) {
                            const sectionProgress = getSectionProgress(self.progress, 4);
                            gsap.to(".satellites-4", {
                                rotate: -60 * sectionProgress, overwrite: true,
                            });
                            gsap.to(".satellites-4-wrapper", {
                                scaleX: 1 + 0.05 * (sectionProgress), scaleY: 1 + 0.05 * (sectionProgress),
                            });

                        } else if (activeSectionIndex === 5) {
                            const sectionProgress = getSectionProgress(self.progress, 5);
                            gsap.to(".satellites-5", {
                                x: -150 * sectionProgress,
                                y: 50 * sectionProgress,
                                rotate: -45 * sectionProgress,
                                overwrite: true,
                                autoAlpha: sectionProgress > 0.8 ? 0 : 1,
                            });
                            gsap.to(".satellites-5-wrapper", {
                                scaleX: 1 + 0.05 * (sectionProgress),
                                scaleY: 1 + 0.05 * (sectionProgress),
                            });

                        }
                        else if(activeSectionIndex === 6){
                            const sectionProgress = getSectionProgress(self.progress, 6);
                            gsap.to(".satellites-6", {
                                y: -25 * (sectionProgress),
                                scaleX: 1 + 0.15 * (sectionProgress),
                                scaleY: 1 + 0.15 * (sectionProgress),
                            });
                        }
                    });
                },
            }
        });

        return () => {
            scrollTrigger.scrollTrigger.kill();
            gsap.set(sections, {autoAlpha: 0, duration: 0.5});
        };
    }, []);

    return (
        <div id="future">
        <div id="satellites" className="relative overflow-hidden min-h-screen max-lg:mb-[100vh]">
        <div
            className="absolute inset-0"
            style={{
                backgroundImage: `url('landing/Starfield.svg')`,
                backgroundRepeat: 'repeat-y',
                backgroundSize: '100% auto',
                backgroundPosition: 'top left',
                opacity: 0.2,
            }}
        />

        <div ref={addToRefs} className="section absolute inset-0 flex flex-col gap-16 items-center justify-center">
            <div
                className="satellites-title leading-none pb-24  text-center  tracking-tight koulen text-[32px] md:text-[48px] lg:text-[64px] text-landing-yellow">
                Satellites and future Space Activities
            </div>
        </div>

        <div ref={addToRefs}
             className="section absolute inset-0 flex max-lg:flex-col max-w-6xl top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 gap-20 items-center justify-center">
            <div data-aos={"fade-up"} className="slow-floating text-lg  md:text-[24px] text-start flex items-center">
                The space sector is growing quickly in the UK and around the world
            </div>
            <img className=" floating h-[171px] mx-auto rotate-[8deg]" src="landing/Satellite.svg"
                 alt=""/>
        </div>

        <div ref={addToRefs}
             className="section absolute inset-0 max-w-6xl top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 flex max-lg:flex-col gap-16 items-center justify-center">
            <div className="h-[118px] w-full flex relative justify-start relative">
                <img
                    className=" absolute -left-[50vw] top-[20vh] satellites-2 h-[62px]"
                    src="landing/RocketLaunch.svg" alt="Spaceship"
                />
            </div>
            <div data-aos="fade-up" className="slow-floating text-lg  md:text-[24px] text-center">
                There will be a lot more satellites launched in the future to provide benefits to people everywhere
            </div>

        </div>
        <div ref={addToRefs}
             className="section absolute inset-0 max-w-6xl top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 flex max-lg:flex-col gap-16 items-center justify-center">
            <div className="flex justify-between max-lg:flex-col items-center gap-12">
                <div data-aos={"fade-up"} className="slow-floating max-w-md  text-lg  md:text-[24px] text-start flex items-center">
                    But satellites are a bit different to technology we have on Earth
                </div>
                <div className="satellites-3-wrapper shrink-0 h-[215px] aspect-square relative">
                    <img className="h-full aspect-square " src="landing/satellite_path.svg" alt=""/>
                    <img className="satellites-3 absolute top-0 left-0  h-[215px] aspect-square z-40"
                         src="landing/satellite_mini.svg" alt=""/>
                </div>

            </div>
        </div>
        <div ref={addToRefs}
             className="section absolute inset-0 max-w-6xl top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 flex max-lg:flex-col gap-16 items-center justify-center">
            <div className="flex justify-between max-lg:flex-col items-center gap-8">
                <div className="flex justify-between items-center max-lg:flex-col-reverse gap-12">
                    <div
                        className="h-[191px] !w-[191px] satellites-4-wrapper w-full lg:w-1/3 flex justify-center relative lg:justify-end">
                        <img className="h-[191px] " src="landing/fuel.svg" alt=""/>
                        <img className=" satellites-4  absolute -right-[30px] -bottom-16 h-[240px]  absolute"
                             src="landing/fuel_pointer.svg" alt=""/>
                    </div>
                    <div data-aos={"fade-up"} className="slow-floating w-full lg:w-2/3 flex justify-end text-lg  md:text-[24px] text-end flex items-center">
                        Once a satellite is in space, it's much harder to fix it if it breaks, or add fuel to it
                        when it
                        runs out, like you can with a car
                    </div>
                </div>

            </div>
        </div>
        <div ref={addToRefs}
             className="section absolute inset-0 max-w-6xl top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 flex max-lg:flex-col gap-16 items-center justify-center">
            <div className="flex justify-between max-lg:flex-col items-center gap-8">
                <div className="flex justify-between max-lg:flex-col-reverse justify-center items-center gap-12">
                    <div data-aos={"fade-up"} className="slow-floating max-w-xl text-lg  md:text-[24px] text-start flex items-center">
                        Creative people are starting to develop robotic systems for fixing and refuelling satellites in
                        orbit and removing old satellites from space
                    </div>
                    <div className="satellites-6-wrapper h-[215px] !w-[215px] aspect-square shrink-0 relative">
                        <img className="h-full" src="landing/Deorbit.svg" alt=""/>
                        <img className="satellites-5 absolute h-12 top-10 -right-24" src="landing/orbit.svg" alt=""/>
                    </div>
                </div>

            </div>
        </div> <div ref={addToRefs}
             className="section absolute inset-0 max-w-6xl top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 flex max-lg:flex-col gap-16 items-center justify-center">
            <div className="flex justify-between max-lg:flex-col items-center gap-8">
                <div className="flex justify-between max-lg:flex-col-reverse gap-12">
                    <div className="w-full lg:w-1/2 flex justify-center  lg:justify-end">
                        <img className="satellites-6 h-[191px] " src="landing/SpaceDebris.svg" alt=""/>
                    </div>
                    <div data-aos={"fade-up"} className=" slow-floating w-full max-w-md lg:w-1/2 flex justify-end text-lg  md:text-[24px] text-end flex items-center">
                        It's important we look after the space environment to preserve it for future generations
                    </div>
                </div>

            </div>
    </div>

    </div>
        </div>);
};

export default SatellitesGSAP;
