import React from "react";
import AlarmIcon from "@mui/icons-material/Alarm";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BusinessIcon from '@mui/icons-material/Business';
import Box from "@mui/material/Box";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import PlaceIcon from "@mui/icons-material/Place";
import { Link as RouterLink } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { parse } from "date-fns";
import { useTheme } from "@mui/material/styles";

export const JobCardSkeletons = ({ count = 5 }) => {
    return (
        <Grid container spacing={2}>
            {Array.from({ length: count }).map(() => (
                <Grid item xs={12} sx={{ paddingLeft: "36px" }}>
                    <Skeleton variant="rectangular" height={196} />
                </Grid>
            ))}
        </Grid>
    );
};

const EllipsisOverflowTypography = (props) => {
    return (
        <Typography {...props} sx={{ textOverflow: "ellipsis", overflow: "hidden" }}></Typography>
    );
};

const DuplicateJobDisplay = ({ job, employersList }) => {
    let employerName = employersList ? employersList.find(employer => employer.id === job.employer_id,)?.name : undefined;
    const theme = useTheme();

    function parseDuration(duration) {
        if (duration === 'permanent') {return 'Permanent';}
        const match = duration.match(/(\d+)y(\d+)m(\d+)w/);
        if (!match) {return '-';}
        const [, years, months, weeks] = match.map(Number);
        let result = '';
        if(years) result += `${years} year${years !== 1 ? 's' : ''} `;
        if(months) result += `${months} month${months !== 1 ? 's' : ''} `;
        if(weeks) result += `${weeks} week${weeks !== 1 ? 's' : ''} `;
        return result.trim() || '-';
    };

    return (
        <Card 
            sx={{
                display: "flex",
                border: job?.private ? `3px solid ${theme.palette.highlight.main}` : "",
                borderRadius: "20px",
            }}
            color={job?.expired ? "disabled" : "primary"}
        >
            <Grid container>
                {job?.job_id ? (
                    <Grid item lg={12} md={12} sx={{ width:'100%'}} component={RouterLink} to={`/jobs/${job?.job_id}`} target="_blank">
                        <Box>
                            <CardContent>
                                <Stack spacing={0.5}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                                <EllipsisOverflowTypography color={job.similarity.title > 0.5 ? "error" : "secondary" } variant={'h6'}>
                                                    {job?.title || "-"}
                                                </EllipsisOverflowTypography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <EllipsisOverflowTypography color="secondary" style={{ display:'flex', alignItems: 'end', height:'100%' }}>
                                                Similarity: {job.similarity.total.toFixed(1)}/12
                                            </EllipsisOverflowTypography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item md={4} sm={6} xs={12}>
                                            <Stack direction="row" spacing={1}>
                                                <Tooltip title="Job Type">
                                                    <AssignmentIcon color={job.similarity.job_type > 0.5 ? "error" : "secondary" } />
                                                </Tooltip>
                                                <EllipsisOverflowTypography color={job.similarity.job_type > 0.5 ? "error" : "secondary" }>
                                                    
                                                    {job?.job_type || "-"}
                                                </EllipsisOverflowTypography>
                                            </Stack>
                                        </Grid>
                                        <Grid item md={4} sm={6} xs={12}>
                                            <Stack direction="row" spacing={1}>
                                                <Tooltip title="Company">
                                                    <BusinessIcon color={job.similarity.employer_id > 0.5 ? "error" : "secondary" } />
                                                </Tooltip>
                                                <EllipsisOverflowTypography color={job.similarity.employer_id > 0.5 ? "error" : "secondary" }>
                                                    {employerName || "-"}
                                                </EllipsisOverflowTypography>
                                            </Stack>
                                        </Grid>
                                        <Grid item md={4} sm={6} xs={12}>
                                            <Stack direction="row" spacing={1}>
                                                <Tooltip title="Job Location">
                                                    <PlaceIcon color={job.similarity.city+job.similarity.country > 1 ? "error" : "secondary" } />
                                                </Tooltip>
                                                <EllipsisOverflowTypography>
                                                    <span style={{ color: job.similarity.city > 0.5 ? "#d32f2f" : "#FFFFFF" }}>
                                                        {job?.city ? `${job.city}, ` : ""}
                                                    </span>
                                                    <span style={{}}>
                                                        {(!job?.city && !job?.country) ? "-" : ""}
                                                    </span>
                                                    <span style={{ color: job.similarity.country > 0.5 ? "#d32f2f" : "#FFFFFF" }}>
                                                        {job?.country || ""}
                                                    </span>
                                                </EllipsisOverflowTypography>
                                            </Stack>
                                        </Grid>
                                        <Grid item md={4} sm={6} xs={12}>
                                            <Stack direction="row" spacing={1}>
                                                <Tooltip title="Job Duration">
                                                    <CalendarMonthIcon color={job.similarity.duration > 0.5 ? "error" : "secondary" } />
                                                </Tooltip>
                                                <EllipsisOverflowTypography color={job.similarity.duration > 0.5 ? "error" : "secondary" }>
                                                    {parseDuration(job?.duration)}
                                                </EllipsisOverflowTypography>
                                            </Stack>
                                        </Grid>
                                        <Grid item md={4} sm={6} xs={12}>
                                            <Stack direction="row" spacing={1}>
                                                <Tooltip title="Application Deadline">
                                                    <AlarmIcon color={job.similarity.application_deadline > 0.5 ? "error" : "secondary" } />
                                                </Tooltip>
                                                <EllipsisOverflowTypography color={job.similarity.application_deadline > 0.5 ? "error" : "secondary" }>
                                                    {job?.rolling_deadline
                                                        ? "Rolling deadline"
                                                        : parse(
                                                            job?.application_deadline,
                                                            "dd-MM-yyyy",
                                                            new Date()
                                                        )
                                                            .toDateString()
                                                            .slice(4)}
                                                </EllipsisOverflowTypography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </CardContent>
                        </Box>
                    </Grid>
                ) : null}
            </Grid>
        </Card>
    );
};

export default DuplicateJobDisplay;
