import React, { useEffect, useRef } from "react";
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
const FindYourPlaceInSpace = () => {
    const sectionRefs = useRef([]);
    sectionRefs.current = [];

    const addToRefs = (el) => {
        if (el && !sectionRefs.current.includes(el)) {
            sectionRefs.current.push(el);
        }
    };

    useEffect(() => {
        const sections = sectionRefs.current;

        gsap.set(sections, { autoAlpha: 0 });
        gsap.to(sections[0], { autoAlpha: 1 }); // Start with the first section visible

        const scrollTrigger = gsap.to(sections, {
            scrollTrigger: {
                trigger: "#career",
                start: "top top",
                end: "+=800",
                scrub: 1,
                pin: true,
                snap: 1 / (sections.length - 1),
                onUpdate: (self) => {
                    const progress = self.progress * (sections.length - 1);
                    sections.forEach((section, index) => {
                        const isActive = Math.abs(index - progress) < 0.5;
                        gsap.to(section, {
                            autoAlpha: isActive ? 1 : 0,
                            duration: 0.5,
                        });
                    });
                },
            }
        });
        return () => {
            scrollTrigger.scrollTrigger.kill();
            gsap.set(sections, { autoAlpha: 0 });
        };
    }, []);
    return (
        <div
            id="career"
            className="relative  max-lg:text-[28px] text-[36px] fredoka-bold min-h-screen flex items-center py-12 justify-center overflow-hidden"
        >
            <div
                className="absolute inset-0 "
                style={{
                    backgroundImage: `url('landing/Starfield.svg')`,
                    backgroundRepeat: 'repeat-y',
                    backgroundSize: '100% auto',
                    backgroundPosition: 'top left',
                    opacity: 1,
                }}
            />
            <div className="px-8 mx-auto max-w-5xl flex flex-col gap-4 justify-between py-10">
                <div
                    data-aos={"fade-up"}
                    className="text-center leading-none pb-12  tracking-tight koulen text-[48px] lg:text-[64px] text-landing-yellow">
                    Find your place in Space
                </div>
                <div
                    className=" relative px-8 mx-auto w-full max-w-5xl flex flex-col gap-12 max-lg:text-[28px] text-[40px] fredoka-bold  "
                >
                    <div ref={addToRefs} className="flex justify-start w-full">
                        <h1 data-aos={"fade-up"} className="md:text-start max-w-xl">But how do you start on your path to a space job?</h1>
                    </div>

                    <div ref={addToRefs} className="flex justify-center w-full">
                        <h2 data-aos={"fade-up"} className="md:text-start max-w-3xl ">The most important thing is to follow the subjects you’re interested in</h2>
                    </div>

                    <div ref={addToRefs} className="flex justify-end w-full">
                        <h2 data-aos={"fade-up"} className="md:text-start max-w-2xl">Any subject can lead to working in the space sector!</h2>
                    </div>

                </div>

        </div>
</div>
)
    ;
}
export default FindYourPlaceInSpace;