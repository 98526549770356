import React, { useCallback, useEffect, useState } from "react";
import { format, parse } from "date-fns";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Button from "@mui/material/Button";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import axiosInstance from "../axiosInstance";
import { useSnackbar } from "notistack";

const JobBufferDialog = ({ job }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [bufferProfiles, setBufferProfiles] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState("");
    const [postText, setPostText] = useState("");
    const [scheduledAt, setScheduledAt] = useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeProfile = (event) => {
        setSelectedProfile(event.target.value);
    };
    const handleChangeScheduleAt = (value) => {
        setScheduledAt(value);
        try {
            setScheduledAt(format(value, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS"));
        } catch (e) {
            setScheduledAt(null);
        }
    };

    const submitPost = useCallback(() => {
        const notificationId = enqueueSnackbar("Submitting post...", {
            variant: "info",
            persist: true,
        });
        axiosInstance
            .post(`/api/jobs/${job.id}/buffer_posts/`, {
                text: postText,
                profile: selectedProfile,
                scheduled_at: scheduledAt,
            })
            .then((response) => {
                closeSnackbar(notificationId);
                enqueueSnackbar(response.data, { variant: "success" });
            })
            .catch((err) => {
                closeSnackbar(notificationId);
                enqueueSnackbar(err.response.data, { variant: "error" });
            });
    }, [job, postText, scheduledAt, selectedProfile]);

    useEffect(() => {
        axiosInstance.get("/api/jobs/buffer_profiles/").then((response) => {
            setBufferProfiles(response.data);
        });
    }, []);

    return (
        <>
            <Button
                variant="contained"
                sx={{ marginBottom: "20px", marginLeft: "20px" }}
                onClick={handleClickOpen}
                disabled={job.private}
            >
                Schedule Buffer Post
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{"Schedule Buffer Post"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>Select a buffer profile</DialogContentText>
                    <Select
                        value={selectedProfile}
                        onChange={handleChangeProfile}
                        fullWidth
                        sx={{ mb: 2 }}
                    >
                        {bufferProfiles.map((profile) => (
                            <MenuItem key={profile} value={profile}>
                                {profile}
                            </MenuItem>
                        ))}
                    </Select>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            fullWidth
                            label="Schedule a time"
                            value={scheduledAt}
                            onChange={handleChangeScheduleAt}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                    <TextField
                        value={postText}
                        onChange={(e) => {
                            setPostText(e.target.value);
                        }}
                        margin="dense"
                        label="Post text"
                        fullWidth
                        variant="standard"
                        multiline
                        minRows={3}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        onClick={submitPost}
                        disabled={!(postText && selectedProfile && scheduledAt)}
                    >
                        Submit Post
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default JobBufferDialog;
