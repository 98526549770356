import React, { useContext, useEffect } from "react";

import Container from "@mui/material/Container";
import { DarkModeContext } from "../App";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const addBodyClass = (className) => document.body.classList.add(className);
const removeBodyClass = (className) => document.body.classList.remove(className);
const PageContainer = ({ sx, dark = false, error404 = false, ...props }) => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const { darkMode, setDarkMode } = useContext(DarkModeContext);

    useEffect(() => {
        if (dark) {
            addBodyClass("dark");
            setDarkMode(true);
        }
        return () => {
            removeBodyClass("dark");
            setDarkMode(false);
        };
    }, []);

    return (
        <Container
            {...props}
            sx={{
                padding: { xs: "0px", md: "40px" },
                "& > *": {
                    padding: "20px",
                },
                minHeight: "calc(100vh - 196px)",
                ...sx,
            }}
        >
            {error404 ? <Error404Screen /> : props.children}
        </Container>
    );
};

const Error404Screen = () => {
    const theme = useTheme();
    return (
        <React.Fragment>
            <Typography variant="h4" align="center" color={theme.palette.text.primary}>
                404
            </Typography>
            <Typography variant="h5" align="center" color={theme.palette.text.primary}>
                Page not found
            </Typography>
        </React.Fragment>
    );
};

export default PageContainer;
