import { getAccountType, isLoggedIn } from "../auth/functions";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Link from "@mui/material/Link";
import Rating from "@mui/material/Rating";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import axiosInstance from "../axiosInstance";

export const EmployerCardSkeleton = () => {
    return <Skeleton variant="rectangular" height={218} />;
};

const EmployerCard = ({ employer, saved, onSavedChange, canSave = true }) => {
    return (
        <Card
            sx={{
                display: "flex",
                padding: "24px",
                height: "170px",
                position: "sticky",
            }}
            color="secondary"
        >
            {canSave && isLoggedIn() && getAccountType() === "candidate" ? (
                <Tooltip title="Save">
                    <Rating
                        icon={<StarRoundedIcon fontSize="inherit" />}
                        emptyIcon={<StarBorderRoundedIcon fontSize="inherit" />}
                        sx={{ position: "absolute", right: "2px", top: "2px" }}
                        value={saved ? 1 : 0}
                        max={1}
                        onChange={(e, value) => {
                            if (value === 1) {
                                axiosInstance
                                    .post(`/api/employers/${employer.id}/save/`)
                                    .then((response) => {
                                        onSavedChange();
                                    });
                            } else {
                                axiosInstance
                                    .post(`/api/employers/${employer.id}/unsave/`)
                                    .then((response) => {
                                        onSavedChange();
                                    });
                            }
                        }}
                    />
                </Tooltip>
            ) : null}
            <Link component={RouterLink} to={`/employers/${employer.id}`} sx={{ width: "100%" }}>
                <CardMedia
                    component="img"
                    sx={{
                        objectFit: "scale-down",
                        maxWidth: "100%",
                        maxHeight: "calc(100% - 20px)",
                        height: "100%",
                    }}
                    image={employer.logo}
                    alt="Employer logo"
                />
                <Typography
                    sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        background:
                            "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 20%)",
                        color: "#283E72",
                    }}
                    align="center"
                    variant="h6"
                >
                    {employer.name}
                </Typography>
            </Link>
        </Card>
    );
};

export default EmployerCard;
