import React, {useEffect, useRef} from "react";
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const AfterSchoolGSAP = () => {
    const sectionRefs = useRef([]);
    sectionRefs.current = [];

    const addToRefs = (el) => {
        if (el && !sectionRefs.current.includes(el)) {
            sectionRefs.current.push(el);
        }
    };

    function getActiveSectionIndex(progress) {
        // Ensure progress is clamped between 0 and 1
        progress = Math.max(0, Math.min(1, progress));

        // Calculate the index based on the progress
        const numberOfElements = sectionRefs.current.length;
        const segmentLength = 1 / numberOfElements;

        for (let i = 0; i < numberOfElements; i++) {
            if (progress < (i + 1) * segmentLength) {
                return i; // Return the index of the active section
            }
        }

        return numberOfElements - 1; // If progress is 1, return the last index
    }

    const getSectionProgress = (progress, section) => {
        const numberOfElements = sectionRefs.current.length;
        const segmentLength = 1 / numberOfElements;

        // Ensure section is within the bounds
        if (section < 0 || section >= numberOfElements) {
            throw new Error('Section index out of bounds');
        }

        // Calculate the start and end of the current section
        const sectionStart = section * segmentLength;
        const sectionEnd = sectionStart + segmentLength;

        // Calculate the section progress
        if (progress < sectionStart) {
            return 0;
        } else if (progress > sectionEnd) {
            return 1;
        } else {
            return (progress - sectionStart) / segmentLength;
        }
    };


    useEffect(() => {
        const sections = sectionRefs.current;

        gsap.set(sections, {autoAlpha: 0});

        const scrollTrigger = gsap.to(sections, {
            scrollTrigger: {
                trigger: "#after-school",
                start: "top top",
                end: "+=1500",
                scrub: 1, pin: true,
                onUpdate: (self) => {
                    sections.forEach((section, index) => {
                        const activeSectionIndex = getActiveSectionIndex(self.progress);
                        const isActive = index === activeSectionIndex;
                        gsap.to(section, {
                            autoAlpha: !isActive && (getSectionProgress(self.progress, index) < 0.2 || getSectionProgress(self.progress, index) > 0.8) ? 0 : 1,
                            duration: 0.3,
                            pointerEvents: isActive ? "auto" : "none",
                        });
                    });
                },
            }
        });

        return () => {
            scrollTrigger.scrollTrigger.kill();
            gsap.set(sections, {autoAlpha: 0, duration: 0.5});
        };
    }, []);

    return (<div id="after-school" className="relative overflow-hidden min-h-screen max-lg:mb-[100vh]">
        <div
            className="absolute inset-0"
            style={{
                backgroundImage: `url('landing/Starfield.svg')`,
                backgroundRepeat: 'repeat-y',
                backgroundSize: '100% auto',
                backgroundPosition: 'top left',
                opacity: 0.2,
            }}
        />


        <div ref={addToRefs}
             className="section absolute inset-0 flex max-lg:flex-col max-w-6xl top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 gap-20 gap-y-16 items-center justify-center">
            <div data-aos={"fade-up"} className=" text-lg lg:max-w-sm  md:text-[24px] text-start flex items-center">
                After school you could start learning on the job by doing an apprenticeship or go to university to study
                in more detail
            </div>
            <img className=" slow-floating h-[200px]  " src="landing/Teacher.svg"
                 alt=""/>
        </div>

        <div ref={addToRefs}
             className="section absolute inset-0 flex max-lg:flex-col max-w-6xl top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 gap-20 gap-y-16 items-center justify-center">
            <img className=" slow-floating h-[200px]  " src="landing/Programmer.svg"
                 alt=""/>
            <div data-aos={"fade-up"} className=" text-lg lg:max-w-sm md:text-[24px] text-start flex items-center">
                Then you could work for a space company that designs spacecraft
                or uses satellite data
            </div>

        </div>

        <div ref={addToRefs}
             className="section absolute inset-0 flex max-lg:flex-col max-w-6xl top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 gap-20 gap-y-16 items-center justify-center">
            <div data-aos={"fade-up"} className=" text-lg lg:max-w-sm md:text-[24px] text-start flex items-center">
                As a researcher studying the universe, the economics or
                politics of space, or creating new technologies
            </div>
            <img className=" slow-floating h-[200px] " src="landing/Telescope-man.svg"
                 alt=""/>
        </div>

        <div ref={addToRefs}
             className="section absolute inset-0 flex max-lg:flex-col max-w-6xl top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 gap-20 gap-y-16 items-center justify-center">
            <img className=" slow-floating h-[200px]  " src="landing/Briefcase.svg"
                 alt=""/>
            <div data-aos={"fade-up"} className=" lg:max-w-sm text-lg  md:text-[24px] text-start flex items-center">
                Or in government, helping to grow the space sector and support businesses
            </div>

        </div>


    </div>);
};

export default AfterSchoolGSAP;
