import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import axiosInstance from "../../axiosInstance";
import Cookies from "js-cookie";

const FindOutMore = (props) => {
    const [answer, setAnswer] = useState(null);
    const [answered, setAnswered] = useState(false);

    useEffect(() => {
        const isUseful = Cookies.get('spacecareers-visitor-is-useful');
        if (isUseful) {
            setAnswered(true);
        }
    }, []);

    useEffect(() => {
        if (answer !== null) {
            const visitorId = Cookies.get('spacecareers-visitor-id');
            if (visitorId) {
                axiosInstance
                    .put(`/api/survey101/${visitorId}/`, {
                        is_useful: answer,
                    })
                    .then((response) => {
                        setAnswered(true);
                        Cookies.set('spacecareers-visitor-is-useful', answer);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                axiosInstance
                    .post(`/api/survey101/`, {
                        is_useful: answer,
                    })
                    .then((response) => {
                        setAnswered(true);
                        Cookies.set('spacecareers-visitor-is-useful', answer);
                        Cookies.set('spacecareers-visitor-id', response.data.id);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    }, [answer]);

    return (<div  id="more" className=" relative pt-10">
        <div
            className="relative z-0"
        >
            <div className=" flex pt-12 pb-20 flex-col items-center gap-8">
                <div
                    className="text-center leading-none   tracking-tight koulen text-[32px] lg:text-[36px] text-landing-yellow">Did
                    you find this page useful?
                </div>

                <div className="w-full relative">
                    {answered ? (
                        <div className="mx-auto text-center text-[24px]">Thank you for your feedback!</div>
                    ) : (
                        <div className="px-8 flex-wrap flex justify-center w-full gap-8">
                            <button onClick={() => setAnswer(true)}
                                    className=" flex justify-center landing-button py-2.5 text-center rounded-md bg-landing-yellow fredoka-bold text-landing-blue text-2xl px-12">
                                <img className="h-7 " src="landing/smile.svg" alt=""/>
                            </button>
                            <button onClick={() => setAnswer(false)}
                                    className="flex justify-center landing-button py-2.5 text-center rounded-md bg-landing-yellow fredoka-bold text-landing-blue text-2xl px-12">
                                <img className="h-7 " src="landing/frown.svg" alt=""/>
                            </button>
                        </div>
                    )}

                </div>
                <div className="text-[24px] lg:px-16 xl:px-6 px-6 flex flex-col gap-2 max-w-5xl mt-8 text-center">
                    <div className={"fredoka-bold text-[24px] mx-auto text-landing-yellow"}>Poster</div>
                    <p>Click <a className={"underline"} target={"_blank"} href="https://jmp.sh/r8AIWJdu">here</a> to download this page as a poster and <a
                        className={"underline"}
                        href="mailto:hello@spacecareers.uk">contact us</a> if you would like to request a physical A2
                        copy for
                        your organisation.</p>
                    <div className={"fredoka-bold mt-10 text-[24px] mx-auto text-landing-yellow"}>Feedback</div>
                    <p>We would love to hear any feedback you have on this page through <a className={"underline"}
                                                                                           href="https://forms.fillout.com/t/bdMfE2qpGtus">this form</a>.
                    </p>

                </div>

            </div>


            <div className="flex justify-end items-center">
                <img className="h-[200px] object-cover xl:mt-10 my-24 " src="landing/Hills.svg" alt=""/>
            </div>
            <img className="absolute z-0 bottom-0 object-top w-[120vw] overflow-hidden   h-[220px] object-cover "
                 src="landing/Base2.svg" alt=""/>
        </div>

    </div>)
}

export default FindOutMore;