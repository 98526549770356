import { Link, Navigate, useLocation } from "react-router-dom";
import React, { useState } from "react";

import Button from "@mui/material/Button";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axiosInstance from "../axiosInstance";
import { isLoggedIn } from "./functions";
import { useSnackbar } from "notistack";

const LoginPage = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    let location = useLocation();
    const [email, setEmail] = useState(location.state ? location.state.email : "");
    const [password, setPassword] = useState("");
    const [signinSubmitted, setSigninSubmitted] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        axiosInstance
            .post("/api/auth/jwt/create/", {
                email: email,
                password: password,
            })
            .then((response) => {
                axiosInstance.defaults.headers["Authorization"] = "JWT " + response.data.access;
                localStorage.setItem("access_token", response.data.access);
                localStorage.setItem("refresh_token", response.data.refresh);
                // Force a re-render to process the redirect
                setSigninSubmitted(true);
            })
            .catch((error) => {
                enqueueSnackbar("Login failed. Your username or password may be incorrect.", {
                    variant: "error",
                });
            });
    };

    return isLoggedIn() || signinSubmitted ? (
        <Navigate to="/home" />
    ) : (
        <PageContainer maxWidth="sm" sx={{ display: "flex", alignItems: "center" }}>
            <Paper sx={{ width: "100%" }}>
                <Typography variant="h4" paragraph>
                    Sign in
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={1}>
                        <TextField
                            type="email"
                            placeholder="email"
                            fullWidth
                            name="email"
                            variant="outlined"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            required
                            autoFocus
                        />
                        <TextField
                            type="password"
                            placeholder="Password"
                            fullWidth
                            name="password"
                            variant="outlined"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            required
                        />
                        <Button variant="contained" color="primary" type="submit">
                            Submit
                        </Button>
                    </Stack>
                </form>
                <Stack spacing={1} sx={{ paddingTop: "15px" }}>
                    <Link to="/signup" color="primary">
                        Haven't signed up?
                    </Link>
                    <Link to="/reset" color="primary">
                        Forgotten password?
                    </Link>
                </Stack>
            </Paper>
        </PageContainer>
    );
};
export default LoginPage;
