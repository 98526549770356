import Cookies from "js-cookie";
import axios from "axios";
import { removeTokens } from "./auth/functions";

const baseURL = "/";

const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
        Authorization: localStorage.getItem("access_token")
            ? "JWT " + localStorage.getItem("access_token")
            : null,
        "Content-Type": "application/json",
        accept: "application/json",
        "X-CSRFToken": Cookies.get("csrftoken"),
    },
});

export function handleRequestException(error) {
    try {
        if (error.isAxiosError) {
            if (!error.response) {
                console.log("Request timed out");
            } else if (error.response.status === 403) {
                console.log("Request failed. You may have insufficient privileges.d");
            } else if (error.response.data) {
                console.log(error.response.data);
            } else {
                console.log("An unknown error occurred");
            }
        } else {
            throw error;
        }
    } catch (err) {
        console.log("An error occurred while trying to display error message");
    }
}

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        const originalRequest = error.config;

        // If request was cancelled by axios, do nothing
        if (axios.isCancel(error)) {
            return Promise.reject(error);
        }

        // Prevent infinite loops
        if (
            error.response.status === 401 &&
            originalRequest.url === baseURL + "api/auth/jwt/create/"
        ) {
            localStorage.removeItem("refresh_token");
            return Promise.reject(error);
        }

        if (error.response.status === 401 && error.response.data.code === "user_inactive") {
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("access_token");
            return Promise.reject(error);
        }

        if (error.response.data.code === "token_not_valid" && error.response.status === 401) {
            const refreshToken = localStorage.getItem("refresh_token");
            localStorage.removeItem("access_token");

            if (refreshToken) {
                const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));

                // exp date in token is expressed in seconds, while now() returns milliseconds:
                const now = Math.ceil(Date.now() / 1000);

                if (tokenParts.exp > now) {
                    return axiosInstance
                        .post("/api/auth/jwt/refresh/", { refresh: refreshToken })
                        .then((response) => {
                            localStorage.setItem("access_token", response.data.access);
                            localStorage.setItem("refresh_token", response.data.refresh);

                            axiosInstance.defaults.headers["Authorization"] =
                                "JWT " + response.data.access;
                            originalRequest.headers["Authorization"] =
                                "JWT " + response.data.access;

                            return axiosInstance(originalRequest);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    console.log("Refresh token is expired", tokenParts.exp, now);
                    localStorage.removeItem("refresh_token");
                    window.location.href = "/login/";
                }
            } else {
                console.log("Refresh token not available.");
                window.location.href = "/login/";
            }
        } else if (error.response.data.code === "user_not_found" && error.response.status === 401) {
            removeTokens();
        }

        // specific error handling done elsewhere
        return Promise.reject(error);
    }
);

export default axiosInstance;
