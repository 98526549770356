import "survey-core/modern.min.css";
import "survey-core/defaultV2.min.css";

import { useEffect, useMemo, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogAction from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import Typography from "@mui/material/Typography";
import axiosInstance from "./axiosInstance";
import { useNavigate } from "react-router-dom";

const WelcomePromptDialog = ({ open, onClose, user }) => {
    const [page, setPage] = useState(0);
    const [survey, setSurvey] = useState(null);
    let navigate = useNavigate();

    const handleFinish = () => {
        onClose();
        navigate("/profile");
    };

    const pages = useMemo(
        () => [
            <Box>
                <Typography variant="h5" paragraph>
                    Welcome to SpaceCareers.uk. We’re here to help.
                </Typography>
                <Typography paragraph>
                    Before you get started, we have a few questions. These are optional and your
                    answers will not be shown on your profile, and they cannot be linked to
                    personally identifiable information. They will only be used as anonymised
                    aggregated information to help us gauge the audience we have, and hence where we
                    should focus more effort in our goal to make the space sector accessible and
                    inclusive to everyone.
                </Typography>
                <Typography>You can change your answers at any time.</Typography>
                <Survey model={survey} />
            </Box>,
            <Box>
                <Typography paragraph>
                    Now, if you’re looking for a job, set your preferences so you can be found by
                    employers and emailed as soon as potentially suitable jobs become available!
                </Typography>
                <Typography paragraph>
                    This information will be visible to employers, however to ensure fairness and to
                    remove bias, your name and email will not be visible.
                </Typography>
                <Typography paragraph> We wish you all the best in your job hunt!</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                    Bonus: if you’re an early career candidate, become a member of UKSEDS{" "}
                    <a href="https://ukseds.org/join">here</a> for more space-related opportunities!
                </Typography>
            </Box>,
        ],
        [survey]
    );

    useEffect(() => {
        axiosInstance.get("/api/surveys/").then((response) => {
            const surveyId = response.data.results[0]?.id;
            let new_survey = new Model(response.data.results[0]?.survey_details);
            new_survey.onComplete.add((sender) => {
                axiosInstance.post("/api/surveys/responses/", {
                    survey: surveyId,
                    response: sender.data,
                    profile: user.id,
                });
            });
            setSurvey(new_survey);
        });
    }, [user]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                "& .MuiDialog-paper": {
                    width: "700px",
                    minHeight: "600px",
                    maxWidth: "100vw",
                    padding: "24px",
                },
            }}
        >
            <DialogContent sx={{ padding: 0 }}>{pages[page]}</DialogContent>
            <DialogAction>
                <Typography sx={{ marginRight: "auto", marginLeft: "12px" }}>
                    {page + 1} / {pages.length}
                </Typography>
                <Button onClick={() => setPage(page - 1)} disabled={page === 0}>
                    Back
                </Button>
                {page === pages.length - 1 ? (
                    <Button onClick={handleFinish}>Finish</Button>
                ) : (
                    <Button onClick={() => setPage(page + 1)}>Next</Button>
                )}
            </DialogAction>
        </Dialog>
    );
};

export default WelcomePromptDialog;
