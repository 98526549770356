import { Link, Navigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import PrivacyPolicyDialog from "../PrivacyPolicyDialog";
import Stack from "@mui/material/Stack";
import TermsAndConditionsDialog from "../TermsAndConditionsDialog";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axiosInstance from "../axiosInstance";

const SignupPage = () => {
    let params = useParams();
    const [password, setPassword] = useState("");
    const [rePassword, setRePassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});
    const [employer, setEmployer] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [over18, setOver18] = useState(false);
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [signupSuccessful, setSignupSuccessful] = useState(
        localStorage.getItem("access_token") ? localStorage.getItem("access_token") : false
    );
    const [termsOpen, setTermsOpen] = useState(false);
    const [privacyOpen, setPrivacyOpen] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        axiosInstance
            .post("/api/auth/users/", {
                password: password,
                re_password: rePassword,
                first_name: firstName,
                last_name: lastName,
                email: email,
                registration_token: params.token || undefined,
            })
            .then((response) => {
                setSignupSuccessful(true);
            })
            .catch((error) => {
                setErrors(error.response.data);
            });
    };

    useEffect(() => {
        if (params.token)
            axiosInstance
                .get(`/api/employers/from_token/?token=${params.token}`)

                .then((response) => {
                    setEmployer(response.data);
                })
                .catch((error) => {
                    setErrorMessage(error.response.data);
                });
    }, []);

    return signupSuccessful ? (
        <Navigate to="/verify" state={{ email: email }} />
    ) : (
        <PageContainer maxWidth="sm" sx={{ display: "flex", alignItems: "center" }}>
            <Paper sx={{ width: "100%" }}>
                <Accordion id="candidate">
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Stack spacing={2}>
                            <Typography variant="h5">
                                {params.token ? "Employer sign up" : "Candidate sign up"}
                            </Typography>
                            {params.token && employer?.name ? (
                                <React.Fragment>
                                    <Typography>{employer?.name}</Typography>
                                    <img
                                        style={{
                                            maxWidth: "100px",
                                            maxHeight: "100px",
                                        }}
                                        src={employer?.logo}
                                        alt="Employer logo"
                                    />
                                </React.Fragment>
                            ) : null}
                            {params.token && errorMessage ? (
                                <Typography>{errorMessage}</Typography>
                            ) : null}
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <form onSubmit={handleSubmit}>
                            <Stack spacing={1}>
                                <TextField
                                    size="small"
                                    error={errors.email}
                                    helperText={errors.email}
                                    type="email"
                                    placeholder="Email"
                                    fullWidth
                                    name="email"
                                    variant="outlined"
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                    required
                                    autoFocus
                                />
                                <TextField
                                    size="small"
                                    error={errors.first_name}
                                    helperText={errors.first_name}
                                    type="text"
                                    placeholder="First name"
                                    fullWidth
                                    name="firstName"
                                    variant="outlined"
                                    value={firstName}
                                    onChange={(event) => setFirstName(event.target.value)}
                                    required
                                />
                                <TextField
                                    size="small"
                                    error={errors.last_name}
                                    helperText={errors.last_name}
                                    type="text"
                                    placeholder="Last name"
                                    fullWidth
                                    name="lastName"
                                    variant="outlined"
                                    value={lastName}
                                    onChange={(event) => setLastName(event.target.value)}
                                    required
                                />
                                <TextField
                                    size="small"
                                    error={errors.password || errors.non_field_errors}
                                    helperText={errors.password || errors.non_field_errors}
                                    type="password"
                                    placeholder="Password"
                                    fullWidth
                                    name="password"
                                    variant="outlined"
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                    required
                                />
                                <TextField
                                    size="small"
                                    error={errors.re_password || errors.non_field_errors}
                                    helperText={errors.re_password || errors.non_field_errors}
                                    type="password"
                                    placeholder="Retype password"
                                    fullWidth
                                    name="rePassword"
                                    variant="outlined"
                                    value={rePassword}
                                    onChange={(event) => setRePassword(event.target.value)}
                                    required
                                />
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={over18}
                                                onChange={(event) => {
                                                    setOver18(event.target.checked);
                                                }}
                                            />
                                        }
                                        label="I'm over 18"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={termsAgreed}
                                                onChange={(event) => {
                                                    setTermsAgreed(event.target.checked);
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography>
                                                I accept the{" "}
                                                <Typography
                                                    sx={{
                                                        textDecoration: "underline",
                                                        cursor: "pointer",
                                                        display: "inline",
                                                    }}
                                                    onClick={(e) => {
                                                        setTermsOpen(true);
                                                    }}
                                                >
                                                    terms and conditions
                                                </Typography>
                                                {" and "}
                                                <Typography
                                                    sx={{
                                                        textDecoration: "underline",
                                                        cursor: "pointer",
                                                        display: "inline",
                                                    }}
                                                    onClick={(e) => {
                                                        setPrivacyOpen(true);
                                                    }}
                                                >
                                                    privacy policy
                                                </Typography>
                                            </Typography>
                                        }
                                    />
                                </FormGroup>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={errorMessage || !over18 || !termsAgreed}
                                >
                                    Submit
                                </Button>
                            </Stack>
                        </form>
                    </AccordionDetails>
                </Accordion>
                {params.token ? null : (
                    <Accordion id="employer">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h5">Employer sign up</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="h6">
                                Is your employer on SpaceCareers.uk?
                            </Typography>
                            <Typography paragraph>
                                If your employer doesn't appear on SpaceCareers.uk or doesn't have
                                an admin, contact us at advertise@spacecareers.uk to get set up
                            </Typography>
                            <Typography paragraph>
                                If your employer already has an admin, contact them for a
                                registration link
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )}
                <Stack spacing={1} sx={{ paddingTop: "15px" }}>
                    <Link to="/login">Already signed up?</Link>
                    <Link to="/reset" color="primary">
                        Forgotten password?
                    </Link>
                </Stack>
            </Paper>
            <TermsAndConditionsDialog open={termsOpen} onClose={() => setTermsOpen(false)} />
            <PrivacyPolicyDialog open={privacyOpen} onClose={() => setPrivacyOpen(false)} />
        </PageContainer>
    );
};
export default SignupPage;
