import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const LikeSpace = () => {
    const likeSpaceRef = useRef(null);

    useEffect(() => {
        // Select all sections that need to be animated
        const sections = gsap.utils.toArray(".like-space-section");

        sections.forEach((section) => {
            gsap.fromTo(
                section,
                {
                    opacity: 0,   // Start at 0 opacity
                    y: 0         // Slight movement down
                },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1,
                    scrollTrigger: {
                        trigger: section,
                        start: "top 85%",
                        end: "top 30%",
                        scrub: true,
                    }
                }
            );
        });
    }, []);

    return (
        <div className="overflow-x-hidden">
            <div
                id="space"
                className="relative  px-8 mx-auto max-w-5xl flex flex-col lg:flex-row justify-center gap-12  min-h-screen py-[15vh]"
            >
                <img
                    src="landing/Saturn.svg"
                    alt=""
                    className="lg:absolute lg:top-20 lg:-right-32 h-[130px] floating object-contain"
                />
                <div
                    id="likeSpace"
                    ref={likeSpaceRef}
                    className="flex lg:w-1/2 justify-center flex-col gap-6 max-lg:text-[28px] text-[40px] fredoka-bold"
                >
                    <div className="like-space-section">
                        <h1 className="md:text-start">Like space?</h1>
                    </div>
                    <div className="like-space-section">
                        <h2 className="text-start">Want interesting work with meaning?</h2>
                    </div>
                    <div className="like-space-section">
                        <p className="text-start">
                            Whatever your background, the space sector needs you!
                        </p>
                    </div>
                </div>
                <div className="lg:w-1/3 flex flex-col justify-center gap-6">
                    <div className="like-space-section text-[24px]">
                        The space sector is growing fast in the UK and around the world as
                        the demand for space-derived data and services increases and new
                        technologies enable bigger exploration missions.
                    </div>
                    <div className="like-space-section text-[24px] text-landing-yellow fredoka-bold">
                        With hundreds of jobs available across the UK, you can find your
                        place in space.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LikeSpace;
